import React, {Component} from "react";
import {numberMask} from "../../tools/mask";
import {getFormData} from "../../tools/form-helper";
import FormField from "../common/form/FormField";
import CheckBox from "../common/form/CheckBox";
import Form from "../common/form/Form";
import PropTypes from "prop-types";

export default class CompanyForm extends Component{

    constructor(props){
        super(props);
        var company = props.data || {};

        var isFactAddressEqualsLegalAddress = (company.ZipLegal == company.ZipPost &&
        company.CityLegal == company.CityPost &&
        company.AddressLegal == company.AddressPost);

        this.state = {
            fields: {
                TIN: {
                    type: 'text',
                    label: "ИНН",
                    value: company.TIN || "",
                    error: "",
                    mask: numberMask(12)
                },
                KPP: {
                    type: 'text',
                    label: "КПП",
                    value: company.KPP || "",
                    error: "",
                    mask: numberMask(9)
                },
                FullName: {
                    type: 'textarea',
                    label: "Полное наименование",
                    value: company.FullName || "",
                    error: ""
                },
                ShortName: {
                    type: 'text',
                    label: "Сокращенное наименование",
                    value: company.ShortName || "",
                    error: ""
                },
                ZipLegal: {
                    type: 'text',
                    label: "Индекс",
                    value: company.ZipLegal || "",
                    error: "",
                    mask: numberMask(6)
                },
                CityLegal: {
                    type: 'text',
                    label: "Город",
                    value: company.CityLegal || "",
                    error: ""
                },
                AddressLegal: {
                    type: 'textarea',
                    label: "Адрес",
                    value: company.AddressLegal || "",
                    error: ""
                },
                ZipPost: {
                    type: 'text',
                    label: "Индекс",
                    value: company.ZipPost || "",
                    error: "",
                    mask: numberMask(6),
                    disabled: isFactAddressEqualsLegalAddress
                },
                CityPost: {
                    type: 'text',
                    label: "Город",
                    value: company.CityPost || "",
                    error: "",
                    disabled: isFactAddressEqualsLegalAddress
                },
                AddressPost: {
                    type: 'textarea',
                    label: "Адрес",
                    value: company.AddressPost || "",
                    error: "",
                    disabled: isFactAddressEqualsLegalAddress
                },
                FactAddressEqualsLegalAddress: {
                    type: 'checkbox',
                    label: 'Совпадает с юридическим',
                    value: isFactAddressEqualsLegalAddress,
                    id: 'FactAddressEqualsLegalAddress'
                }
            },
            isValid: false
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.data != nextProps.data) {
            var company = nextProps.data || {};
            var fields = {...this.state.fields};

            var isFactAddressEqualsLegalAddress = (company.ZipLegal == company.ZipPost &&
            company.CityLegal == company.CityPost &&
            company.AddressLegal == company.AddressPost);

            for (var attr in company) {
                fields[attr].value = company[attr];
                if (['CityPost','AddressPost','FactAddressEqualsLegalAddress'].indexOf(attr) != -1) {
                    fields[attr].disabled = isFactAddressEqualsLegalAddress;
                }
            }

            this.setState({fields});
        }
    }

    onChangeField = (attribute, value) => {
        var fields = {...this.state.fields};
        fields[attribute].value = value;

        if (attribute == 'FactAddressEqualsLegalAddress') {
            fields.ZipPost.disabled = value;
            if (value) fields.ZipPost.value = fields.ZipLegal.value;
            fields.CityPost.disabled = value;
            if (value) fields.CityPost.value = fields.CityLegal.value;
            fields.AddressPost.disabled = value;
            if (value) fields.AddressPost.value = fields.AddressLegal.value;
        }

        if (attribute == 'ZipLegal' && fields.FactAddressEqualsLegalAddress.value) {
            fields.ZipPost.value = value;
        }

        if (attribute == 'CityLegal' && fields.FactAddressEqualsLegalAddress.value) {
            fields.CityPost.value = value;
        }

        if (attribute == 'AddressLegal' && fields.FactAddressEqualsLegalAddress.value) {
            fields.AddressPost.value = value;
        }

        this.setState({fields, isValid: this.isChanged(fields)});
    }

    renderField = (attr) => {
        var fields = {...this.state.fields};
        if (fields.hasOwnProperty(attr)) {
            return <FormField attribute={attr} fieldConfig={{...fields[attr], label: this.context.translate(fields[attr].label)}} onChange={this.onChangeField}/>
        }
    }

    onSubmit = () => {
        var fields = {...this.state.fields};
        delete fields['FactAddressEqualsLegalAddress'];
        var body = getFormData(fields);

        this.props.onSubmit(body);
    }

    isChanged = (fields) => {
        var data = this.props.data;

        for (var attr in fields) {
            if (attr == 'FactAddressEqualsLegalAddress') continue;
            if (fields[attr].value != data[attr]) {
                return true;
            }
        }
        return false;
    }

    render(){

        return <Form validate={true} onSubmit={this.onSubmit}>
            <h2 className="company-info__title">{this.context.translate('Информация о компании')}</h2>
            <div className="company-info__row">
                <div className="company-info__input company-info__input_type_inn">
                    {this.renderField('TIN')}
                </div>
                <div className="company-info__input company-info__input_type_kpp">
                    {this.renderField('KPP')}
                </div>
                <div className="company-info__input company-info__input_type_full-name">
                    {this.renderField('FullName')}
                </div>
                <div className="company-info__input company-info__input_type_mini-name">
                    {this.renderField('ShortName')}
                </div>
            </div>
            <h2 className="company-info__title">{this.context.translate('Юридический адрес')}</h2>
            <div className="company-info__row">
                <div className="company-info__input company-info__input_type_index">
                    {this.renderField('ZipLegal')}
                </div>
                <div className="company-info__input company-info__input_type_city">
                    {this.renderField('CityLegal')}
                </div>
                <div className="company-info__input company-info__input_type_address">
                    {this.renderField('AddressLegal')}
                </div>
            </div>
            <h2 className="company-info__title">{this.context.translate('Почтовый адрес')}</h2>
            <div className="company-info__row">
                <div className="company-info__input company-info__input_type_checkbox">
                    {this.renderField('FactAddressEqualsLegalAddress')}
                </div>

                <div className="company-info__input company-info__input_type_index">
                    {this.renderField('ZipPost')}
                </div>
                <div className="company-info__input company-info__input_type_city">
                    {this.renderField('CityPost')}
                </div>
                <div className="company-info__input company-info__input_type_address">
                    {this.renderField('AddressPost')}
                </div>
            </div>
            <div className="company-info__u-block">
                <button className={"u-btn u-btn_adapt-type_wide"+(this.state.isValid ? "" : " disabled")}>{this.context.translate('Отправить')}</button>
            </div>
        </Form>
    }
}
