import React, {Component, Fragment} from 'react'
import {Link} from "react-router-dom";
import OrdersListItem from "../orders/OrdersListItem";
import PropTypes from "prop-types";
import TogglePanel from "../common/TogglePanel";
import {OrderActions, OrderStatus} from "../orders/OrderHelpers";
import DocumentLoader from "../common/DocumentLoader";
import {getInsuranceCommunications} from "../../actions/insurance";

export default class InsuranceWidget extends Component {

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render() {

        const {items, goToForm, addFile, dropFile, updateFileName, addMessage, fileBuffer, clearBuffer, getInsuranceCommunications} = this.props;
        const {data, pagination} = items;

        return <div className="main-page__section main-page__section_banner">
            <div className={"main-banner_adapt-default"}>
                <div className="main-banner__header insurance-banner__header">
                    <p className="main-banner__header-title">{this.context.translate('Произошло страховое событие?')}</p>
                    <button className="btn btn_red" onClick={goToForm}>{this.context.translate('Сообщить о страховом событии')}</button>
                </div>
                {(data.length && pagination.totalCount) ? <TogglePanel className="search"
                             labelClassName="toggle__head"
                             contentClassName="toggle__main"
                             label={<span>{this.context.translate("Текущие страховые события")}<sup>({pagination.totalCount})</sup></span>}
                             slide={""}
                             key="search-toggle"
                >
                    {data.map((item) => <DocumentItem
                        key={item.CommunicationId}
                        {...item}
                        addFile={addFile}
                        dropFile={dropFile}
                        updateFileName={updateFileName}
                        addMessage={addMessage}
                        fileBuffer={fileBuffer}
                        clearBuffer={clearBuffer}
                    />)}
                    {data.length < pagination.totalCount ? <Link className={'pagination-show-more'} to={'#'} onClick={(e) => {
                        e.preventDefault();
                        getInsuranceCommunications(parseInt(pagination.currentPage) + 1)
                    }}>Показать еще</Link> : null}
                </TogglePanel> : null}
            </div>
        </div>
    }
}

class DocumentItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            uploadOpen: false
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    toggleUpload = (e) => {
        if (e) {
            e.preventDefault();
        }
        this.setState((prevState) => ({uploadOpen: !prevState.uploadOpen}))
    }

    addDocument = (file) => {
        this.props.addFile(this.props.CommunicationId, file);
    }

    removeDocument = (fileId) => {
        this.props.dropFile(this.props.CommunicationId, fileId);
    }

    updateDocument = (fileId, fileName) => {
        this.props.updateFileName(this.props.CommunicationId, fileId, fileName);
    }

    closeWidget = () => {

        const {addMessage, CommunicationId, clearBuffer} = this.props;

        this.toggleUpload();
        addMessage(CommunicationId);
        clearBuffer();
    }

    render() {

        const {CommunicationId, ContractId, ContractName, ContractNumber, ContractDate, fileBuffer} = this.props;
        const {uploadOpen} = this.state;

        return <div className={"applications__item"}>
            <div>
                <div className="applications__item-title">
                    Договор {ContractId ? <Fragment><Link to={`/projects/dfa/${ContractId}`}>№{ContractNumber}</Link> «{ContractName}» {ContractDate ? `от ${ContractDate}` : ''}</Fragment> : null}
                </div>
                <div className="applications__item-actions">
                    <Link className={"applications__action applications__action_add"} to={{pathname: `/communications/${CommunicationId}`, state: 'upload'}}>{this.context.translate('Добавить файлы')}</Link>
                    <Link className={"applications__action applications__action_project"} to={`/communications/${CommunicationId}`}>{this.context.translate('Перейти в обращение')}</Link>
                </div>

                {uploadOpen ? <DocumentLoader files={fileBuffer}
                                              accept={'.pdf, .jpg, .jpeg, .png'}
                                              addDocument={this.addDocument}
                                              removeDocument={this.removeDocument}
                                              updateDocument={this.updateDocument}
                                              // cancelDocument={cancelApplicationDocument}
                                              close={this.closeWidget}
                />: null}
            </div>
        </div>
    }
}