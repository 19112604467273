import React, {Component} from "react";
import PropTypes from "prop-types";

import {Link} from "react-router-dom";

export default class DocumentsList extends Component{

    constructor(props){
        super(props);

        this.labels = [
            "Номер договора",
            "Дата подписания",
            "Статус",
            "Предмет лизинга",
            "Стоимость",
            "Лизингополучатель",
            "График платежей"
        ];

        var rows = [];

        props.documents && props.documents.map((doc) => {
            rows.push([
                <div className={"u-round-icon u-round-icon_"+doc.ContractSeverityStyle}>
                    <Link className="u-link" to={{pathname: "/projects/dfa/"+doc.ContractId, state: {activeTab: props.activeTab}}}><nobr>{doc.ContractNumber}</nobr></Link>
                </div>,
                doc.SignDate,
                doc.ContractStatusName,
                doc.EquipmentName,
                <nobr>{doc.Price}</nobr>,
                doc.LesseeName,
                doc.HasValidSchedule ? <a className="u-big-icon u-big-icon_xls"  href="#" onClick={this.download(doc.ContractId)}/> : <span>&mdash;</span>
            ])
        })

        this.state = {
            rows
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.documents != nextProps.documents) {
            var rows = [];

            nextProps.documents && nextProps.documents.map((doc) => {
                rows.push([
                    <div className={"u-round-icon u-round-icon_"+doc.ContractSeverityStyle}>
                        <Link className="u-link" to={{pathname: "/projects/dfa/"+doc.ContractId, state: {activeTab: nextProps.activeTab}}}><nobr>{doc.ContractNumber}</nobr></Link>
                    </div>,
                    doc.SignDate,
                    doc.ContractStatusName,
                    doc.EquipmentName,
                    <nobr>{doc.Price}</nobr>,
                    doc.LesseeName,
                    doc.HasValidSchedule ? <a className="u-big-icon u-big-icon_xls"  href="#" onClick={this.download(doc.ContractId)}/> : null
                ])
            })

            this.setState({rows});
        }
    }

    download = (id) => {
        return (e) => {
            e.preventDefault();
            this.props.downloadContractSchedule(id);
        }
    }

    getCellClass = (cell) => {
        switch (cell) {
            case 4:
                return " default-table__th_align_right";
            case 5:
                return this.props.lesseeCount < 2 ? " hidden" : "";
            case 6:
                return " u-mobile-hide";
            default:
                return "";
        }
    }

    render(){
        return <div className="projects-list__dfa-table">
            <div className="default-table default-table_projects-dfa">
                <div className="default-table__head">
                    <div className="default-table__row">
                        {this.labels.map(
                            (item, key) => <div key={key} className={"default-table__th"+this.getCellClass(key)}>
                                {this.context.translate(item)}</div>)}
                    </div>
                </div>
                <div className="default-table__body">
                    {this.state.rows.map((row, rowKey) => <div className="default-table__row" key={rowKey}>
                        {row.map((col, colKey) =>
                            <div key={colKey} className={"default-table__td"+this.getCellClass(colKey)}>
                                <div className="default-table__inner-label">{this.context.translate(this.labels[colKey])}</div>
                                <div className="default-table__value">{col}</div>
                            </div>
                        )}
                    </div>)}
                </div>
            </div>
        </div>
    }
}