const initState = {isOk: false};

export function help(state = initState, action) {
    switch (action.type) {
        case 'FAQ_SUCCESS':
            return {data: action.data.map((item) => {
                var title = {};
                item.title.map((arr) => {
                    title[arr.lang] = arr.translation;
                });
                var questions = item.questions.map((arr) => {
                    var question = {};
                    arr.question.map((tmp) => {
                        question[tmp.lang] = tmp.translation;
                    });
                    var answer = {};
                    arr.answer.map((tmp) => {
                        answer[tmp.lang] = tmp.translation;
                    });
                    return {...arr, question, answer};
                });
                return {
                    ...item,
                    title,
                    questions
                }
            }), isOk: true};
    }
    return state;
}