import React, {Component, Fragment} from "react";
import FormField from "../common/form/FormField";
import Radio from "../common/form/Radio";
import {Steps} from "../common/Steps";
import {Link} from "react-router-dom";
import FileIcon from "../common/FileIcon";
import Select from "../common/form/Select";

export default class InsuranceSteps extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: 1
        }

        this.tabs = [{label: "Транспорт и спецтехника", value: 1},{label: "Оборудование", value: 2},{label: "Перевозка грузов", value: 3}];
        const {documentsInsuredEvent} = props.documents;
        this.tabSteps = [
            [
                {
                    title: "Незамедлительно обратиться в компетентные органы для регистрации происшествия",
                    content: <Fragment>
                        <ul>
                            <li>При ДТП - ГИБДД</li>
                            <li>При противоправных действиях третьих лиц - районное отделение полиции</li>
                            <li>При пожаре – МЧС</li>
                            <li>При повреждении ТС в результате стихийных бедствий - территориальное подразделение гидрометеослужбы</li>
                        </ul>
                        <p>Если повреждение ТС (СТ) произошло ВНЕ ДОРОГ ОБЩЕГО ПОЛЬЗОВАНИЯ, то на место аварии необходимо вызвать полицию и зафиксировать повреждения.</p>
                        <p className={'alert alert-info'}>Вы можете пропустить этот шаг, если возможность получить страховое возмещение без предоставления документов из компетентных органов указана в пункте договора страхования «Права и обязанности сторон».</p>
                    </Fragment>
                },
                {
                    title: "В течение 1 рабочего дня сообщить Сименс Финанс о происшествии",
                    content: <p>Чтобы сообщить о страховом событии, заполните <Link to={'#'} onClick={props.goToForm}>короткую форму</Link>.</p>
                },
                {
                    title: "Предоставить предмет лизинга к осмотру представителю Страховщика",
                    content: <p>Представитель Страховщика имеет право осмотреть предмет лизинга в течение 10 дней после получения вашего уведомления о произошедшем событии.</p>
                },
                {
                    title: "Собрать документы по списку и предоставить их Сименс Финанс",
                    content: <p>Страховая компания может сделать дополнительный запрос документов, если потребуются дополнительные сведения, относящиеся к страховому случаю.</p>,
                    links:
                        documentsInsuredEvent ? <p><a className={'link-with-icon'} href={'#'} onClick={this.download(documentsInsuredEvent.id)}><FileIcon ext={documentsInsuredEvent.file_ext}/>&nbsp;Скачать список документов для ТС и СТ</a></p> : null
                },
                {
                    title: "Получить возмещение убытка или произвести ремонт транспортного средства",
                    content: <Fragment>
                        <p><b>Средний срок принятия решения о выплате страхового возмещения - 20 рабочих дней</b> со дня получения всех необходимых документов. Выплата осуществляется в течение 10 рабочих дней после принятия решения о выплате. Точные сроки указаны в договоре страхования.</p>
                        <p><b>Приоритетной формой возмещения является выдача направления на ремонт на СТО дилера</b></p>
                        <br/>
                        <p>Возможны другие варианты:</p>
                        <ul>
                            <li>выплата на расчетный счет Страхователя/Лизингополучателя по документам дилера, определяющим размер ущерба</li>
                            <li>выдача направления на ремонт на СТО Страховщика или СТО по выбору Страхователя</li>
                        </ul>
                        <br/>
                        <p>В отношении транспорта старше 5 лет:</p>
                        <p>выплата на основании рассчитанного с учетом износа ТС заключения оценщика, привлеченного Страхователем или Лизингополучателем. Исключение - остекление салона, фары, фонари</p>
                    </Fragment>
                },
                {
                    title: "Уведомить об обнаруженных в процессе ремонта скрытых повреждениях",
                    content: <p>При обнаружении скрытых повреждений необходимо уведомить об этом Сименс Финанс и Страховщика, а также предоставить ТС на повторный осмотр Страховщику.</p>
                },
            ],
            [
                {
                    title: "Принять меры к спасанию застрахованного имущества и уменьшению убытка",
                    content: <p>если это представляется возможным.</p>
                },
                {
                    title: "Не менять картину произошедшего до момента осмотра объекта страхования Страховщиком",
                    content: <p>или до согласования со Страховщиком возможности изменения первоначальной картины произошедшего.</p>
                },
                {
                    title: "Обеспечить  Страховщику  право  требования  к  виновной  стороне",
                    content: <p>Не признавать себя ответственным за ущерб, не выдавать расписок, гарантийных писем и иным образом не освобождать от ответственности потенциально виновное лицо до момента официальной регистрации события компетентным органом, либо до момента согласования данных действий со Страховщиком.</p>
                },
                {
                    title: "Незамедлительно обратиться в компетентные органы",
                    content: <ul>
                        <li>При пожаре – в МЧС</li>
                        <li>При ударе молнии и стихийном бедствии  -  в территориальное подразделение Гидрометеослужбы</li>
                        <li>При взрыве – в МЧС или аварийно-техническую службу</li>
                        <li>В результате воздействия электрического тока  -  в энергетическую компанию, обслуживающую объект</li>
                        <li>При повреждении водой  -  в обсуживающую сети организацию либо к владельцу помещения</li>
                        <li>При противоправных действиях третьих лиц, а также бой витрин, зеркал, оконных стекол – вп олицию</li>
                        <li>Наезд транспортных  средств  или  самодвижущихся  машин,  падение  на застрахованное имущество инородных предметов – вполицию/ГИБДД</li>
                    </ul>
                },
                {
                    title: "В течение 1 рабочего дня сообщить Сименс Финанс о происшествии",
                    content: <p>Чтобы сообщить о страховом событии, заполните <Link to={'#'} onClick={props.goToForm}>короткую форму</Link>.</p>
                },
                {
                    title: "Предоставить имущество к осмотру представителю Страховщика",
                    content: <p>Представитель Страховщика имеет право осмотреть предмет лизинга в течение 10 дней после получения вашего уведомления о произошедшем событии.</p>
                },
                {
                    title: "Собрать документы по списку и предоставить их Сименс Финанс",
                    content: <Fragment>
                        <p>Страховая компания может сделать дополнительный запрос документов, если потребуются дополнительные сведения, относящиеся к страховому случаю.</p>
                        {/*<p><a href={'#'}><FileIcon ext={'pdf'}/>&nbsp;Скачать список документов для оборудования</a></p>*/}
                    </Fragment>
                },
                {
                    title: "Получить возмещение убытка или произвести ремонт",
                    content: <Fragment>
                        <p>Размер ущерба определяется:</p>
                        <p>Для оборудования, находящегося на гарантийном обслуживании - на основании документов поставщика, выполняющего ремонт, или организации, поддерживающей гарантию производителя.</p>
                        <p>В остальных случаях – заключения оценщика.</p>
                        <p><b>Средний срок принятия решения о выплате страхового возмещения  -  10 рабочих дней</b> со дня получения всех необходимых документов. Выплата осуществляется втечение  5  рабочих дней после принятия решения о выплате. Точные сроки указаны в договоре страхования.</p>
                    </Fragment>
                },
                {
                    title: "Уведомить об обнаруженных в процессе ремонта скрытых повреждениях",
                    content: <p>Если во время восстановительного ремонта обнаружились скрытые повреждения, изменился первоначальный перечень и стоимость работ, то необходимо незамедлительно об этом сообщить в страховую компанию и получить от неё ответ о её согласии или несогласии, либо овозможности проведения/продолжения ремонта и восстановления поврежденного имущества.</p>
                }
            ],
            [
                {
                    title: "Принять разумные и доступные меры по уменьшению ущерба",
                    content: null
                },
                {
                    title: "Принять меры по установлению лица, ответственного за причиненный ущерб",
                    content: null
                },
                {
                    title: "Совместно с представителем перевозчика составить акт по результатам приемки и осмотра груза",
                    content: <p>Акт должен содержать дату, место составления, наименование груза и описание повреждений.</p>
                },
                {
                    title: "В течение 2 рабочих дней сообщить Сименс Финанс о происшествии",
                    content: <Fragment>
                        <p>Чтобы сообщить о страховом событии заполните <Link to={'#'} onClick={props.goToForm}>короткую форму</Link>.</p>
                        <p>Нам важно знать:</p>
                        <ul>
                            <li>наименование застрахованного груза с указанием вида причиненного ущерба</li>
                            <li>предполагаемый размер ущерба</li>
                            <li>обстоятельства события: место, дата и время, предполагаемая причина</li>
                            <li>перечень мер, принятых для сохранения застрахованного имущества</li>
                            <li>информацию о лице, на которое возлагается ответственность за причиненный ущерб</li>
                        </ul>
                    </Fragment>
                },
                {
                    title: "Обеспечить Страховщику возможность проведения осмотра поврежденного имущества",
                    content: <p>Представитель Страховщика имеет право осмотреть предмет лизинга в течение  10  дней после получения вашего уведомления о произошедшем событии.</p>
                },
                {
                    title: "Собрать документы по списку и предоставить их Сименс Финанс",
                    content: <Fragment>
                        <p>Страховая компания может сделать дополнительный запрос документов, если потребуются дополнительные сведения, относящиеся к страховому случаю.</p>
                        {/*<p><a href={'#'}><FileIcon ext={'pdf'}/>&nbsp;Скачать список документов для грузов</a></p>*/}
                    </Fragment>
                },
            ]
        ];
        this.selfSteps = [
            {
                title: "Обратиться в свою страховую компанию",
                content: <p>Свяжитесь со страховым агентом и сообщите ему всю необходимую информацию.</p>
            },
            {
                title: "В течение 1 рабочего дня сообщить Сименс Финанс о происшествии",
                content: <p>Чтобы сообщить о страховом событии, заполните <Link to={'#'} onClick={props.goToForm}>короткую форму</Link>.</p>
            },
        ];
    }

    download = (id) => {
        return (e) => {
            if (e) {
                e.preventDefault();
                this.props.downloadInsuranceDoc(id)
            }
        }
    }

    setActiveTab = (attr, val) => {
        this.setState({[attr]: val})
    }

    render() {

        const {activeTab} = this.state;
        const {self, documents} = this.props;

        const {equipmentRules, specialEquipmentRules, transportRules} = documents;

        return <div>
            <h3 className='insurance__subtitle'>Что делать при страховом событии?</h3>
            {self ? <Fragment>
                <Steps items={this.selfSteps} disableCollapse/>
                <h3 className='insurance__subtitle'>Риски, подлежащие страхованию:</h3>
                <div className='insurance__content'>
                    <p className='insurance__risks'>
                        <span>пожар</span>
                        <span>взрыв</span>
                        <span>стихийные бедствия</span>
                        <span>падение на застрахованное имущество предметов</span>
                        <span>противоправные действия третьих лиц</span>
                        <span>хищение или угон</span>
                    </p>
                    <p>В случае использования грузового ТС за пределами дорог общего пользования, дополнительно страхуется риск «авария».</p>
                    <p>Дополнительно установленное оборудование, не установленное в ТС заводом-изготовителем должно быть застраховано по риску «Ущерб по дополнительному оборудованию».</p>
                    {equipmentRules || specialEquipmentRules || transportRules ? <p className='insurance__docs'>
                        {transportRules ? <a href={'#'} onClick={this.download(transportRules.id)}><FileIcon ext={transportRules.file_ext}/><span>Скачать правила страхования Транспорта</span></a> : null}
                        {specialEquipmentRules ? <a href={'#'} onClick={this.download(specialEquipmentRules.id)}><FileIcon ext={specialEquipmentRules.file_ext}/><span>Скачать правила страхования Спецтехники</span></a> : null}
                        {equipmentRules ? <a href={'#'} onClick={this.download(equipmentRules.id)}><FileIcon ext={equipmentRules.file_ext}/><span>Скачать правила страхования Оборудования</span></a> : null}
                    </p> : null}
                </div>
                <h3 className='insurance__subtitle'>Требования к условиям Договора самостоятельного страхования</h3>
                <div className='insurance__content'>
                    <ul>
                        <li>Собственник – ООО «ЛК Сименс Финанс», в полисе должен быть указан номер договора финансовой аренды</li>
                        <li>
                            Выгодоприобретатель ООО «ЛК Сименс Финанс»:
                            <ul>
                                <li>Транспорт и спецтехника: при полной гибели или краже</li>
                                <li>Оборудование: при сумме ущерба более 250 тысяч рублей, полной гибели или краже</li>
                            </ul>
                        </li>
                        <li>Оплата страховой премии – единовременно (1 раз в год, без рассрочки)</li>
                        <li>Страховая сумма на первый год страхования должна быть не ниже стоимости предмета лизинга по договору купли-продажи</li>
                        <li>Страховая сумма в последующие годы страхования должна определяться с учетом износа по нормам, согласованным с Лизингодателем</li>
                    </ul>
                </div>
                <h3 className='insurance__subtitle'>Способ получения страхового возмещения - ремонт на СТО или в специализированной организации</h3>
                <div className='insurance__content'>
                    <p>Допускается получение страхового возмещения на расчетный счет Лизингополучателя только в случае, если ремонт был осуществлен Лизингополучателем за свой счет до получения страховой выплаты.</p>
                </div>
            </Fragment> : <Fragment>
                <div className='hidden-xs'>
                    <Radio
                        value={activeTab}
                        options={this.tabs}
                        attribute={'activeTab'}
                        onChange={this.setActiveTab}
                    />
                </div>
                <div className='visible-xs'>
                    <div className="form__field ">
                        <label>Предмет лизинга</label>
                        <div className="form__select">
                            <Select
                                value={activeTab}
                                options={this.tabs}
                                attribute={'activeTab'}
                                onChange={this.setActiveTab}
                                allowEmpty={false}
                            />
                        </div>
                    </div>
                </div>
                <Steps items={this.tabSteps[activeTab - 1]}/>
            </Fragment>}
        </div>
    }
}