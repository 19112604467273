import {
    call,
    put,
    select,
    take
} from 'redux-saga/effects';

import {
    fetchApi,
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import {checkToken} from "./auth";

const company = state => state.company;

/**
 *
 * @returns {{type: string}}
 */
export function newApplication(id) {
    return {
        type: 'NEW_APPLICATION',
        id
    }
}

/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* fetchNewApplication(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/applications/"+action.id, {
                method: 'POST',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

/**
 *
 * @param applicationId
 * @param fieldName
 * @param fieldValue
 * @returns {{type: string, applicationId: *, fieldName: *, fieldValue: *}}
 */
export function saveField(applicationId, fieldName, fieldValue) {
    return {
        type: 'SAVE_FIELD',
        applicationId,
        fieldName,
        fieldValue
    }
}

/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* fetchSaveField(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/applications"+(action.applicationId ? "/"+action.applicationId : "")+"?action=edit", {
                method: 'PUT',
                body: action.fieldName+'='+action.fieldValue,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status === 200) yield put(checkErrors(action.type, action, status));
            else yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

/**
 *
 * @param applicationId
 * @param fields
 * @param isCompany
 * @returns {{type: string, applicationId: int, fields, isCompany}}
 */
export function saveFields(applicationId, fields, isCompany = false) {
    return {
        type: 'SAVE_FIELDS',
        applicationId,
        fields,
        isCompany
    }
}

/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* fetchSaveFields(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');



        var body = [];

        for (var attr in action.fields) {
            body.push(attr+"="+action.fields[attr]);
        }

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/applications"+(action.applicationId ? "/"+action.applicationId : "")+"?action=edit", {
                method: 'PUT',
                body: body.join('&'),
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status === 200) {
                yield put(checkErrors(action.type, action, status));
                if (action.isCompany) {
                    yield put({type: "SAVE_COMPANY_SUCCESS"});
                } else {
                    yield put({type: "UPDATE_APPLICATION_SUCCESS"});
                }
            }
            else yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

/**
 *
 * @param applicationId
 * @returns {{type: string, applicationId: *}}
 */
export function sendApplication(applicationId) {
    return {
        type: 'SEND_APPLICATION',
        applicationId
    }
}

/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* fetchSendApplication(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/applications/"+action.applicationId+"?action=send", {
                method: 'PUT',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else yield put(checkErrors(action.type, data, status));

    } catch(e) {
        alert(e);
    }
}

export function viewApplication(id) {
    return {
        type: 'DETAIL_APPLICATION',
        id
    }
}


/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* fetchViewApplication(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/applications/"+action.id+"?action=form-values", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function applicationFormInfo(id) {
    return {
        type: 'FORM_INFO_APPLICATION',
        id
    }
}


/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* fetchApplicationFormInfo(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/applications"+(action.id ? "/"+action.id : "")+"?action=form-info", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function findCompany(inn) {
    return {
        type: 'FIND_COMPANY',
        inn
    }
}

export function* fetchFindCompany(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/applications/companies?inn="+action.inn, {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function clearApplicationForm() {
    return {
        type: "CLEAR_APPLICATION_FORM"
    }
}

export function clearCompanyForm() {
    return {
        type: "CLEAR_COMPANY_FORM"
    }
}

export function setField(attribute, value) {
    return {
        type: "SET_FIELD",
        attribute,
        value
    }
}