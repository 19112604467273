import React, {Component} from "react";
import Message from "./Message";

export default class MessagesList extends Component{

    constructor(props){
        super(props);

        this.state = {
            hidden: true
        }
    }

    toggleMessages = (e) => {
        e.preventDefault();
        this.setState((prevState) => {
            return {
                hidden: !prevState.hidden
            }
        })
    }

    render(){
        var {
            messages,
            downloadFile
        } = this.props;

        var hidden = this.state.hidden;

        var len = messages ? messages.length : 0;

        return <div className="page__request-messages">
            {len ? (len < 8 ? <div key="messages-full-list" className="request-messages request-messages_adapt-default">
                {messages.map((message) => <Message {...message} key={message.MessageId} downloadFile={downloadFile}/>)}
            </div> : <div key="messages-list" className="request-messages request-messages_adapt-default">
                {hidden ? <Message {...messages[0]} downloadFile={downloadFile} key={messages[0].MessageId}/> :
                    messages.slice(0, len - 3).map((message) => <Message {...message} key={message.MessageId} downloadFile={downloadFile}/>)}
                <p className="request-messages__prev-messages">
                    <a className="request-messages__prev-messages-link" href="#" onClick={this.toggleMessages}>
                        {hidden ? "Показать предыдущие сообщения ("+(len - 4)+")" : "Скрыть сообщения"}
                    </a>
                </p>
                <Message {...messages[len - 3]} key={messages[len - 3].MessageId} downloadFile={downloadFile}/>
                <Message {...messages[len - 2]} key={messages[len - 2].MessageId} downloadFile={downloadFile}/>
                <Message {...messages[len - 1]} key={messages[len - 1].MessageId} downloadFile={downloadFile}/>
            </div>) : null}
        </div>
    }
}