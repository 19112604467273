import React, { Component } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { connect } from 'react-redux';
import { withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
import createHistory from 'history/createBrowserHistory';
import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';

import {rootSaga} from '../actions/actions';
import rootReducer from '../reducers/rootReducer';
import {getScreenSize, isSupportUser} from "../tools/tools";

export const history = isSupportUser ? createHistory({basename: "/users/frontend"}) : createHistory();
const middleware = routerMiddleware(history);

let initState = {};

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, initState, applyMiddleware(middleware, sagaMiddleware));

sagaMiddleware.run(rootSaga);

import Loadable from 'react-loadable';

const LoadableLoginComponent = Loadable({
    loader: () => import('./Login'),
    loading: function () {
        return <div/>;
    }
});
const LoadableCabinetComponent = Loadable({
    loader: () => import('./Router'),
    loading: function () {
        return <div />;
    }
});

class App extends Component {

    constructor(props){
        super(props);
        this.state = {};
    }

    componentDidMount = () => {
        var target = document.getElementById('modal');
        var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                if (mutation.type == 'childList') {
                    let scrollWidth = window.innerWidth - document.body.clientWidth;
                    if (document.getElementById("modal").innerHTML) {
                        if (!document.body.classList.contains("unscrollable")) {
                            document.body.classList.add("unscrollable");
                            if (scrollWidth) {
                                document.body.setAttribute('style', 'padding-right:' + scrollWidth + 'px');
                                document.getElementById("modal").setAttribute('style', 'padding-right:' + scrollWidth + 'px');
                            }
                        }
                    } else {
                        document.body.classList.remove("unscrollable");
                        document.body.removeAttribute('style');
                    }
                }
            });
        });
        var config = { attributes: true, childList: true, characterData: true };
        observer.observe(target, config);

        if (isSupportUser) {
            var target2 = document.getElementById('root');
            var observer2 = new MutationObserver(function (mutations) {
                mutations.forEach(function (mutation) {
                    if (mutation.type == 'childList') {
                        if (document.getElementsByClassName('page-blank').length) {
                            if (!document.body.classList.contains("unscrollable")) {
                                document.body.classList.add("unscrollable");
                            }
                        } else {
                            document.body.classList.remove("unscrollable");
                        }
                    }
                });
            });
            observer2.observe(target2, config);
        }
    };

    render() {
        let {
            isGuest
        } = this.props;

        return isGuest ? <LoadableLoginComponent /> : <LoadableCabinetComponent/>;
    }
}

export default withRouter(connect(
    state => ({
        isGuest: state.auth && state.auth.isGuest
    })
)(App));

export {store};