import React, {Component} from "react"
import {connect} from "react-redux";
import {Switch, Route, Redirect} from "react-router-dom";
import {dealsList, downloadInvoice, downloadPaymentNotification} from "../../actions/deals";
import DealsList from "../../components/deals/DealsList";
import Pagination from "../../components/common/Pagination";
import DealItem from "../../components/deals/DealItem";
import Preloader from "../../components/common/Preloader";
import PropTypes from "prop-types";

class DealsContainer extends Component{

    componentWillMount(){
        // this.props.getList('overdue');
        // this.props.getList('contracts');
        // this.props.getList('payments');
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    toPage = (type) => {
        return (num) => {
            this.props.getList(type, 'page='+num)
        }
    }

    render(){
        let {
            deals,
            isOk,
            downloadInvoice,
            paymentNotification
        } = this.props;

        return <div className="page">
            <div className="page__main page__main_default-pads">
                <h1 className="page__title">{this.context.translate("Мои дела")}</h1>
                <DealsList {...deals} isOk={isOk} history={this.props.history} key="deals-container">
                    <Switch>
                        {deals.overdue.length ? <Route path="/deals/overdue">
                            <div className="page__tabs-content" data-tab="1">
                                <div className="page__tabs-inner">
                                    <div className="notice-list notice-list_last-events notice-list_adapt-events">
                                        {deals.overdue.map((item, key) => <DealItem item={item} type='overdue' key={key} downloadInvoice={downloadInvoice}/>)}
                                    </div>
                                </div>
                                <Pagination key="pagination" {...deals.overdue.pagination} toPage={this.toPage('overdue')}/>
                            </div>
                        </Route> : null}
                        {deals.contracts.length ? <Route path="/deals/contracts">
                            <div className="page__tabs-content" data-tab="1">
                                <div className="page__tabs-inner">
                                    <div className="notice-list notice-list_last-events notice-list_adapt-events">
                                        {deals.contracts.map((item, key) => <DealItem item={item} type='contracts' key={key} downloadInvoice={downloadInvoice}/>)}
                                    </div>
                                </div>
                                <Pagination key="pagination" {...deals.contracts.pagination} toPage={this.toPage('contracts')}/>
                            </div>
                        </Route> : null}
                        {deals.payments.length ? <Route path="/deals/payments">
                            <div className="page__tabs-content" data-tab="1">
                                <div className="page__tabs-inner">
                                    <div className="notice-list notice-list_last-events notice-list_adapt-events">
                                        {deals.payments.map((item, key) => <DealItem item={item} type='payments' key={key} paymentNotification={paymentNotification} downloadInvoice={downloadInvoice}/>)}
                                    </div>
                                </div>
                                <Pagination key="pagination" {...deals.payments.pagination} toPage={this.toPage('payments')}/>
                            </div>
                        </Route> : null}
                        <Route path="/deals/*" render={() => { return <Redirect to="/deals"/>}}/>
                    </Switch>
                </DealsList>
                {isOk ? null : <Preloader/>}
            </div>
        </div>
    }

}

export default connect(
    state => ({
        deals: state.deals,
        isOk: state.deals.overdue.isOk && state.deals.contracts.isOk && state.deals.payments.isOk
    }),
    dispatch => () => {
        return {
            getList: (type, params = null) => dispatch(dealsList(type, params)),
            downloadInvoice: (data) => dispatch(downloadInvoice(data)),
            paymentNotification: (data) => dispatch(downloadPaymentNotification(data))
        }
    }
)(DealsContainer)
