import React from "react";

export default function FileIcon({ext}) {

    var canvas = [
        {
            id: 'Fill1',
            use: '<use xlink:href="#path0_fill" transform="translate(-299 -146)" fill="#7ED0E4"/>'
        },
        {
            id: 'Fill2',
            use: '<use xlink:href="#path1_fill" transform="translate(-299 -146)" fill="#2487A9"/>'
        },
        {
            id: 'Fill3',
            use: '<use xlink:href="#path2_fill" transform="translate(-301 -137)" fill="#2487A9"/>'
        },
        {
            id: {ext},
            use: '<use xlink:href="#path3_fill'+ext+'" transform="translate(-300 -131.5)" fill="#FEFEFF"/>'
        }
    ];

    return <svg width="18" height="20" viewBox="0 0 18 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>{ext}</title>
        <desc>Created using Figma</desc>
        <g id="Canvas" transform="translate(301 146)">
            <g id="icons/xls_icon">
                <g id="Page 1">
                    {canvas.map((item, key) => <g id={item.id} key={key} dangerouslySetInnerHTML={{__html: item.use}}/>)}
                </g>
            </g>
        </g>
        <defs>
            <path id="path0_fill" fillRule="evenodd" d="M 5 0L 0 4.999L 0 20L 16 20L 16 0L 5 0Z"/>
            <path id="path1_fill" fillRule="evenodd" d="M 5 0L 0 4.999L 5 4.999L 5 0Z"/>
            <path id="path2_fill" fillRule="evenodd" d="M 0 7L 16 7L 16 0L 0 0L 0 7Z"/>
            <text id={"path3_fill"+ext} style={{fontSize: '6px', fontWeight: 'bold', textTransform: 'uppercase'}}>{ext}</text>
        </defs>
    </svg>
}