import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import Profile from "../../components/profile/Profile";
import {changePassword, dataChangeRequest, profileInfo, saveSettings} from "../../actions/profile";

class ProfileContainer extends Component{

    constructor(props){
        super(props);
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render(){

        let {
            profile,
            changePassword,
            saveSettings,
            dataChangeRequest
        } = this.props;

        return <div className="page">
            <div className="page__main page__main_default">
                <div className="page__content-container">
                    <h1 className="page__title">{this.context.translate('Профиль')}</h1>
                    <div className="page__profile-wrap">
                        <Profile {...profile} changePassword={changePassword} saveSettings={saveSettings} dataChangeRequest={dataChangeRequest}/>
                    </div>
                </div>
            </div>
        </div>
    }

}

export default connect(
    state => ({
        profile: state.profile
    }),
    (dispatch) => {
        return {
            getProfileInfo: () => dispatch(profileInfo()),
            changePassword: (oldPassword, newPassword) => dispatch(changePassword(oldPassword, newPassword)),
            saveSettings: (body) => dispatch(saveSettings(body)),
            dataChangeRequest: (body) => dispatch(dataChangeRequest(body))
        }
    }
)(ProfileContainer)
