import React, { Component } from 'react';
import { connect } from 'react-redux';
import {pdfBlank} from "../../actions/applications";
import Preloader from "../../components/common/Preloader";
import {isSupportUser} from "../../tools/tools";
import error from "../../../img/inhtml/error.png";
import PropTypes from "prop-types";

class PdfBlankContainer extends Component {

    constructor(props){
        super(props);
        props.pdfBlank(props.match.params.id);
    }

    static contextTypes = {
        translate: PropTypes.func
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.blankTmpUrl != this.props.blankTmpUrl && nextProps.blankTmpUrl && location.href != nextProps.blankTmpUrl) {
            if (!isSupportUser) setTimeout(window.close, 100);
        }
    }

    render() {

        return <div className="page page-blank">
            <div className="page__main">
                {this.props.actionMessage ? <div className="wrapper error-page">
                    <div className="page">
                        <div className="page__main">
                            <img src={error} width={210}/>
                            <h1 className="page__title">{this.context.translate('Ошибка формирования бланка')}</h1>
                            {this.props.actionMessage}
                            <a href="#" className="btn btn_orange" onClick={(e) => {
                                e.preventDefault();
                                if (isSupportUser) {
                                    this.props.history.goBack();
                                } else {
                                    window.close()
                                }
                            }}>{this.context.translate('Закрыть')}</a>
                        </div>
                    </div>
                </div> : (
                    isSupportUser && this.props.blankTmpUrl ? <iframe src={this.props.blankTmpUrl} width="100%" height="100%" /> : <Preloader />
                )}
            </div>
        </div>
    }
}

export default connect(
    state => ({
        blankTmpUrl: state.applications.blankTmpUrl,
        actionMessage: state.applications.actionMessage
    }),
    (dispatch) => {
        return {
            pdfBlank: (id) => dispatch(pdfBlank(id))
        }
    }
)(PdfBlankContainer);