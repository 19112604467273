import React, {Component} from "react";
import Collapser from "../common/Collapser";
import PropTypes from "prop-types";

export default class QuestionItem extends Component{


    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    action = (act) => {
        return (e) => {
            e.preventDefault();
            let {item, action} = this.props;
            action(item.id, act);
        }
    };

    render(){

        let {
            question,
            answer
        } = this.props.item;

        return <Collapser label={question[this.context.currentLanguage.alias]}
                          className="instruction__item"
                          labelClassName="instruction__item-title"
                          labelActiveClassName="instruction__item-title_open"
                          contentClassName="instruction__item-content"
        >
            <div className="instruction__text">
                <div className={"inner-html"} dangerouslySetInnerHTML={{__html: answer[this.context.currentLanguage.alias]}} />
                {/*<div className="instruction__links">*/}
                    {/*<a className="instruction__link instruction__link_helped"*/}
                    {/*href="#" onClick={this.action('like')}>Помогло</a>*/}
                    {/*<a className="instruction__link instruction__link_not-helped"*/}
                    {/*href="#" onClick={this.action('dislike')}>Не помогло</a>*/}
                {/*</div>*/}
            </div>
        </Collapser>;
    }
}