import {
    call,
    put,
    take
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import {checkToken} from "./auth";
import {pageLoaded} from "./catalog";

/**
 *
 * @returns object
 */
export function setLanguage(lang) {
    localStorage.setItem("currentLangAlias", lang.alias);
    localStorage.setItem("currentLangValue", lang.label);
    return {
        type: 'SET_CURRENT_LANGUAGE',
        lang
    }
}

/**
 *
 * @returns object
 */
export function getDictionary() {
    return {
        type: 'DICTIONARY'
    }
}

/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* fetchDictionary(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/dictionaries", {
                method: 'GET'
            })
        });

        if (error) yield put(checkErrors('DICTIONARY', {message: error}, 403));
        else {
            yield put(checkErrors('DICTIONARY', data, status));
            // yield put(pageLoaded(location.pathname));
        }

    } catch(e) {
        alert(e);
    }
}

/**
 *
 * @returns object
 */
export function getLanguages() {
    return {
        type: 'LANGUAGES'
    }
}

/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* fetchLanguages(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/languages", {
                method: 'GET'
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}
