import React, {Component} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

import {getTime} from "../../tools/tools";

import ready from "../../../img/icons/ready.svg";
import abandon from "../../../img/icons/status_complete_almost.svg";
import recall from "../../../img/icons/status_decline.svg";
import add from "../../../img/icons/add.svg";
import pause from "../../../img/icons/status_pause.svg";
import play from "../../../img/icons/status_process.svg";

export default class NotificationsList extends Component{

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    downloadHelpful = (id) => {
        return (e) => {
            e.preventDefault();
            this.props.download(id);
        }
    }

    render() {
        var {
            list,
            className = "notification-list"
        } = this.props;

        return <div className={className}>
            {list.map((item, key) => <div className={"profile-notice" + (item.IsNew ? " message_in-progress" : "")}
                                          key={key}>
                <div className="profile-notice__aside">{getIcon(item.IconName)}</div>
                <div className="profile-notice__main">
                    <p>{notificationText(item, this.downloadHelpful)}</p>
                    <span className="profile-notice__date">{getRelativeTime(item.NotificationTimestamp, this.context.translate)}</span>
                </div>
            </div>)}
        </div>
    }
}

function notificationText(item, downloadHelpful) {
    var template = item.NotificationTemplate.match(/{(\w)*}/gi);

    var result = item.NotificationTemplate;

    var link;
    switch (item.EntityName) {
        case "application":
            link = <Link key={item.NotificationId+"-link"} to={"/applications/view/"+item.EntityId}>{item.EntityLinkTitle}</Link>;
            break;
        case "promotion":
            link = <Link key={item.NotificationId+"-link"} to={{pathname: "/promotions", state: {promotion: item.EntityId}}}>{item.EntityLinkTitle}</Link>;
            break;
        case "helpful":
            link = <a key={item.NotificationId+"-link"} href="#" onClick={downloadHelpful(item.EntityId)}>{item.EntityLinkTitle}</a>;
            break;
    }

    template.map((tmp) => {
        if (tmp != '{EntityLinkTitle}') {
            result = result.replace(tmp, '<strong>'+item[tmp.substr(1, tmp.length - 2)]+'</strong>');
        }
    });

    result = result.split('{EntityLinkTitle}');

    return [<span key={item.NotificationId+"-1"} dangerouslySetInnerHTML={{__html: result[0]}}/>,
        link,
        <span key={item.NotificationId+"-2"} dangerouslySetInnerHTML={{__html: result[1]}}/>];
}

function getIcon(name) {
    switch (name) {
        case "done":
            return <img src={ready} />;
        case "abandon":
            return <img src={abandon} />;
        case "recall":
            return <img src={recall} />;
        case "plus":
            return <img src={add} />;
        case "pause":
            return <img src={pause} />;
        case "play":
            return <img src={play} />;
        default:
            return null;
    }
}

function getRelativeTime(time, translate) {
    var now = new Date(Date.now());
    var diff = now.getTime()/1000 - time;

    if (diff < 0) {
        return translate("через")+" "+getTime(Math.abs(diff), translate);
    } else {
        return getTime(diff, translate)+" "+translate("назад");
    }
}

