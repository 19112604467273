import React, {Component} from "react";
import {Link} from "react-router-dom";
import OrdersDocs from "./OrdersDocs";
import Tabs from "../common/Tabs";
import {OrderActions, OrderStatus} from "./OrderHelpers";
import {PaymentSchedule} from "../common/Schedule";
import PropTypes from "prop-types";

export default class DetailOrder extends Component{

    constructor(props){
        super(props);

        var tabMenu = [];
        var tabContent = [];

        this.state = {
            tabMenu,
            tabContent
        }
    }

    componentDidMount() {
        var tabMenu = [];
        var tabContent = [];

        if (this.props.CommercialOffers && this.props.CommercialOffers.length > 1) {
            this.props.CommercialOffers.map((item, key) => {
                tabMenu.push(item.CommercialOfferTitle);
                tabContent.push(<CommercialOffer data={item} key={key} download={this.download} translate={this.context.translate}/>);
            })
        }

        this.setState({tabMenu, tabContent})
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    download = (offerId) => {
        return (e) => {
            e.preventDefault();
            this.props.download(this.props.ApplicationId, offerId);
        }
    }

    addDocument = (file) => {
        this.props.addApplicationDocument(this.props.ApplicationId, file);
    }

    removeDocument = (id) => {
        this.props.removeApplicationDocument(this.props.ApplicationId, id);
    }

    updateDocument = (id, name) => {
        this.props.updateApplicationDocument(this.props.ApplicationId, id, name)
    }

    render(){
        let {
            ApplicationId,
            EquipmentName,
            Date,
            Status,
            FrozenTo,
            Documents,
            CommercialOffers,
            Actions,
            ProjectId,
            isActionOk,
            actionMessage,
            fileBuffer,
            fileParams,
            clearBuffer,
            cancelApplicationDocument,

            recallApplication,
            requestApplication,
            resumeApplication,
            location,


            downloadApplicationFile,
            documentTypes
        } = this.props;

        return (
                    <div className="page__content-container">
                        <div className="page__back-link">
                            <Link className="u-back-link" to={location.state && location.state.from || "/applications"}><span>{this.context.translate('Заявки')}</span></Link>
                        </div>
                        <h1 className="page__title">{this.context.translate('Заявка «{name}»', {name: EquipmentName})}</h1>
                        <p className="page__title-date">{this.context.translate('от {date} г.', {date: Date})}</p>
                        <div className="page__application-view">
                            <div className="application application_adapt-default">
                                <OrderStatus className="application__status" status={Status} paused={FrozenTo}/>
                                <OrderActions actions={Actions}
                                              firstAsBtn={true}
                                              id={ApplicationId}
                                              projectId={ProjectId}

                                              isActionOk={isActionOk}
                                              actionMessage={actionMessage}

                                              recallApplication={recallApplication}
                                              requestApplication={requestApplication}
                                              resumeApplication={resumeApplication}
                                />
                                <OrdersDocs docs={Documents}
                                            download={downloadApplicationFile}
                                            fileBuffer={fileBuffer}
                                            fileParams={fileParams}
                                            clearBuffer={clearBuffer}
                                            addDocument={this.addDocument}
                                            updateDocument={this.updateDocument}
                                            removeDocument={this.removeDocument}
                                            cancelDocument={cancelApplicationDocument}
                                            documentTypes={documentTypes}

                                />
                                <div className="application__offer">
                                    <p className="application__title">{this.context.translate('Коммерческое предложение')}</p>
                                    {this.state.tabMenu.length ? <Tabs tabsMenu={this.state.tabMenu} >
                                            {this.state.tabContent}
                                        </Tabs> :
                                        <CommercialOffer data={CommercialOffers[0]} download={this.download} translate={this.context.translate}/>}
                                </div>
                            </div>
                        </div>
                    </div>
        )
    }
}

function CommercialOffer({data, download, translate})
{
    var equipmentInfo = [
        {
            label: "Название",
            value: data.EquipmentName || <span>&mdash;</span>
        },
        {
            label: "Производитель (торговая марка)",
            value: data.EquipmentBrandName || <span>&mdash;</span>
        },
        {
            label: "Общая стоимость",
            value: data.EquipmentTotalCost || <span>&mdash;</span>
        },
        {
            label: "Регион эксплуатации",
            value: data.EquipmentOperationRegionName || <span>&mdash;</span>
        }
    ];

    var leaseInfo = [
        {
            label: "Лизингополучатель",
            value: data.LesseeName || <span>&mdash;</span>
        },
        {
            label: "Срок",
            value: data.ContractTerm || <span>&mdash;</span>
        },
        {
            label: "Аванс",
            value: data.AdvancePercent || <span>&mdash;</span>
        },
        {
            label: "Валюта",
            value: data.ScheduleCurrencySymbol || <span>&mdash;</span>
        },
        {
            label: "День платежа",
            value: data.LeasePaymentDay || <span>&mdash;</span>
        },
        {
            label: "Балансодержатель",
            value: data.AssetHolderName || <span>&mdash;</span>
        },
    ];

    return  <div>
        <div className="application__offer-about">
            <div className="application__offer-info">
                <p className="application__subtitle">{translate('Предмет лизинга')}</p>
                <div className="application__offer-params">
                    <div className="params-list params-list_application">
                        <div className="params-list__items">
                            {equipmentInfo.map((item, key) => <div className="params-list__list-item" key={key}>
                                <span className="params-list__term">{translate(item.label)}:</span>&nbsp;
                                <span className="params-list__defenition">{item.value}</span>
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="application__offer-finance">
                <p className="application__subtitle">{translate('Условия финансирования')}</p>
                <div className="application__offer-params">
                    <div className="params-list params-list_application">
                        <div className="params-list__items">
                            {leaseInfo.map((item, key) => <div className="params-list__list-item" key={key}>
                                <span className="params-list__term">{translate(item.label)}:</span>&nbsp;
                                <span className="params-list__defenition">{item.value}</span>
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {data.Schedule && data.Schedule.payments && data.Schedule.payments.length ? <div className="application__offer-graph">
            <p className="application__subtitle">{translate('График лизинговых платежей')}</p>
            <div className="application__offer-graph-info">
                <div className="application__offer-graph-params">
                    <div className="application__offer-graph-param"><span
                        className="application__offer-graph-param-name">{translate('Дата расчета')}:</span><span
                        className="application__offer-graph-param-value">{data.CommercialOfferDate}</span>
                    </div>
                    <div className="application__offer-graph-param"><span
                        className="application__offer-graph-param-name">{translate('Курс конвертации')}:</span><span
                        className="application__offer-graph-param-value">{data.CommercialOfferRate}</span>
                    </div>
                    <div className="application__offer-graph-param u-mobile-only"><span
                        className="application__offer-graph-param-name">{translate('Размер платежа')}:</span><span
                        className="application__offer-graph-param-value">{data.Schedule.payments[0].PaymentAmountTooltipLabel}</span>
                    </div>
                    <div className="application__offer-graph-param u-mobile-only"><span
                        className="application__offer-graph-param-name">{translate('В т.ч. НДС')}:</span><span
                        className="application__offer-graph-param-value">{data.Schedule.payments[0].PaymentVatTooltipLabel}</span>
                    </div>
                </div>
                {data.HasOfferDoc ? <div className="application__offer-graph-downloads">
                    <span className="application__offer-graph-download-help">{translate('Скачать коммерческое предложение')}:</span>
                    <a href="#" className="u-big-icon u-big-icon_pdf application__offer-graph-download" onClick={download(data.CommercialOfferId)}/>
                </div> : null}
            </div>
            <PaymentSchedule {...data.Schedule} translate={translate}/>
                <p className="application__offer-graph-payments">
                    {translate('Итого сумма лизинговых платежей')}:&nbsp;
                    <span className="application__offer-graph-payments-value">{data.EquipmentFullCost}</span>,
                    {translate('в т.ч. НДС')}&nbsp;
                    <span  className="application__offer-graph-payments-value">{data.EquipmentCostNdsPart}</span>
                </p>

            </div>
            : null}
    </div>
}