const initState = {
    token: localStorage.getItem('authToken') || '',
    isGuest: !localStorage.getItem('authToken'),
    step: 1,
    blocked: false,
    authErrors: {},
    codevalidateErrors: {},
    fields: {},
    isRefreshing: 0,
    sendRequest: false,
    allowSplash: false
};

import {
    parseErrors
} from "../tools/api-helper";

export function auth(state = initState, action) {
    switch (action.type) {
        case "CHECK_LOCKED": {
            return {...state, checkingLocked: true}
        }
        case "CHECK_LOCKED_COMPLETE": {
            return {...state, checkingLocked: false}
        }
        case "BLOCKED":
            return {...initState, token: '', isGuest: true, blocked: true};

        case "CODE_REQUEST":
            return {...state, authErrors: {}, codevalidateErrors: {}, sendRequest: true};
        case "CODE_REQUEST_SUCCESS":
            return {...state, step: 2, fields: action.data, sendRequest: false};
        case "CODE_REQUEST_VALIDATION_ERROR":
            return {...state, authErrors: parseErrors(action.data), sendRequest: false};
        case "TO_CODE_REQUEST":
            return {...initState};

        case "CODE_VALIDATE":
        case "RECOVERY_CODE-VALIDATE":
            return {...state, codevalidateErrors: {}, sendRequest: true};
        case "CODE_VALIDATE_SUCCESS":
            return {...state, ...action.data, step: 1, isGuest: false, sendRequest: false, allowSplash: true};
        case "RECOVERY_CODE-VALIDATE_SUCCESS":
            return {...state, ...action.data, step: 3, sendRequest: false};
        case "CODE_VALIDATE_VALIDATION_ERROR":
        case "RECOVERY_CODE-VALIDATE_VALIDATION_ERROR":
            return {...state, codevalidateErrors: parseErrors(action.data.errors), triesMore: action.data.tries_more, sendRequest: false};
        case "RECOVERY_CODE-VALIDATE_BLOCKED":
            return {...state, codevalidateErrors: {code: action.data.message}, triesMore: 0, sendRequest: false};
        case "RECOVERY_CODE-VALIDATE_BAD_REQUEST":
            return {...state, codevalidateErrors: {code: action.data.message}};

        case "LAYOUT_INFO_SUCCESS":
            var data = action.data.user;
            return {...state, ...data};

        case "ACTIVATION_CHANGE-PASSWORD":
            return {...state, sendRequest: true};
        case "ACTIVATION_ACCEPT-AGREEMENT":
            return {...state, is_active: 1, is_email_confirmed: 1, is_phone_confirmed: 1, sendRequest: true};
        case "ACTIVATION_CONFIRM-EMAIL":
            return {...state, is_email_confirmed: 1, sendRequest: true};
        case "ACTIVATION_CONFIRM-PHONE":
            return {...state, is_phone_confirmed: 1, sendRequest: true};

        case "ACTIVATION_CHANGE-PASSWORD_SUCCESS":
            return {...state, is_temporary: 0, sendRequest: false, activationErrors: {}};
        case "ACTIVATION_ACCEPT-AGREEMENT_SUCCESS":
            return {...state, is_active: 1, is_email_confirmed: 1, is_phone_confirmed: 1, sendRequest: false};
        case "ACTIVATION_CONFIRM-EMAIL_SUCCESS":
            return {...state, is_email_confirmed: 1, sendRequest: false};
        case "ACTIVATION_CONFIRM-PHONE_SUCCESS":
            return {...state, is_phone_confirmed: 1, sendRequest: false};

        case "RECOVERY_CODE-SEND":
            return {...state, recoveryErrors: {}, codevalidateErrors: {}, sendRequest: true};
        case "RECOVERY_CODE-SEND_VALIDATION_ERROR":
            return {...state, recoveryErrors: parseErrors(action.data), sendRequest: false};
        case "ACTIVATION_CHANGE-PASSWORD_VALIDATION_ERROR":
            return {...state, activationErrors: parseErrors(action.data), sendRequest: false};
        case "RECOVERY_CODE-SEND_SUCCESS":
            return {...state, step: 2, fields: action.data, sendRequest: false};

        case "@@router/LOCATION_CHANGE":
            return {...state, step: 1};

        case "CHECK_TOKEN":
            return {...state, isRefreshing: state.isRefreshing + 1};

        case "LOGOUT_SUCCESS":
            return {...initState, token: '', isGuest: true};

        default:
            if (action.type.indexOf("_TOKEN_OK") != -1) {
                return {...state, isRefreshing: state.isRefreshing - 1};
            }
    }
    return state;
}