import React,{Component} from "react";
import Pagination from "../common/Pagination";
import PropTypes from "prop-types";

export default class LibraryList extends Component{


    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render(){

        let {
            children,
            paginationParams,
            toPage
        } = this.props;

        return (<div className="page page_projects page_library">
                <div className="page__main page__main_default">
                    <div className="page__content-container">
                        <div className="page__title-section">
                            <h1 className="page__title">{this.context.translate("Полезное")}</h1>
                        </div>
                        <div className="page__articles-list">
                            <div className="articles articles_adapt-default">
                                <div className="articles__list">
                                    {children}
                                </div>
                            </div>
                        </div>
                        {paginationParams ? <Pagination key="pagination" {...paginationParams} toPage={toPage}/> : null}
                    </div>
                </div>
            </div>
        )
    }

}