import React, {Component} from "react";
import Collapser from "../common/Collapser";
import CheckBox from "../common/form/CheckBox";
import Form from "../common/form/Form";
import Modal from "../common/Modal";
import ok from "../../../img/notification_ready.svg";
import error from "../../../img/icons/status_decline.svg";
import PropTypes from "prop-types";

export default class NotificationSettings extends Component{

    constructor(props){
        super(props);

        var companies = [];
        var settings = [];

        if (props.notificationSettings) {
            props.notificationSettings.map((company) => {
                companies[companies.length] = company.CompanyId;
                company.Subscriptions.map((subscription) => {
                    subscription.notificationWay.map((way) => {
                        if (way.isChecked) {
                            settings[settings.length] = {
                                CompanyId: company.CompanyId,
                                NotificationTypeId: subscription.id,
                                NotificationWayId: way.id
                            }
                        }
                    });
                })
            })
        }

        this.state = {
            companies,
            settings
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps(nextProps){
        if (nextProps.notificationSettings != this.props.notificationSettings) {
            var companies = [];
            var settings = [];

            nextProps.notificationSettings.map((company) => {
                companies[companies.length] = company.CompanyId;
                company.Subscriptions.map((subscription) => {
                    subscription.notificationWay.map((way) => {
                        if (way.isChecked) {
                            settings[settings.length] = {
                                CompanyId: company.CompanyId,
                                NotificationTypeId: subscription.id,
                                NotificationWayId: way.id
                            }
                        }
                    });
                })
            });

            this.setState({
                companies,
                settings
            })
        }

        if (nextProps.savingStatus != this.props.savingStatus && (nextProps.savingStatus == 'success' || nextProps.savingStatus == 'error') && this.state.sendRequest) {
            this.setState({modal: true, sendRequest: false});
            if (nextProps.savingStatus == 'success') {
                setTimeout(() => {this.setState({modal: false})}, 2000);
            }
        }
    }

    changeSelection = (company, type, way) => {
        var settings = [...this.state.settings];
        var index = this.getIndex(company, type, way);

        if (index == -1) {
            settings[settings.length] = {
                CompanyId: company,
                NotificationTypeId: type,
                NotificationWayId: way
            }
        } else {
            settings.splice(index, 1);
        }

        this.setState({settings});
    }

    isChecked = (company, type, way) => {
        var settings = [...this.state.settings];
        var index = this.getIndex(company, type, way);

        return index != -1;
    }

    getIndex = (company, type, way) => {
        var settings = [...this.state.settings];
        for (var i = 0; i < settings.length; i++){
            if (settings[i].CompanyId == company &&
                settings[i].NotificationTypeId == type &&
                settings[i].NotificationWayId == way
            ) {
                return i;
            }
        }
        return -1;
    }

    onSubmit = () => {
        var {companies, settings} = this.state;
        var body = [];
        companies.map((item, key) => {
            body.push('AccessibleCompanies['+key+']='+item);
        })
        settings.map((item,key) => {
            body.push('Subscriptions['+key+'][CompanyId]='+item.CompanyId);
            body.push('Subscriptions['+key+'][NotificationTypeId]='+item.NotificationTypeId);
            body.push('Subscriptions['+key+'][NotificationWayId]='+item.NotificationWayId);
        })

        this.setState({sendRequest: true});
        this.props.saveSettings(body.join('&'));
    }

    render(){
        let {
            notificationSettings,
            savingStatus,
            message
        } = this.props;

        return <Form onSubmit={this.onSubmit}>
            <div className="profile-form__container">
                {notificationSettings.map((item ,key) => <NotificationSetting {...item} changeSelection={this.changeSelection} isChecked={this.isChecked} key={key} translate={this.context.translate}/>)}
                <div className="profile-form__item profile-form__item_btn">
                    <button type="submit" className={"u-btn u-btn_adapt-type_wide"+(savingStatus == 'load' ? ' disabled' : '')}>{this.context.translate('Сохранить')}</button>
                </div>
            </div>
            {this.state.modal ? <Modal onClickBackground={() => {this.setState({modal: false})}} classNameContent="modal modal_visible modal-wrapper modal__xs text-center">
                <p><img width={60} src={savingStatus == 'success' ? ok : error} /></p>
                <h2 className="form__title">{savingStatus == 'success' ? this.context.translate("Настройки успешно сохранены") : this.context.translate("Ошибка сохранения")}</h2>
                {message ? <p>{message}</p> : null}
            </Modal> : null}
        </Form>
    }

}

function NotificationSetting({CompanyId, CompanyName, Subscriptions, changeSelection, isChecked, translate}){

    return <div className="profile-form__item">
        <span className="collapser-before-text">{CompanyName}</span>
        <Collapser label={translate("Показать настройки уведомлений")}
                   labelClassName="u-more-link"
                   labelActiveClassName="u-more-link_open"
                   typeCollapsed={"notification"}
        >
            <div className="profile-form__notice-form-box">
                <div className="notice-form notice-form_adapt_default">
                    <div className="notice-form__container">
                        <div className="notice-form__wrap">
                            {Subscriptions.map((item, key) => <NotificationSettingItem {...item} CompanyId={CompanyId} isChecked={isChecked} changeSelection={changeSelection} key={key}/>)}
                        </div>
                    </div>
                </div>
            </div>
        </Collapser>
    </div>
}

class NotificationSettingItem extends Component{



    render(){
        var {CompanyId, id, name, notificationWay, changeSelection, isChecked} = this.props;

        return <div className="notice-form__item">
            <div className="notice-form__label">{name}</div>
            <div className="notice-form__check-list">
                {notificationWay.map((item, key) => <div className="notice-form__check-list-item" key={key}>
                    <CheckBox id={CompanyId+"-"+id+"-"+item.id} className="form__checkbox" label={item.name} value={isChecked(CompanyId, id, item.id)} onChange={() => {changeSelection(CompanyId, id, item.id)}}/>
                </div>)}
            </div>
        </div>
    }
}