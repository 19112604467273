import {
    call,
        put,
        take
} from 'redux-saga/effects';

import {checkErrors, clientApiUrl, fetchClientApi, fetchFileApi} from "../tools/api-helper";

import {checkToken} from "./auth";
import fileDownload from "js-file-download";

export function getInsuranceCommunications(page = 1) {
    return {
        type: 'GET_INSURANCE_COMMUNICATIONS',
        page
    }
}

export function* fetchInsuranceCommunications(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error, pagination } = yield call( () => {
            return fetchClientApi("/insurance/communications?page="+action.page, {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else if (status === 200) {
            yield put(checkErrors(action.type+(action.page > 1 ? '_LOAD_MORE' : ''), {data, pagination}, status));
        } else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function getInsuranceCompanies() {
    return {
        type: 'GET_INSURANCE_COMPANIES'
    }
}

export function* fetchInsuranceCompanies(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/insurance/companies", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function getInsuranceDocuments() {
    return {
        type: 'GET_INSURANCE_DOCUMENTS'
    }
}

export function* fetchInsuranceDocuments(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/insurance/documents", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function downloadInsuranceDocument(id) {
    return {
        type: 'DOWNLOAD_INSURANCE_DOCUMENT',
        id
    }
}

export function* fetchDownloadInsuranceDocument(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        const { status, data, headers, error } = yield call( () => {
            return fetchFileApi(clientApiUrl+"/insurance/documents/"+action.id, localStorage.getItem('authToken'))
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status == 200) {
                var filename = headers.get("Content-Disposition").match(/filename="(.*)"/ig)[0];
                filename = filename.substr(10, filename.length - 11);

                fileDownload(data, filename);
            }
            else yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function sendInsuranceCommunication(data) {
    return {
        type: 'SEND_INSURANCE_COMMUNICATION',
        data
    }
}

export function* fetchSendInsuranceCommunication(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/insurance/communications", {
                method: 'POST',
                body: action.data,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status === 201) {
                yield put(getInsuranceCommunications())
            }
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}