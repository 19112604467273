import React, { Component } from 'react';
import ReactDOM from 'react-dom';

let modalContainer = document.getElementById('modal');

export default class Modal extends Component {

	onClick = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

	render() {
		let {
			classNameContent = "",
			onClickBackground,
			closeOnBackground = true,
            children
		} = this.props;

		return ReactDOM.createPortal(
			<div className="modal-container" onClick={closeOnBackground ? onClickBackground : () => {}}>
				<div id="modal-wrapper" className={"modal-wrapper "+classNameContent} onClick={this.onClick}>
					<button onClick={onClickBackground} className="fancybox-close-small" title="Закрыть">
						<svg viewBox="0 0 32 32">
							<path d="M10,10 L22,22 M22,10 L10,22"/>
						</svg>
					</button>
					{children}
				</div>
			</div>, modalContainer);
	}
}
