import React, {Component} from "react";
import PropTypes from "prop-types";

import Preloader from "../common/Preloader";
import Tabs from "../common/Tabs";
import ProfileForm from "./ProfileForm";
import PasswordForm from "./PasswordForm";
import NotificationSettings from "./NotificationSettings";

export default class Profile extends Component{
    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render(){

        this.tabsMenu = [
            this.context.translate("Основная информация"),
            this.context.translate("Безопасность"),
            this.context.translate("Настройки уведомлений")
        ];

        let {
            profile,
            notificationSettings,
            isOk,
            savingStatus,
            message,

            changePassword,
            passwordErrors,

            saveSettings,
            dataChangeRequest
        } = this.props;

        return isOk ? <Tabs tabsMenu={this.tabsMenu} >
            <div className="page__profile-form">
                <div className="profile-form profile-form_two-columns profile-form_adapt_default">
                    <ProfileForm {...profile} dataChangeRequest={dataChangeRequest} savingStatus={savingStatus} message={message}/>
                </div>
            </div>
            <div className="page__profile-form">
                <div className="profile-form profile-form_one-columns profile-form_adapt_default">
                    <PasswordForm onSubmit={changePassword} errors={passwordErrors}/>
                </div>
            </div>
            <div className="page__profile-form">
                <div className="profile-form profile-form_adapt_full">
                    <NotificationSettings savingStatus={savingStatus} message={message} notificationSettings={notificationSettings} saveSettings={saveSettings}/>
                </div>
            </div>
        </Tabs> : <Preloader/>
    }
}