import React, {Component} from "react"
import {connect} from "react-redux";
import {documentView, documentEquipments, downloadContractSchedule} from "../../actions/projects";
import DocumentDetail from "../../components/projects/DocumentDetail";
import {downloadInvoice, downloadPaymentNotification} from "../../actions/deals";
import Preloader from "../../components/common/Preloader";

class DocumentsContainer extends Component{


    componentWillReceiveProps(nextProps){
        if (nextProps.document != this.props.document && nextProps.document && nextProps.document.ContractId && !nextProps.document.ContractId) {
            this.props.history.goBack();
        }
    }

    toPage = (num) => {
        this.props.getEquipments(this.props.match.params.id, num);
    }

    downloadContractSchedule = (e) => {
        if (e) e.preventDefault();
        this.props.downloadContractSchedule(this.props.match.params.id);
    }

    render(){
        let {
            document,
            isOk,
            downloadInvoice,
            paymentNotification,
            location
        } = this.props;

        return <div className="page">
            <div className="page__main page__main_project">
                {isOk ? <DocumentDetail {...document}
                                        downloadContractSchedule={this.downloadContractSchedule}
                                        toPage={this.toPage}
                                        downloadInvoice={downloadInvoice}
                                        paymentNotification={paymentNotification}
                                        location={location}
                /> : null}
            </div>
        </div>
    }

}

export default connect(
    state => ({
        document: state.detailDocument.data,
        isOk: state.detailDocument.isOk
    }),
    dispatch => {
        return {
            getItem: (id) => dispatch(documentView(id)),
            getEquipments: (id, page) => dispatch(documentEquipments(id, page)),
            downloadContractSchedule: (id) => dispatch(downloadContractSchedule(id)),
            downloadInvoice: (id, data) => dispatch(downloadInvoice(id, data)),
            paymentNotification: (data) => dispatch(downloadPaymentNotification(data))
        }
    }
)(DocumentsContainer)
