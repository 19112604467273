import React, {Component} from "react"
import {connect} from "react-redux";
import {contactsList, coordinates, detailContact} from "../../actions/contacts";
import OfficeInfo from "../../components/contacts/OfficeInfo";
import Preloader from "../../components/common/Preloader";
import OfficeEmployees from "../../components/contacts/OfficeEmployees";
import Modal from "../../components/common/Modal";
import OfficesList from "../../components/contacts/OfficesList";
import OfficeMap from "../../components/contacts/OfficeMap";
import PropTypes from "prop-types";

class ContactsContainer extends Component{

    constructor(props){
        super(props);

        this.state = {
            modalOpened: false
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps(nextProps){
        if (nextProps.supportOffice != this.props.supportOffice) {
            nextProps.detailContact(nextProps.supportOffice);
        }
    }

    toggleModal = (e) => {

        if (e) e.preventDefault();

        this.setState((prevState) => {
            return {
                modalOpened: !prevState.modalOpened
            }
        })
    }

    selectOffice = (id) => {
        return (e) => {
            if (e) e.preventDefault();
            this.props.detailContact(id);
            this.toggleModal();
        }
    }

    renderModal = () => {

        let {
            currentContact,
            contacts
        } = this.props;

        return this.state.modalOpened && contacts.isOk ? <Modal classNameContent="modal modal_visible modal_fullscreen" onClickBackground={this.toggleModal}>
            <OfficesList currentRegion={currentContact.CityName}
                         contacts={contacts.list}
                         selectOffice={this.selectOffice}
            />
        </Modal> : null;
    }

    render(){
        let {
            currentContact,
            apiKey
        } = this.props;

        return <div className="page page_projects page_contacts">
            <div className="page__main page__main_default">
                <div className="page__content-container">
                    <div className="page__title-section">
                        <h1 className="page__title">{this.context.translate('Контакты')}</h1>
                    </div>
                    {currentContact.isOk ? <OfficeInfo key="office-info" {...currentContact} changeOffice={this.toggleModal}>
                        <div className="agency__map" id="agency-map">
                            <OfficeMap address={currentContact.Address} apiKey={apiKey} language={this.context.currentLanguage.alias}/>
                        </div>
                        {this.renderModal()}
                    </OfficeInfo> : <Preloader/>}
                    {currentContact.isOk && currentContact.Employers && currentContact.Employers.length ? <OfficeEmployees list={currentContact.Employers} translate={this.context.translate}/> : null}
                </div>
            </div>
        </div>
    }

}

export default connect(
    state => ({
        contacts: state.contacts,
        currentContact: state.detailContact,
        supportOffice: state.catalog && state.catalog.info && state.catalog.info.OfficeId,
        apiKey: state.catalog && state.catalog.googleApiKey
    }),
    dispatch => () => {
        return {
            contactsList: () => dispatch(contactsList()),
            detailContact: (id) => dispatch(detailContact(id))
        }
    }
)(ContactsContainer)
