import React, {Component} from "react";
import Slider from 'rc-slider';

export default class NumberSlide extends Component{
    constructor(props){
        super(props);
        this.state = {
            changeValid: true
        }
    }

    onChange = (value) => {
        if (typeof this.props.max !== undefined) {
            if (value === this.props.max) this.setState({ changeValid: false });
        }
        this.props.onChange && this.props.onChange(this.props.attribute, value);
    };

    save = (value) => {
        this.props.saveField && this.props.saveField(this.props.attribute, value);
    }

    onChangeField = (e) => {
        let {
            max,
            attribute,
            onChange
        } = this.props;
        var newVal = e.target.value;

        if (!this.state.changeValid && newVal < this.props.max) this.setState({ changeValid: true });

        if (this.state.changeValid || !this.state.changeValid && newVal < max) {
            if (newVal !== '00' ) onChange && onChange(attribute, newVal);
        }
    };


    render(){
        let {
            min,
            max,
            step,
            value = '',
            postfix = '',
            onBlur
        } = this.props;

        return <div className="form__slider">
            <input
                value={value}
                type="text"
                readOnly={false}
                onChange={this.onChangeField}
                onBlur={onBlur}
            />
            <span className="input-postfix">{postfix}</span>
            <Slider min={min} max={max} step={step} value={value} onChange={this.onChange} onAfterChange={this.save}/>
        </div>
    }
}