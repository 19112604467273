import React, {Component} from "react";

import {connect} from "react-redux";
import {Switch, Route, NavLink, Redirect, Link} from "react-router-dom";
import {projectView, downloadContractSchedule, projectDocuments} from "../../actions/projects";
import ProjectDetail from "../../components/projects/ProjectDetail";
import {downloadInvoice, downloadPaymentNotification} from "../../actions/deals";
import Preloader from "../../components/common/Preloader";

class DetailProjectContainer extends Component{

    componentWillReceiveProps(nextProps){
        if (nextProps.project != this.props.project && !nextProps.project.ProjectId) {
            this.props.history.goBack();
        }
    }

    toPage = (num) => {
        this.props.getDocuments(this.props.match.params.id, num)
    }

    render(){
        let {
            project,
            catalog,
            isOk,
            downloadContractSchedule,
            downloadInvoice,
            paymentNotification,
            location
        } = this.props;

        return <div className="page page_projects">
            <div className="page__main page__main_projects">
                {isOk ? <ProjectDetail {...project}
                                        location={location}
                                       downloadContractSchedule={downloadContractSchedule}
                                       toPage={this.toPage}
                                       downloadInvoice={downloadInvoice}
                                       paymentNotification={paymentNotification}
                                       lesseeCount={(catalog.companies.filter(function (item) {
                                           return item.IsSelected;
                                       })).length}
                /> : null}
            </div>
        </div>
    }

}

export default connect(
    state => ({
        catalog: state.catalog,
        project: state.detailProject.data,
        isOk: state.detailProject.isOk
    }),
    (dispatch) => {
        return {
            getItem: (id) => dispatch(projectView(id)),
            getDocuments: (id, page) => dispatch(projectDocuments(id, page)),
            downloadContractSchedule: (id) => dispatch(downloadContractSchedule(id)),
            downloadInvoice: (id, data) => dispatch(downloadInvoice(id, data)),
            paymentNotification: (data) => dispatch(downloadPaymentNotification(data))
        }
    }
)(DetailProjectContainer)
