const initState = {isOk: false};

export function notifications(state = initState, action) {
    switch (action.type) {
        case 'READ_NOTIFICATION_SUCCESS':
            var list = [...state.list];
            var newCount = state.newCount;
            if (action.data.id) {
                list = list.map((item) => {
                    if (item.NotificationId == action.data.id) {
                        item.IsNew = 0;
                        newCount--;
                    }
                    return item;
                })
            } else {
                newCount = 0;
                list = list.map((item) => {
                    item.IsNew = 0;
                    return item;
                })
            }
            return {...state, list, newCount};
        case 'NOTIFICATIONS_LIST_SUCCESS':
            var data = action.data;
            return {list: data, isOk: true, newCount: data.filter((item) => {
                return item.IsNew;
            }).length, totalCount: data.length};
        case 'LOGOUT_SUCCESS':
            return {...initState};
    }
    return state;
}
