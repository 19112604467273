import React, {Component} from "react";
import {Link} from "react-router-dom";
import CommunicationsListItem from "./CommunicationsListItem";
import Pagination from "../common/Pagination";

import noFiles from "../../../img/no-files.svg";
import notFound from "../../../img/magnigier.svg";

export default function CommunicationsList({list, isSearch, ratingList, setRating, decline, toPage, className = 'messages-list messages-list_adapt-default', translate}){

    return list.length ?
        <div className="page__messages-list">
            <div className={className}>
            {list.map(
                (item) => <CommunicationsListItem item={item}
                                                  ratingList={ratingList}
                                                  setRating={setRating}
                                                  decline={decline}
                                                  key={item.CommunicationId}
                />)}
            </div>
            {list.pagination ? <Pagination key="pagination" {...list.pagination} toPage={toPage} /> : null}
    </div> : renderEmpty(isSearch, translate);
}

function renderEmpty(isSearch, translate) {
    return <div className="page__zero-results">
        {isSearch ? <div className="page__zero-results">
            <div className="info-widget">
                <div className="info-widget__head"><img className="info-widget__image" src={notFound} /></div>
                <div className="info-widget__title">{translate('Ничего не найдено')}</div>
                <div className="info-widget__message">{translate('Пожалуйста, повторите запрос с другими параметрами.')}</div>
            </div>
        </div> : <div className="info-widget">
            <div className="info-widget__head"><img className="info-widget__image" src={noFiles} /></div>
            <div className="info-widget__title">{translate('У вас нет обращений')}</div>
            <div className="info-widget__message">{translate('Вы можете добавить новое обращение.')}</div>
            <div className="info-widget__footer">
                <Link className="info-widget__footer-btn u-btn" to="/communications/create">{translate('Новое обращение')}</Link>
            </div>
        </div>}
    </div>
}
