import {
    call,
    put,
    select,
    take
} from 'redux-saga/effects';

const catalog = state => state.catalog;
const applications = state => state.applications;
const projects = state => state.projects;
const deals = state => state.deals;

import {
    fetchApi,
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import {applicationsList, archiveSearchInfo, detailApplication, getDocumentTypes} from "./applications";
import {applicationFormInfo, viewApplication, clearApplicationForm} from "./add-applications";
import {dealsList} from "./deals";
import {projectsList, projectsFilter, projectView, projectDocuments, documentView, documentEquipments} from "./projects";
import {checkToken, refreshToken} from "./auth";
import {documentsList, documentsWidgetInfo, documentsSearchInfo} from "./documents";
import {promotionsList, topicsList} from "./promotions";
import {library} from "./library";
import {contactsList, detailContact} from "./contacts";
import {notificationsList} from "./notifications";
import {usersList, subscriptions} from "./users";
import {companiesList, companiesDetail} from "./companies";
import {profileInfo} from "./profile";
import {faq} from "./help";
import {communicationsList, communicationsSearchInfo, communicationsFormInfo, communicationsDetail} from "./communications";
import {getInsuranceCommunications, getInsuranceCompanies, getInsuranceDocuments} from "./insurance";

export function autoComplete(type, query) {
    return {
        type: 'AUTOCOMPLETE',
        entity: type,
        query
    }
}

export function* fetchAutoComplete(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/catalog/autocomplete/"+action.entity+'?query='+action.query, {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, {type: action.entity, data}, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function layoutInfo() {
    return {
        type: 'LAYOUT_INFO'
    }
}

export function* fetchLayoutInfo(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/settings", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function saveCompanies(companiesIds, pathname = location.pathname) {
    return {
        type: 'SAVE_CLIENT_COMPANIES',
        companiesIds,
        pathname
    }
}

export function* fetchSaveCompanies(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        var body = [];
        action.companiesIds.map((item, key) => {
            body.push("CompaniesArray["+key+"]="+item);
        });
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/settings", {
                method: 'PUT',
                body: body.join('&'),
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status === 200) {
                yield put(checkErrors(action.type, action.companiesIds, status));

                if (action.pathname.indexOf('applications') !== -1) {
                    yield put(applicationsList('draft'));
                    yield put(applicationsList('in-progress'));
                    yield put(applicationsList('archive'));
                    yield put(archiveSearchInfo());
                } else if (action.pathname.indexOf('projects') !== -1){
                    yield put(projectsList());
                    yield put(projectsFilter());
                } else if (action.pathname.indexOf('deals') !== -1){
                    yield put(dealsList('overdue'));
                    yield put(dealsList('contracts'));
                    yield put(dealsList('payments'));
                } else if (action.pathname.indexOf('documents') !== -1){
                    yield put(documentsList());
                    yield put(documentsSearchInfo());
                    yield put(documentsWidgetInfo());
                } else if (action.pathname === '/') {
                    yield put(dealsList('overdue'));
                    yield put(dealsList('contracts'));
                    yield put(dealsList('payments'));
                    yield put(investPotential());
                    yield put(applicationsList('widget'));
                }
            }
            else yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function splashImages() {
    return {
        type: 'SPLASH_IMAGES'
    }
}

export function* fetchSplashImages(action) {
    try {

        var now = new Date(Date.now());

        var time = 'evening';

        if ((now.getHours() > 6 || (now.getHours() === 6 && now.getMinutes() >= 45)) && now.getHours() < 12) {
            time = 'morning';
        } else if (now.getHours() >= 12 && now.getHours() < 18) {
            time = 'noon';
        }

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/settings/splash?time="+time, {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}


export function splashMessage() {
    return {
        type: 'SPLASH_MESSAGE'
    }
}

export function* fetchSplashMessage(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/settings/message", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function authPageSettings() {
    return {
        type: 'AUTH_PAGE_SETTINGS'
    }
}

export function* fetchAuthPageSettings(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/settings/auth-page", {
                method: 'GET'
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function footerSettings() {
    return {
        type: 'FOOTER_SETTINGS'
    }
}

export function* fetchFooterSettings(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/settings/footer", {
                method: 'GET'
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}


export function investPotential() {
    return {
        type: 'INVEST_POTENTIAL'
    }
}

export function* fetchInvestPotential(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/settings/invest-potential", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function clearError() {
    return {
        type: "CLEAR_ERROR"
    }
}

export function clearDownloading() {
    return {
        type: 'CLEAR_DOWNLOADING'
    }
}

export function* scrollTop(action) {
    window.scroll(0,0);
    yield put(pageLoaded(action.payload));
}

export function pageLoaded(payload) {
    return {
        type: "PAGE_LOADED",
        ...payload
    }
}

export function* checkPageLoaded(action) {
    var actions = [];
    var finCnt = 0;

    switch (action.pathname) {
        case "/":
            if (localStorage.getItem('authToken')) {
                yield put(dealsList('overdue'));
                yield put(dealsList('contracts'));
                yield put(dealsList('payments'));
                yield put(investPotential());
                yield put(applicationsList('widget'));
                yield put(promotionsList());
                yield put(library('isHome=1'));
                yield put(documentsWidgetInfo());


                actions = [
                    "DEALS_LIST_OVERDUE_SUCCESS",
                    "DEALS_LIST_CONTRACTS_SUCCESS",
                    "DEALS_LIST_PAYMENTS_SUCCESS",
                    "APPLICATION_LIST_WIDGET_SUCCESS",
                    "DOCUMENTS_WIDGET_INFO_SUCCESS",
                    "PROMOTIONS_LIST_SUCCESS",
                    "LIBRARY_SUCCESS",
                    "INVEST_POTENTIAL_SUCCESS"
                ];
            } else {

            }



            break;

        case "/deals":
            yield put(dealsList('overdue'));
            yield put(dealsList('contracts'));
            yield put(dealsList('payments'));

            actions = [
                "DEALS_LIST_OVERDUE_SUCCESS",
                "DEALS_LIST_CONTRACTS_SUCCESS",
                "DEALS_LIST_PAYMENTS_SUCCESS"
            ];

            break;
        case "/deals/contracts":
        case "/deals/payments":
        case "/deals/overdue":
            var dealsState = yield select(deals);
            if (!dealsState.overdue.isOk) {
                yield put(dealsList('overdue'));
                actions.push("DEALS_LIST_OVERDUE_SUCCESS")
            }
            if (!dealsState.payments.isOk) {
                yield put(dealsList('payments'));
                actions.push("DEALS_LIST_CONTRACTS_SUCCESS")
            }
            if (!dealsState.contracts.isOk) {
                yield put(dealsList('contracts'));
                actions.push("DEALS_LIST_PAYMENTS_SUCCESS")
            }

            break;

        case "/applications":
            yield put(applicationsList('draft'));
            yield put(applicationsList('in-progress'));
            yield put(applicationsList('archive'));
            yield put(getDocumentTypes());
            yield put(archiveSearchInfo());

            actions = [
                "APPLICATION_LIST_DRAFT_SUCCESS",
                "APPLICATION_LIST_IN-PROGRESS_SUCCESS",
                "APPLICATION_LIST_ARCHIVE_SUCCESS",
                "ARCHIVE_SEARCH_INFO_APPLICATION_SUCCESS"
            ];

            break;
        // case "/applications/drafts":
        // case "/applications/progress":
        // case "/applications/archive":
        //     if (location.pathname != '/applications') {
        //         yield put(applicationsList('draft'));
        //         yield put(applicationsList('in-progress'));
        //         yield put(applicationsList('archive'));
        //         yield put(archiveSearchInfo());
        //
        //         actions = [
        //             "APPLICATION_LIST_DRAFT_SUCCESS",
        //             "APPLICATION_LIST_IN-PROGRESS_SUCCESS",
        //             "APPLICATION_LIST_ARCHIVE_SUCCESS",
        //             "ARCHIVE_SEARCH_INFO_APPLICATION_SUCCESS"
        //         ];
        //     }
        //
        //     break;

        case "/projects":
        case "/projects/archive":
        case "/projects/active":
            yield put(projectsList());
            yield put(projectsFilter());

            actions = [
                "PROJECT_FILTER_SUCCESS",
                "PROJECT_LIST_SUCCESS"
            ];

            break;

        case "/documents":
            var params = [];
            if (action.state) {
                for (var key in action.state) {
                    params.push(key+"="+action.state[key]);
                }
            }
            yield put(documentsList(params.length ? params.join('&') : ''));
            yield put(documentsSearchInfo());
            yield put(documentsWidgetInfo());

            actions = [
                "DOCUMENTS_SEARCH_INFO_SUCCESS",
                "DOCUMENTS_LIST_SUCCESS",
                "DOCUMENTS_WIDGET_INFO_SUCCESS"
            ];
            finCnt = 1;

            break;

        case "/promotions":
            yield put(promotionsList(action.state && action.state.topic ? "topic_id="+action.state.topic : ''));
            yield put(topicsList());

            actions = [
                "PROMOTIONS_LIST_SUCCESS",
                "TOPICS_LIST_SUCCESS"
            ];

            break;

        case "/contacts":
            var catalogState = yield select(catalog);
            if (!catalogState.info || !catalogState.info.OfficeId) {
                yield take('LAYOUT_INFO_SUCCESS');
            }
            catalogState = yield select(catalog);
            yield put(contactsList());
            yield put(detailContact(catalogState.info.OfficeId));

            actions = [
                "CONTACTS_LIST_SUCCESS",
                "DETAIL_CONTACT_SUCCESS"
            ];

            break;

        case "/library":
            yield put(library());

            actions = [
                "LIBRARY_SUCCESS"
            ];

            break;

        case "/help":
            yield put(faq());

            actions = [
                "FAQ_SUCCESS"
            ];

            break;

        case "/profile":
            yield put(profileInfo());

            actions = [
                "PROFILE_INFO_SUCCESS"
            ];

            break;

        case "/users":
            yield put(usersList());
            yield put(subscriptions());

            actions = [
                "USERS_LIST_SUCCESS",
                "SUBSCRIPTIONS_SUCCESS"
            ];

            break;

        case "/companies":
            yield put(companiesList());

            actions = [
                "COMPANIES_LIST_SUCCESS"
            ];

            break;
        case "/notifications":
            yield put(notificationsList());

            actions = [
                "NOTIFICATIONS_LIST_SUCCESS"
            ];

            break;
        case "/communications":
            yield put(communicationsList());
            yield put(communicationsSearchInfo());

            actions = [
                "COMMUNICATIONS_LIST_SUCCESS",
                "COMMUNICATIONS_SEARCH_INFO_SUCCESS"
            ];

            break;
        case "/insurance":
            yield put(getInsuranceCommunications());
            yield put(getInsuranceCompanies());
            yield put(getInsuranceDocuments());
            yield put(autoComplete('projectContracts', ''))

            actions = [
                "GET_INSURANCE_COMMUNICATIONS_SUCCESS",
                "GET_INSURANCE_COMPANIES_SUCCESS",
                "GET_INSURANCE_DOCUMENTS_SUCCESS",
            ];

            break;
        default:
            var id = null;
            if (action.pathname.indexOf("projects/dfa") != -1) {
                id = action.pathname.substr(action.pathname.lastIndexOf("/") + 1);
                yield put(documentView(id));
                yield put(documentEquipments(id, 1));
                actions = [
                    "DOCUMENT_EQUIPMENTS_SUCCESS",
                    "DOCUMENT_VIEW_SUCCESS"
                ];
            } else if (action.pathname.indexOf("projects") != -1) {
                id = action.pathname.substr(action.pathname.lastIndexOf("/") + 1);
                yield put(projectView(id));
                yield put(projectDocuments(id, 1));
                actions = [
                    "PROJECT_DOCUMENTS_SUCCESS",
                    "PROJECT_VIEW_SUCCESS"
                ];
            } else if (action.pathname.indexOf("applications") != -1) {
                yield put(getDocumentTypes());
                id = action.pathname.substr(action.pathname.lastIndexOf("/") + 1);
                if (action.pathname.indexOf("view") != -1) {
                    yield put(detailApplication(id));
                    actions = [
                        "APPLICATION_INFO_SUCCESS"
                    ];
                } else if (action.pathname.indexOf("update") != -1){
                    yield put(applicationFormInfo(id));
                    yield put(viewApplication(id));
                    actions = [
                        "FORM_INFO_APPLICATION_SUCCESS",
                        "DETAIL_APPLICATION_SUCCESS"
                    ];
                } else {
                    yield put(clearApplicationForm());
                    yield put(applicationFormInfo());
                    actions = [
                        "FORM_INFO_APPLICATION_SUCCESS"
                    ];
                    if (action.state && action.state.from == 'recreate') {
                        yield put(viewApplication(action.state.id));
                        actions.push("DETAIL_APPLICATION_SUCCESS");
                    }
                }
            } else if (action.pathname.indexOf("communications") != -1) {

                if (action.pathname.indexOf("create") != -1) {
                    yield put(communicationsFormInfo());
                    actions = ["COMMUNICATIONS_FORM_INFO_SUCCESS"];
                } else {
                    id = action.pathname.substr(action.pathname.lastIndexOf("/") + 1);
                    yield put(communicationsDetail(id));
                    actions = ["COMMUNICATIONS_DETAIL_SUCCESS"];
                }
            } else if (action.pathname.indexOf("companies") != -1) {
                id = action.pathname.substr(action.pathname.lastIndexOf("/") + 1);
                yield put(companiesDetail(id));
                actions = ["COMPANIES_DETAIL_SUCCESS"];
            }
    }

    while (actions.length > finCnt) {
        var act = yield take(actions);
        var ind = actions.indexOf(act.type);
        actions.splice(ind,1);
    }

    yield put({type: action.type+"_SUCCESS"});
}

