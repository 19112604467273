import {
    call,
    put,
    take,
    select
} from 'redux-saga/effects';

import {
    fetchClientApi,
    fetchFileApi,
    checkErrors,
    clientApiUrl
} from '../tools/api-helper';

import fileDownload from "js-file-download";

import {checkToken} from "./auth";

import {getFileSize} from "../tools/tools";

const catalog = state => state.catalog;
const communications = state => state.communications;

export function communicationsList(params = null) {
    return {
        type: params ? 'COMMUNICATIONS_LIST_SEARCH' : 'COMMUNICATIONS_LIST',
        params
    }
}

export function* fetchCommunicationsList(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, pagination, error } = yield call( () => {
            return fetchClientApi("/communications"+(action.params ? "?"+encodeURI(action.params) : ""), {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (pagination) data.pagination = pagination;
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function communicationsWidget() {
    return {
        type: 'COMMUNICATIONS_WIDGET'
    }
}

export function* fetchCommunicationsWidget(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/communications/widget", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (!error && (status === 200 || status === 401)) {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function communicationsSearchInfo() {
    return {
        type: 'COMMUNICATIONS_SEARCH_INFO'
    }
}

export function* fetchCommunicationsSearchInfo(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/communications/search-info", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function communicationsFormInfo() {
    return {
        type: 'COMMUNICATIONS_FORM_INFO'
    }
}

export function* fetchCommunicationsFormInfo(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/communications/form-info", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function setRating(id, rating, comment) {
    return {
        type: 'COMMUNICATIONS_SET_RATING',
        id,
        rating,
        comment
    }
}

export function* fetchCommunicationsSetRating(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        var catalogState = yield select(catalog);
        var ratingList = catalogState.communicationRatings && catalogState.communicationRatings.filter((item) => {return item.RatingId == action.rating});
        var value = ratingList && ratingList.length && ratingList[0].Value;

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/communications/"+action.id, {
                method: 'PUT',
                body: 'RatingId='+action.rating+'&Comment='+action.comment,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, {id: action.id, rating: action.rating, value}, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function communicationsCreateDraft(communicationTypeId) {
    return {
        type: 'COMMUNICATIONS_CREATE_DRAFT',
        communicationTypeId
    }
}

export function* fetchCommunicationsCreateDraft(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        var body = new FormData();
        body.append('CommunicationTypeId', action.communicationTypeId);

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/communications/draft", {
                method: 'POST',
                body: body,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function communicationsSend(communicationId, message) {
    return {
        type: 'COMMUNICATIONS_SEND',
        communicationId,
        message,
        isDraft: 1
    }
}

export function communicationsAddMessage(communicationId, message) {
    return {
        type: 'COMMUNICATIONS_ADD_MESSAGE',
        communicationId,
        message,
        isDraft: 0
    }
}

export function* fetchCommunicationsMessage(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        var body = new FormData();
        body.append('Message', action.message);
        body.append('IsDraft', action.isDraft);

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/communications/"+action.communicationId+"/messages", {
                method: 'POST',
                body: body,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
            if (status == 201) {
                if (action.isDraft) {
                    yield put(clearDraft());
                    yield put(communicationsWidget());
                } else {
                    yield put(communicationsDetail(action.communicationId))
                }
            }
        }

    } catch(e) {
        alert(e);
    }

}

export function communicationsAddFile(communicationId, file) {
    return {
        type: 'COMMUNICATIONS_ADD_FILE',
        communicationId,
        file
    }
}

export function communicationsDeleteFile(communicationId, fileId) {
    return {
        type: 'COMMUNICATIONS_DELETE_FILE',
        communicationId,
        fileId
    }
}

export function* fetchCommunicationsAddFile(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        var body = new FormData();
        body.append('file', action.file);

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/communications/"+action.communicationId+"/files", {
                method: 'POST',
                body: body,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, {
                ...data,
                communicationId: action.communicationId,
                file: action.file
            }, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function* fetchCommunicationsDeleteFile(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/communications/files/"+action.fileId, {
                method: 'DELETE',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, action, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function clearDraft() {
    return {
        type: "CLEAR_DRAFT"
    }
}

export function communicationsUpdateFile(id, fileId, fileName) {
    return {
        type: 'COMMUNICATIONS_UPDATE_FILE',
        id,
        fileId,
        fileName
    }
}

export function* fetchCommunicationsUpdateFile(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');



        const { status, data, error } = yield call( () => {
            return fetchClientApi("/communications/files/"+action.fileId, {
                method: 'PUT',
                body: "name="+action.fileName,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, {...action, data}, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function communicationsDownloadFile(fileId, fileName) {
    return {
        type: 'COMMUNICATIONS_DOWNLOAD_FILE',
        fileId,
        fileName
    }
}

export function* fetchCommunicationsDownloadFile(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');



        const { status, data, error } = yield call( () => {
            return fetchFileApi(clientApiUrl+"/communications/files/"+action.fileId, localStorage.getItem('authToken'))
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status == 200) fileDownload(data, action.fileName);
            else yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function communicationsDetail(id) {
    return {
        type: 'COMMUNICATIONS_DETAIL',
        id
    }
}

export function* fetchCommunicationsDetail(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');



        const { status, data, error } = yield call( () => {
            return fetchClientApi("/communications/"+action.id, {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function communicationsDecline(id, message) {
    return {
        type: 'COMMUNICATIONS_DECLINE',
        id,
        message
    }
}

export function* fetchCommunicationsDecline(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        var data = new FormData();
        data.append('message', action.message);

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/communications/"+action.id, {
                method: 'POST',
                body: data,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
            if (status === 200) {
                yield put(communicationsDetail(action.id));
            }
        }

    } catch(e) {
        alert(e);
    }

}