import React from "react";
import {phoneMask} from "../../tools/mask";

export default function OfficeEmployees({list, translate}) {
    return <div className="page__contacts-wrap page__contacts-wrap_crew">
        {!!list ?
            <React.Fragment>
                <p className="page__contacts-crew-heading">{translate('Ваша проектная команда')}</p>
                <div className="contacts-crew contacts-crew_adapt-default">
                    {list.map((item, key) => <EmployerCard key={key} {...item}/>)}
                </div>
            </React.Fragment>
        : null}
    </div>
}

function EmployerCard({EmployeeName, Email, Phone, Photo, RoleName}){

    return <div className="contacts-crew__item">
        <div className="crewman crewman_adapt-default">
            {/* <div className="crewman__img-wrap">
                <img className="crewman__img"
                     src={Photo} alt=""
                     height="80" width="80"
                />
            </div> */}
            <p className="crewman__name">{EmployeeName}</p>
            <p className="crewman__position">{RoleName}</p>
            <p className="crewman__phone">{phoneMask(Phone, 'def')}</p>
            <a className="crewman__email" href={"mailto:"+Email}>{Email}</a>
        </div>
    </div>
}