import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import NewMessageForm from "../../components/communications/NewMessageForm";
import {Link} from "react-router-dom";
import {cancelFile, clearBuffer} from "../../actions/file-buffer";
import {
    communicationsCreateDraft,
    communicationsSend,
    communicationsAddFile,
    communicationsDeleteFile,
    communicationsUpdateFile,
    clearDraft
} from "../../actions/communications";

class CommunicationDraftContainer extends Component{

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps(nextProps){

        if (Object.keys(this.props.detail).length && !Object.keys(nextProps.detail).length) {
            nextProps.history.push("/communications");
        }
    }

    componentWillUnmount(){
        if (Object.keys(this.props.detail).length) {
            this.props.clearDraft();
        }
    }

    render(){
        return <div className="page page_projects page_request">
            <div className="page__main page__main_projects">
                <div className="page__content-container">
                    <div className="page__back-link">
                        <Link className="u-back-link" to="/communications"><span>{this.context.translate('Обращения')}</span></Link>
                    </div>
                    <h1 className="page__title">{this.context.translate('Новое обращение')}</h1>
                    <div className="page__request-add">
                        <NewMessageForm {...this.props} isFirst={true} fromHelp={this.props.location.state === "help"}/>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default connect(
    state => ({
        fileBuffer: state.fileBuffer,
        fileParams: state.catalog.fileParams,
        detail: state.communications.draft || {},
        files: state.communications.files || [],
        catalog: {communicationTypes: state.catalog.communicationTypes}
    }),
    (dispatch) => {
        return {
            addDocument: (id, file) => dispatch(communicationsAddFile(id, file)),
            removeDocument: (id, fileId) => dispatch(communicationsDeleteFile(id, fileId)),
            updateDocument: (id, fileId, fileName) => dispatch(communicationsUpdateFile(id, fileId, fileName)),
            cancelDocument: (id) => dispatch(cancelFile(id)),
            clearBuffer: () => dispatch(clearBuffer()),
            createDraft: (typeId) => dispatch(communicationsCreateDraft(typeId)),
            send: (id, message) => dispatch(communicationsSend(id, message)),
            clearDraft: () => dispatch(clearDraft())
        }
    }
)(CommunicationDraftContainer);