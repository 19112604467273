import React, {Component} from "react";
import {connect} from "react-redux";
import Help from "../../components/help/Help";
import {faq, faqAction} from "../../actions/help";
import CategoryItem from "../../components/help/CategoryItem";
import QuestionItem from "../../components/help/QuestionItem";
import Preloader from "../../components/common/Preloader";
import PropTypes from "prop-types";


class HelpContainer extends Component{

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    search = (query) => {
        this.props.getList(query)
    }

    render(){
        let {
            data,
            isOk
        } = this.props.help;

        return <Help search={this.search}>
            {isOk ? data.map((catItem, catIndex) => <CategoryItem key={catIndex}
                                                                  label={catItem.title[this.context.currentLanguage.alias]}
            >
                {catItem.questions.map((item, key) => <QuestionItem key={key}
                                                                  item={item}
                                                                   action={this.props.makeAction}
                />)}
            </CategoryItem>) : null}
        </Help>
    }
}
export default connect(
    state => ({
        help: state.help
    }),
    (dispatch) => {
        return {
            getList: (query) => dispatch(faq(query)),
            makeAction: (id, action) => dispatch(faqAction(id, action))
        }
    }
)(HelpContainer);