import {
    call,
    put,
    take
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors, fetchFileApi
} from '../tools/api-helper';
import fileDownload from "js-file-download";
import {clientApiUrl} from "../tools/api-helper";
import {checkToken} from "./auth";

export function projectsList(params = null) {
    return {
        type: 'PROJECT_LIST'+(params ? "_SEARCH" : ""),
        params
    }
}


export function* fetchProjectsList(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/projects"+(action.params ? '?'+encodeURI(action.params) : ''), {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function projectView(id) {
    return {
        type: 'PROJECT_VIEW',
        id
    }
}


export function* fetchProjectView(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/projects/"+action.id, {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function projectDocuments(id, page) {
    return {
        type: 'PROJECT_DOCUMENTS',
        id,
        page
    }
}


export function* fetchProjectDocuments(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, pagination, error } = yield call( () => {
            return fetchClientApi("/projects/"+action.id+"/documents?page="+action.page, {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status === 200) {
                data.pagination = pagination;
                data.projectId = action.id;
            }
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function projectsFilter() {
    return {
        type: 'PROJECT_FILTER'
    }
}

export function* fetchProjectFilter(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/projects/info", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function downloadContractSchedule(id) {
    return {
        type: 'DOWNLOAD_CONTRACT_SCHEDULE',
        id
    }
}


export function* fetchDownloadContractSchedule(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchFileApi(clientApiUrl+"/documents/"+action.id+"/schedule", localStorage.getItem('authToken'))
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            fileDownload(data, "schedule-"+action.id+".xlsx");
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}



export function documentView(id) {
    return {
        type: 'DOCUMENT_VIEW',
        id
    }
}


export function* fetchDocumentView(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/documents/"+action.id, {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function documentEquipments(id, page) {
    return {
        type: 'DOCUMENT_EQUIPMENTS',
        id,
        page
    }
}


export function* fetchDocumentEquipments(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, pagination, error } = yield call( () => {
            return fetchClientApi("/documents/"+action.id+"/equipments?page="+action.page, {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status === 200) data.pagination = pagination;
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}