import React, {Component} from "react";
import DropZoneElement from "react-dropzone";
import Modal from "./Modal";
import FileIcon from "./FileIcon";
import PropTypes from "prop-types";
import FormField from "./form/FormField";

export default class DocumentLoader extends Component{

    constructor(props){
        super(props);

        this.state = {
            files: [],
            errors: {},
            fullsize: true,
            loadingCnt: 0,
            loadedCnt: 0,
            typesWithComment: props.documentTypes ? props.documentTypes.filter((type) => type.isCommentRequired).map((type) => type.value) : []
        }

        this.forApplication = !!props.documentTypes;
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    static childContextTypes = {
        validate: PropTypes.bool
    }

    getChildContext(){
        return {
            validate: true
        }
    }

    toggleSize = () => {
        this.setState({
            fullsize: !this.state.fullsize
        })
    }

    componentWillReceiveProps(nextProps){
        if (this.state.files != nextProps.files) {
            this.setState((prevState) => {
                return {
                    files: [
                        ...nextProps.files.filter(function(item){
                            return item.status != 'error';
                        }),
                        ...prevState.files.filter(function(item){
                            return item.status == 'error';
                        })
                    ],
                    loadingCnt: nextProps.files.filter((file) => {return file.status == 'loading'}).length,
                    loadedCnt: nextProps.files.filter((file) => {return file.status == 'success'}).length
                }
            });
        }
    }

    getError = (file) => {
        var maxSize = this.props.maxSize ? this.props.maxSize : 2*1024*1024;

        if (file.size > maxSize) {
            return this.context.translate("Размер файла {name} превышает {size} мб, пожалуйста, загрузите другой.", {
                name: file.name,
                size: maxSize/(1024*1024)
            });
        }

        if (this.props.accept && this.props.accept.indexOf(file.name.substr(file.name.lastIndexOf('.') + 1)) == -1) {
            return this.context.translate("Файл {name} имеет недопустимый формат, пожалуйста, загрузите другой.", {
                name: file.name
            })
        }
        return this.context.translate("Ошибка загрузки файла");
    }

    onDrop = (acceptedFiles, rejectedFiles) => {
        var files = [...this.state.files],
        loadingCnt = this.state.loadingCnt;

        acceptedFiles.map((file) => {
            file.ID = Date.now()+file.size;
            if (this.forApplication) {
                file.status = 'ready';
                file.additionalData = {};
                files.push(file);
            } else {
                file.status = 'loading';
                files.push(file);
                loadingCnt++;
                this.props.addDocument(file);
            }
        });
        rejectedFiles.map((file, key) => {
            file.status = 'error';
            file.error = this.getError(file);
            files.push(file);
        });

        this.setState({files, loadingCnt})
    }

    onRemove = (id) => {
        return () => {
            this.setState((prevState) => {
                return {
                    files: prevState.files.filter((file) => file.ID !== id)
                }
            })
            this.props.removeDocument(id);
        }
    }

    onCancel = (id, isLocal = false) => {
        return () => {
            if (isLocal) {
                this.setState((prevState) => {
                    return {
                        files: prevState.files.filter((file) => file.ID !== id)
                    }
                })
            } else {
                this.props.cancelDocument(id);
            }
        }
    }

    onNameChange = (id) => {
        return (e) => {
            if (e.target.value) {
                this.props.updateDocument(id, e.target.value)
            }
        }
    }

    onFieldChange = (id) => {
        return (attribute, value) => {
            this.setState((prevState) => {
                let errors = prevState.errors;
                if (errors[id]) {
                    delete errors[id][attribute];
                    if (attribute === "DocumentTypeId") {
                        delete errors[id]["Comment"]
                    }
                }
                return {
                    files: prevState.files.map((file) => {
                        if (file.ID === id) {
                            file.additionalData[attribute] = value;
                            if (attribute === "DocumentTypeId" && prevState.typesWithComment.indexOf(value) === -1) {
                                delete file.additionalData.Comment
                            }
                        }
                        return file;
                    }),
                    errors
                }
            })
        }
    }

    uploadAll = () => {
        let errors = {};
        this.state.files.forEach((file) => {
            if (file.status === 'ready') {
                if (!file.additionalData.DocumentTypeId) {
                    errors[file.ID] = {DocumentTypeId: "Необходимо указать тип документа"}
                } else if (this.state.typesWithComment.indexOf(file.additionalData.DocumentTypeId) !== -1 && !file.additionalData.Comment) {
                    errors[file.ID] = {Comment: "Необходимо указать описание документа"}
                }
            }
        })
        if (!Object.keys(errors).length) {
            this.setState((prevState) => {
                let loadingCnt = 0;
                const files = prevState.files.map((file) => {
                    if (file.status === 'ready') {
                        file.status = 'loading';
                        loadingCnt++
                    }
                    return file
                })
                return {
                    files,
                    loadingCnt
                }
            }, () => {
                this.state.files.map((file) => {
                    if (file.status === 'loading') {
                        this.props.addDocument(file);
                    }
                })
            })
        } else {
            this.setState({errors})
        }
    }

    getButton = () => {
        let {
            files,
            loadingCnt,
            loadedCnt
        } = this.state;

        if (loadingCnt) {
            return <button className="u-btn u-btn_adapt-type_wide application-upload__button"
                           onClick={this.toggleSize}>{this.context.translate('Скрыть')}</button>
        }

        if (this.forApplication) {
            if (files.filter((file) => file.status === 'ready').length) {
                return <button className="u-btn u-btn_adapt-type_wide application-upload__button" onClick={this.uploadAll}>{this.context.translate('Готово')}</button>
            }
        }

        if (loadedCnt) {
            if (this.forApplication) {
                this.props.close();
            }
            return <button className="u-btn u-btn_adapt-type_wide application-upload__button" onClick={this.props.close}>{this.context.translate(this.forApplication ? 'Закрыть' : 'Готово')}</button>
        }
    }
    
    render(){
        let {
            accept,
            acceptDescription,
            maxSize = 2*1024*1024,
            minSize = 128,
            maxCount,
            close,
            documentTypes
        } = this.props;

        let {
            files,
            errors,
            fullsize,
            loadingCnt,
            loadedCnt
        } = this.state;

        return fullsize ?

            <Modal classNameContent="modal modal_visible modal_fullscreen fancybox-content" onClickBackground={loadingCnt ? this.toggleSize : close}>
                <DropZoneElement ref={(el) => { this.dropzone = el; }}
                     className={"application-upload application-upload_adapt-default"+(files.length ? " application-upload_with-files" : "")}
                     activeClassName="application-upload_dragging"
                     onDrop={this.onDrop}
                     maxSize={maxSize}
                     minSize={minSize}
                     accept={accept}
                     multiple={!maxCount || maxCount > 1}
                     disabled={false}
                     disableClick={true}
                >
                    <p className="application-upload__title">{this.context.translate('Добавление документа')}</p>
                    <div className="application-upload__upload">
                        <div className="application-upload__info">
                            <p className="application-upload__text"><span className="u-tablet-hide">{this.context.translate('Поместите файлы сюда или')}</span>&nbsp;
                                <a className="u-dotted-link u-tablet-hide" href="#" onClick={(e) => { e.preventDefault(); this.dropzone.open() }}><span>{this.context.translate('загрузите их со своего компьютера')}</span></a>
                                <a className="u-dotted-link u-desktop-hide" href="#" onClick={(e) => { e.preventDefault(); this.dropzone.open() }}><span>{this.context.translate('Загрузите файлы со своего устройства')}</span></a>
                            </p>
                        </div>
                        <div className="application-upload__file-specs">
                            <p className="application-upload__text u-gray">{this.context.translate('Поддерживаемые файлы')}: {acceptDescription || accept}.</p>
                            <p className="application-upload__text u-gray">{this.context.translate('Вес файла: не более {size} Мб.', {size: maxSize/(1024*1024)})}</p>
                        </div>
                    </div>
                    {files.length ? <div className="application-upload__files">
                        <p className="application-upload__files-title u-mobile-hide">
                            {this.context.translate(documentTypes ? 'Тип документа' : 'Название в онлайн-офисе')}
                        </p>
                        {files.map((file) => <DropzoneFile key={file.ID} file={file}
                                                                onRemove={this.onRemove(file.ID)}
                                                                onCancel={this.onCancel(file.ID, file.status === 'ready')}
                                                                onNameChange={this.onNameChange}
                                                                onFieldChange={this.onFieldChange(file.ID)}
                                                                errors={errors[file.ID]}
                                                                needComment={file.additionalData ? this.state.typesWithComment.indexOf(file.additionalData.DocumentTypeId) !== -1 : false}
                                                                documentTypes={documentTypes}
                            />
                        )}
                    </div> : null}
                    {this.getButton()}
                </DropZoneElement>
            </Modal>

            :

            <div className={"application-upload-mini application-upload-mini_adapt-default "+(loadingCnt ? "application-upload-mini_uploading" : "application-upload-mini_uploaded")}>
                <div className="application-upload-mini__container">
                    <div className="application-upload-mini__inner">
                        <div className="application-upload-mini__content">
                            <p className="application-upload-mini__action">{loadingCnt ? this.context.translate("Загрузка файла") : this.context.translate("Загрузка {n} файлов прошла успешно", {n: loadedCnt})}</p>
                            {loadingCnt ? <div className="application-upload-mini__controls">
                                <p className="application-upload-mini__control">
                                    <span className="u-gray">{this.context.translate('Осталоcь')}</span>
                                    <a className="application-upload-mini__link u-dotted-link" href="#" onClick={this.toggleSize}><span>{loadingCnt} {this.context.translate('файлов')}</span></a>
                                </p>
                            </div> :  <div className="application-upload-mini__controls">
                                    <p className="application-upload-mini__control">
                                        <a className="application-upload-mini__link u-dotted-link" href="#" onClick={this.toggleSize}><span>{this.context.translate('Посмотреть файлы')}</span></a>
                                    </p>
                                    <p className="application-upload-mini__control">
                                        <a className="application-upload-mini__link u-dotted-link" href="#" onClick={close}><span>{this.context.translate('Закрыть')}</span></a>
                                    </p>
                                </div>}
                        </div>
                        <div className="application-upload-mini__status"/>
                    </div>
                </div>
            </div>;
    }
}
class DropzoneFile extends Component{

    constructor(props){
        super(props);

        this.state = {
            loadPercent: 0,
            filename: props.file.filename || ''
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillMount(){
        if (this.props.file.status == 'loading') {
            this.loader = setInterval(() => {
                this.setState((prevState) => {
                    return {
                        loadPercent: prevState.loadPercent + 1
                    }
                })
            }, 500)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.file.status == 'loading') {
            this.loader = setInterval(() => {
                this.setState((prevState) => {
                    return {
                        loadPercent: prevState.loadPercent + 1
                    }
                })
            }, 500)
        }
    }

    componentWillUnmount(){
        clearInterval(this.loader)
    }

    render(){
        let {
            file,
            errors = {},
            documentTypes,

            onRemove,
            onCancel,
            onNameChange,
            onFieldChange,
            needComment,
        } = this.props;

        return <div className="application-upload__file">
            <div className="application-upload__file-loader">
                <div className={"attach attach_application " + (file.status == 'success' ? "attach_application-uploaded" :
                    (file.status == 'error' ? "attach_application-error" : "attach_application-uploading"))}
                     style={file.status == 'loading' ? {background: "linear-gradient(to right, #d4f5fc "+this.state.loadPercent+"%, #fff "+this.state.loadPercent+"%, #fff)"} : null}
                >
                    <div className="attach__type"><FileIcon ext={file.name.substr(file.name.lastIndexOf('.') + 1)}/></div>
                    <div className="attach__content">
                        <p className="attach__name">
                            {file.status == 'error' ? file.error : file.name}
                        </p>
                    </div>
                    <button className="attach__remove" onClick={onRemove}/>
                    <button className="attach__cancel" onClick={onCancel}/>
                </div>
            </div>
            <div className="application-upload__file-name">
            {documentTypes ? <React.Fragment>
                <div className="select-large">
                    <FormField fieldConfig={{
                        placeholder: "Укажите тип документа",
                        type: "select",
                        options: documentTypes,
                        value: file.additionalData ? file.additionalData.DocumentTypeId : '',
                        error: errors.DocumentTypeId,
                        disabled: file.status !== 'ready'
                    }} attribute={"DocumentTypeId"} onChange={onFieldChange} />
                </div>
                {needComment ? <div>
                    <FormField fieldConfig={{
                        placeholder: "Введите описание документа",
                        value: file.additionalData ? file.additionalData.Comment : '',
                        error: errors.Comment
                    }} attribute={"Comment"} onChange={onFieldChange} />
                </div> : null}
            </React.Fragment> :
                    <div className="text-input">
                    <label className="form__field text-input__wrap">
                        <div className={"form__input text-input__input-wrap"+(file.filenameError ? " error" : null)}>
                            <input className="text-input__input"
                                   type="text"
                                   placeholder={this.context.translate("Название")}
                                   disabled={file.status != 'success'}
                                   value={this.state.filename}
                                   onChange={(e) => {this.setState({filename: e.target.value})}}
                                   onBlur={onNameChange(file.ID)}
                                   key={file.ID + "-filename"}
                            />
                            {file.filenameError ? <span className="error">{file.filenameError}</span> : null}
                        </div>
                    </label>
                </div>}
            </div>
        </div>
    }
}