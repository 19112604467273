import React, {Component} from "react";
import { connect } from 'react-redux';
import {Link} from "react-router-dom";
import Rating from "../../communications/Rating";
import PropTypes from "prop-types";
import {communicationsDecline, communicationsWidget, setRating} from "../../../actions/communications";

/**
 * Component for header messages
 */
class ProfileMessages extends Component {

    constructor(props){
        super(props);


        this.state = {
            active: false
        };
    }

    static contextTypes = {
        translate: PropTypes.func
    }

    onClick = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        var active = !this.state.active;
        var event = document.createEvent("Event");
        event.initEvent("click", false, true);
        document.dispatchEvent(event);
        this.setState({active});
    };

    componentWillMount() {
        this.props.getCommunicationsWidget();
        this.communicationsInterval = setInterval(() => {this.props.getCommunicationsWidget()}, 60000);
    }

    componentDidMount(){
        document.addEventListener('click', this.windowClick);
    }

    componentWillUnmount(){
        document.removeEventListener('click', this.windowClick);
        clearInterval(this.communicationsInterval);
    }

    windowClick = () => {
        this.setState({active: false});
    };

    setRating = (id) => {
        return (rating, comment) => {
            this.props.setRating(id, rating, comment)
        }
    }

    decline = (id) => {
        return (comment) => {
            this.props.decline(id, comment)
        }
    }

    render() {
        let {
            communications,
            ratingList
        } = this.props;

        if (communications) {
            let {
                list,
                newCount,
                totalCount,
            } = communications;

            return <div className="profile-messages active">
                <div className="profile-messages__head" onClick={this.onClick}>
                    {newCount != 0 ? <span className="profile-messages__num">{newCount}</span> : null}
                    {!this.state.active ? <div className="icon-appeal-txt">Обращения</div> : null}
                </div>
                {this.state.active ? <div className="profile-messages__dropdown">
                    <div className="profile-messages__dropdown-inner">
                        {list.map((item) => <Link to={"/communications/" + item.CommunicationId}
                                                  key={item.CommunicationId}>
                            <div className={"profile-notice" + (item.HasUnreadMessages ? " message_in-progress" : "")}>
                                <div className="profile-notice__main">
                                    <p className="profile-notice__type">{item.TypeName} №{item.Number} от {item.CommunicationDate}</p>
                                    <p>
                                        <strong>{item.AuthorName}:&nbsp;</strong>
                                        <span>{item.CommunicationTextPreview}</span>
                                    </p>
                                </div>
                                {item.CanRate || item.RatingValue ? <Rating clasName="profile-notice__aside-rating"
                                                                            rating={item.RatingValue}
                                                                            ratingList={ratingList}
                                                                            setRating={this.setRating(item.CommunicationId)}
                                                                            decline={this.decline(item.CommunicationId)}
                                                                            canComment={item.CanComment}
                                /> : null}
                            </div>
                        </Link>)}
                        <div className="profile-messages__more-appeal">
                            <Link to={"/communications/create"} className="profile-messages__appeal">
                                <span>{this.context.translate('Обратиться')}</span>
                            </Link>
                            <Link to={"/communications"} className="profile-messages__more">
                                <span>{this.context.translate('Показать все')}<sup>({totalCount})</sup></span>
                            </Link>
                        </div>
                    </div>
                </div> : null}
            </div>
        }
        return null;
    }
}

export default connect(state => ({
    communications: state.communications.widget,
    ratingList: state.catalog && state.catalog.communicationRatings || [],
}), (dispatch) => {
    return {
        getCommunicationsWidget: () => dispatch(communicationsWidget()),
        setRating: (id, rating, comment) => dispatch(setRating(id, rating, comment)),
        decline: (id, comment) => dispatch(communicationsDecline(id, comment)),
    }
})(ProfileMessages)