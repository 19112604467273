import React, {Component} from "react";
import Collapser from "../common/Collapser";
import CheckBox from "../common/form/CheckBox";

export default function NotificationSettings({notificationSettings, changeSelection, isChecked, changeCompanyAccess, hasAccess, isDisabled}){

    return <div className="users-modal__companies">
            {notificationSettings.map((item) => <NotificationSetting {...item}
                                                                          changeCompanyAccess={changeCompanyAccess}
                                                                          hasAccess={hasAccess}
                                                                          changeSelection={changeSelection}
                                                                          isChecked={isChecked}
                                                                          isDisabled={isDisabled}
                                                                          key={item.CompanyId}/>
            )}
    </div>
}

function NotificationSetting({CompanyId, CompanyName, Subscriptions, changeSelection, isChecked, changeCompanyAccess, hasAccess, isDisabled}){

    return <div className="users-modal__company">
        <div className="collapser-before-text">
            <CheckBox id={CompanyId} label={CompanyName} attribute={CompanyId} value={hasAccess(CompanyId)} onChange={changeCompanyAccess} className={isDisabled ? "form__checkbox disabled" : "form__checkbox"} />
        </div>
        <Collapser label="Показать уведомления"
                   labelClassName="u-more-link"
                   labelActiveClassName="u-more-link_open"
        >
            <div className="profile-form__notice-form-box">
                <div className="notice-form notice-form_adapt_default">
                    <div className="notice-form__container">
                        <div className="notice-form__wrap">
                            {Subscriptions.map((item, key) => <NotificationSettingItem {...item} isDisabled={isDisabled || !hasAccess(CompanyId)} CompanyId={CompanyId} isChecked={isChecked} changeSelection={changeSelection} key={key}/>)}
                        </div>
                    </div>
                </div>
            </div>
        </Collapser>
    </div>
}

class NotificationSettingItem extends Component{



    render(){
        var {CompanyId, id, name, notificationWay, changeSelection, isChecked, isDisabled} = this.props;

        return <div className="notice-form__item">
            <div className="notice-form__label">{name}</div>
            <div className="notice-form__check-list">
                {notificationWay.map((item, key) => <div className="notice-form__check-list-item" key={key}>
                    <CheckBox id={CompanyId+"-"+id+"-"+item.id} className={isDisabled ? "form__checkbox disabled" : "form__checkbox"} label={item.name} value={isChecked(CompanyId, id, item.id)} onChange={() => {changeSelection(CompanyId, id, item.id)}}/>
                </div>)}
            </div>
        </div>
    }
}