import React,{Component} from "react";
import PropTypes from "prop-types";
import OrdersListItem from "./OrdersListItem";
import Pagination from "../common/Pagination";
import TogglePanel from "../common/TogglePanel";
import OrdersSearch from "./OrdersSearch";
import {Redirect} from "react-router-dom";
import {serverDateFormatMask} from "../../tools/mask";

export default class OrdersListArchive extends Component{

    constructor(props){
        super(props);

        this.state = {
            fields: props.searchFields || {}
        }
    }


    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillUnmount() {
        // if (Object.keys(this.state.fields).length) this.reset();
        this.props.searchFieldsSave(this.state.fields);
    }

    setField = (attribute, value) => {
        this.setState({fields: {...this.state.fields, [attribute]: value}})
    }


    search = (page = 1) => {
        var state = {...this.state.fields};
        var params = [];
        for (var key in state) {
            if (key == 'date') {
                if (state[key] && state[key].from) params.push('ApplicationDateMin='+serverDateFormatMask(state[key].from));
                if (state[key] && state[key].to) params.push('ApplicationDateMax='+serverDateFormatMask(state[key].to));
            } else if (state[key]) params.push(key+"="+state[key]);
        }
        params.push('page='+page);
        this.props.search && this.props.search(params.join('&'));
    }

    reset = () => {
        this.setState({fields: {}});
        this.props.search && this.props.search();
    }

    render(){

        let {
            list,
            catalog,
            getCatalog,

            fileBuffer,
            fileParams,

            addApplicationDocument,
            removeApplicationDocument,
            updateApplicationDocument,
            cancelApplicationDocument,
            clearBuffer,
            resumeApplication,
            isActionOk,
            actionMessage,
            documentTypes
        } = this.props;

        // if (!list.length && !list.message) return <Redirect to={"/applications"}/>;

        return (<div className="tabs__main">
                <div className="tabs__content">
                    <TogglePanel className="search"
                                 labelClassName="search__head"
                                 contentClassName="search__main"
                                 label={this.context.translate("Поиск")}
                                 slide={Object.keys(this.props.searchFields).length ? "down" : ""}
                                 key="search-toggle"
                    >
                        <OrdersSearch catalog={catalog}
                                      getCatalog={getCatalog}

                                      onChange={this.setField}
                                      onSubmit={this.search}
                                      onReset={this.reset}

                                      data={this.state}

                                      key="orders-search-form"
                        />
                    </TogglePanel>
                    {list.length ? <div className="applications" key="orders-list">
                            {list.map((item, key) => <OrdersListItem item={item}
                                                                   key={key}

                                                                   fileBuffer={fileBuffer}
                                                                   fileParams={fileParams}

                                                                   addApplicationDocument={addApplicationDocument}
                                                                   removeApplicationDocument={removeApplicationDocument}
                                                                   updateApplicationDocument={updateApplicationDocument}
                                                                   cancelApplicationDocument={cancelApplicationDocument}
                                                                   clearBuffer={clearBuffer}
                                                                   documentTypes={documentTypes}

                                                                   resumeApplication={resumeApplication}
                                                                   isActionOk={isActionOk}
                                                                   actionMessage={actionMessage}
                        />)}
                    </div>: <div className="applications" key="orders-message">{list.message}</div>}
                    <Pagination key="pagination" {...list.pagination} toPage={this.search}/>
                </div>
            </div>
        )
    }

}