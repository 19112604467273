import React, {Component} from "react";

import {Link} from "react-router-dom";
import Slider from "react-slick";
import PropTypes from "prop-types";
import CompaniesSlideItem from "./CompaniesSlideItem";

export default class CompaniesWidget extends Component{

    constructor(props){
        super(props);

        this.state = {
            slider: React.createRef(),
            all: false
        }

    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    showAll = (e) => {
        e.preventDefault()

        this.setState({all: true})
    }

    render(){

        const {
            items,
            self,
            document,
            downloadInsuranceDoc
        } = this.props;

        let isItems = items && items.length;

        var slider = this.state.slider;

        var adaptiveHeight = !isItems || (window.innerWidth > 1279 && items.length <= 3) ||
            (window.innerWidth <= 1279 && window.innerWidth > 767 && items.length <= 2) ||
            (window.innerWidth <= 767 && items.length <= 1);

        let showArrows = window.innerWidth >= 1024 && items.length > 4 || window.innerWidth < 1024 && window.innerWidth >= 768 && items.length > 3 || window.innerWidth < 768;

        var settings = {
            dots: true,
            dotsClass: 'main-carousel__dots',
            adaptiveHeight,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        fade: true
                    }
                }
            ]
        };

        return  isItems ? <div className="companies__carousel">
                <div className="companies__carousel-inner">
                    <div className="main-carousel main-carousel_adapt-default">
                        {self ? <p className="main-carousel__title">{this.context.translate("Мы рекомендуем страховать предмет лизинга через компании, занимающие лидирующие позиции в рэнкинге страховых компаний РФ")}</p> : null}
                        <div className="main-carousel__header">
                            {self && document ? <Link to={'#'}
                                                      onClick={
                                                          (e) => {
                                                            e.preventDefault();
                                                            downloadInsuranceDoc(document.id)
                                                          }
                                                      }>
                                Скачать список ТОП 20 страховых компаний
                            </Link> : <p className="main-carousel__title">{this.context.translate("Страховые компании-партнеры")}</p>}
                            {!adaptiveHeight && showArrows && !this.state.all ? <div className="main-carousel__controls">
                                <div className="main-carousel__buttons">
                                    <button className="main-carousel__button main-carousel__button_prev" onClick={() => {slider.current.slickPrev()}}/>
                                    <button className="main-carousel__button main-carousel__button_next" onClick={() => {slider.current.slickNext()}}/>
                                </div>
                            </div> : null}
                        </div>
                        <div className="main-carousel__slides-holder">
                            {this.state.all ? items.map((item) => <CompaniesSlideItem item={item} key={item.id}/>) : <Slider className="main-carousel__slides" {...settings} ref={slider}>
                                {items.map((item) => <CompaniesSlideItem item={item} key={item.id} length={items.length} showAll={this.showAll}/>)}
                            </Slider>}
                        </div>
                    </div>
                </div>
            </div> : null
    }
}