import React, {Component} from "react";
import Collapser from "../common/Collapser";

export default class CategoryItem extends Component{

    render(){
        return <div className="instructions-list__item">
            <Collapser label={this.props.label}
                       className="instruction instruction_adapt-default"
                       labelClassName="instruction__title"
                       labelActiveClassName="instruction__title_open"
                       contentClassName="instruction__content"
            >
                <div className="instruction__content-inner u-collapser__container">
                    {this.props.children}
                </div>
            </Collapser>
        </div>
    }
}