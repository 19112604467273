const initState = {
    draft: [],
    inProgress: [],
    archive: [],
    documentTypes: []
};

export function applications(state = initState, action) {
    switch (action.type) {
        case 'APPLICATION_LIST_DRAFT':
            var list = state.draft ? [...state.draft] : [];
            list.isOk = false;
            return {...state, draft: list};
        case 'APPLICATION_IN-PROGRESS':
            var list = state.inProgress ? [...state.inProgress] : [];
            list.isOk = false;
            return {...state, inProgress: list};
        case 'APPLICATION_ARCHIVE':
            var list = state.archive ? [...state.archive] : [];
            if (state.archive && state.archive.pagination) {
                list.pagination = state.archive.pagination
            }
            list.isOk = false;
            return {...state, archive: list};
        case 'APPLICATION_LIST_DRAFT_SUCCESS':
            var list = action.data;
            list.isOk = true;
            return {...state, draft: list};
        case 'APPLICATION_LIST_IN-PROGRESS_SUCCESS':
            var list = action.data;
            list.isOk = true;
            return {...state, inProgress: list};
        case 'APPLICATION_LIST_ARCHIVE_SUCCESS':
            var list = action.data;
            list.isOk = true;
            return {...state, archive: list};
        case 'APPLICATION_LIST_WIDGET':
            var list = state.widget ? [...state.widget] : [];
            list.isOk = false;
            return {...state, widget: list};
        case 'APPLICATION_LIST_WIDGET_SUCCESS':
            var list = action.data;
            list.isOk = true;
            return {...state, widget: list};
        case 'APPLICATION_LIST_SEARCH_ARCHIVE_SUCCESS':
            var list = action.data;
            if (!list.length) list.message = 'По вашему запросу не найдено ни одной заявки. Попробуйте изменить параметры поиска.';
            list.isOk = true;
            return {...state, archive: list};
        case 'SEND_APPLICATION_SUCCESS':
            var draft = [...state.draft];
            var inProgress = [...state.inProgress];
            draft = draft.filter((item) => {
                return item.ApplicationId != action.data.ApplicationId;
            });
            inProgress.unshift(action.data);
            draft.isOk = true;
            inProgress.isOk = true;
            return {...state, draft, inProgress};
        case "RECALL_APPLICATION":
        case "REQUEST_APPLICATION":
        case "RESUME_APPLICATION":
            return {...state, isActionOk: false, actionMessage: ""};
        case "RECALL_APPLICATION_BAD_REQUEST":
        case "REQUEST_APPLICATION_BAD_REQUEST":
        case "RESUME_APPLICATION_BAD_REQUEST":
            return {...state, actionMessage: action.data.message};
        case "RECALL_APPLICATION_SUCCESS":
            var inProgress = [...state.inProgress];
            inProgress.map((item) => {
                if (item.ApplicationId == action.data.ApplicationId) {
                    var index = item.Actions.indexOf('recall');
                    if (index !== -1) item.Actions.splice(index, 1);
                }
                return item;
            });
            inProgress.isOk = state.inProgress.isOk;
            return {...state, inProgress, isActionOk: true, actionMessage: ""};
        case "RESUME_APPLICATION_SUCCESS":
            var archive = [...state.archive];
            archive.map((item) => {
                if (item.ApplicationId == action.data.ApplicationId) {
                    var index = item.Actions.indexOf('resume');
                    if (index !== -1) item.Actions.splice(index, 1);
                }
                return item;
            });
            archive.isOk = state.archive.isOk;
            archive.pagination = state.archive.pagination;
            return {...state, archive, isActionOk: true, actionMessage: ""};
        case "REQUEST_APPLICATION_SUCCESS":
            return {...state, isActionOk: true, actionMessage: ""};
        case "APPLICATION_PDF_BLANK":
            return {...state, blankTmpUrl: null, actionMessage: ""};
        case "APPLICATION_PDF_BLANK_SUCCESS":
            return {...state, blankTmpUrl: action.data.url};
        case "APPLICATION_PDF_BLANK_BAD_REQUEST":
            return {...state, actionMessage: action.data.message};
        case "APPLICATION_DOCUMENT_TYPES_SUCCESS":
            return {...state, documentTypes: action.data};
    }
    return state;
}