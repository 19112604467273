import React, {Component, Fragment} from 'react'
import {connect} from "react-redux";
import InsuranceWidget from "../../components/insurance/InsuranceWidget";
import {Link} from "react-router-dom";
import Advantages from "../../components/insurance/Advantages";
import InsuranceSteps from "../../components/insurance/InsuranceSteps";
import InsuranceForm from "../../components/insurance/InsuranceForm";
import CompaniesWidget from "../../components/insurance/CompaniesWidget";

import logo1 from "../../../img/inhtml/logo1.svg"
import logo2 from "../../../img/inhtml/logo2.svg"
import {
    communicationsAddFile,
    communicationsAddMessage,
    communicationsDeleteFile,
    communicationsUpdateFile
} from "../../actions/communications";
import {clearBuffer} from "../../actions/file-buffer";
import {
    downloadInsuranceDocument,
    getInsuranceCommunications,
    sendInsuranceCommunication
} from "../../actions/insurance";

class InsuranceContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0,
            showFloatingBtn: false
        }

        this.tabsMenu = [
            {key: 0, tab: 0, content: 'Страхование Сименс Финанс'},
            {key: 1, tab: 1, content: 'Самостоятельное страхование'},
        ];
    }

    onScroll = () => {
        if (this.state.showFloatingBtn !== window.scrollY > window.innerHeight / 2) {
            this.setState({showFloatingBtn: window.scrollY > window.innerHeight / 2})
        }
    }

    componentDidMount() {
        this.onScroll();
        window.addEventListener('scroll', this.onScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll)
    }

    toTab = (tab) => {
        return (e) => {
            if (e) {
                e.preventDefault();
            }
            this.setState({activeTab: tab})
        }
    }

    goToForm = (e) => {
        if (e) {
            e.preventDefault();
        }
        const el = document.getElementById('insurance-form')
        if (el) {
            el.scrollIntoView({behavior: "smooth"})
        }
    }

    render() {

        const {activeTab, showFloatingBtn} = this.state;
        const {
            insurance,
            getInsuranceCommunications,

            addFile,
            dropFile,
            updateFileName,
            addMessage,
            fileBuffer,
            clearBuffer,

            catalog,
            downloadInsuranceDoc,
            sendInsuranceCommunication,
            clearForm
        } = this.props;

        const {communications, companies, documents, form} = insurance;

        return <Fragment>
            <div className="page page_insurance">
                <div className="page__main page__main_default">
                    <div className="page__content-container">
                        <InsuranceWidget
                            items={communications}
                            goToForm={this.goToForm}
                            fileBuffer={fileBuffer}
                            clearBuffer={clearBuffer}
                            addFile={addFile}
                            dropFile={dropFile}
                            updateFileName={updateFileName}
                            addMessage={addMessage}
                            getInsuranceCommunications={getInsuranceCommunications}
                        />
                        <div className='tabs__menu'>
                            {this.tabsMenu.map((menuItem, key) =>
                                <Link key={key} to={"#"} className={"tabs__menu-link"+(activeTab === menuItem.tab ? " active" : "")} onClick={this.toTab(menuItem.tab)}>{menuItem.content}</Link>
                            )}
                        </div>
                        <div className="tabs__main">
                            <div className="tabs__content">
                                {activeTab === 0 ? <Advantages/> : null}
                                {documents ? <InsuranceSteps
                                    self={activeTab === 1}
                                    documents={documents}
                                    downloadInsuranceDoc={downloadInsuranceDoc}
                                    goToForm={this.goToForm}
                                /> : null}
                            </div>
                        </div>
                        <InsuranceForm catalog={catalog}
                                       self={activeTab === 1}
                                       {...form}
                                       clearForm={clearForm}
                                       sendInsuranceCommunication={sendInsuranceCommunication}
                        />
                    </div>
                </div>
            </div>
            {companies.length ? <CompaniesWidget
                    self={activeTab === 1}
                    document={documents && documents.top20List}
                    downloadInsuranceDoc={downloadInsuranceDoc}
                    items={companies}
                /> : null}
            {showFloatingBtn ? <button className={'btn btn__floating'} onClick={() => {window.scrollTo(0, 0)}} /> : null}
        </Fragment>
    }
}

export default connect((state) => ({
    insurance: state.insurance,
    catalog: state.catalog
}), (dispatch) => {
    return {
        addFile: (id, file) => dispatch(communicationsAddFile(id, file)),
        dropFile: (id, fileId) => dispatch(communicationsDeleteFile(id, fileId)),
        updateFileName: (id, fileId, fileName) => dispatch(communicationsUpdateFile(id, fileId, fileName)),
        addMessage: (id) => dispatch(communicationsAddMessage(id, 'Загружены файлы')),
        clearBuffer: () => dispatch(clearBuffer()),
        downloadInsuranceDoc: (id) => dispatch(downloadInsuranceDocument(id)),
        sendInsuranceCommunication: (data) => dispatch(sendInsuranceCommunication(data)),
        clearForm: () => dispatch({type: "CLEAR_FORM"}),
        getInsuranceCommunications: (page) => dispatch(getInsuranceCommunications(page))
    }
})(InsuranceContainer)