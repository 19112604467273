import React, { Component } from 'react';

import preloader from "../../../../img/elements/elem-preload.svg";
import {getRandomInt} from "../../../tools/tools";
import morning from "../../../../img/elements/elem-morning.svg";
import noon from "../../../../img/elements/elem-noon.svg";
import evening from "../../../../img/elements/elem-evening.svg";

/**
 * Splash screen with random messages
 */
export default class SplashScreen extends Component {

    constructor(props) {
        super(props);

        var now = new Date(Date.now());
        var hello = '', icon = '', bg = props.images ? props.images[getRandomInt(0,props.images.length - 1)] : null;

        if ((now.getHours() > 6 || (now.getHours() === 6 && now.getMinutes() >= 45)) && now.getHours() < 12) {
            hello = 'Доброе утро';
            icon = morning;
        } else if (now.getHours() >= 12 && now.getHours() < 18) {
            hello = 'Добрый день';
            icon = noon;
        } else {
            hello = 'Добрый вечер';
            icon = evening;
        }

        this.state = {
            hello,
            bg,
            icon
        };
    }

    componentDidMount(){
        this.randomMessages = setInterval(
            () => {
                this.props.getMessage();
            }, 5000)
    }

    componentWillUnmount(){
        clearInterval(this.randomMessages);
    }

    componentWillReceiveProps(nextProps){
        if (this.props.images != nextProps.images) {
            this.setState({bg: nextProps.images[getRandomInt(0,nextProps.images.length - 1)]})
        }
        if (this.props.message != nextProps.message) {
            this.setState({
                bg: nextProps.images && nextProps.images.length && nextProps.images[getRandomInt(0,nextProps.images.length - 1)]
            });
        }
    }

    render() {
        let {
            hello,
            bg,
            icon
        } = this.state;

        return (<div id="splashscreen" className="splash splash_adapt-default splash_open">
            <div className="splash__container">
                <div className="splash__bg">{bg ? <img src={bg} /> : null}</div>
                <div className="splash__loader"/>
                {this.props.user ? <div className="splash__content">
                    <div className="splash__appeal">
                        <div className="splash__appeal-icon splash__appeal-icon_noon">
                            <img src={icon} alt="" width="49" height="35"/>
                        </div>
                        <div className="splash__appeal-text">{hello}, {this.props.user}!</div>
                    </div>
                    {this.props.message ? <div className="splash__main-text" dangerouslySetInnerHTML={{__html: this.props.message}}/> : null}
                </div> : null}
            </div>
        </div>);
    }
}
