import React, {Component} from "react";
import {connect} from "react-redux";
import LibraryList from "../../components/library/LibraryList";
import {library, libraryDownload} from "../../actions/library";
import LibraryListItem from "../../components/library/LibraryListItem";
import Preloader from "../../components/common/Preloader";


class LibraryContainer extends Component{

    toPage = (number) => {
        this.props.getList("page="+number)
    }

    render(){
        let {
            library,
            download
        } = this.props;
        return <LibraryList paginationParams={library.isOk ? library.data.pagination : false} toPage={this.toPage}>
            {library.isOk ? library.data.map((item, key) => <LibraryListItem key={key} item={item} download={download}/>) : null}
        </LibraryList>
    }
}
export default connect(
    state => ({
        library: state.library
    }),
    (dispatch) => {
        return {
            getList: (params) => dispatch(library(params)),
            download: (url, name) => dispatch(libraryDownload(url, name))
        }
    }
)(LibraryContainer);