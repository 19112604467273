export const clientApiUrl = API_URL;
import {defaultLanguage} from "../reducers/multilingual";

import {isSupportUser} from "./tools";

/**
 * Fetch helper function
 * @param url
 * @param params
 * @returns {Promise<{status: number, data: any, headers: Headers} | {error: any}>}
 */
export function fetchApi(url, params = {})
{
    //send language to api
    var lang = localStorage.getItem("currentLangAlias") || defaultLanguage;
    url += (url.indexOf("?") !== -1 ? "&" : "?")+"_lang="+lang;
    if (isSupportUser) url += '&ver='+Date.now();

    return fetch(url, params).then(response => {
        return response.json()
            .then(json => {

                var pagination = {};
                if (response.headers.get('X-Pagination-Current-Page')) pagination.currentPage = response.headers.get('X-Pagination-Current-Page');
                if (response.headers.get('X-Pagination-Page-Count')) pagination.pageCount = response.headers.get('X-Pagination-Page-Count');
                if (response.headers.get('X-Pagination-Per-Page')) pagination.perPage = response.headers.get('X-Pagination-Per-Page');
                if (response.headers.get('X-Pagination-Total-Count')) pagination.totalCount = response.headers.get('X-Pagination-Total-Count');

                return {
                    status: response.status,
                    data: json,
                    headers: response.headers,
                    pagination: pagination
                }
            });
    }).catch(error => ({
        error: error
    }));
}

/**
 * Fetch to client api
 * @param url
 * @param params
 * @returns {Promise<{status: number, data: any, headers: Headers}|{error: any}>}
 */
export function fetchClientApi(url, params = {})
{
    let apiurl = clientApiUrl + url;

    if (params.token) {

        var headers = {'Authorization': 'Bearer ' + params.token};

        return fetchApi(apiurl, {...params, headers: headers});
    }

    return fetchApi(apiurl, params);
}

/**
 *
 * @param url
 * @param token
 * @param method
 * @param body
 * @param headers
 * @returns {Promise<{status: number, data: Blob, headers: Headers} | {error: *}>}
 */
export function fetchFileApi(url, token, method = 'GET', body = null, headers = {}){

    //send language to api
    var lang = localStorage.getItem("currentLangAlias") || defaultLanguage;
    url += (url.indexOf("?") !== -1 ? "&" : "?")+"_lang="+lang;

    return fetch(url,{
        method,
        headers: {
            Authorization: 'Bearer ' + token,
            ...headers
        },
        ...body ? {body} : {}
    }).then(response => {
        return response.status == 200 ? response.blob()
            .then(value => ({
                status: response.status,
                data: value,
                headers: response.headers
            })) : response.json()
            .then(value => ({
                status: response.status,
                data: value,
                headers: response.headers
            }));
    }).catch(error => ({error: error.message}));
}

/**
 * Check response
 * @param type
 * @param data
 * @param status
 * @returns {*}
 */
export function checkErrors(type, data, status)
{
    switch (status) {
        case 200:
            return { type: type + '_SUCCESS', data: data};
        case 201:
            return { type: type + '_CREATED', data: data};
        case 204:
            return { type: type + '_EMPTY_BODY', data: data};
        case 400:
            return { type: type + '_BAD_REQUEST', data: data};
        case 401:
            return { type: 'LOGOUT' };
        case 403:
            return { type: type + '_FAILED_TO_FETCH', data: data};
        case 404:
            return { type: type + '_NOT_FOUND', data: data};
        case 422:
            return { type: type + '_VALIDATION_ERROR', data: data};
        case 423:
            return { type: 'BLOCKED', data: data};
        case 424:
            return { type: type + '_FAILED_DEPENDENCY', data: data};
        default:
            return { type: type + '_ERROR',  data: data};
    }
}

/**
 * Parse errors of data validation response as object
 * @param errors
 * @returns {*}
 */
export function parseErrors(errors) {
    var errorsObject = {};
    errors.map((err) => {
        errorsObject[err.field] = err.message;
    })
    return errorsObject;
}