import React, {Component} from "react";
import {phoneMask} from "../../tools/mask";
import FormField from "../common/form/FormField";
import NotificationSettings from "../users/NotificationSettings";
import Form from "../common/form/Form";
import {getBodyData, getFormData} from "../../tools/form-helper";
import PropTypes from "prop-types";

export default class UserForm extends Component{

    constructor(props){
        super(props);

        var companies = [];
        var settings = [];

        if (props.notificationSettings) {
            props.notificationSettings.map((company) => {
                if (company.IsCompanyChecked) companies[companies.length] = company.CompanyId;
                company.Subscriptions.map((subscription) => {
                    subscription.notificationWay.map((way) => {
                        if (way.isChecked) {
                            settings[settings.length] = {
                                CompanyId: company.CompanyId,
                                NotificationTypeId: subscription.id,
                                NotificationWayId: way.id
                            }
                        }
                    });
                })
            })
        }

        var isDisabled = !props.isPhoneChecked;

        this.phoneInput = React.createRef();

        this.state = {
            fields: {
                LastName: {
                    type: 'text',
                    label: "Фамилия",
                    value: props.profile && props.profile.LastName || "",
                    error: '',
                    required: true,
                    disabled: isDisabled || !props.isNew || (props.profile && !props.profile.IsUserDataAvailable)
                },
                FirstName: {
                    type: 'text',
                    label: "Имя",
                    value: props.profile && props.profile.FirstName || "",
                    error: '',
                    required: true,
                    disabled: isDisabled || !props.isNew || (props.profile && !props.profile.IsUserDataAvailable)
                },
                MiddleName: {
                    type: 'text',
                    label: "Отчество (опционально)",
                    value: props.profile && props.profile.MiddleName || "",
                    error: '',
                    disabled: isDisabled || !props.isNew || (props.profile && !props.profile.IsUserDataAvailable)
                },
                Position: {
                    type: 'text',
                    label: "Должность",
                    value: props.profile && props.profile.Position || "",
                    error: '',
                    disabled: isDisabled || (props.profile && !props.profile.IsUserDataAvailable)
                },
                Email: {
                    type: 'email',
                    label: "E-mail",
                    value: props.profile && props.profile.Email || "",
                    error: '',
                    required: true,
                    disabled: isDisabled || (props.profile && !props.profile.IsUserDataAvailable)
                },
                Phone: {
                    type: 'text',
                    label: "Мобильный телефон",
                    value: props.profile && props.profile.Phone ? phoneMask(props.profile.Phone) : "",
                    error: '',
                    mask: phoneMask,
                    ref: this.phoneInput,
                    required: true,
                    disabled: !props.isCreation
                }
            },
            isValid: false,
            isDisabled,
            companies,
            settings
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps(nextProps){
        if (nextProps.notificationSettings != this.props.notificationSettings) {
            var companies = [];
            var settings = [];

            nextProps.notificationSettings.map((company) => {
                if (company.IsCompanyChecked) companies[companies.length] = company.CompanyId;
                company.Subscriptions.map((subscription) => {
                    subscription.notificationWay.map((way) => {
                        if (way.isChecked) {
                            settings[settings.length] = {
                                CompanyId: company.CompanyId,
                                NotificationTypeId: subscription.id,
                                NotificationWayId: way.id
                            }
                        }
                    });
                })
            });

            this.setState({
                companies,
                settings
            })
        }

        if (nextProps.isPhoneChecked != this.props.isPhoneChecked) {
            var fields = {...this.state.fields};
            for (var attr in fields) {
                if (attr == 'Phone') {
                    fields[attr].disabled = !nextProps.isCreation;
                } else if (['LastName', 'FirstName', 'MiddleName'].indexOf(attr) == -1) {
                    fields[attr].disabled = (!nextProps.isNew && !nextProps.isMine) || (nextProps.profile && !nextProps.profile.IsUserDataAvailable)
                } else {
                    fields[attr].disabled = !nextProps.isCreation || (!nextProps.isNew && !nextProps.isMine) || (nextProps.profile && !nextProps.profile.IsUserDataAvailable)
                }
            }
            this.setState({isDisabled: !nextProps.isPhoneChecked, fields, isValid: this.isValid(fields)})
        }

        if (nextProps.formErrors != this.props.formErrors) {
            var fields = {...this.state.fields};
            for (var attr in fields) {
                fields[attr].error = nextProps.formErrors && nextProps.formErrors.hasOwnProperty(attr) ? nextProps.formErrors[attr] : '';
            }
            this.setState({fields, isValid: this.isValid(fields)});
        }

        if (nextProps.profile != this.props.profile) {
            var fields = {...this.state.fields};
            for (var attr in fields) {
                if (attr == 'Phone') continue;
                fields[attr].value = nextProps.profile && nextProps.profile[attr] ? nextProps.profile[attr] : '';
            }
            this.setState({fields, isDisabled: false, isValid: this.isValid(fields)});
        }
    }

    changeCompanyAccess = (company, value) => {
        var companies = [...this.state.companies];

        if (value) {
            companies.push(company);
        } else {
            var index = companies.indexOf(company);
            companies.splice(index,1);
        }

        this.setState({companies, isValid: this.isValid({...this.state.fields})});
    }

    hasAccess = (company) => {
        return this.state.companies.indexOf(company) != -1;
    }

    changeSelection = (company, type, way) => {
        var settings = [...this.state.settings];
        var index = this.getIndex(company, type, way);

        if (index == -1) {
            settings[settings.length] = {
                CompanyId: company,
                NotificationTypeId: type,
                NotificationWayId: way
            }
        } else {
            settings.splice(index, 1);
        }

        this.setState({settings, isValid: this.isValid({...this.state.fields})});
    }

    isChecked = (company, type, way) => {
        var index = this.getIndex(company, type, way);

        return index != -1;
    }

    getIndex = (company, type, way) => {
        var settings = [...this.state.settings];
        for (var i = 0; i < settings.length; i++){
            if (settings[i].CompanyId == company &&
                settings[i].NotificationTypeId == type &&
                settings[i].NotificationWayId == way
            ) {
                return i;
            }
        }
        return -1;
    }

    isValid = (fields) => {
        for (var attr in fields) {
            if (fields[attr].disabled) continue;
            if (fields[attr].required && !fields[attr].value || fields[attr].error) {
                return false;
            }
        }
        return this.props.isPhoneChecked && this.props.userId !== false;
    }

    onChange = (attribute, value, error, selectionStart) => {
        var fields = {...this.state.fields};
        var companies = [...this.state.companies];

        if (attribute == 'Phone' && value != fields.Phone.value) {
            var phone =  value.replace(/\D/gim, '');
            if (phone.length != 11) companies = [];
            this.props.checkPhone("+"+phone);
        }

        fields[attribute].value = value;
        fields[attribute].error = error;
        this.setState({fields, companies, isValid: error ? false : this.isValid(fields)}, () => {
            if (attribute == 'Phone' && selectionStart) {
                this.phoneInput.current.selectionStart = this.phoneInput.current.selectionEnd = selectionStart;
            }
        });

    };

    onSubmit = () => {
        var {companies, settings} = this.state;

        var fields = {...this.state.fields};

        fields.Phone.value = "+"+fields.Phone.value.replace(/\D/gim, '');

        if (this.props.isNew) {

            var body = getFormData(fields);
            companies.map((item, key) => {
                body.append('AccessibleCompanies['+key+']', item);
            })

            settings.map((item, key) => {
                body.append('Subscriptions[' + key + '][CompanyId]', item.CompanyId);
                body.append('Subscriptions[' + key + '][NotificationTypeId]', item.NotificationTypeId);
                body.append('Subscriptions[' + key + '][NotificationWayId]', item.NotificationWayId);
            })

            this.props.createUser(body);

        } else {
            var body = [];

            companies.map((item, key) => {
                body.push('AccessibleCompanies['+key+']='+item);
            })

            settings.map((item,key) => {
                body.push('Subscriptions['+key+'][CompanyId]='+item.CompanyId);
                body.push('Subscriptions['+key+'][NotificationTypeId]='+item.NotificationTypeId);
                body.push('Subscriptions['+key+'][NotificationWayId]='+item.NotificationWayId);
            })


            body = getBodyData(fields)+"&"+body.join("&");

            this.props.updateUser(body);
        }

        this.setState({isValid: false})
    }

    renderField = (attr) => {
        var {fields} = this.state;
        return <FormField fieldConfig={{
                ...fields[attr],
                label: this.context.translate(fields[attr].label),
                placeholder: this.context.translate(fields[attr].placeholder)
            }}
            attribute={attr}
            onChange={this.onChange}/>
    }

    render(){
        var {
            notificationSettings,
            isNew,
            isMine,
            isPhoneChecked,
            profile,
            message,
            userId = null
        } = this.props;

        return <Form className="users-modal__form" validate={true} onSubmit={this.onSubmit}>
            {!isPhoneChecked || isNew || (isMine && profile && profile.IsUserDataAvailable) ? <div className="users-modal__user">
                <div className="users-modal__input users-modal__input_user-email">
                    {this.renderField('Phone')}
                </div>
                {message ? <p className="users-modal__user-exist">{message}</p> : null}
                <div className="users-modal__input users-modal__input_user-surname">
                    {this.renderField('LastName')}
                </div>
                <div className="users-modal__input users-modal__input_user-name">
                    {this.renderField('FirstName')}
                </div>
                <div className="users-modal__input users-modal__input_user-patronymic">

                    {this.renderField('MiddleName')}
                </div>
                <div className="users-modal__input users-modal__input_user-position">
                    {this.renderField('Position')}
                </div>
                <div className="users-modal__input users-modal__input_user-phone">
                    {this.renderField('Email')}
                </div>
            </div> : <div className="users-modal__user">
                <div className="users-modal__input users-modal__input_user-email">
                    {this.renderField('Phone')}
                </div>
                {message ? <p className="users-modal__user-exist">{message}</p> : null}
            </div>}
            {userId === false ? null : <React.Fragment>
                <p className="users-modal__subtitle">{this.context.translate('Компании, к которым будет предоставлен доступ')}:</p>
                <NotificationSettings notificationSettings={notificationSettings}
                                      changeCompanyAccess={this.changeCompanyAccess}
                                      hasAccess={this.hasAccess}
                                      changeSelection={this.changeSelection}
                                      isChecked={this.isChecked}
                                      isDisabled={this.state.isDisabled}
                />
                <p className="users-modal__info">{this.context.translate('Пользователю будет выдан доступ в онлайн-офис, а уведомления начнут приходить только после активации профиля и подтверждения контактных данных.')}</p>
            </React.Fragment>}
            <button className={"u-btn u-btn_adapt-type_wide users-modal__submit"+(this.state.isValid ? "" : " disabled")}
                    type="submit">{this.context.translate('Сохранить')}
            </button>
        </Form>
    }
}