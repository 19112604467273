import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import {phoneMask} from "../../tools/mask";
import {authPageSettings} from "../../actions/catalog";

class AuthLocked extends React.Component{

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillMount() {
        if (!this.props.pageSettings) {
            this.props.authPageSettings();
        }
    }

    render(){

        let {pageSettings} = this.props;

        return <div className="auth page">
            <div className="auth__inner auth_ban">
                <div className="auth-ban">
                    <div className="ban-icon" />
                    <h1 className="auth-ban__title">{this.context.translate('Ваш аккаунт заблокирован')}</h1>
                    <p>{this.context.translate('Восстановить доступ в Личный кабинет Сименс Финанс Вы можете по следующему телефону')}:</p>
                    {pageSettings ? <div className="auth-ban__phones">
                        <div className="b-phone"><a  target="_blank" href={pageSettings.support_phone[this.context.currentLanguage.alias] ? "tel:"+(pageSettings.support_phone[this.context.currentLanguage.alias].replace(/\s/g, '')) : "#"}>{phoneMask(pageSettings.support_phone[this.context.currentLanguage.alias], 'def')}</a><span>{this.context.translate('по россии')}</span></div>
                    </div> : null}
                </div>
            </div>
        </div>
    }

}

export default connect(
    state => ({
        pageSettings: state.catalog && state.catalog.authPageSettings
    }),
    (dispatch) => {
        return {
            authPageSettings: () => dispatch(authPageSettings())
        }
    }
)(AuthLocked);