import React, {Component} from "react";

export default class Radio extends Component{
    constructor(props){
        super(props);
        var selected = null;
        if (props.value) {
            for (var i = 0; i < props.options.length; i++) {
                if (props.options[i].value == props.value) {
                    selected = props.options[i];
                    break;
                }
            }
        }
        this.state = {
            selected,
            opened: false
        }
    }

    componentWillReceiveProps(nextProps){
        if ((this.state.selected && nextProps.value != this.state.selected.value) || (!this.state.selected && nextProps.value)) {
            var selected = null;
            if (nextProps.value && nextProps.options) {
                for (var i = 0; i < nextProps.options.length; i++) {
                    if (nextProps.options[i].value == nextProps.value) {
                        selected = nextProps.options[i];
                        break;
                    }
                }
            }
            this.setState({selected});
        }
    }

    onSelect = (item) => {
        return () => {
            var isNull = this.props.allowEmpty && this.state.selected == item;

            this.setState({
                selected: isNull ? null : item
            }, () => {
                this.props.onChange && this.props.onChange(this.props.attribute, isNull ? '' : item.value)
                this.props.saveField && this.props.saveField(this.props.attribute, isNull ? '' : item.value)
            })
        }
    };

    render(){
        let {
            attribute,
            value,
            options = []
        } = this.props;

        var elems = [];
        {options.map(
            (item) => {
                    elems.push(<input type="checkbox" name={attribute} id={attribute+'-'+item.value} key={attribute+'-'+item.value+'-input'}
                                      value={item.value} checked={item.value == value} onChange={this.onSelect(item)}/>);
                    elems.push(<label htmlFor={attribute+'-'+item.value} key={attribute+'-'+item.value+'-label'}>{item.label}</label>);
             }
        )}

        return <div className="radio-tabs">
            {elems}
        </div>;
    }
}