import {
  takeEvery,
  takeLatest,
  select,
  call,
  put,
} from 'redux-saga/effects';

import {
    fetchCodeRequest,
    fetchCodeValidate,
    clearToken,
    fetchActivation,
    fetchRecovery, fetchRefreshToken, fetchCheckToken, fetchCheckLocked
} from "./auth";

import {
    fetchAutoComplete,
    fetchLayoutInfo,
    fetchSaveCompanies,
    fetchSplashImages,
    fetchSplashMessage,
    fetchAuthPageSettings,
    fetchFooterSettings,
    fetchInvestPotential,
    scrollTop,
    checkPageLoaded
} from "./catalog";

import {
    fetchNewApplication,
    fetchSaveField,
    fetchSaveFields,
    fetchApplicationFormInfo,
    fetchViewApplication,
    fetchSendApplication,
    fetchFindCompany
} from "./add-applications";

import {
    fetchApplicationsList,
    fetchArchiveSearchInfo,
    fetchDetailApplication,
    fetchDownloadCommercialOffer,
    fetchPdfBlank,
    fetchRecallApplication,
    fetchRequestApplication,
    fetchResumeApplication,
    fetchDownloadFile,
    fetchApplicationDocuments,
    fetchDocumentTypes
} from "./applications";

import {
    fetchPromotionsList,
    fetchTopicsList
} from "./promotions";

import {
    fetchLibrary,
    fetchLibraryDownload
} from "./library";

import {
    fetchFaq,
    fetchFaqAction
} from "./help";

import {
    fetchProjectsList,
    fetchProjectFilter,
    fetchDownloadContractSchedule,
    fetchProjectView,
    fetchProjectDocuments,
    fetchDocumentView,
    fetchDocumentEquipments
} from "./projects";

import {
    fetchDealsList,
    fetchDownloadInvoice, fetchDownloadPaymentNotification
} from "./deals";

import {
    fetchAddFile,
    fetchDeleteFile,
    fetchUpdateFile
} from "./file-buffer";

import {
    fetchContactsList,
    fetchDetailContact
} from "./contacts";

import {
    fetchProfileInfo,
    fetchChangePassword,
    fetchSaveSettings,
    fetchDataChangeRequest
} from "./profile";

import {
    fetchCompaniesList,
    fetchCompanyAccept,
    fetchCompanyReject,
    fetchCompaniesDetail,
    fetchCompaniesRequest
} from "./companies";

import {
    fetchUsersList,
    fetchCheckPhone,
    fetchCreateUser,
    fetchUpdateUser,
    fetchViewUser,
    fetchDeleteUser,
    fetchSubscriptions
} from "./users";

import {
    fetchDocumentsList,
    fetchDocumentsSearchInfo,
    fetchDownloadDocument,
    fetchDocumentsWidgetInfo,
    fetchDownloadHelpfulDocument,
    fetchAddDocumentsRequest,
    fetchDownloadReconciliationStatement
} from "./documents";

import {
    fetchNotificationsList,
    fetchReadNotification
} from "./notifications";

import {
    fetchCommunicationsList,
    fetchCommunicationsSearchInfo,
    fetchCommunicationsSetRating,
    fetchCommunicationsDecline,
    fetchCommunicationsWidget,

    fetchCommunicationsFormInfo,
    fetchCommunicationsCreateDraft,
    fetchCommunicationsMessage,
    fetchCommunicationsAddFile,
    fetchCommunicationsDeleteFile,
    fetchCommunicationsUpdateFile,

    fetchCommunicationsDetail,
    fetchCommunicationsDownloadFile
} from "./communications";

import {fetchDictionary, fetchLanguages} from "./multilingual";
import {
    fetchDownloadInsuranceDocument,
    fetchInsuranceCommunications,
    fetchInsuranceCompanies,
    fetchInsuranceDocuments, fetchSendInsuranceCommunication
} from "./insurance";

export function* rootSaga() {
  yield takeLatest("CODE_REQUEST", fetchCodeRequest);
  yield takeLatest("CODE_VALIDATE", fetchCodeValidate);
  yield takeLatest("CHECK_LOCKED", fetchCheckLocked);
  yield takeLatest("REFRESH_TOKEN", fetchRefreshToken);
  yield takeEvery("CHECK_TOKEN", fetchCheckToken);
  yield takeLatest("LOGOUT", clearToken);

  yield takeLatest("ACTIVATION_CHANGE-PASSWORD", fetchActivation);
  yield takeLatest("ACTIVATION_ACCEPT-AGREEMENT", fetchActivation);
  yield takeLatest("ACTIVATION_CONFIRM-EMAIL", fetchActivation);
  yield takeLatest("ACTIVATION_CONFIRM-PHONE", fetchActivation);

  yield takeLatest("RECOVERY_CODE-SEND", fetchRecovery);
  yield takeLatest("RECOVERY_CODE-VALIDATE", fetchRecovery);

  yield takeEvery("AUTOCOMPLETE", fetchAutoComplete);
  yield takeLatest("LAYOUT_INFO", fetchLayoutInfo);
  yield takeLatest("SPLASH_IMAGES", fetchSplashImages);
  yield takeLatest("SPLASH_MESSAGE", fetchSplashMessage);
  yield takeEvery("SAVE_CLIENT_COMPANIES", fetchSaveCompanies);
  yield takeLatest("AUTH_PAGE_SETTINGS", fetchAuthPageSettings);
  yield takeLatest("FOOTER_SETTINGS", fetchFooterSettings);
  yield takeLatest("INVEST_POTENTIAL", fetchInvestPotential);

  yield takeLatest("NEW_APPLICATION", fetchNewApplication);
  yield takeEvery("SAVE_FIELD", fetchSaveField);
  yield takeLatest("SAVE_FIELDS", fetchSaveFields);
  yield takeLatest("FORM_INFO_APPLICATION", fetchApplicationFormInfo);
  yield takeLatest("DETAIL_APPLICATION", fetchViewApplication);
  yield takeLatest("SEND_APPLICATION", fetchSendApplication);
  yield takeLatest("FIND_COMPANY", fetchFindCompany);

  yield takeLatest("APPLICATION_LIST_DRAFT", fetchApplicationsList);
  yield takeLatest("APPLICATION_LIST_IN-PROGRESS", fetchApplicationsList);
  yield takeLatest("APPLICATION_LIST_ARCHIVE", fetchApplicationsList);
  yield takeLatest("APPLICATION_LIST_SEARCH_ARCHIVE", fetchApplicationsList);
  yield takeLatest("APPLICATION_LIST_WIDGET", fetchApplicationsList);
  yield takeLatest("ARCHIVE_SEARCH_INFO_APPLICATION", fetchArchiveSearchInfo);
  yield takeLatest("APPLICATION_PDF_BLANK", fetchPdfBlank);
  yield takeLatest("APPLICATION_INFO", fetchDetailApplication);
  yield takeLatest("DOWNLOAD_COMMERCIAL_OFFER", fetchDownloadCommercialOffer);
  yield takeLatest("RECALL_APPLICATION", fetchRecallApplication);
  yield takeLatest("REQUEST_APPLICATION", fetchRequestApplication);
  yield takeLatest("RESUME_APPLICATION", fetchResumeApplication);
  yield takeEvery("DOWNLOAD_APPLICATION_FILE", fetchDownloadFile);
  yield takeLatest("APPLICATION_DOCUMENTS", fetchApplicationDocuments);
  yield takeLatest("APPLICATION_DOCUMENT_TYPES", fetchDocumentTypes);

  yield takeLatest("PROMOTIONS_LIST", fetchPromotionsList);
  yield takeLatest("TRANSLATE_PROMOTIONS", fetchPromotionsList);
  yield takeLatest("TOPICS_LIST", fetchTopicsList);

  yield takeLatest("LIBRARY", fetchLibrary);
  yield takeLatest("LIBRARY_DOWNLOAD", fetchLibraryDownload);

  yield takeLatest("FAQ", fetchFaq);
  yield takeLatest("FAQ_ACTION", fetchFaqAction);

  yield takeLatest("PROJECT_LIST", fetchProjectsList);
  yield takeLatest("PROJECT_LIST_SEARCH", fetchProjectsList);
  yield takeLatest("PROJECT_FILTER", fetchProjectFilter);
  yield takeEvery("DOWNLOAD_CONTRACT_SCHEDULE", fetchDownloadContractSchedule);
  yield takeLatest("PROJECT_VIEW", fetchProjectView);
  yield takeLatest("PROJECT_DOCUMENTS", fetchProjectDocuments);
  yield takeLatest("DOCUMENT_VIEW", fetchDocumentView);
  yield takeLatest("DOCUMENT_EQUIPMENTS", fetchDocumentEquipments);

  yield takeEvery("ADD_FILE", fetchAddFile);
  yield takeEvery("DELETE_FILE", fetchDeleteFile);
  yield takeEvery("UPDATE_FILE", fetchUpdateFile);

  yield takeLatest("DEALS_LIST_OVERDUE", fetchDealsList);
  yield takeLatest("DEALS_LIST_CONTRACTS", fetchDealsList);
  yield takeLatest("DEALS_LIST_PAYMENTS", fetchDealsList);
  yield takeEvery("DOWNLOAD_INVOICE", fetchDownloadInvoice);
  yield takeEvery("DOWNLOAD_PAYMENT_NOTIFICATION", fetchDownloadPaymentNotification);

  yield takeLatest("CONTACTS_LIST", fetchContactsList);
  yield takeLatest("DETAIL_CONTACT", fetchDetailContact);

  yield takeLatest("PROFILE_INFO", fetchProfileInfo);
  yield takeLatest("CHANGE_PASSWORD", fetchChangePassword);
  yield takeLatest("SAVE_NOTIFICATION_SETTINGS", fetchSaveSettings);
  yield takeLatest("DATA_CHANGE_REQUEST", fetchDataChangeRequest);

  yield takeLatest("COMPANIES_LIST", fetchCompaniesList);
  yield takeLatest("COMPANIES_DETAIL", fetchCompaniesDetail);
  yield takeLatest("COMPANIES_REQUEST", fetchCompaniesRequest);
  yield takeEvery("COMPANY_ACCEPT", fetchCompanyAccept);
  yield takeEvery("COMPANY_REJECT", fetchCompanyReject);

  yield takeLatest("USERS_LIST", fetchUsersList);
  yield takeLatest("CHECK_USER_PHONE", fetchCheckPhone);
  yield takeLatest("USER_CREATE", fetchCreateUser);
  yield takeLatest("USER_UPDATE", fetchUpdateUser);
  yield takeLatest("USER_VIEW", fetchViewUser);
  yield takeLatest("USER_DELETE", fetchDeleteUser);
  yield takeLatest("SUBSCRIPTIONS", fetchSubscriptions);

  yield takeLatest("DOCUMENTS_LIST", fetchDocumentsList);
  yield takeLatest("DOCUMENTS_LIST_SEARCH", fetchDocumentsList);
  yield takeLatest("DOCUMENTS_SEARCH_INFO", fetchDocumentsSearchInfo);
  yield takeLatest("DOWNLOAD_DOCUMENT", fetchDownloadDocument);
  yield takeLatest("DOCUMENTS_WIDGET_INFO", fetchDocumentsWidgetInfo);
  yield takeLatest("DOWNLOAD_HELPFUL_DOCUMENT", fetchDownloadHelpfulDocument);
  yield takeLatest("DOCUMENTS_REQUEST", fetchAddDocumentsRequest);
  yield takeEvery("DOWNLOAD_RECONCILIATION_STATEMENT", fetchDownloadReconciliationStatement);

  yield takeLatest("NOTIFICATIONS_LIST", fetchNotificationsList);
  yield takeEvery("READ_NOTIFICATION", fetchReadNotification);

  yield takeLatest("COMMUNICATIONS_LIST", fetchCommunicationsList);
  yield takeLatest("COMMUNICATIONS_LIST_SEARCH", fetchCommunicationsList);
  yield takeLatest("COMMUNICATIONS_SEARCH_INFO", fetchCommunicationsSearchInfo);
  yield takeLatest("COMMUNICATIONS_FORM_INFO", fetchCommunicationsFormInfo);
  yield takeLatest("COMMUNICATIONS_DECLINE", fetchCommunicationsDecline);
  yield takeLatest("COMMUNICATIONS_SET_RATING", fetchCommunicationsSetRating);
  yield takeLatest("COMMUNICATIONS_WIDGET", fetchCommunicationsWidget);
  yield takeLatest("COMMUNICATIONS_CREATE_DRAFT", fetchCommunicationsCreateDraft);
  yield takeLatest("COMMUNICATIONS_SEND", fetchCommunicationsMessage);
  yield takeLatest("COMMUNICATIONS_ADD_MESSAGE", fetchCommunicationsMessage);
  yield takeEvery("COMMUNICATIONS_ADD_FILE", fetchCommunicationsAddFile);
  yield takeEvery("COMMUNICATIONS_DELETE_FILE", fetchCommunicationsDeleteFile);
  yield takeEvery("COMMUNICATIONS_UPDATE_FILE", fetchCommunicationsUpdateFile);
  yield takeLatest("COMMUNICATIONS_DETAIL", fetchCommunicationsDetail);
  yield takeLatest("COMMUNICATIONS_DOWNLOAD_FILE", fetchCommunicationsDownloadFile);

  yield takeLatest("DICTIONARY", fetchDictionary);
  yield takeLatest("SET_CURRENT_LANGUAGE", fetchDictionary);
  yield takeLatest("LANGUAGES", fetchLanguages);

  yield takeLatest("GET_INSURANCE_COMMUNICATIONS", fetchInsuranceCommunications);
  yield takeLatest("SEND_INSURANCE_COMMUNICATION", fetchSendInsuranceCommunication);
  yield takeLatest("GET_INSURANCE_COMPANIES", fetchInsuranceCompanies);
  yield takeLatest("GET_INSURANCE_DOCUMENTS", fetchInsuranceDocuments);
  yield takeEvery("DOWNLOAD_INSURANCE_DOCUMENT", fetchDownloadInsuranceDocument);

  yield takeEvery("@@router/LOCATION_CHANGE", scrollTop);
  yield takeEvery("PAGE_LOADED", checkPageLoaded);
}
