import React, {Component} from "react";
import FileIcon from "../common/FileIcon";

export default class Message extends Component{

    renderAuthor = () => {
        var {
            MessageId,
            EmployeeName,
            EmployeePhoto
        } = this.props;

        return <div key={"author-"+MessageId} className="request-messages__author">
            <div className="request-messages__author-photo">
                {EmployeePhoto ? <img height="50" width="50" alt="" src={EmployeePhoto}/> : null}
            </div>
            <p className="request-messages__author-name">{EmployeeName}</p>
        </div>
    }

    renderMessage = () => {
        var {
            MessageId,
            MessageText,
            MessageDateTime,
            IsSystem,
            IsAnswer,
            Files,
            downloadFile
        } = this.props;

        var date = new Date(1000*MessageDateTime);
        date.setTime( date.getTime() + date.getTimezoneOffset()*60*1000 )

        return <div key={"message-"+MessageId} className={"request-messages__message"+(IsAnswer ? (" request-messages__message_rcvd" + (IsSystem ? " request-messages__message_info" : "")) : " request-messages__message_sent")}>
            <p className="request-messages__message-text">{MessageText}</p>
            {Files && Files.map((file) => <MessageFile key={file.FileId}
                                                       id={file.FileId}
                                                       title={file.Title}
                                                       size={file.FileSize}
                                                       extension={file.FileExtension}
                                                       downloadFile={downloadFile}
            />)}
            <div className="request-messages__message-datetime request-messages__message-datetime_sent">
                {date.toLocaleDateString('ru')+" "+date.toLocaleTimeString('ru').substr(0, date.toLocaleTimeString('ru').lastIndexOf(':'))}
            </div>
        </div>
    }

    render(){
        var {
            IsSystem,
            IsAnswer
        } = this.props;

        return IsAnswer && !IsSystem ? [this.renderAuthor(), this.renderMessage()] : this.renderMessage();

    }
}

function MessageFile({id, title, size, extension, downloadFile}) {
    return <p className="request-messages__message-attach request-messages__message-attach_sent">
        <FileIcon ext={extension}/>
        <a className="request-messages__message-attach-link" href="#" onClick={(e) => {
            e.preventDefault();
            downloadFile(id, title+'.'+extension);
        }}>{title}</a>&ensp;
        <span className="request-messages__message-attach-size">{size}</span>
    </p>
}
