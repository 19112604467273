import {
    call,
    put,
    take
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import {checkToken} from "./auth";

export function profileInfo() {
    return {
        type: 'PROFILE_INFO'
    }
}


export function* fetchProfileInfo(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/profile", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function changePassword(oldPassword, password) {
    return {
        type: 'CHANGE_PASSWORD',
        oldPassword,
        password
    }
}


export function* fetchChangePassword(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        var body = 'old_password='+action.oldPassword+"&password="+action.password;

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/profile?action=change-password", {
                method: 'PUT',
                body,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function saveSettings(body) {
    return {
        type: 'SAVE_NOTIFICATION_SETTINGS',
        body
    }
}


export function* fetchSaveSettings(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/profile?action=notification-settings", {
                method: 'PUT',
                body: action.body,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function dataChangeRequest(body) {
    return {
        type: 'DATA_CHANGE_REQUEST',
        body
    }
}


export function* fetchDataChangeRequest(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/profile?action=change-request", {
                method: 'PUT',
                body: action.body,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}
