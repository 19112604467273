import React, {Component} from "react";
import {connect} from "react-redux";
import CommunicationsList from "../../components/communications/CommunicationsList";
import CommunicationsSearchForm from "../../components/communications/CommunicationsSearchForm";
import {Link} from "react-router-dom";
import {setRating, communicationsList, communicationsDecline} from "../../actions/communications";
import {getBodyData} from "../../tools/form-helper";
import PropTypes from "prop-types";

class CommunicationsContainer extends Component{

    constructor(props){
        super(props);
        this.state = {
            searchForm: {}
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    toPage = (num) => {
        this.props.getCommunicationsList(Object.keys(this.state.searchForm).length ? getBodyData(this.state.searchForm)+'&page='+num : 'page='+num);
    }

    search = (fields) => {
        this.setState({searchForm: fields});
        this.props.getCommunicationsList(getBodyData(fields));
    }

    render(){
        var {
            communications,
            catalog,
            ratingList,
            setRating,
            decline
        } = this.props;

        return <div className="page page_projects page_messages">
            <div className="page__main page__main_projects">
                <div className="page__content-container">
                    <div className="page__title-section">
                        <h1 className="page__title">{this.context.translate('Обращения')}</h1>
                        <div className="page__title-ui">
                            <Link className="page__title-button u-btn" to="/communications/create">{this.context.translate('Создать Обращение')}</Link>
                        </div>
                    </div>
                    {communications.list.length || communications.isSearch ? <div className="page__messages-filter">
                        <div className="messages-filter messages-filter_adapt-default">
                            <CommunicationsSearchForm catalog={catalog}
                                                      search={this.search}
                            />
                        </div>
                    </div> : null}
                    <CommunicationsList list={communications.list}
                                        isSearch={communications.isSearch}
                                        ratingList={ratingList}
                                        setRating={setRating}
                                        decline={decline}
                                        toPage={this.toPage}
                                        translate={this.context.translate}
                    />
                </div>
            </div>
        </div>
    }
}

export default connect(
    state => ({
        communications: state.communications,
        catalog: {
            communicationStatuses: state.catalog.communicationStatuses || [],
            communicationTypes: state.catalog.communicationTypes || []
        },
        ratingList: state.catalog.communicationRatings || []
    }),
    (dispatch) => {
        return {
            setRating: (id, rating, comment) => dispatch(setRating(id, rating, comment)),
            decline: (id, comment) => dispatch(communicationsDecline(id, comment)),
            getCommunicationsList: (params) => dispatch(communicationsList(params))
        }
    }
)(CommunicationsContainer);