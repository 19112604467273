import React, {Component} from "react";
import Rating from "./Rating";
import {Link} from "react-router-dom";

export default class CommunicationsListItem extends Component {

    setRating = (rating, comment) => {
        this.props.setRating(this.props.item.CommunicationId, rating, comment);
    }

    decline = (comment) => {
        this.props.decline(this.props.item.CommunicationId, comment);
    }

    render() {
        var {
            item,
            ratingList
        } = this.props;

        var {
            CommunicationId,
            TypeName,
            Number,
            CommunicationDate,
            AuthorName,
            CommunicationTextPreview,
            RatingId,
            RatingValue,
            CanRate,
            HasUnreadMessages,
            CanComment
        } = item;

        return <Link to={"/communications/" + CommunicationId}>
            <div className={"messages-list__item" + (HasUnreadMessages ? " message_in-progress" : "")}>
                <div className={"message message_adapt-default " + (RatingValue ? " message_closed" : " message_open")}>
                    <div className="message__content">
                        <div className="message__title">{TypeName} №{Number} от {CommunicationDate}</div>
                        <div className="message__text">
                            <strong>{AuthorName}:&nbsp;</strong>
                            <span>{CommunicationTextPreview}</span>
                        </div>
                    </div>
                    {CanRate || RatingValue ? <Rating rating={RatingValue}
                                                      ratingList={ratingList}
                                                      setRating={this.setRating}
                                                      decline={this.decline}
                                                      canComment={CanComment}
                    /> : null}
                </div>
            </div>
        </Link>
    }
}
