import React,{Component} from "react";
import {getScreenSize} from "../../tools/tools";
import FormField from "../common/form/FormField";
import {onlyNumbers} from "../../tools/mask";

/**
 * List pagination component
 * Properties:
 *      currentPage: integer,
 *      totalCount: integer,
 *      totalPageCount: integer,
 *      perPage: integer
 *      toPage - function
 *
 */
export default class Pagination extends Component{
    constructor(props){
        super(props);
        this.state = {
            screenSize: 'lg',
            SearchPage: {
                type: 'text',
                label: '',
                value: '',
                error: '',
                required: false,
                mask: onlyNumbers,
                success: false
            },
            tooltip: "Введите номер страницы и нажмите клавишу Enter",
            errorShown: false
        };
    }

    componentDidMount = () => {
        this.setState({screenSize: getScreenSize()});
        if (typeof window !== "undefined" && typeof document !== "undefined") {
            window.addEventListener('resize', this.onResize);
            const el = document.querySelector('.pagination__required-page');
            if (!!el) el.addEventListener('keydown', this.handleSearch);
        }
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
        const el = document.querySelector('.pagination__required-page');
        if (!!el) el.addEventListener('keydown', this.handleSearch);
    };

    onResize = () => {
        this.setState({screenSize: getScreenSize()});
    };

    handleSearch = (e) => {
        if (e.keyCode === 13 || e.code === "Enter" && valueSearch !== '') {
            const item = this.state.SearchPage.value;

            if (item !== '' && !!this.props.pageCount && +item <= +this.props.pageCount) {
                this.props.toPage(+item);
            } else if (item !== '') {
                this.setState({errorShown: true});
            }

            this.setState({
                ...this.state,
                SearchPage: {
                    ...this.state.SearchPage,
                    value: ''
                }
            })
        }
    };

    searchOnBlur =()=> {
        if (!!this.state.errorShown) {
            this.setState({errorShown: false});
        }
    }

    toPage = (number) => {
        return (e) => {
            e.preventDefault();
            this.props.toPage && this.props.toPage(number)
        }
    };

    pagination = (c, m, delta = 2) => {
        let current = c,
            last = m,
            left = current - delta,
            right = current + delta + 1,
            range = [],
            rangeWithDots = [],
            pages = [],
            l;

            if (last <= 10) {
                for (let i = 1; i <= last; i++) {
                    pages.push(<a key={i} className={"pagination__item"+(i == current ? " active": "")} href="#" onClick={this.toPage(i)}>{i}</a>)
                }
            } else {
                for (let i = 1; i <= last; i++) {
                    if (i == 1 || i == last || i >= left && i < right) {
                        range.push(i);
                    }
                }

                for (let i of range) {
                    if (l) {
                        if (i - l === 2) {
                            rangeWithDots.push(l + 1);
                        } else if (i - l !== 1) {
                            rangeWithDots.push('...');
                        }
                    }
                    rangeWithDots.push(i);
                    l = i;
                }

                let key = 0;
                rangeWithDots.forEach(el => {
                    if (isNaN(el)) {
                        pages.push(<span key={key} className={"pagination__item--dot"}>{el}</span>);
                        key++
                    } else {
                        pages.push(<a key={key} className={"pagination__item"+(el == current ? " active": "")} href="#" onClick={this.toPage(el)}>{el}</a>);
                        key++
                    }
                })
            }
        return pages;
    }

    renderField = (attribute, className = "", error = '') => {
        const { SearchPage } = this.state;

        return <FormField className={className + (!!error ? ' SearchPage__error' : '') + " " + attribute}
                fieldConfig={{
                ...SearchPage
                }}
                attribute={attribute}
                onChange={this.onChangeField}
                searchOnBlur={this.searchOnBlur}
            />
    };

    onChangeField = (attribute, value) => {
        this.searchOnBlur();
        this.setState((prevState) => {
            return {
                ...prevState,
                [attribute]: {
                    ...prevState[attribute],
                    value
                },
            };
        });
    };

    render(){

        let {
            currentPage,
            totalCount,
            pageCount,
            perPage
        } = this.props;

        currentPage = parseInt(currentPage);
        const { screenSize, tooltip, SearchPage, errorShown } = this.state;

        if (totalCount == 0 || pageCount == 1) return null;

        let delta = 2;
        if (!!screenSize && screenSize === 'sm') delta = 1;

        let pages = this.pagination(currentPage, pageCount, delta);
        let field = this.renderField('SearchPage', 'pagination__required-page', errorShown);

        return <div className={"pagination" + (pageCount <= 10 ? " without_pagination" : "")}>
            <div className="wrap-pagination-items">
                {currentPage != 1 && pageCount > 10 ? <a className="pagination__prev" href="#" onClick={this.toPage(currentPage - 1)}/> : null}
                {pages}
                {currentPage != pageCount && pageCount > 10 ? <a className="pagination__next" href="#" onClick={this.toPage(currentPage + 1)}/> : null}
            </div>
            {pageCount > 10 ? <div className="wrap-fieldSearch">
                {field}
                {SearchPage.value === '' && !errorShown ? <span className="field-search__tooltip">{tooltip}</span> : null}
            </div> :null}
            {errorShown ? <span className="pagination__errot-txt">Страница не найдена</span> : null}
        </div>
    }

}