import React, {Component} from "react";
import PropTypes from "prop-types";

function prepareOptions(props){
    let options = props.options || [];
    if (props.withGroups && props.options) {
        options = [];
        props.options.map((group) => {
            options.push({label: group.title}, ...group[props.withGroups].sort((a, b) => a.sort > b.sort ? 1 : -1));
        })
    }
    return options;
}

export default class Select extends Component{
    constructor(props){
        super(props);
        var selected = null;
        let options = prepareOptions(props);
        if (props.value) {
            for (var i = 0; i < options.length; i++) {
                if (options[i].value == props.value) {
                    selected = options[i];
                    break;
                }
            }
        }
        this.state = {
            selected,
            opened: false,
            options: options
        }
    }


    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps(nextProps){
        if (nextProps.options != this.props.options) {
            let options = prepareOptions(nextProps)
            this.setState((prevState) => ({...prevState, options}));
        }
        if ((this.state.selected && nextProps.value != this.state.selected.value) || (!this.state.selected && nextProps.value)) {
            var selected = null;
            if (nextProps.value && nextProps.options) {
                let options = prepareOptions(nextProps)
                for (var i = 0; i < options.length; i++) {
                    if (options[i].value == nextProps.value) {
                        selected = options[i];
                        break;
                    }
                }
            }
            if (this.filter) {
                this.filter.value = selected ? selected.label : ''
            }
            this.setState((prevState) => ({...prevState, selected}));
        }
    }

    onClick = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        var event = document.createEvent("Event");
        event.initEvent("click", false, true);
        document.dispatchEvent(event);

        this.setState((prevState) => {
            return {
                opened: !prevState.opened
            }
        });
    };

    componentDidMount(){
        document.addEventListener('click', this.windowClick);
        document.getElementById("modal").addEventListener('click', this.windowClick);
        if (this.filter) {
            this.filter.value = this.state.selected ? this.state.selected.label : "";
            this.filter.addEventListener('input', this.onFilter);
        }
    }

    componentWillUnmount(){
        document.removeEventListener('click', this.windowClick);
        document.getElementById("modal").removeEventListener('click', this.windowClick);
        if (this.filter) this.filter.removeEventListener('input', this.onFilter);
    }

    windowClick = (e) => {
        if (e.target.nodeName != "LI") {
            if (this.filter) this.filter.value = this.state.selected ? this.state.selected.label : "";
            this.setState({opened: false, options: prepareOptions(this.props)});
        }
    };

    onSelect = (item) => {
        return (e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();

            this.setState({
                selected: item,
                opened: false
            }, () => {
                if (this.filter) this.filter.value = item ? item.label : "";
                this.props.onChange && this.props.onChange(this.props.attribute, item ? item.value : '');
                this.props.saveField && this.props.saveField(this.props.attribute, item ? item.value : '');
                if (!!this.props.valueSelected) this.props.valueSelected(item);
            })
        }
    };

    onFilter = (e) => {
        if (this.props.getOptions) {
            this.props.getOptions(this.props.optionsType, e.target.value);
        }
        var options = [...this.props.options];
        options = options.filter(function (item) {
            return (item.label.toLowerCase()).indexOf(e.target.value.toLowerCase()) !== -1;
        })
        this.setState({options});
    }

    render(){

        let {
            placeholder = this.context.translate("Не выбрано"),
            allowEmpty = false,
            allowSearch = false
        } = this.props;

        let {
            opened,
            selected,
            options
        } = this.state;

        return <div className="select-styler">
            <div className={"jq-selectbox__select"+(opened ? " opened" : "")} onClick={this.onClick}>
                {allowSearch ? <input  placeholder={placeholder}
                                       ref={(input) => this.filter = input}
                                       key="search-input"
                    /> :
                    <div key={"placeholder"+(selected ? 1 : 0)} className={"jq-selectbox__select-text"+(selected? "" : " placeholder")}>{selected ? selected.label : placeholder}</div>}
                <div className="jq-selectbox__trigger" key="arrow">
                    <div className="jq-selectbox__trigger-arrow"/>
                </div>
            </div>
            {opened ?
            <div className="jq-selectbox__dropdown">
                <ul>
                    {allowEmpty ? <li onClick={this.onSelect(null)} className={!selected ? "sel selected placeholder" : "placeholder"}>{placeholder}</li> : null}
                    {options && options.map(
                        (item, key) => <li key={key} onClick={item.value ? this.onSelect(item) : () => {}}
                                           className={(selected && selected.value == item.value ? "sel selected" : "")+(item.value ? "" : " group-title")}>
                        {item.label}
                    </li>)}
                </ul>
            </div> : null}
        </div>
    }
}