import {
    call,
    put,
    take
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors,
    fetchFileApi
} from '../tools/api-helper';

import fileDownload from "js-file-download";

import {checkToken} from "./auth";

/**
 *
 * @returns {{expand: bool}}
 */
export function library(params = null) {
    return {
        type: 'LIBRARY',
        params
    }
}

/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* fetchLibrary(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, pagination, error } = yield call( () => {
            return fetchClientApi("/helpful"+(action.params ? '?'+action.params : ''), {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (pagination) data.pagination = pagination;
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function libraryDownload(url, filename) {
    return {
        type: 'LIBRARY_DOWNLOAD',
        url,
        filename
    }
}

/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* fetchLibraryDownload(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchFileApi(action.url, localStorage.getItem('authToken'))
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status === 200) fileDownload(data, action.filename);
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}