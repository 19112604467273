import React, {Component} from "react";
import {phoneMask} from "../../tools/mask";
import PropTypes from "prop-types";

export default class UsersListItem extends Component{

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render(){

        var {item, onUpdate, onDelete} = this.props;

        return <div className="users__item">
            <div className="users__user">
                {item.IsUserDataAvailable ?
                    <p className="users__user-name">{item.LastName + " " + item.FirstName + (item.MiddleName ? " " + item.MiddleName : "")}</p>
                        : null}

                <p className="users__user-position">{item.IsUserDataAvailable ? item.Position : null}</p>
                <div className="users__user-contacts">
                    <p className="users__user-phone">
                        <span className="users__user-data-title">{this.context.translate('Мобильный телефон')}:&nbsp;</span>
                        <a href={"tel:"+item.Phone} target="_blank">{phoneMask(item.Phone, 'def')}</a>
                    </p>
                    {item.IsUserDataAvailable ? <p className="users__user-email">
                        <span className="users__user-data-title">E-mail:&nbsp;</span>
                        <a href={"mailto:"+item.Email}>{item.Email}</a>
                    </p> : null}
                </div>
                <p className="users__user-companies">
                    <span className="users__user-data-title">{this.context.translate('Компании')}:&nbsp;</span>
                    {item.AccessibleCompanies}
                </p>
                <div className="users__user-actions">
                    <a className="users__user-edit u-icon-link u-icon-link_edit" href="#" onClick={onUpdate}>{this.context.translate('Редактировать')}</a>
                    {item.CanDelete ? <a className="users__user-remove u-icon-link u-icon-link_remove" href="#" onClick={onDelete}>{this.context.translate('Удалить')}</a> : null}
                </div>
            </div>
        </div>
    }
}