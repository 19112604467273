import React from "react";
import {Link} from "react-router-dom";
import { isIE } from "react-device-detect";

export default function PromotionsListItem({item, translate, currentLanguage}){
        return <div id={"promotion-"+item.id} className="offers-list__offer-item">
            <div className="offers-list__block">
                <span className="offers-list__label offers-list__label_icon">{item.topic && item.topic.title}</span>
                <div className="offers-list__counter">
                    <div className={"counter "+(item.days_more >= 10 ? "counter_type_default" : "counter_type_warning")}>
                        <span className="counter__label">{translate('Осталось дней')}:</span>
                        <div className="counter__inner">{item.days_more}</div>
                    </div>
                </div>
                <div className="offers-list__img">
                    <img src={item.img}/>
                </div>
                <h2 className="offers-list__title">
                    {item.title}
                </h2>
                <div className="offers-list__text inner-html"  dangerouslySetInnerHTML={{__html: item.description}}/>
                <div className="offers-list__offer-end">
                    {translate('Предложение действительно до')}:<strong> {item.finish_dt}</strong>
                </div>
                <div className="offers-list__button">
                    <Link to={{pathname: "/applications/create", state: {from: 'promotion', id: item.id}}} className="btn btn_orange">{translate('отправить заявку')}</Link>
                </div>
            </div>
        </div>
}

export function PromotionsSlideItem({item, translate, currentLanguage}){

        return <div className="main-carousel__slide-item">
            <div className="main-carousel__slide">
                <div className="main-carousel__slide-header">
                    <Link className="main-carousel__slide-category" to={{pathname: "/promotions", state: {topic: item.topic && item.topic.id}}}>{item.topic && item.topic.title}</Link>
                    <p className="main-carousel__slide-remaning">
                        {translate("Осталось дней")}:
                        <span className={"main-carousel__slide-remaning-value"+(item.days_more < 10 ? " main-carousel__slide-remaning-value_red" : "")}>{item.days_more}</span>
                    </p>
                </div>
                <div className="main-carousel__slide-image-wrap">
                    {isIE === true ?
                        <svg role="image"
                             preserveAspectRatio="xMidYMid slice"
                             viewBox="0 0 1024 640" className="main-carousel__slide-image--IE">
                            <image width="100%" height="100%" xlinkHref={item.img}/>
                        </svg>
                        :
                        <img className="main-carousel__slide-image" height="88" src={item.img} alt=""/>
                    }
                </div>
                <Link className="main-carousel__slide-link" to={{pathname: "/promotions", state: {promotion: item.id}}}>{item.title}</Link>
                <div className="main-carousel__slide-offer u-mobile-only">
                    <div className={"inner-html"} dangerouslySetInnerHTML={{__html: item.description}} />
                    <p className="main-carousel__slide-offer-valid">
                        {translate("Предложение действительно до")}:
                        <span className="u-bold">{item.finish_dt}</span>
                    </p>
                    <Link to={{pathname: "/applications/create", state: {from: 'promotion', id: item.id}}} className="btn btn_orange">{translate("отправить заявку")}</Link>
                </div>
            </div>
        </div>
}
