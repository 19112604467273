import React, {Component} from "react";
import PropTypes from "prop-types";
import CheckBox from "../form/CheckBox";

export default class CompaniesFilter extends Component{

    constructor(props){
        super(props);

        var items = [], selected = [];
        if (props.companies) {
            props.companies.map((item, key) => {
                items.push({label: item.CompanyName, value: item.CompanyId});
                if (item.IsSelected) selected.push(item.CompanyId);
            });
        }

        this.state = {
            menuOpened: false,
            items,
            selected
        }
    }

    static contextTypes = {
        translate: PropTypes.func
    }

    componentWillReceiveProps(nextProps){
        if (this.props.companies != nextProps.companies) {
            var items = [], selected = [];
            nextProps.companies.map((item, key) => {
                items.push({label: item.CompanyName, value: item.CompanyId});
                if (item.IsSelected) selected.push(item.CompanyId);
            });
            this.setState({items, selected})
        }
    }

    componentDidMount(){
        document.addEventListener('click', this.windowClick);
    }

    componentWillUnmount(){
        document.removeEventListener('click', this.windowClick);
    }

    windowClick = () => {
        this.setState({menuOpened: false});
    };

    onClick = () => {
        this.setState({menuOpened: false});
        this.props.saveCompanies(this.state.selected)
    };

    onChange = (attribute, value) => {
        var selected = [];

        if (attribute == 'company_all') {
             if (value) {
                 selected = this.state.items.map((item) => {return item.value});
             }
        } else {
            selected = [...this.state.selected];
            if (value) {
                selected.push(attribute);
            } else {
                selected = selected.filter((item) => {
                    return item != attribute;
                })
            }
        }
        this.setState({selected});
    };

    toggleMenu = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        var prevState = this.state.menuOpened;

        var event = document.createEvent("Event");         event.initEvent("click", false, true);
        document.dispatchEvent(event);

        this.setState({menuOpened: !prevState});
    };

    renderLabel = (items) => {
        var selected = items.filter((item) => {
            return item.IsSelected;
        });
        switch (selected.length) {
            case items.length:
                return this.context.translate("всем компаниям");
            case 1:
                return selected[0].CompanyName;
            default:
                var ost = selected.length % 10;
                if ((selected.length >= 11 && selected.length <= 14) || (ost >= 2 && ost <= 4)) {
                    return selected.length+this.context.translate("-м компаниям");
                } else if (ost == 1) {
                    return selected.length+this.context.translate("-й компании");
                } else if (ost >= 7 && ost <= 8 && selected.length != 17 && selected.length != 18) {
                    return selected.length+this.context.translate("-ми компаниям");
                }
                return selected.length+this.context.translate("-ти компаниям");
        }
    };

    render(){
        let items = this.props.companies;

        let {
            selected,
            menuOpened
        } = this.state;

        if (!items || items.length < 2) return null;

        return <div className={"contact contact_address"+(menuOpened ? " active" : "")}>
            <div className="contact__row">
                <span className="contact__title">{this.context.translate('Вы смотрите информацию по')}:</span>
            </div>
            <div className="contact__row contact__companies" onClick={this.toggleMenu}>
                <span>
                    {this.renderLabel(items)}
                </span>
                {menuOpened ? <div className="contact__dropdown" onClick={(e) => {e.stopPropagation();e.nativeEvent.stopImmediatePropagation()}}>
                    <div>
                        <CheckBox id={"company_all"}
                                  attribute={"company_all"}
                                  label={this.context.translate("Все компании")}
                                  value={selected.length == items.length}
                                  className="contact__checkbox"
                                  onChange={this.onChange}
                        />
                        {items.map((item, key) => <CheckBox key={key}
                                                            id={"company"+item.CompanyId}
                                                            label={item.CompanyName}
                                                            attribute={item.CompanyId}
                                                            value={selected.indexOf(item.CompanyId) != -1}
                                                            className="contact__checkbox"
                                                            onChange={this.onChange}
                        />)}
                    </div>
                    <button className={"btn"+(selected.length == 0 ? " disabled" : "")} type="submit" onClick={this.onClick}>{this.context.translate('Применить')}</button>
                </div> : null}
            </div>
        </div>
    }
}