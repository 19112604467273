import React, {Component} from "react";

export default class CheckBox extends Component{

    onChange = () => {
        let {
            attribute,
            value,
            onChange
        } = this.props;

        onChange && onChange(attribute, !value);
    }

    render(){

        let {
            id,
            value,
            label,
            className
        } = this.props;

        return <div className={className ? className : "form__checkbox"} onClick={(e) => {e.stopPropagation();e.nativeEvent.stopImmediatePropagation()}}>
            <input id={id} type="checkbox" checked={value} onChange={this.onChange}/>
            <label htmlFor={id}>{label}</label>
        </div>
    }
}