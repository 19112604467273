import React, {Component} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

export default class CompaniesListItem extends Component{

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render(){
        let {item} = this.props;

        return <div className="company company_adapt-default">
            <p className="company__heading">{item.CompanyName}</p>
            <p className="company__info company__info_reqs">
                <span className="company__info-reqs company__info-reqs_inn">
                    <span className="company__title">{this.context.translate('ИНН')}:</span>
                    <span className="company__text">{item.TIN}</span>
                </span>
                <span className="company__info-reqs company__info-reqs_kpp">
                    <span className="company__title">{this.context.translate('КПП')}:</span>
                    <span className="company__text">{item.KPP}</span>
                </span>
            </p>
            <p className="company__info">
                <span className="company__title">{this.context.translate('Юридический адрес')}:</span>
                <span className="company__text">{item.AddressLegal}</span>
            </p>
            <p className="company__info">
                <span className="company__title">{this.context.translate('Почтовый адрес')}:</span>
                <span className="company__text">{item.AddressPost == item.AddressLegal ? this.context.translate("совпадает с юридическим") : item.AddressPost}</span>
            </p>
            <Link className="company__request" to={"/companies/"+item.CompanyId}>{this.context.translate('Запросить изменение данных')}</Link>
        </div>
    }
}