import React, {Component} from "react";
import {PaymentSchedule, ScheduleTable} from "../common/Schedule";
import Radio from "../common/form/Radio";
import PropTypes from "prop-types";

export default class DocumentSchedule extends Component{

    constructor(props, context){
        super(props, context);

        this.state = {
            activeTab: 'table'
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    tabChange = (attribute, value) => {
        this.setState({activeTab: value});
    }

    render(){

        this.tabOptions = [
            {
                label: this.context.translate("Диаграмма"),
                value: "schedule"
            },
            {
                label: this.context.translate("Таблица"),
                value: "table"
            }
        ];

        let {
            ScheduleDate,
            Schedule,
            downloadContractSchedule,
            Price,
            PriceVat
        } = this.props;

        let {
            activeTab
        } = this.state;

        return <div className="page__graph-holder">
            <div className="graph-dfa graph-dfa_adapt-default js-demo">
                <div className="graph-dfa__title">{this.context.translate('График лизинговых платежей')}</div>
                <div className="graph-dfa__header">
                    <div className="graph-dfa__top-section">
                        <div className="graph-dfa__date-holder">
                            <div className="graph-dfa__param">
                                <span className="graph-dfa__param-label">{this.context.translate('Дата расчета')}:&nbsp;</span>
                                <span className="graph-dfa__param-value">{ScheduleDate}</span>
                            </div>
                        </div>
                        <div className="graph-dfa__link-holder">
                            <a className="u-doc-link u-doc-link_no-color u-doc-link_xls" href="#" onClick={downloadContractSchedule}>
                                <span>{this.context.translate('Скачать график')}</span>
                            </a>
                        </div>
                    </div>
                    <div className="graph-dfa__bottom-section">
                        <div className="graph-dfa__switcher">
                            <div className="switcher">
                                <Radio options={this.tabOptions} value={activeTab} attribute="tabBtns" onChange={this.tabChange} />
                            </div>
                        </div>
                        <div className="graph-dfa__legend">
                            <div className="chart-legend chart-legend_adapt-default">
                                <div className="chart-legend__items">
                                    <div className="chart-legend__item chart-legend__item_colored">
                                        <div className="chart-legend__color paid"/>
                                        <div className="chart-legend__label">{this.context.translate('Оплачено')}</div>
                                    </div>
                                    <div className="chart-legend__item chart-legend__item_colored">
                                        <div className="chart-legend__color overdue"/>
                                        <div className="chart-legend__label">{this.context.translate('Просрочено')}</div>
                                    </div>
                                    <div className="chart-legend__item chart-legend__item_colored">
                                        <div className="chart-legend__color" />
                                        <div className="chart-legend__label">{this.context.translate('Будущие платежи')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {Schedule ? <div className="graph-dfa__body">
                    <div className={"graph-dfa__tab"+(activeTab === 'schedule' ? " graph-dfa__tab_table graph-dfa__tab_active" : "")}>
                        <PaymentSchedule {...Schedule} translate={this.context.translate}/>
                    </div>
                    <div className={"graph-dfa__tab"+(activeTab === 'table' ? " graph-dfa__tab_table graph-dfa__tab_active" : "")}>
                        <ScheduleTable {...Schedule} translate={this.context.translate}/>
                    </div>
                </div> : null}
                <div className="graph-dfa__footer">
                    <div className="graph-dfa__result">{this.context.translate('Итого сумма лизинговых платежей')}: <span className="u-summ u-summ_bold">{Price}</span>, {this.context.translate('в т.ч. НДС')} <span className="u-summ u-summ_bold">{PriceVat}</span></div>
                </div>
            </div>
        </div>
    }

}