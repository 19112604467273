import React, {Component} from "react";

export class Steps extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isCollapsed: props.disableCollapse ? false : true
        }
    }

    toggleCollapse = () => {
        this.setState((prevState) => ({isCollapsed: !prevState.isCollapsed}))
    }

    render() {

        const {items, disableCollapse} = this.props;
        const {isCollapsed} = this.state;

        return <div className={'steps'}>
            {items.map((item, key) => <Step
                key={key}
                index={key + 1}
                isLast={key === items.length - 1}
                isCollapsed={isCollapsed}
                title={item.title}
                content={item.content}
                links={item.links}
            />)}
            {disableCollapse ? null : <p
                className={`js-collapser u-more-link-bb u-more-link${isCollapsed ? '' : ' u-more-link_open'}`}
                onClick={this.toggleCollapse}
            >{isCollapsed ? "Подробнее" : "Свернуть шаги"}</p>}
        </div>
    }
}

class Step extends Component {
    render() {

        const {index, isLast = false, isCollapsed = false, title = "", content, links = null} = this.props;

        return <div className={`step${isLast ? ' step__last' : ""}`}>
            <div className='step__title' data-index={index}>{title}</div>
            {isCollapsed ? null : <div className={`step__body${isLast ? ' step__body__last' : ""}`}>
                {content}
            </div>}
            {links ? <div className={'step__links'}>
                {links}
            </div> : null}
        </div>
    }
}