export const screenSizeLg = 1280;
export const screenSizeMd = 768;

export const isSupportUser = IS_SUPPORT_USER;

/**
 * @returns {string}
 */
export function getScreenSize() {
    if (window.innerWidth >= screenSizeLg) return 'lg';
    if (window.innerWidth >= screenSizeMd) return 'md';
    return 'sm';
}

/**
 * @param cnt - items count
 * @param one - title for 1 item
 * @param some - title for 2 items
 * @param plural - title for plural items
 * @return string
 */
export function getWord(cnt, one, some, plural){
    if ([11,12,13,14].indexOf(cnt % 100) !== -1) return plural;
    switch (cnt % 10) {
        case 1:
            return (cnt % 100 === 11 ? plural : one);
        case 2:
        case 3:
        case 4:
            return some;
        default:
            return plural;
    }
}

export function getRandomInt(min, max){
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getFileSize(bytes) {
    var measure = [
        'б',
        'Кб',
        'Мб',
        'Гб'
    ];
    var n = 0;
    while (bytes/1024 > 1 && n < 3) {
        bytes = bytes/1024;
        n++;
    }
    return bytes.toFixed(n)+" "+measure[n];
}

export function detectmobile() {
    if( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    ){
        return true;
    }
    else {
        return false;
    }
}


export function getTime(diff, translate){
    if (diff >= 60) {
        diff = Math.round(diff/60);
        if (diff >= 60) {
            diff = Math.round(diff/60);
            if (diff >= 24) {
                diff = Math.round(diff/24);
                if (diff >= 7) {
                    if (diff >= 30) {
                        diff = Math.round(diff/30);
                        if (diff >= 12) {
                            diff = Math.round(diff/12);
                            return diff+" "+getWord(diff, translate("год"), translate("года"), translate("лет"));
                        } else {
                            return diff+" "+getWord(diff, translate("месяц"), translate("месяца"), translate("месяцев"));
                        }
                    } else {
                        diff = Math.round(diff/7);
                        return diff+" "+getWord(diff, translate("неделю"), translate("недели"), translate("недель"));
                    }
                } else {
                    return diff+" "+getWord(diff, translate("день"), translate("дня"), translate("дней"));
                }
            } else {
                return diff+" "+getWord(diff, translate("час"), translate("часа"), translate("часов"));
            }
        } else {
            return diff+" "+getWord(diff, translate("минуту"), translate("минуты"), translate("минут"));
        }
    } else {
        diff = Math.round(diff);
        return diff+" "+getWord(diff, translate("секунду"), translate("секунды"), translate("секунд"));
    }
}