import React, {Component} from "react";
import Form from "../common/form/Form";
import CheckBox from "../common/form/CheckBox";
import PropTypes from "prop-types";

export default class ConfirmEmailForm extends Component{

    constructor(props){
        super(props);
        this.state = {
            accept: false
        };

    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    onChangeField = () => {
        this.setState((prevState) => {
            return {
                accept: !prevState.accept
            }
        })
    };

    onSubmit = () => {
        this.props.onSubmit();
    }

    render() {

        return (
            <Form className="auth__form-slide active"
                  validate={true}
                  onSubmit={this.onSubmit}
            >
                <CheckBox id="accept" value={this.state.accept} label={this.context.translate("Я подтверждаю, что это мой e-mail адрес")} onChange={this.onChangeField}/>
                <div className="form__buttons">
                    <button type="submit"
                            className={"btn btn_orange" + (this.state.accept && !this.props.sendRequest ? "" : " disabled")}
                    >{this.context.translate('Отправить')}
                    </button>
                </div>
            </Form>
        );
    }
}