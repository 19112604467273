const initState = {};

export function singleApplication(state = initState, action)
{
    switch (action.type){
        case "APPLICATION_INFO":
            return {...state, isOk: false};
        case "APPLICATION_INFO_SUCCESS":
            return {...action.data, isOk: true};
        case "RECALL_APPLICATION":
        case "REQUEST_APPLICATION":
        case "RESUME_APPLICATION":
            return {...state, isActionOk: false, actionMessage: ""};
        case "RECALL_APPLICATION_BAD_REQUEST":
        case "REQUEST_APPLICATION_BAD_REQUEST":
        case "RESUME_APPLICATION_BAD_REQUEST":
            return {...state, actionMessage: action.data.message};

        case "RECALL_APPLICATION_SUCCESS":
            if (state.Actions) {
                var Actions = [...state.Actions];
                if (state.ApplicationId == action.data.ApplicationId) {
                    var index = Actions.indexOf('recall');
                    if (index !== -1) Actions.splice(index, 1);
                }
                return {...state, Actions, isActionOk: true, actionMessage: ""};
            }
            return state;
        case "RESUME_APPLICATION_SUCCESS":
            if (state.Actions) {
                var Actions = [...state.Actions];
                if (state.ApplicationId == action.data.ApplicationId) {
                    var index = Actions.indexOf('resume');
                    if (index !== -1) Actions.splice(index, 1);
                }
                return {...state, Actions, isActionOk: true, actionMessage: ""};
            }
            return state;
        case "REQUEST_APPLICATION_SUCCESS":
            return {...state, isActionOk: true, actionMessage: ""};
        case 'DELETE_FILE_SUCCESS':
            if (state.ApplicationId == action.data.id) {
                var docs = [...state.Documents];
                docs = docs.filter(function(doc){
                    return doc.ID != action.data.fileId;
                });
                return {...state, Documents: docs};
            } else {
                return state;
            }
        case 'APPLICATION_DOCUMENTS_SUCCESS':
            if (state.ApplicationId == action.data.id) {
                var docs = action.data.docs;
                return {...state, Documents: docs};
            } else {
                return state;
            }
        case 'UPDATE_FILE_SUCCESS':
            if (state.ApplicationId == action.data.id) {
                var docs = [...state.Documents];
                docs = docs.map((item) => {
                    if (item.ID == action.data.fileId) item.DocumentName = action.data.fileName;
                    return item;
                });
                return {...state, Documents: docs};
            } else {
                return state;
            }

    }
    return state;
}