const iniState = {added: false};

export function company(state = iniState, action) {

    switch (action.type) {
        case 'FIND_COMPANY':
            return {errors: {}, added: false};
        case 'FIND_COMPANY_VALIDATION_ERROR':
            var errors = {...state.errors} || {};
            errors.Inn = action.data.message;
            return {errors, added: false};
        case 'FIND_COMPANY_NOT_FOUND':
            return {modalMessage: action.data.message, added: false};
        case 'FIND_COMPANY_SUCCESS':
            return {modalMessage: 'Нижеуказанные данные взяты из СПАРК. В случае обнаружения несоответствий обратитесь, пожалуйста, к вашему менеджеру.', fields: action.data, added: false};
        case 'SAVE_FIELDS':
            return {...state, modalMessage: '', added: false};
        case 'SAVE_COMPANY_SUCCESS':
            return {modalMessage: '', added: true};
        case 'FIND_COMPANY_BAD_REQUEST':
            return {modalMessage: action.data.message, added: false};
        case 'CLEAR_COMPANY_FORM':
            return {errors: {}, added: false};
    }

    return state;
}