import React,{Component} from "react";

import PropTypes from "prop-types";
import DocumentsList from "./DocumentsList";
import {Link} from "react-router-dom";
import Preloader from "../../components/common/Preloader";
export default class ProjectsListItem extends Component{

    constructor(props){
        super(props);
        this.state = {
            documentsListOpened: false
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    getStatusClass = (status) => {
        switch (status) {
            case 'red':
                return 'projects-list__item-title_red';
            case 'pink':
                return 'projects-list__item-title_pink';
            case 'orange':
                return 'projects-list__item-title_yellow';
            case 'green':
                return 'projects-list__item-title_green';
        }
    };

    toggleDocumentsList = (e) => {
        e.preventDefault();

        this.setState((prevState) => {
            return {
                documentsListOpened: !prevState.documentsListOpened
            }
        }, () => {
            if (this.state.documentsListOpened && !this.props.item.Documents) {
                this.props.getDocuments(this.props.item.ProjectId);
            }
        });
    };

    render(){
        let {
            item,
            lesseeCount,
            downloadContractSchedule,
            activeTab
        } = this.props;

        let listOpened = this.state.documentsListOpened;

        return  <div className="projects-list__item">
            <div className="projects-list__plate">
                <div className={"projects-list__item-title "+this.getStatusClass(item.ProjectObligationStyle)}>
                    <Link to={{pathname: "/projects/"+item.ProjectId, state: {activeTab: activeTab}}} className="u-link">{item.LinkTitle}</Link>
                    {item.AdditionalTitle}
                </div>
                <div className="projects-list__item-ui">
                    <div className="projects-list__ui-link">
                        <a className={"u-more-link u-icon-link"+(listOpened ? " u-more-link_open" : "")} href="#" onClick={this.toggleDocumentsList}><span>{this.context.translate('Показать список договоров')}</span></a>
                    </div>
                    {/*<div className="projects-list__ui-link u-mobile-hide">*/}
                        {/*<Link className="u-icon-link" to={"/documents"}>*/}
                            {/*<span>Перейти к документам</span>*/}
                        {/*</Link>*/}
                    {/*</div>*/}
                </div>
                {listOpened && !item.Documents ? <Preloader /> : null}
                {listOpened && item.Documents ? <DocumentsList documents={item.Documents}
                                             lesseeCount={lesseeCount}
                                             downloadContractSchedule={downloadContractSchedule}
                                             activeTab={activeTab}
                /> : null}
                {/*<div className="projects-list__item-footer u-mobile-only">*/}
                    {/*<div className="projects-list__ui-link">*/}
                        {/*<Link className="u-icon-link" to={"/documents"}>*/}
                            {/*<span>Перейти к документам</span>*/}
                        {/*</Link>*/}
                    {/*</div>*/}
                {/*</div>*/}
            </div>
        </div>
    }

}