import {
    call,
    put,
    take
} from 'redux-saga/effects';

import {checkErrors, fetchClientApi} from "../tools/api-helper";

import {checkToken} from "./auth";

export function faq(query) {
    return {
        type: 'FAQ',
        query
    }
}

export function* fetchFaq(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/faq"+(action.query ? '?'+encodeURI('query='+action.query) : ''), {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function faqAction(id, action) {
    return {
        type: 'FAQ_ACTION',
        id,
        action
    }
}

export function* fetchFaqAction(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/faq/"+action.id+"?action="+action.action, {
                method: 'PUT',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}