import React from "react";
import {floatMask} from "../../tools/mask";

export function PaymentSchedule({payments, settings, translate}){

    var barKey = Math.floor(payments.length/12),
        labelKey = Math.floor(payments.length/6);

    return  <div className="application__offer-chart">
                <div className="bar-chart bar-chart_adapt-default bar-chart_application">
                    <div className="bar-chart__holder">
                        <div className="bar-chart__canvas">
                            <div className="bar-chart__y-labels">
                                {settings.amountAxisLabels.map((item, key) => <div key={key}
                                                                                   className={"bar-chart__y-label"+(item == 0 ? " bar-chart__y-label_no-currency" : '')}>
                                    {item}</div>)}
                            </div>
                            <div className="bar-chart__body">
                                <div className="bar-chart__y-lines">
                                    <div className="bar-chart__y-line"/>
                                    <div className="bar-chart__y-line"/>
                                    <div className="bar-chart__y-line"/>
                                </div>
                                <div className="bar-chart__bars">
                                    {payments.map((item, key) => <BarItem payment={item}
                                                                          amountAxisMax={settings.amountAxisMax}
                                                                          key={key}
                                                                          isBar={key % barKey === 0}
                                                                          isLabel={key % labelKey === 0}
                                                                          translate={translate}
                                    />)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
}

function BarItem({payment, amountAxisMax, isBar, isLabel, translate}){

    return <div className="bar-chart__bar-item">
        <div className={"bar-chart__bar"+(payment.IsPaid === 1 ? " bar-chart__bar_green" : (payment.IsOverdue === 1 ? " bar-chart__bar_red" : ""))} style={{height: payment.PaymentAmount*100/amountAxisMax+'%'}}/>
        {isBar ? <div className="bar-chart__bar-bottom"/> : null}
        {isLabel ? <div className="bar-chart__bar-label">{payment.PaymentDateLabel}</div> : null}
        <div className="bar-chart__tooltip">
            <p className="bar-chart__tooltip-title">{payment.PaymentDateTooltipLabel}</p>
            <div className="bar-chart__tooltip-data">
                <span className="bar-chart__tooltip-data-name">{translate('Размер платежа')}</span>
                <span className="bar-chart__tooltip-data-value">{payment.PaymentAmountTooltipLabel}</span>
            </div>
            <div className="bar-chart__tooltip-data">
                <span className="bar-chart__tooltip-data-name">{translate('В т.ч. НДС')}</span>
                <span className="bar-chart__tooltip-data-value">{payment.PaymentVatTooltipLabel}</span>
            </div>
        </div>
    </div>
}

export function ScheduleTable({payments, settings, translate}) {

    return <div className="dfa-table dfa-table_adapt-default dfa-table_pp-detail">
        <div className="dfa-table__head">
            <div className="dfa-table__th"/>
            <div className="dfa-table__th">№</div>
            <div className="dfa-table__th">{translate('Дата платежа')}</div>
            <div className="dfa-table__th dfa-table__th_align-right">
                <nobr>{translate('Платеж')}, {settings.currency}</nobr>
            </div>
            <div className="dfa-table__th dfa-table__th_align-right">
                <nobr>{translate('Оплачено')}, {settings.currency}</nobr>
            </div>
            <div className="dfa-table__th"/>
        </div>
        <div className="dfa-table__body">
            <div className="dfa-table__scroll-holder js-nicescroll">
                <table className="dfa-table__table">
                    <tbody>
                        {payments.map((item, key) => <tr className="dfa-table__tr" key={key}>
                            <td className={"dfa-table__td "+(item.IsPaid ? "dfa-table__td_green" : (item.IsOverdue ? "dfa-table__td_red" : "dfa-table__td_blue"))}/>
                            <td className="dfa-table__td">{item.PaymentNumber}</td>
                            <td className="dfa-table__td">{item.PaymentDate}</td>
                            <td className="dfa-table__td dfa-table__td_align-right">
                                <span className="u-summ">{floatMask(item.PaymentAmount)}</span>
                            </td>
                            <td className="dfa-table__td dfa-table__td_align-right">
                                <span className="u-summ">{item.PaidAmount ? floatMask(item.PaidAmount) : '-'}</span></td>
                            <td className="dfa-table__td"/>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}