import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {promotionsList, topicsList, translatePromotions} from "../../actions/promotions";
import PromotionsListItem from "../../components/promotions/PromotionsListItem";
import Pagination from "../../components/common/Pagination";
import Preloader from "../../components/common/Preloader";
import Collapser from "../../components/common/Collapser";

class PromotionsContainer extends Component{

    constructor(props){
        super(props);
        this.state = {
            topic: props.location.state && props.location.state.topic ? props.location.state.topic : null,
            page: 1,
            collapsed: false,
            getId: [],
            topicsRender: []
        }
    }

    static contextTypes = {
        screenSize: PropTypes.string,
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    toPage = (number) => {
        var params = this.state.topic ? "topic_id="+this.state.topic+"&" : "";
        params += 'page='+number;
        this.props.getList(params);
        this.setState({page: number});
    }

    scrollTo = (y) => {
        var top = Math.max(document.body.scrollTop,document.documentElement.scrollTop);

        if(top < y) {
            window.scrollBy(0, 10);
            this.t = setTimeout(this.scrollTo(y),20);
        } else clearTimeout(this.t);

    }

    componentDidMount(){
        var state = this.props.location.state;
        if (state && state.promotion) {
            if (this.props.promotions.isOk) {
                setTimeout(() => {
                    var y = document.getElementById('promotion-'+state.promotion).offsetTop;
                    this.scrollTo(y);
                }, 100)
            }
        }
    }

    componentWillReceiveProps(nextProps, nextContext){
        var state = this.props.location.state;
        if (state && state.promotion) {
            if (nextProps.promotions.isOk != this.props.promotions.isOk && nextProps.promotions.isOk) {
                setTimeout(() => {
                    var y = document.getElementById('promotion-'+state.promotion).offsetTop;
                    this.scrollTo(y);
                }, 100)
            }
        }
        if (nextContext.currentLanguage.alias !== this.context.currentLanguage.alias) {
            var params = this.state.topic ? "topic_id="+this.state.topic+"&" : "";
            params += 'page='+this.state.page;
            this.props.translatePromotions(params);
        }

        if (this.state.topicsRender.length === 0 && this.props.promotions && this.props.promotions.topics.length > 0) {
            this.setState({topicsRender: this.props.promotions.topics})
        }
    }

    setStateValue = (type, val) => {
        let topicsRender = [...this.state.topicsRender];

        if (topicsRender.length > 0) {
            for (let i = 0; i < topicsRender.length; i++) {
                if (topicsRender[i].id === val) {
                    if (type === 'set') topicsRender[i].active = true;
                    if (type === 'remove') delete topicsRender[i].active;
                }
            }
        }
        this.setState({topicsRender});
    }

    onTopicClick = (val) => {
        let getId = [...this.state.getId];

        return (e) => {
            e.preventDefault();
            if (this.state.topic === val) {
                // this.props.getList();
                this.setState({topic: null, page: 1, collapsed: false});
            } else {
                // this.props.getList("topic_id="+val);
                // this.setState({topic: val, page: 1, collapsed: false});
            }

            let findItem = getId.includes(val);

            if (!findItem) {
                this.setStateValue('set', val);
                getId.push(val);
                this.setState({getId});
                let tempArr = getId.map((item) => {return item});
                this.props.getList('topic_id=' + tempArr.join(','));
            }
        }
    }

    onClose = val => {
        let getId = this.state.getId.filter(item => item !== val);

        this.setState({getId});
        this.setStateValue('remove', val)
        let tempArr = getId.map((item) => {return item});
        this.props.getList('topic_id=' + tempArr.join(','));
    }

    renderTopicsList = (topics, topic) => {
        return <ul className="page__offers-topics">
            {topics.map((item) => <li className={"page__offers-topic"+(!!item.active ? " active" : "")} key={item.id}>
                <a href="#" onClick={this.onTopicClick(item.id)}>
                    {item.title[this.context.currentLanguage.alias]}
                    {!!item.active ? <span onClick={(e)=> {e.preventDefault(); this.onClose(item.id)}}>&times;</span> : null}
                </a>
            </li>)}
        </ul>
    }

    renderTopics = (topics) => {
        if (!topics.length) return null;

        let {topic, collapsed} = this.state;

        if (this.context.screenSize !== 'sm') {
            return this.renderTopicsList(topics, topic);
        }

        if (topic) {
            let topicName = topics.filter((item) => {return item.id === topic})[0].title;
            return <div>
                <span className="promotions-collapser-before-text collapser-before-text">{this.context.translate('Фильтровать по')}:</span>
                <Collapser label={topicName}
                           collapsed={collapsed}
                           labelClassName="promotions-collapser"
                           labelActiveClassName="promotions-collapser active"
                >
                    {this.renderTopicsList(topics, topic)}
                </Collapser>
            </div>
        } else {
            return <Collapser label={collapsed ? this.context.translate("Скрыть") : this.context.translate("Показать все")}
                              collapsed={collapsed}
                              labelClassName="promotions-collapser"
                              labelActiveClassName="promotions-collapser active"
            >
                {this.renderTopicsList(topics, topic)}
            </Collapser>
        }
    }

    render(){
        let {
            list,
            topics = [],
            isOk
        } = this.props.promotions;

        let topicsRender = this.state.topicsRender;

        return <div className="page">
            <div className="page__main page__main_default">
                <div className="page__content-container">
                    <h1 className="page__title">{this.context.translate('Акции')}</h1>
                    {this.renderTopics(topicsRender)}
                    {isOk ? <div className="page__offers-list">
                        <div className="offers-list offers-list_adapt_default">
                            <div className="offers-list__items">
                                {list.map((item, key) => <PromotionsListItem item={item} key={key} {...this.context}/>)}
                            </div>
                        </div>
                        <Pagination key="pagination" {...list.pagination} toPage={this.toPage}/>
                    </div> : null}
                </div>
            </div>
        </div>
    }

}

export default connect(
    state => ({
        promotions: state.promotions
    }),
    (dispatch) => {
        return {
            getList: (params) => dispatch(promotionsList(params)),
            translatePromotions: (params) => dispatch(translatePromotions(params)),
            getTopics: () => dispatch(topicsList())
        }
    }
)(PromotionsContainer)