import React, {Component} from "react";
import PropTypes from "prop-types";

export default class OfficesList extends Component{

    constructor(props){
        super(props);

        this.state = {
            query: "",
            list: props.contacts || []
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    changeQuery = (e) => {
        var val = e.target.value.toLowerCase();

        this.setState({
            query: e.target.value,
            list: this.props.contacts.filter((item) => {
                return item.Name.toLowerCase().indexOf(val) !== -1;
            })
        })
    }

    render(){

        let {
            currentRegion,
            selectOffice
        } = this.props;

        let {
            query,
            list
        } = this.state;

        return  <div className="contacts-modal contacts-modal_adapt-default">
            <p className="contacts-modal__title">{this.context.translate('Выбор офиса')}</p>
            <p className="contacts-modal__current-city">
                {this.context.translate('Текущий выбранный регион')}:
                <span className="contacts-modal__current-city-name">{currentRegion}</span>
            </p>
            <div className="contacts-modal__search">
                <div className="search-input">
                    <label className="search-input__wrap">
                        <div className="search-input__label-text"/>
                        <div className="search-input__input-wrap">
                            <input className="search-input__input"
                                   type="text"
                                   value={query}
                                   onChange={this.changeQuery}
                                   placeholder={this.context.translate("Название или номер региона")}
                            />
                        </div>
                    </label>
                </div>
            </div>
            <ul className="contacts-modal__city-list">
                {list.map((item, key) => <li className="contacts-modal__city-item" key={key}>
                    <a className={"contacts-modal__city-link"+(item.IsUserSupportingOffice ? " contacts-modal__city-link_current" : "")}
                       href="#" onClick={selectOffice(item.OfficeId)}>
                        {item.Name} {item.IsHeadquarters ? "("+this.context.translate('головной офис')+")" : (item.IsUserSupportingOffice ? "("+this.context.translate('мой офис')+")" : null)}
                    </a>
                </li>)}
            </ul>
        </div>
    }
}