import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { withRouter} from 'react-router-dom';

import AuthContainer from "./auth/AuthContainer";

import LoginLayout from "../components/common/layout/LoginLayout";

import {footerSettings} from "../actions/catalog";
import {getScreenSize} from "../tools/tools";
import {setLanguage, getLanguages, getDictionary} from "../actions/multilingual";

import "../../styles/login.css";
import {checkLocked} from "../actions/auth";

class Login extends Component{

    constructor(props){
        super(props);
        this.state = {};
    }

    componentWillMount() {
        this.props.checkLocked();
    }

    componentDidMount = () => {
        this.setState({screenSize: getScreenSize()});
        window.addEventListener('resize', this.onResize);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    };

    onResize = () => {
        this.setState({screenSize: getScreenSize()});
    };

    static childContextTypes = {
        screenSize: PropTypes.string,
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    getChildContext() {
        return {
            screenSize: this.state.screenSize,
            currentLanguage: this.props.multilingual.currentLanguage,
            translate: (message, attributes = {}) => {
                var message = this.props.multilingual.dictionary[message] || message;
                for (var key in attributes) {
                    message = message.replace("{"+key+"}", attributes[key]);
                }
                return message;
            }
        };
    }

    render(){
        return <div className="wrapper">
            <LoginLayout {...this.props}>
                <AuthContainer/>
            </LoginLayout>
        </div>
    }
}

export default withRouter(connect(
    state => ({
        footerLinks: state.catalog && state.catalog.footerSettings,
        multilingual: state.multilingual
    }),
    (dispatch) => {
        return {
            footerSettings: () => dispatch(footerSettings()),
            setLanguage: (lang) => dispatch(setLanguage(lang)),
            getLanguages: () => dispatch(getLanguages()),
            getDictionary: () => dispatch(getDictionary()),
            checkLocked: () => dispatch(checkLocked())
        }
    }
)(Login));