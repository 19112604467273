import React,{Component} from "react";

import DocumentsList from "./DocumentsList";
import {Link} from "react-router-dom";
import ProjectDealsList from "../deals/ProjectDealsList";
import Pagination from "../common/Pagination";
import PropTypes from "prop-types";


export default class ProjectDetail extends Component{

    constructor(props){
        super(props);
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render(){
        let {
            ProjectId,
            ProjectNumber,
            ProjectName,
            Date,
            TotalCost,
            Deals,
            Documents,

            downloadContractSchedule,
            toPage,
            downloadInvoice,
            paymentNotification,
            lesseeCount,

            location
        } = this.props;

        return  <div className="page__content-container">
            <div className="page__back-link">
                <Link className="u-back-link" to={{pathname: "/projects", state: location.state}}>
                    <span>{this.context.translate('Проекты')}</span>
                </Link>
            </div>
            <h1 className="page__title">{this.context.translate('Проект')} №{ProjectNumber} «{ProjectName}»</h1>
            <div className="page__project-detail">
                <div className="project-detail project-detail_adapt-default">
                    <div className="project-detail__params">
                        <div className="params-list">
                            <div className="params-list__items">
                                <div className="params-list__list-item">
                                    <span className="params-list__term">{this.context.translate('Дата одобрения проекта')}:</span>
                                    <span className="params-list__defenition">{Date}</span></div>
                                <div className="params-list__list-item">
                                    <span className="params-list__term">{this.context.translate('Стоимость предметов лизинга')}:</span>
                                    <span className="params-list__defenition"><nobr>{TotalCost}</nobr></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ProjectDealsList items={Deals} paymentNotification={paymentNotification} downloadInvoice={downloadInvoice}/>
                    <div className="project-detail__sub-title">{this.context.translate('Список договоров проекта')}</div>
                    <DocumentsList lesseeCount={lesseeCount}
                                   documents={Documents}
                                   downloadContractSchedule={downloadContractSchedule}
                                   activeTab={location.state && location.state.activeTab}
                    />
                    {Documents && Documents.pagination ? <Pagination key="pagination" {...Documents.pagination} toPage={toPage} /> : null}
                </div>
            </div>
        </div>
    }

}