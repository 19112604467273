import React, {Component} from "react";
import {Link} from "react-router-dom";
import OrdersForm from "./OrdersForm";
import Modal from "../common/Modal";
import CompanyForm from "./CompanyForm";
import Preloader from "../../components/common/Preloader";
import PropTypes from "prop-types";

export default class AddOrder extends Component{
    constructor(props){
        super(props);
        this.state = {
            modalOpened: false
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    toggleCompanyModal = (e) => {
        e.preventDefault();
        this.setState((prevState) => {
            if (prevState.modalOpened) {
                this.props.clearCompanyForm();
            }
            return {
                modalOpened: !prevState.modalOpened
            }
        })
    };

    componentWillReceiveProps(nextProps){
        if (nextProps.company.added != this.props.company.added && nextProps.company.added) {
            this.setState({modalOpened: false})
        }
    }

    render(){
        let {
            fields,
            status,
            message,

            company,
            findCompany,
            addCompany,
            documentTypes
        } = this.props;

        return  <div className="page">
                    <div className="page__main page__main_default">
                        <div className="page__application-edit-container">
                            <h1 className="page__title">{!fields || fields.IsDraft ? this.context.translate('Новая заявка') : this.context.translate('Редактирование заявки')}</h1>
                            {fields && !fields.IsDraft ?
                                <h2 className="page__sub-title page__sub-title_mini">
                                    {fields.EquipmentName || this.context.translate('Заявка')+' № '+fields.ApplicationId}
                                </h2> : null}
                            <div className="page__application-edit">
                                <div className="application-edit application-edit_adapt_default">
                                    <OrdersForm
                                        {...this.props}
                                        addCompany={this.toggleCompanyModal}
                                    />
                                </div>
                            </div>
                            {this.state.modalOpened ? <Modal classNameContent="modal modal_visible" onClickBackground={this.toggleCompanyModal}>
                                <CompanyForm {...company}
                                    key="company-form"
                                    findCompany={findCompany}
                                    addCompany={addCompany}
                                />
                            </Modal> : null}
                            {status == 'error' ? <Modal classNameContent="modal modal_visible modal_xs">
                                <h2 className="form__title">{message}</h2>
                                <Link to={"/applications" + "#drafts"}>{this.context.translate('Перейти к заявкам')}</Link>
                            </Modal> : null}
                        </div>
                    </div>
                </div>
    }
}