import React, {Component} from "react";
import PropTypes from "prop-types";
import {phoneMask} from "../../tools/mask"

import {getTime} from "../../tools/tools";

export default class OfficeInfo extends Component{

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    renderTimeString = (time) => {
        var timeStr = (new Date(1000*time)).toLocaleTimeString('ru');
        return timeStr.substr(0, timeStr.lastIndexOf(':'))
    }

    renderRelativeTimeString = (timeStart, timeEnd) => {
        var now = new Date(Date.now());
        var diff = timeEnd - now.getTime()/1000;

        if (diff < 0) {
            diff = timeStart + (24*3600) - now.getTime()/1000;
            return this.context.translate("Откроется через")+" "+getTime(diff, this.context.translate);
        } else {
            return this.context.translate("Закроется через")+" "+getTime(diff, this.context.translate);
        }
    }

    render(){

        let {
            CityName,
            Phone,
            Fax,
            Address,
            Email,
            WorkDayStartTime,
            WorkDayEndTime,
            children,

            changeOffice
        } = this.props;

        return <div className="page__contacts-wrap page__contacts-wrap_agency">
            <div className="agency agency_adapt-default">
                <div className="agency__about">
                    <p className="agency__city">{this.context.translate('Представительство в городе')} {CityName}</p>
                    <a className="agency__select u-dotted-link2" href="#" onClick={changeOffice}>{this.context.translate('Выбрать другой офис')}</a>
                    <div className="agency__data">
                        <p className="agency__info agency__info_phones">
                            <span className="agency__info-phone">{phoneMask(Phone, 'def')}</span>
                            {Fax ? <span className="agency__info-phones-comma">,</span> : null}
                            {Fax ? <span className="agency__info-phone">{phoneMask(Fax, 'def')} ({this.context.translate('факс')})</span> : null}
                        </p>
                        <p className="agency__info agency__info_address">{Address}</p>
                        <p className="agency__info agency__info_email">
                            <a href={"mailto:"+Email}>{Email}</a>
                        </p>
                        <p className="agency__info agency__info_worktime">
                            {this.renderTimeString(WorkDayStartTime)} - {this.renderTimeString(WorkDayEndTime)}
                            <span className="agency__info-worktime-left">({this.renderRelativeTimeString(WorkDayStartTime, WorkDayEndTime)})</span>
                        </p>
                    </div>
                </div>
                {children}
            </div>
        </div>
    }
}