import {
    call,
    put,
    select,
    take
} from 'redux-saga/effects';

import {
    fetchApi,
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';
import {isSupportUser} from "../tools/tools";

import {authPageSettings, splashImages, splashMessage} from "./catalog";

const auth = state => state.auth;

export function backToRequest() {
    return {
        type: 'TO_CODE_REQUEST'
    }
}

export function codeRequest(phone, password) {
    return {
        type: 'CODE_REQUEST',
        phone,
        password
    }
}

export function* fetchCodeRequest(action) {
    try {
        var body = new FormData();
        if (action.phone) body.append('phone_number', '+'+action.phone.replace(/\D/gi, ''));
        body.append('password', action.password);

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/auth?action=code-send", {
                method: 'POST',
                body
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status === 200) {
                // console.log("Your code: "+data.code);
                //add phone and password properties to data for code request repeating
                yield put(checkErrors(action.type, {...data, ...action.data}, status));
                yield put(splashImages());
            } else {
                if (status === 423) {
                    localStorage.setItem('blockedPhone', action.phone);
                }
                yield put(checkErrors(action.type, data, status));
            }
        }

    } catch(e) {
        alert(e);
    }

}

export function checkLocked() {
    return {
        type: 'CHECK_LOCKED'
    }
}

export function* fetchCheckLocked(action) {
    try {
        let phone = localStorage.getItem('blockedPhone');
        if (phone) {
            var body = new FormData();
            body.append('mobile_phone', '+'+phone.replace(/\D/gi, ''));

            const { status, data, error } = yield call( () => {
                return fetchClientApi("/check-locked", {
                    method: 'POST',
                    body
                })
            });

            if (status === 423 || status === 500) {
                yield put(checkErrors(action.type, {message: error}, status));
            } else {
                localStorage.removeItem('blockedPhone');
            }
        }
    } catch(e) {
        yield put(checkErrors(action.type, {message: 'Возникла ошибка'}, 500));
    } finally {
        yield put({type: "CHECK_LOCKED_COMPLETE"})
    }

}

export function codeValidate(phone, code) {
    return {
        type: 'CODE_VALIDATE',
        phone,
        code
    }
}

export function* fetchCodeValidate(action) {
    try {

        var body = new FormData();
        if (action.phone) body.append('phone_number', '+'+action.phone.replace(/\D/gi, ''));
        body.append('code', action.code);

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/auth?action=code-validate", {
                method: 'POST',
                body
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status === 200) {
                localStorage.setItem('authToken', data.access_token);
                localStorage.setItem('authTokenExpire', data.access_token_expire);
                localStorage.setItem('refreshToken', data.refresh_token);
                yield put(splashMessage());
            }
            if (status === 423) {
                localStorage.setItem('blockedPhone', action.phone);
            }
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function refreshToken() {
    return {
        type: 'REFRESH_TOKEN'
    }
}

export function* fetchRefreshToken(action) {
    try {
        if (localStorage.getItem('refreshToken')) {
            var body = new FormData();
            body.append('refresh_token', localStorage.getItem('refreshToken'));

            const {status, data, error} = yield call(() => {
                return fetchClientApi("/auth?action=refresh-token", {
                    method: 'POST',
                    body
                })
            });

            if (error) yield put(checkErrors(action.type, {message: error}, 403));
            else {
                if (status === 200) {
                    localStorage.setItem('authToken', data.access_token);
                    localStorage.setItem('authTokenExpire', data.access_token_expire);
                    localStorage.setItem('refreshToken', data.refresh_token);

                    yield put(checkErrors(action.type, data, status));
                } else {
                    yield put(checkErrors(action.type, data, 401));
                }
            }
        }

    } catch(e) {
        alert(e);
    }

}

export function checkToken(actionType) {
    return {
        type: 'CHECK_TOKEN',
        actionType
    }
}

export function* fetchCheckToken(action) {

    var authState = yield select(auth);

    if (!localStorage.getItem('authTokenExpire')) {
        yield put({type: action.actionType+'_TOKEN_OK', logout: true});
    }

    if (localStorage.getItem('authTokenExpire') <= Math.round(Date.now()/1000)) {

        if (authState.isRefreshing <= 1) {
            yield put(refreshToken());
        }

        var act = yield take(['REFRESH_TOKEN_SUCCESS', 'LOGOUT']);
        yield put({type: action.actionType+'_TOKEN_OK', logout: act.type == 'LOGOUT'});

    } else {
        yield put({type: action.actionType+'_TOKEN_OK'});
    }

}

export function logout() {
    return {
        type: 'LOGOUT'
    }
}

export function* clearToken(action) {
    try {

        yield put(checkToken(action.type));
        var act = yield take(action.type+'_TOKEN_OK');

        if (act.logout){

            localStorage.removeItem("authToken");
            localStorage.removeItem("authTokenExpire");
            localStorage.removeItem("refreshToken");
            if (!isSupportUser){
                yield put({ type: action.type + '_SUCCESS'});
                yield put(authPageSettings());
            } else {
                window.top.location.href = "/users/index";
            }
        } else {
            const {status, data, error} = yield call(() => {
                return fetchClientApi("/logout", {
                    method: 'POST',
                    token: localStorage.getItem('authToken')
                })
            });

            if (error) yield put(checkErrors(action.type, {message: error}, 403));

            else {
                localStorage.removeItem("authToken");
                localStorage.removeItem("authTokenExpire");
                localStorage.removeItem("refreshToken");
                if (!isSupportUser) {
                    yield put({type: action.type + '_SUCCESS'});
                    yield put(authPageSettings());
                } else {
                    window.top.location.href = "/users/index";
                }

            }
        }

    } catch(e) {
        alert(e);
    }
}

export function activation(action, password = null) {
    return {
        type: 'ACTIVATION_'+action.toUpperCase(),
        action,
        password
    }
}


export function* fetchActivation(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        if (action.action == 'change-password') {
            var body = new FormData();
            body.append('password', action.password);
        }


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/activation?action="+action.action, {
                method: 'POST',
                body,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function recovery(action, data) {
    return {
        type: 'RECOVERY_'+action.toUpperCase(),
        action,
        data
    }
}

export function* fetchRecovery(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/recovery?action="+action.action, {
                method: 'POST',
                body: action.data
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}