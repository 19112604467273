import React, {Component} from "react";
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';

import balloon from "../../../img/balloon.svg";

export class OfficeMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            coords: null
        }
    }

    componentDidMount() {
        const geocoder = new this.props.google.maps.Geocoder();
        geocoder.geocode({ address: this.props.address }, (results, status) => {
            if (status === "OK" && results.length) {
                this.setState({coords: {lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()}})
            }
        });
    }

    render() {

        if (!this.state.coords) {
            return null;
        }

        return (
            <Map google={this.props.google}
                 zoom={16}
                 initialCenter={this.state.coords}
                 mapTypeControl={false}
                 zoomControl={false}
                 fullscreenControl={false}
                 streetViewControl={false}
            >

                <Marker position={this.state.coords}
                        icon={{
                            url: balloon
                        }} />
            </Map>
        );
    }
}

export default GoogleApiWrapper(
    (props) => ({
        apiKey: props.apiKey,
        language: props.language
    }
))(OfficeMap)