import {
    call,
    put,
    select,
    take
} from 'redux-saga/effects';

import {
    fetchApi,
    fetchFileApi,
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import fileDownload from "js-file-download";
import {clientApiUrl} from "../tools/api-helper";
import {checkToken} from "./auth";
import {isSupportUser} from "../tools/tools";

const company = state => state.company;

/**
 *
 * @returns {{type: string}}
 */
export function applicationsList(type, params = null) {
    return {
        applicationsType: type,
        type: 'APPLICATION_LIST_'+(params ? 'SEARCH_' : '')+type.toUpperCase(),
        params
    }
}

/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* fetchApplicationsList(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, pagination, error } = yield call( () => {
            return fetchClientApi("/applications/"+action.applicationsType+(action.params ? '?'+encodeURI(action.params) : ''), {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (Object.keys(pagination).length) data.pagination = pagination;
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function archiveSearchInfo() {
    return {
        type: 'ARCHIVE_SEARCH_INFO_APPLICATION',
    }
}


/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* fetchArchiveSearchInfo(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/applications/archive-info", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function pdfBlank(id) {
    return {
        type: 'APPLICATION_PDF_BLANK',
        id
    }
}

export function* fetchPdfBlank(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchFileApi(clientApiUrl+"/applications/"+action.id+"?action=blank", localStorage.getItem('authToken'))
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status === 200) {

                var url = URL.createObjectURL(data);
                yield put(checkErrors(action.type, {url}, status));
                if (!isSupportUser) location.href = url;

            } else {
                yield put(checkErrors(action.type, data, status));
            }
        }

    } catch(e) {
        alert(e);
    }
}

export function detailApplication(id) {
    return {
        type: 'APPLICATION_INFO',
        id
    }
}

export function* fetchDetailApplication(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/applications/"+action.id+"?action=detail", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function applicationDocuments(id) {
    return {
        type: 'APPLICATION_DOCUMENTS',
        id
    }
}

export function* fetchApplicationDocuments(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/applications/"+action.id+"/documents", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, {id: action.id, docs: data}, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function downloadCommercialOffer(id, offerId) {
    return {
        type: 'DOWNLOAD_COMMERCIAL_OFFER',
        id,
        offerId
    }
}


export function* fetchDownloadCommercialOffer(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchFileApi(clientApiUrl+"/applications/"+action.id+"/commercial-offer/"+action.offerId, localStorage.getItem('authToken'))
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            fileDownload(data, "commercial-offer-"+action.id+"-"+action.offerId+".pdf");
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function downloadFile(fileId, fileName) {
    return {
        type: 'DOWNLOAD_APPLICATION_FILE',
        fileId,
        fileName
    }
}


export function* fetchDownloadFile(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchFileApi(clientApiUrl+"/applications/file/"+action.fileId, localStorage.getItem('authToken'))
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status == 200) {
                fileDownload(data, action.fileName);
            }
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function recallApplication(id, reason) {
    return {
        type: 'RECALL_APPLICATION',
        id,
        reason
    }
}

export function* fetchRecallApplication(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/applications/"+action.id+"?action=recall", {
                method: 'PUT',
                body: 'RecallReason='+action.reason,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, {ApplicationId: action.id}, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function requestApplication(id, description) {
    return {
        type: 'REQUEST_APPLICATION',
        id,
        description
    }
}

export function* fetchRequestApplication(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/applications/"+action.id+"?action=change-request", {
                method: 'PUT',
                body: 'Description='+action.description,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function resumeApplication(id, description) {
    return {
        type: 'RESUME_APPLICATION',
        id,
        description
    }
}

export function* fetchResumeApplication(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/applications/"+action.id+"?action=resume", {
                method: 'PUT',
                body: 'Description='+action.description,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, {ApplicationId: action.id}, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function getDocumentTypes() {
    return {
        type: 'APPLICATION_DOCUMENT_TYPES'
    }
}

export function* fetchDocumentTypes(action) {
    try {
        if (!(yield select((state) => state.applications.documentTypes)).length) {
            yield put(checkToken(action.type));
            yield take(action.type + '_TOKEN_OK');

            const {status, data, error} = yield call(() => {
                return fetchClientApi("/application-document-types", {token: localStorage.getItem('authToken')})
            });

            if (error) yield put(checkErrors(action.type, {message: error}, 403));
            else {
                yield put(checkErrors(action.type, data, status));
            }
        }

    } catch(e) {
        alert(e);
    }
}