import {
    call,
    put,
    take,
    select
} from 'redux-saga/effects';

const usersListState = state => state.usersList;

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import {checkToken} from "./auth";

export function usersList() {
    return {
        type: 'USERS_LIST'
    }
}

export function* fetchUsersList(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/users", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function checkPhone(phone) {
    return {
        type: "CHECK_USER_PHONE",
        phone
    }
}

export function* fetchCheckPhone(action) {
    try {
        if (action.phone.length == 12) {
            yield put(checkToken(action.type));
            yield take(action.type + '_TOKEN_OK');

            const {status, data, error} = yield call(() => {
                return fetchClientApi("/users/phone-info?phone=" + encodeURIComponent(action.phone), {
                    method: 'GET',
                    token: localStorage.getItem('authToken')
                })
            });

            if (data.userId) {
                if (data.isMine) {
                    yield put(viewUser(data.userId));
                    var users = yield select(usersListState);
                    var user = users.list.filter((item) => {return data.userId == item.LoginId});
                    if (user.length) yield put({type: "USER_PROFILE_SUCCESS", data: user[0]});
                } else yield put(subscriptions())
            }

            if (error) yield put(checkErrors(action.type, {message: error}, 403));
            else {
                yield put(checkErrors(action.type, data, status));
            }
        }

    } catch(e) {
        alert(e);
    }

}



export function createUser(body) {
    return {
        type: "USER_CREATE",
        body
    }
}

export function* fetchCreateUser(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/users", {
                method: 'POST',
                body: action.body,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
            if (status == 201) {
                yield put(usersList())
            }
        }

    } catch(e) {
        alert(e);
    }

}

export function updateUser(id, body) {
    return {
        type: "USER_UPDATE",
        id,
        body
    }
}

export function* fetchUpdateUser(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/users/"+action.id, {
                method: 'PUT',
                body: action.body,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
            if (status == 200) {
                yield put(usersList())
            }
        }

    } catch(e) {
        alert(e);
    }

}

export function viewUser(id) {
    return {
        type: "USER_VIEW",
        id
    }
}

export function* fetchViewUser(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/users/"+action.id, {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function deleteUser(id) {
    return {
        type: "USER_DELETE",
        id
    }
}

export function* fetchDeleteUser(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/users/"+action.id, {
                method: 'DELETE',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
            if (status == 200) {
                yield put(usersList())
            }
        }

    } catch(e) {
        alert(e);
    }

}

export function clearForm() {
    return {
        type: "CLEAR_FORM"
    }
}

export function subscriptions() {
    return {
        type: "SUBSCRIPTIONS"
    }
}

export function* fetchSubscriptions(action) {
    try {
        yield put(checkToken(action.type));
        yield take(action.type + '_TOKEN_OK');

        const {status, data, error} = yield call(() => {
            return fetchClientApi("/users/subscriptions", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}
