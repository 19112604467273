import React, { Component } from 'react';

import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Route, Redirect, withRouter, Switch} from 'react-router-dom';

import {getScreenSize, isSupportUser} from "../tools/tools";

import {logout} from "../actions/auth";
import {clearError, footerSettings, layoutInfo, saveCompanies, splashImages, splashMessage} from "../actions/catalog";
import {notificationsList, readNotification} from "../actions/notifications";
import {communicationsWidget, setRating, communicationsFormInfo, communicationsDecline} from "../actions/communications";
import {downloadHelpfulDocument} from "../actions/documents";
import {clearDownloading} from "../actions/catalog";
import {setLanguage, getLanguages, getDictionary} from "../actions/multilingual";
import {detailContact} from "../actions/contacts";

import OrdersContainer from "./orders/OrdersContainer";
import AddOrderContainer from "./orders/AddOrderContainer";
import ProjectsContainer from "./projects/ProjectsContainer";
import DetailOrderContainer from "./orders/DetailOrderContainer";
import PdfBlankContainer from "./orders/PdfBlankContainer";
import DealsContainer from "./deals/DealsContainer";
import DocumentsContainer from "./documents/DocumentsContainer";
import PromotionsContainer from "./promotions/PromotionsContainer";
import LibraryContainer from "./library/LibraryContainer";
import HelpContainer from "./help/HelpContainer";
import DetailProjectContainer from "./projects/DetailProjectContainer";
import DetailDocumentContainer from "./projects/DetailDocumentContainer";
import ContactsContainer from "./contacts/ContactsContainer";
import ActivationContainer from "./auth/ActivationContainer";
import ProfileContainer from "./profile/ProfileContainer";
import ErrorContainer from "./home/ErrorContainer";
import CompaniesContainer from "./companies/CompaniesContainer";
import CompanyChangeRequestContainer from "./companies/CompanyChangeRequestContainer";
import UsersContainer from "./users/UsersContainer";
import CommunicationDraftContainer from "./communications/CommunicationDraftContainer";
import CommunicationDetailContainer from "./communications/CommunicationDetailContainer";
import CommunicationsContainer from "./communications/CommunicationsContainer";
import NotificationsContainer from "./notifications/NotificationsContainer";
import HomeContainer from "./home/HomeContainer";
import InsuranceContainer from "./insurance/InsuranceContainer";

import Layout from "../components/common/layout/Layout";
import Preloader from "../components/common/Preloader";

import "../../styles/style.css";

class Router extends Component {

    constructor(props){
        super(props);
        this.state = {};
    }

    componentDidMount = () => {
        this.setState({screenSize: getScreenSize()});
        window.addEventListener('resize', this.onResize);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    };

    onResize = () => {
        this.setState({screenSize: getScreenSize()});
    };

    static childContextTypes = {
        screenSize: PropTypes.string,
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    getChildContext() {
        return {
            screenSize: this.state.screenSize,
            currentLanguage: this.props.multilingual.currentLanguage,
            translate: (message, attributes = {}) => {
                var message = this.props.multilingual.dictionary[message] || message;
                for (var key in attributes) {
                    message = message.replace("{"+key+"}", attributes[key]);
                }
                return message;
            }
        };
    }

    render() {
        let {
            isGuest,
            isReady,
            isLayoutOk,
            errorMessage,
            user,
            pageLoaded,
            saveCompanies
        } = this.props;

        if (errorMessage && location.pathname != "/error") return <Redirect to={"/error"}/>;

        return (
            <div className="wrapper">
                <Switch>
                    <Route exact path="/applications/pdf/:id" component={PdfBlankContainer}/>
                    <Route exact path="/error" component={ErrorContainer}/>
                    <Layout {...this.props} saveCompanies={saveCompanies(this.props.history.location.pathname)}>
                    {isLayoutOk ?
                    (isReady ? <Switch>
                        <Route exact path="/" component={HomeContainer}/>

                        <Route path="/applications/update/:id" component={AddOrderContainer}/>
                        <Route path="/applications/view/:id" component={DetailOrderContainer}/>
                        <Route path="/applications/create" component={AddOrderContainer}/>
                        <Route path="/applications" component={OrdersContainer}/>

                        <Route path="/projects/active" component={ProjectsContainer}/>
                        <Route path="/projects/archive" component={ProjectsContainer}/>
                        <Route path="/projects/dfa/:id" component={DetailDocumentContainer}/>
                        <Route path="/projects/:id" component={DetailProjectContainer}/>
                        <Route path="/projects" component={ProjectsContainer}/>

                        <Route path="/documents" component={DocumentsContainer}/>

                        <Route path="/deals" component={DealsContainer}/>

                        <Route path="/promotions" component={PromotionsContainer}/>
                        <Route path="/library" component={LibraryContainer}/>
                        <Route path="/help" component={HelpContainer}/>
                        <Route path="/contacts" component={ContactsContainer}/>

                        <Route path="/profile" component={ProfileContainer}/>

                        <Route path="/companies/:id" component={CompanyChangeRequestContainer}/>
                        <Route path="/companies" component={CompaniesContainer}/>

                        <Route path="/communications/create" component={CommunicationDraftContainer}/>
                        <Route path="/communications/:id" component={CommunicationDetailContainer}/>
                        <Route path="/communications" component={CommunicationsContainer}/>

                        <Route path="/notifications" component={NotificationsContainer}/>

                        <Route path="/insurance" component={InsuranceContainer}/>

                        {user && user.is_company_admin ? <Route path="/users" component={UsersContainer}/> : null}

                        <Route path="*" render={() => {return <Redirect to={location.pathname == '/login' || isSupportUser ? "/" : "/error"}/>}}/>
                    </Switch> : <ActivationContainer/>)
                    : <div className="page"><div className="page__main"/></div>}
                </Layout>
            </Switch>
            {pageLoaded || (!isReady && isLayoutOk) || isGuest ? null : <Preloader />}
        </div>)
    }
}

export default withRouter(connect(
    state => ({
        token: state.auth && state.auth.token,
        isGuest: state.auth && state.auth.isGuest,
        allowSplash: state.auth && state.auth.allowSplash,
        isReady: state.auth && !state.auth.is_temporary && state.auth.is_active && state.auth.is_email_confirmed && state.auth.is_phone_confirmed && state.catalog.companies && !state.catalog.companies.filter((item) => {return !item.IsConfirmed}).length,
        user: state.catalog && state.catalog.user,
        info: state.catalog && state.catalog.info,
        companies: state.catalog && state.catalog.companies,
        splashImages: state.catalog && state.catalog.splashImages,
        isLayoutOk: state.catalog && state.catalog.isLayoutOk,
        footerLinks: state.catalog && state.catalog.footerSettings,
        errorMessage: state.catalog && state.catalog.errorMessage,
        downloading: state.catalog.downloading,
        pageLoaded: state.catalog && state.catalog.pageLoaded,
        message: state.catalog.splashMessage,
        multilingual: state.multilingual,
        currentContact: state.detailContact
    }),
    (dispatch) => {
        return {
            logout: () => dispatch(logout()),
            getLayoutInfo: () => dispatch(layoutInfo()),
            getSplashImages: () => dispatch(splashImages()),
            saveCompanies: (pathname) => (arr) => dispatch(saveCompanies(arr, pathname)),
            footerSettings: () => dispatch(footerSettings()),
            clearError: () => dispatch(clearError()),
            clearDownloading: () => dispatch(clearDownloading()),
            getCommunicationsFormInfo: () => dispatch(communicationsFormInfo()),
            getSplashMessage: () => dispatch(splashMessage()),
            setLanguage: (lang) => dispatch(setLanguage(lang)),
            getDictionary: () => dispatch(getDictionary()),
            getLanguages: () => dispatch(getLanguages()),
            detailContact: (id) => dispatch(detailContact(id))
        }
    }
)(Router));