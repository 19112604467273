import {parseErrors} from "../tools/api-helper";

const initState = {};
const initFormState = {isPhoneChecked: false};

import {phoneMask} from "../tools/mask";


export function usersList(state = initState, action) {

    switch (action.type) {
        case "USERS_LIST":
            return {list: []};
        case "USERS_LIST_SUCCESS":
            return {list: action.data};
        case "LOGOUT_SUCCESS":
        case "BLOCKED":
            return {...initState};
    }

    return state;
}

export function usersForm(state = initFormState, action) {
    switch (action.type) {
        case "CHECK_USER_PHONE":
            return {isPhoneChecked: false, formErrors: {}};
        case "CHECK_USER_PHONE_SUCCESS":
            return {...state, ...action.data, isPhoneChecked: true};
        case "USER_VIEW_SUCCESS":
            var data = action.data;
            return {...state, isNew: false, isMine: true, isPhoneChecked: true, ...data};
        case "USER_PROFILE_SUCCESS":
            var data = action.data;
            data.Phone = phoneMask(data.Phone);
            return {...state, profile: data};
        case "USER_CREATE":
        case "USER_UPDATE":
            return {...state, isRequest: true, formErrors: {}};
        case "USER_CREATE_CREATED":
        case "USER_UPDATE_SUCCESS":
            return {...state, isRequest: false};
        case "USER_CREATE_VALIDATION_ERROR":
        case "USER_UPDATE_VALIDATION_ERROR":
            var errors = parseErrors(action.data);
            return {...state, isRequest: false, formErrors: errors};
        case "USER_CREATE_BAD_REQUEST":
        case "USER_UPDATE_BAD_REQUEST":
            return {...state, isRequest: false, message: action.data.message};
        case "CLEAR_FORM":
            return {...initFormState}

    }
    return state;
}