import React,{Component} from "react";
import PropTypes from "prop-types";

import {Link} from "react-router-dom";
import ProjectDealsList from "../deals/ProjectDealsList";
import EquipmentsList from "./EquipmentsList";
import DocumentSchedule from "./DocumentSchedule";


export default class DocumentDetail extends Component{

    constructor(props){
        super(props);
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render(){
        let {
            ContractId,
            ContractNumber,
            Title,
            StatusName,
            StatusDate,
            Deals,
            Equipments,
            ScheduleDate,
            Schedule,
            TotalCost,
            TotalCostVat,

            downloadContractSchedule,
            toPage,
            downloadInvoice,
            paymentNotification,
            location
        } = this.props;

        return  <div className="page__content-container">
            <div className="page__back-link">
                <Link className="u-back-link" to={{pathname: "/projects", state: location.state}}><span>{this.context.translate('Проекты')}</span></Link>
            </div>
            <h1 className="page__title">{Title}</h1>
            <div className="page__title-subsection">
                <div className="u-notice u-notice_ok"><span>{StatusName} <span className="u-notice__remark">({StatusDate})</span></span></div>
            </div>
            <div className="page__head-ui">
                <div className="page__head-ui-item">
                    <Link className="u-btn u-btn_adapt" to={{pathname: "/documents", state: {EntityNumber: ContractNumber}}}>{this.context.translate('Перейти к документам договора')}</Link>
                </div>
            </div>
            <ProjectDealsList items={Deals} paymentNotification={paymentNotification} downloadInvoice={downloadInvoice}/>
            <ParamsList key="params-list" {...this.props} translate={this.context.translate}/>
            {Schedule ? <DocumentSchedule key="schedule"
                  ScheduleDate={ScheduleDate}
                  Schedule={Schedule}
                  downloadContractSchedule={downloadContractSchedule}
                  Price={TotalCost}
                  PriceVat={TotalCostVat}
            /> : null}
            {Equipments && Equipments.length ? <EquipmentsList key="equipments" items={Equipments} toPage={toPage}  translate={this.context.translate}/> : null}
        </div>
    }

}

function ParamsList({
    LesseeName,
    AssetHolderName,
    EquipmentTotalCost,
    LeasePaymentDay,
    AdvancePercent,
    Term,
    ContractCurrencySymbol,
    translate
}) {

    var data = [
        {
            label: "Лизингополучатель",
            value: LesseeName
        },
        {
            label: "Балансодержатель",
            value: AssetHolderName
        },
        {
            label: "Стоимость предметов лизинга",
            value: EquipmentTotalCost
        },
        {
            label: "Срок",
            value: Term
        },
        {
            label: "Аванс",
            value: AdvancePercent
        },
        {
            label: "Валюта",
            value: ContractCurrencySymbol
        },
        {
            label: "День платежа",
            value: LeasePaymentDay
        },
    ];

    return <div className="page__params-list">
        <div className="params-list params-list_adapt-default">
            <div className="params-list__items">
                {data.map((item, key) => <div className="params-list__list-item" key={key}>
                    <span className="params-list__term">{translate(item.label)}:</span>
                    <span className="params-list__defenition">{item.value}</span>
                </div>)}
            </div>
        </div>
    </div>
}