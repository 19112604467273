import React from "react";
import Pagination from "../common/Pagination";
import {floatMask} from "../../tools/mask";
import PropTypes from "prop-types";

export default function EquipmentsList({items, toPage, translate}) {
    return <div>
        <div className="page__sub-title">{translate('Предметы лизинга')}</div>
        <div className="page__dfa-table">
            <div className="default-table default-table_dfa-detail js-demo">
                <div className="default-table__head">
                    <div className="default-table__row">
                        <div className="default-table__th default-table__td_tablet-hide">{translate('Поставщик')}</div>
                        <div className="default-table__th">{translate('Номер договора')}</div>
                        <div className="default-table__th">{translate('Название')}</div>
                        <div className="default-table__th">{translate('Бренд')}</div>
                        <div className="default-table__th">{translate('Тип')}</div>
                        <div className="default-table__th default-table__th_align_right"><nobr>{translate('Кол-во')}</nobr></div>
                        <div className="default-table__th default-table__th_align_right">{translate('Стоимость')}</div>
                    </div>
                </div>
                <div className="default-table__body">
                    {items.map((item, key) => <EquipmentItem item={item} key={key}/>)}
                </div>
            </div>
        </div>
        <Pagination key="pagination" {...items.pagination} toPage={toPage}/>
    </div>
}

class EquipmentItem extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            opened: false
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    toggleMore = () => {
        this.setState((prevState) => {
            return {
                opened: !prevState.opened
            }
        })
    }

    render(){
        let item = this.props.item;
        let opened = this.state.opened;

        return <div className="default-table__row">
            <div className={"default-table__td default-table__td_tablet-hide"+(opened ? "" : " default-table__td_more-item")}>
                <div className="default-table__inner-label">{this.context.translate('Поставщик')}</div>
                <div className="default-table__value">{item.SupplierName}</div>
            </div>
            <div className={"default-table__td"+(opened ? "" : " default-table__td_more-item")}>
                <div className="default-table__inner-label">{this.context.translate('Номер договора')}</div>
                <div className="default-table__value">{item.AgreementNumber}</div>
            </div>
            <div className="default-table__td">
                <div className="default-table__inner-label">{this.context.translate('Название')}</div>
                <div className="default-table__value">{item.EquipmentName}</div>
            </div>
            <div className="default-table__td">
                <div className="default-table__inner-label">{this.context.translate('Бренд')}</div>
                <div className="default-table__value">{item.BrandName}</div>
            </div>
            <div className={"default-table__td"+(opened ? "" : " default-table__td_more-item")}>
                <div className="default-table__inner-label">{this.context.translate('Тип')}</div>
                <div className="default-table__value">{item.CategoryName}</div>
            </div>
            <div className={"default-table__td default-table__td_align_right"+(opened ? "" : " default-table__td_more-item")}>
                <div className="default-table__inner-label">{this.context.translate('Кол-во')}</div>
                <div className="default-table__value">{item.Quantity}</div>
            </div>
            <div className="default-table__td default-table__td_align_right">
                <div className="default-table__inner-label">{this.context.translate('Стоимость')}</div>
                <div className="default-table__value"><nobr>{floatMask(item.Price)+" "+item.PriceCurrencySymbol}</nobr></div>
            </div>
            <div className="default-table__more-toggle">
                <span className={"u-more-link"+(opened ? " u-more-link_open" : "")} onClick={this.toggleMore}>
                    <span>{opened ? this.context.translate("Скрыть") : this.context.translate("Подробнее")}</span>
                </span>
            </div>
        </div>
    }
}