import React, {Component} from "react";
import {connect} from "react-redux";
import UsersList from "../../components/users/UsersList";
import {checkPhone, clearForm, createUser, deleteUser, updateUser, viewUser} from "../../actions/users";
import PropTypes from "prop-types";

class UsersContainer extends Component{

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render(){

        let {
            usersList,
            notificationSettings
        } = this.props;

        return <div className="page page_projects page_users">
            <div className="page__main page__main_projects">
                <div className="page__content-container">
                    <h1 className="page__title">{this.context.translate('Пользователи')}</h1>
                    <div className="page__users">
                        {usersList && notificationSettings ? <UsersList {...this.props}/> : null}
                    </div>
                </div>
            </div>
        </div>
    }
}

export default connect(
    state => ({
        usersList: state.usersList.list,
        notificationSettings: state.catalog.notificationSettings,
        usersForm: state.usersForm
    }),
    (dispatch) => {
        return {
            checkPhone: (phone) => dispatch(checkPhone(phone)),
            createUser: (body) => dispatch(createUser(body)),
            updateUser: (id, body) => dispatch(updateUser(id, body)),
            viewUser: (id) => dispatch(viewUser(id)),
            deleteUser: (id) => dispatch(deleteUser(id)),
            clearForm: () => dispatch(clearForm())
        }
    }
)(UsersContainer)
