import React, {Component} from "react";

export default class NumberRange extends Component{

    onNumFrom = (e) => {
        let {
            attribute,
            value,
            onChange,
            mask
        } = this.props;

        onChange && onChange(attribute, {
            from: mask ? mask(e.target.value) : e.target.value,
            to: value.to
        });
    };

    onNumTo = (e) => {
        let {
            attribute,
            value,
            onChange,
            mask
        } = this.props;

        onChange && onChange(attribute, {
            from: value.from,
            to: mask ? mask(e.target.value) : e.target.value
        });
    };

    render(){

        return <div className="text-range__inputs-holder">
            <div className="text-range__value-item">
                <input type="text"
                     value={this.props.value && this.props.value.from || ""}
                     onChange={this.onNumFrom}
                />
            </div>
            <div className="text-range__separate"/>
            <div className="text-range__value-item">
                <input type="text"
                       value={this.props.value && this.props.value.to || ""}
                       onChange={this.onNumTo}
                />
            </div>
        </div>
    }
}