import React,{Component} from "react";

export default class Collapser extends Component{

    constructor(props){
        super(props);

        this.state = {
            collapsed: false
        };

        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        if (this.props.typeCollapsed === 'notification') {
            this.setState({collapsed: true})
        }
    }

    toggle(){

        this.setState((prevState) => {
            return {
                collapsed: !prevState.collapsed
            }
        });

    };

    render(){

        let {
            label,
            children,
            className = "",
            labelClassName = "",
            labelActiveClassName = "",
            contentClassName = "",
            contentActiveClassName = "u-collapser_open"
        } = this.props;

        let collapsed = this.state.collapsed;

        return <div className={className}>
            <p className={"js-collapser "+labelClassName+(collapsed ? " "+labelActiveClassName : "")} onClick={this.toggle}>{label}</p>
            <div className={"u-collapser "+contentClassName+(collapsed ? " "+contentActiveClassName : "")}>
                {children}
            </div>
        </div>
    }

}