const initState = {isOk: false};

export function detailProject(state = initState, action) {
    switch (action.type) {
        case 'PROJECT_VIEW':
            return {data: {}, isOk: false};
        case 'PROJECT_VIEW_SUCCESS':
            return {data: {...state.data, ...action.data}, isOk: true};
        case 'PROJECT_DOCUMENTS_SUCCESS':
            return {data: {...state.data, Documents: action.data}, isOk: true};

        case 'PROJECT_LIST_SUCCESS':

            if (!(state.data && state.data.ProjectId)) return state;

            let projects = [...action.data.projects.active, ...action.data.projects.archive];
            for (var i = 0; i < projects.length; i++) {
                if (state.data.ProjectId == projects[i].ProjectId) {
                    return state;
                }
            }
            return {...state, data: {}};
        case 'LOGOUT_SUCCESS':
            return {...initState};
    }
    return state;
}