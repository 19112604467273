import {
    call,
    put,
    take
} from 'redux-saga/effects';

import {
    fetchClientApi,
    fetchFileApi,
    checkErrors, clientApiUrl
} from '../tools/api-helper';

import {checkToken} from "./auth";
import fileDownload from "js-file-download";

export function documentsList(params = null, sort = null) {
    return {
        type: params || sort ? 'DOCUMENTS_LIST_SEARCH' : 'DOCUMENTS_LIST',
        params: `${params ? params+'&' : ''}sort=${sort === 'ASC' ? '' : '-'}UploadStamp`
    }
}

export function* fetchDocumentsList(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, pagination, error } = yield call( () => {
            return fetchClientApi("/documents"+(action.params ? "?"+encodeURI(action.params) : ""), {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (Object.keys(pagination).length) data.pagination = pagination;
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}
export function documentsSearchInfo() {
    return {
        type: 'DOCUMENTS_SEARCH_INFO'
    }
}

export function* fetchDocumentsSearchInfo(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/documents/search-info", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}
export function documentsWidgetInfo() {
    return {
        type: 'DOCUMENTS_WIDGET_INFO'
    }
}

export function* fetchDocumentsWidgetInfo(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/documents/widget-info", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function addDocumentsRequest(body) {
    return {
        type: 'DOCUMENTS_REQUEST',
        body
    }
}

export function* fetchAddDocumentsRequest(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/documents", {
                method: 'POST',
                body: action.body,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}



export function downloadDocument(id, title) {
    return {
        type: 'DOWNLOAD_DOCUMENT',
        id,
        title
    }
}

export function* fetchDownloadDocument(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchFileApi(clientApiUrl+"/documents/file/"+action.id, localStorage.getItem('authToken'))
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status == 200) {
                fileDownload(data, action.title);
            } else {
                yield put(checkErrors(action.type, data, status))
            }
        }

    } catch(e) {
        alert(e);
    }
}

export function downloadHelpfulDocument(id) {
    return {
        type: 'DOWNLOAD_HELPFUL_DOCUMENT',
        id
    }
}

export function* fetchDownloadHelpfulDocument(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, headers, error } = yield call( () => {
            return fetchFileApi(clientApiUrl+"/helpful/"+action.id, localStorage.getItem('authToken'))
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status === 200) {
                var filename = headers.get("Content-Disposition").match(/filename="(.*)"/ig)[0];
                filename = filename.substr(10, filename.length - 11);

                fileDownload(data, filename);
            }
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function downloadReconciliationStatement(params) {
    return {
        type: 'DOWNLOAD_RECONCILIATION_STATEMENT',
        params
    }
}

export function* fetchDownloadReconciliationStatement(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, headers, error } = yield call( () => {
            return fetchFileApi(clientApiUrl+"/documents/reconciliation-statement?"+action.params, localStorage.getItem('authToken'))
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status == 200) {
                var filename = headers.get("Content-Disposition").match(/filename="(.*)"/ig)[0];
                filename = filename.substr(10, filename.length - 11);

                fileDownload(data, filename);
            }
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}