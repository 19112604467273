import React, {Component} from "react";
import {connect} from "react-redux";
import {notificationsList, readNotification} from "../../actions/notifications";
import {downloadHelpfulDocument} from "../../actions/documents";
import NotificationsList from "../../components/notifications/NotificationsList";
import Preloader from "../../components/common/Preloader";
import PropTypes from "prop-types";

class NotificationsContainer extends Component{

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentDidMount(){
        this.props.markAsRead();
    }

    render(){
        var {
            isOk,
            list
        } = this.props.notifications;

        return <div className="page">
            <div className="page__main">
                <h1 className="page__title">{this.context.translate('Уведомления')}</h1>
                {isOk ? <NotificationsList list={list} download={this.props.downloadHelpfulDoc}/> : null}
            </div>
        </div>
    }
}

export default connect(
    state => ({
        notifications: state.notifications
    }),
    (dispatch) => {
        return {
            getNotificationsList: () => dispatch(notificationsList()),
            markAsRead: () => dispatch(readNotification()),
            downloadHelpfulDoc: (id) => dispatch(downloadHelpfulDocument(id))
        }
    }
)(NotificationsContainer)
