import React, {Component} from "react";
import {numberMask} from "../../tools/mask";
import Form from "../common/form/Form";
import FormField from "../common/form/FormField";
import CheckBox from "../common/form/CheckBox";
import PropTypes from "prop-types";

export default class CompanyForm extends Component{

    constructor(props){
        super(props);

        var company = props.fields || {};
        var disabled = true;
        this.state = {
            Inn: {
                type: 'text',
                label: "ИНН",
                value: company.Inn || "",
                error: "",
                mask: numberMask()
            },
            FullName: {
                type: 'text',
                label: "Полное наименование",
                value: company.FullName || "",
                error: "",
                disabled
            },
            LegalAddressPostCode: {
                type: 'text',
                label: "Индекс",
                value: company.LeggalAddress && company.LeggalAddress.PostCode || "",
                error: "",
                mask: numberMask(),
                disabled
            },
            LegalAddressLocation: {
                type: 'text',
                label: "Город",
                value: company.LeggalAddress && company.LeggalAddress.Location || "",
                error: "",
                disabled
            },
            LegalAddressStreetAndBuilding: {
                type: 'text',
                label: "Адрес",
                value: company.LeggalAddress && company.LeggalAddress.StreetAndBuilding || "",
                error: "",
                disabled
            },
            FactAddressPostCode: {
                type: 'text',
                label: "Индекс",
                value: "",
                error: "",
                mask: numberMask(),
                disabled
            },
            FactAddressLocation: {
                type: 'text',
                label: "Город",
                value: "",
                error: "",
                disabled
            },
            FactAddressStreetAndBuilding: {
                type: 'text',
                label: "Адрес",
                value: "",
                error: "",
                disabled
            },
            FactAddressEqualsLegalAddress: {
                type: 'checkbox',
                label: 'Совпадает с юридическим',
                value: true,
                disabled,
                id: 'FactAddressEqualsLegalAddress'
            }
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    addValues = (state, company) => {
        state.FullName.value = company && company.FullName || "";
        state.LegalAddressPostCode.value = company && company.LeggalAddress && company.LeggalAddress.PostCode || "";
        state.LegalAddressLocation.value = company && company.LeggalAddress && company.LeggalAddress.Location || "";
        state.LegalAddressStreetAndBuilding.value = company && company.LeggalAddress && company.LeggalAddress.StreetAndBuilding || "";
        state.FactAddressPostCode.value = company && company.LeggalAddress && company.LeggalAddress.PostCode || "";
        state.FactAddressLocation.value = company && company.LeggalAddress && company.LeggalAddress.Location || "";
        state.FactAddressStreetAndBuilding.value = company && company.LeggalAddress && company.LeggalAddress.StreetAndBuilding || "";
        for (var key in state) {
            state[key].success = !!state[key].value && !state[key].error;
        }
        state.FactAddressEqualsLegalAddress.disabled = false;
        return state;
    }

    componentWillReceiveProps(nextProps){
        var state = {...this.state};
        if (nextProps.errors != this.props.errors) {
            for (var key in state) {
                if (nextProps.errors && nextProps.errors[key]) {
                    state[key].error = nextProps.errors[key];
                    state[key].success = false;
                } else {
                    state[key].error = "";
                }
            }
        }
        if (nextProps.fields != this.props.fields) {
            state = this.addValues(state, nextProps.fields);
        }
        if (state != this.state) this.setState(state);
    }

    changeFactEqualsLegal = (attribute, value) => {
        var state = {...this.state};
        state.FactAddressEqualsLegalAddress.value = value;
        if (!value) {
            state.FactAddressPostCode.disabled = false;
            state.FactAddressLocation.disabled = false;
            state.FactAddressStreetAndBuilding.disabled = false;
        } else {
            state.FactAddressPostCode.value = state.LegalAddressPostCode.value;
            state.FactAddressPostCode.disabled = !this.props.company;
            state.FactAddressLocation.value = state.LegalAddressLocation.value;
            state.FactAddressLocation.disabled = !this.props.company;
            state.FactAddressStreetAndBuilding.value = state.LegalAddressStreetAndBuilding.value;
            state.FactAddressStreetAndBuilding.disabled = !this.props.company;
        }
        this.setState(state);
    }

    onChangeField = (attribute, value) => {
        var state = {...this.state};
        if (state.hasOwnProperty(attribute)) {
            state[attribute].value = value;
            this.setState(state)
        }
    };

    renderField = (attribute, options = {}) => {
        var fields = this.state;

        return fields.hasOwnProperty(attribute) ?
            <FormField {...options}
                        key={attribute}
                        fieldConfig={{
                            ...fields[attribute],
                            label: this.context.translate(fields[attribute].label),
                            placeholder: this.context.translate(fields[attribute].placeholder)
                        }}
                       attribute={attribute}
                       onChange={this.onChangeField}
            /> : null;
    };

    onSearch = (e) => {
        e.preventDefault();
        if (this.state.Inn.value) {
            this.props.findCompany && this.props.findCompany(this.state.Inn.value);
        } else {
            this.setState({
                ...this.state,
                Inn: {
                    ...this.state.Inn,
                    error: this.context.translate('Это поле необходимо заполнить.')
                }
            })
        }
    }

    onSubmit = () => {
        var companyFields = {...this.state};

        var fields = {
            LesseeManualFullName: companyFields.FullName.value.replace(/\"/gim, ''),
            LesseeManualTin: companyFields.Inn.value,
            LesseeManualAddressLegal: [
                companyFields.LegalAddressPostCode.value,
                companyFields.LegalAddressLocation.value,
                companyFields.LegalAddressStreetAndBuilding.value
            ].join(', '),
            LesseeManualAddressPost: companyFields.FactAddressEqualsLegalAddress.value ? [
                companyFields.LegalAddressPostCode.value,
                companyFields.LegalAddressLocation.value,
                companyFields.LegalAddressStreetAndBuilding.value
            ].join(', ') : [
                companyFields.FactAddressPostCode.value,
                companyFields.FactAddressLocation.value,
                companyFields.FactAddressStreetAndBuilding.value
            ].join(', ')
        };

        this.props.addCompany && this.props.addCompany(fields);
    };

    render(){
        return <Form className="form_modal"
                     validate={true}
                     onSubmit={this.onSubmit}
        >
            <h2 className="form__title">{this.context.translate('Новая компания')}</h2>
            <h3 className="form__subtitle">{this.context.translate('Информация о компании')}</h3>
            <div className="form__row">
                {this.renderField('Inn', {className: 'form__field_size_s form__field_size_tablet_inner'})}
                <div className="form__field">
                    <a className={this.state.FullName.value ? "" : "btn"} href="#" onClick={this.onSearch}>
                        {this.state.FullName.value ? this.context.translate('Повторить поиск') : this.context.translate('Найти Компанию')}
                    </a>
                </div>
            </div>
            <div className="form__text">
                <p>{this.props.modalMessage ? this.props.modalMessage : this.context.translate('Введите ИНН и КПП компании, чтобы получить информацию о ней из СПАРК.')}</p>
            </div>
            <div className="form__row">
                {this.renderField('FullName')}
            </div>
            <h3 className="form__subtitle">{this.context.translate('Юридический адрес')}</h3>
            <div className="form__row">
                {this.renderField('LegalAddressPostCode', {className: 'form__field_size_xs form__field_size_tablet_inner'})}
                {this.renderField('LegalAddressLocation', {className: 'form__field_size_m'})}
                {this.renderField('LegalAddressStreetAndBuilding')}
            </div>
            <h3 className="form__subtitle form__subtitle_small">{this.context.translate('Почтовый адрес')}</h3>
            <div className="form__row">
                <div className={"form__field "+(this.state.FactAddressEqualsLegalAddress.disabled ? "disabled" : null)}>
                    <CheckBox {...this.state.FactAddressEqualsLegalAddress} onChange={this.changeFactEqualsLegal}/>
                </div>
            </div>
            <div className="form__row">
                {this.renderField('FactAddressPostCode', {className: 'form__field_size_xs form__field_size_tablet_inner'})}
                {this.renderField('FactAddressLocation', {className: 'form__field_size_m'})}
                {this.renderField('FactAddressStreetAndBuilding')}
            </div>
            <div className="form__buttons">
                <button className={"btn"+(this.state.FullName.value ? "" : " disabled")} type="submit">{this.context.translate('Добавить')}</button>
            </div>
        </Form>
    }

}