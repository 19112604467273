import React, {Component} from "react";
import {Link} from "react-router-dom";
import Form from "../common/form/Form";
import FormField from "../common/form/FormField";
import OrdersDocs from "./OrdersDocs";
import PropTypes from "prop-types";

import {priceMask, onlyNumbers} from "../../tools/mask";
import {getFormValues} from "../../tools/form-helper";

export default class OrdersForm extends Component{
    constructor(props){
        super(props);

        var days = [];
        for (var i = 1; i < 29; i++) {
            days.push({
                value: i,
                label: i
            })
        }
        var quantity = [];
        for (var i = 1; i < 1000; i++) {
            quantity.push({
                value: i,
                label: i
            })
        }

        var order = props.fields || {};

        this.state = {
            EquipmentName: {
                type: 'text',
                label: "Название предмета лизинга",
                placeholder: "Введите название или его часть",
                value: order.EquipmentName || "",
                error: '',
                required: true,
                success: !!order.EquipmentName
            },
            EquipmentQuantity: {
                type: 'select',
                label: "Количество предметов лизинга",
                value: order.EquipmentQuantity || 1,
                error: '',
                options: quantity,
                success: !!order.EquipmentQuantity
            },
            EquipmentConditionId: {
                type: 'radio',
                label: "Состояние",
                value: order.EquipmentConditionId || "",
                error: '',
                options: props.catalog && props.catalog.equipmentState || [],
                optionsType: 'equipmentState',
                success: !!order.EquipmentConditionId,
                allowEmpty: true
            },
            LesseeId: {
                type: 'select',
                label: "",
                placeholder: "Укажите компанию",
                value: order.LesseeId || "",
                error: '',
                options: props.catalog && props.catalog.leaseCompanies || [],
                optionsType: 'leaseCompanies',
                success: !!order.LesseeId,
                allowEmpty: true
            },
            EquipmentBrandName: {
                type: 'auto-complete',
                label: "Производитель (торговая марка)",
                placeholder: "Укажите производителя",
                value: order.EquipmentBrandName || "",
                error: '',
                options: props.catalog && props.catalog.equipmentBrands || [],
                optionsType: 'equipmentBrands',
                getOptions: props.getCatalog,
                success: !!order.EquipmentBrandName
            },
            EquipmentProductionCountryName: {
                type: 'auto-complete',
                label: "Страна производства",
                placeholder: "Выберите страну",
                value: order.EquipmentProductionCountryName || "",
                error: '',
                options: props.catalog && props.catalog.countries,
                optionsType: 'countries',
                getOptions: props.getCatalog,
                success: !!order.EquipmentProductionCountryName
            },
            EquipmentOperationRegionName: {
                type: 'auto-complete',
                label: "Регион эксплуатации",
                placeholder: "Выберите регион",
                value: order.EquipmentOperationRegionName || "",
                error: '',
                options: props.catalog && props.catalog.regions || [],
                optionsType: 'regions',
                getOptions: props.getCatalog,
                success: !!order.EquipmentOperationRegionName
            },
            EquipmentStorageAreaAddress: {
                type: 'text',
                label: "Место предполагаемого хранения",
                value: order.EquipmentStorageAreaAddress || "",
                error: '',
                success: !!order.EquipmentStorageAreaAddress
            },
            ContractCurrencyId: {
                type: 'radio',
                label: "В какой валюте вы хотите вносить платежи?",
                value: order.ContractCurrencyId || 2,
                error: '',
                options: props.catalog && props.catalog.currencies || [],
                optionsType: 'currencies',
                success: !!order.ContractCurrencyId
            },
            AssetHolderId: {
                type: 'radio',
                label: "Балансодержатель",
                value: order.AssetHolderId || "",
                error: '',
                options: props.catalog && props.catalog.assetHolders || [],
                optionsType: 'assetHolders',
                success: !!order.AssetHolderId,
                allowEmpty: true
            },
            EquipmentTotalCost: {
                type: 'number',
                label: "Общая стоимость покупки",
                value: order.EquipmentTotalCost && priceMask(order.EquipmentTotalCost) || "",
                error: '',
                required: true,
                mask: priceMask,
                success: !!order.EquipmentTotalCost
            },
            EquipmentCostCurrencyId: {
                type: 'select',
                value: order.EquipmentCostCurrencyId || 2,
                error: '',
                options: props.catalog && props.catalog.equipmentCurrencies || [],
                optionsType: 'equipmentCurrencies',
                required: true,
                success: !!order.EquipmentCostCurrencyId
            },
            LeasePaymentDay: {
                type: 'select',
                label: "В какой день месяца вы хотите вносить платеж?",
                value: order.LeasePaymentDay || 22,
                error: '',
                options: days,
                success: !!order.LeasePaymentDay
            },
            SupplierName: {
                type: 'text',
                label: "Поставщик",
                value: order.SupplierName || "",
                error: '',
                success: !!order.SupplierName
            },
            SupplierCityName: {
                type: 'auto-complete',
                label: "Город",
                value: order.SupplierCityName || "",
                error: '',
                success: !!order.SupplierCityName,
                options: props.catalog && props.catalog.cities || [],
                optionsType: 'cities',
                getOptions: props.getCatalog,
                placeholder: ' '
            },
            SupplierContactPersonName: {
                type: 'text',
                label: "ФИО",
                value: order.SupplierContactPersonName || "",
                error: '',
                success: !!order.SupplierContactPersonName
            },
            SupplierContactPersonPhone: {
                type: 'text',
                label: "Телефон",
                value: order.SupplierContactPersonPhone || "",
                error: '',
                success: !!order.SupplierContactPersonPhone
            },
            SupplierContactPersonWebsite: {
                type: 'url',
                label: "Сайт",
                value: order.SupplierContactPersonWebsite || "",
                error: '',
                success: !!order.SupplierContactPersonWebsite
            },
            SupplierContactPersonPosition: {
                type: 'text',
                label: "Должность",
                value: order.SupplierContactPersonPosition || "",
                error: '',
                success: !!order.SupplierContactPersonPosition
            },
            AdvancePercent: {
                type: 'number-slide',
                label: "Аванс",
                value: order.AdvancePercent && order.AdvancePercent >= 0 ? order.AdvancePercent : 0,
                mask: onlyNumbers,
                error: '',
                min: 0,
                max: 49,
                step: 1,
                postfix: '%',
                required: true,
                success: order.AdvancePercent || order.AdvancePercent === 0
            },
            ContractTerm: {
                type: 'number-slide',
                label: "Срок лизинга",
                value: order.ContractTerm || 6,
                mask: onlyNumbers,
                error: '',
                min: 6,
                max: 120,
                step: 6,
                postfix: 'мес',
                required: true,
                success: !!order.ContractTerm
            },
            Comment: {
                type: 'textarea',
                label: "",
                placeholder: "Введите комментарий",
                value: order.Comment || "",
                error: '',
                success: !!order.Comment
            },
            LesseeManualAddressLegal: {
                value: order.LesseeManualAddressLegal || ""
            },
            LesseeManualAddressPost : {
                value: order.LesseeManualAddressPost || ""
            },
            LesseeManualFullName : {
                value: order.LesseeManualFullName || ""
            },
            LesseeManualTin : {
                value: order.LesseeManualTin || ""
            },
            isChanged: {
                value: false
            }

        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    addDocument = (file) => {
        this.props.addApplicationDocument(this.props.fields && this.props.fields.ApplicationId || null, file);
    }

    removeDocument = (id) => {
        this.props.removeApplicationDocument(this.props.fields.ApplicationId, id);
    }

    updateDocument = (id, name) => {
        this.props.updateApplicationDocument(this.props.fields.ApplicationId, id, name)
    }

    addCatalogOptions = (state, catalog) => {
        for (var key in state) {
            if (state[key].hasOwnProperty('optionsType') && state[key].options != catalog[state[key].optionsType]) {
                state[key].options = catalog[state[key].optionsType];
            }
        }
        return state;
    }

    addValues = (state, application) => {
        for (var key in state) {
            if (key === 'isChanged'){
                state.isChanged.value = true;
                continue;
            }
            if (application && application.hasOwnProperty(key)) {
                if (this.props.fields && this.props.fields[key] != application[key]) {
                    var val = application[key];
                    if (val && state[key].mask) val = state[key].mask(val);
                    state[key].value = val !== null ? val : "";
                }
            } else {
                state[key].value = "";
            }
        }

        return state;
    }

    componentWillReceiveProps(nextProps) {
        var state = {...this.state};
        if (nextProps.catalog != this.props.catalog) {
            state = this.addCatalogOptions(state, nextProps.catalog);
        }
        if (nextProps.errors != this.props.errors) {
            for (var key in state) {
                state[key].error = nextProps.errors ? nextProps.errors[key] : ""
            }
        }
        if (nextProps.success != this.props.success) {
            for (var key in state) {
                if (nextProps.success && nextProps.success[key] == 'ok') state[key].success = true;
                else state[key].success = false;
            }
        }
        if (nextProps.fields != this.props.fields) {
            state = this.addValues(state, nextProps.fields);
        }
        if (nextProps.status != this.props.status && nextProps.status == 'send-error') {
            for (var key in state) {
                if (state[key].error) {
                    var to = document.getElementsByClassName(key)[0].closest('.form__row').offsetTop;
                    window.scrollTo(0, to);
                    break;
                }
            }
        }
        if (state != this.state) this.setState(state);
    }

    onChangeField = (attribute, value) => {
        if (!!value) this.props.setField(attribute, value);
        this.setState((prevState) => {
            return {
                ...prevState,
                [attribute]: {
                    ...prevState[attribute],
                    value
                },
            };
        });
        this.setIsChanged();
    };

    setIsChanged = () => {
        this.setState({isChanged: {value: true}});
    }
    saveField = (attribute, value) => {
        var {
            fields,
            saveField,
            saveFields,
            comeFrom
        } = this.props;

        if (fields && fields.ApplicationId) {
            //application updating
            saveField(fields && fields.ApplicationId, attribute, value);
        } else {
            if (comeFrom) {
                //application added from promotion
                if (comeFrom.from == 'promotion') {
                    var attributes = {[attribute]: value, PromotionId: comeFrom.id};
                    saveFields(null, attributes);
                } else if (comeFrom.from == 'potential') {
                    var attributes = {[attribute]: value, InvestPotential: 1};
                    saveFields(null, attributes);
                }
            } else {
                //create new draft
                saveField(null, attribute, value);
            }
        }
    };

    renderField = (attribute, className = "") => {
        var fields = {...this.state};

         return fields.hasOwnProperty(attribute) ?
             <FormField className={className + " " + attribute}
                   fieldConfig={{
                       ...fields[attribute],
                       label: this.context.translate(fields[attribute].label),
                       placeholder: this.context.translate(fields[attribute].placeholder)
                   }}
                   attribute={attribute}
                   onChange={this.onChangeField}
                   saveField={!this.props.fields || this.props.fields.IsDraft ? this.saveField : null} //send value to server
             /> : null;
    };

    onSubmit = () => {

        let {
            fields,
            sendApplication,
            saveFields,
            IsRecreate
        } = this.props;

        if (!fields || !fields.ApplicationId) {
            var stateFields = {...this.state};
            var to = window.pageYOffset;
            for (var attr in stateFields) {
                if (stateFields[attr].required && !stateFields[attr].value) {
                    stateFields[attr].error = this.context.translate("Обязательное поле");
                    if (to == window.pageYOffset) {
                        to = document.getElementsByClassName(attr)[0].closest('.form__row').offsetTop;
                    }
                }
            }
            this.setState({...stateFields}, () => {
                window.scrollTo(0, to);
            });
        } else if (fields.IsDraft) {
            sendApplication && sendApplication();
        } else {
            var values = getFormValues({...this.state});
            saveFields && saveFields(IsRecreate ? null : fields.ApplicationId, values);
        }
    };

    clearDocs = () => {
        if (this.props.tmpDocs && this.props.tmpDocs.length) {
            this.props.tmpDocs.map((doc) => {
                this.removeDocument(doc);
            })
        }
    }

    hasChanges = () => {
        var fields = this.state;
        var oldFields = this.props.fields;

        for (var attr in fields) {
            if (fields[attr].type == "number") {
                if (fields[attr].value.replace(/\D/gim, '') != oldFields[attr]) {
                    return true;
                }
            } else if (fields[attr].value != oldFields[attr] && !(oldFields[attr] == null && fields[attr].value == "")) {
                return true;
            }
        }
        return false;
    }

    render() {
        let {
            fields,
            fileBuffer,
            fileParams,
            clearBuffer,
            cancelApplicationDocument,
            IsRecreate,
            downloadApplicationFile,
            documentTypes
        } = this.props;

        let {
            LesseeManualAddressLegal,
            LesseeManualAddressPost,
            LesseeManualFullName,
            LesseeManualTin,
            LesseeId
        } = this.state;

        return <Form onSubmit={this.onSubmit}
                     validate={true}>
                <h2 className="form__title">{this.context.translate('Предмет лизинга')}</h2>
                <div className="form__row">
                    {this.renderField('EquipmentName')}
                </div>
                <div className="form__row">
                    {this.renderField('EquipmentProductionCountryName')}
                    {this.renderField('EquipmentBrandName')}
                </div>
                <div className="form__row">
                    {this.renderField('EquipmentQuantity', "application-edit__obj-number-wrap")}
                    {this.renderField('EquipmentConditionId')}
                </div>
                <div className="form__row">
                    {this.renderField('EquipmentOperationRegionName')}
                    {this.renderField('EquipmentStorageAreaAddress')}
                </div>
                <h2 className="form__title">{this.context.translate('Условия финансирования')}</h2>
                <div className="form__row">
                    <div className="form__field ">
                        <div className="form__flex">
                            {this.renderField('EquipmentTotalCost')}
                            {this.renderField('EquipmentCostCurrencyId', "select-xs")}
                        </div>
                    </div>
                    <div className="form__subrow">
                        {this.renderField('AdvancePercent')}
                        {this.renderField('ContractTerm')}
                    </div>
                </div>
                <div className="form__row">
                    {this.renderField('ContractCurrencyId', "application-edit__item_contract-currency")}
                    {this.renderField('LeasePaymentDay', "application-edit__item_pay-day")}
                </div>
                <div className="form__row">
                    {this.renderField('AssetHolderId')}
                </div>
                <h2 className="form__title">{this.context.translate('Компания-лизингополучатель')}</h2>
                <div className="form__row form__row_button">
                    {this.renderField('LesseeId')}
                    {!(LesseeManualFullName.value && !fields.LesseeId && !LesseeId.value) ?
                        <a className={"btn btn_clear_blue btn_add form__button"} href="#" onClick={this.props.addCompany}>{this.context.translate('Добавить компанию')}</a> : null}
                </div>
                 {LesseeManualFullName.value && !fields.LesseeId && !LesseeId.value ? <div className="application-edit__company-reqs">
                    <div className="company-reqs">
                        <div className="company-reqs__wrap">
                            <div className="company-reqs__title">{LesseeManualFullName.value}</div>
                            <div className="company-reqs__inner">
                                <div className="company-reqs__item company-reqs__item_inline"><strong>{this.context.translate('ИНН')}:&nbsp;</strong>{LesseeManualTin.value}
                                </div>
                                <div className="company-reqs__item"><strong>{this.context.translate('Юридический адрес')}:&nbsp;</strong>
                                    {LesseeManualAddressLegal.value}
                                </div>
                                <div className="company-reqs__item"><strong>{this.context.translate('Почтовый адрес')}:&nbsp;</strong>
                                    {LesseeManualAddressPost.value}
                                </div>
                            </div>
                            <div className="company-reqs__btn">
                                <div className="u-btn u-btn_clear_blue" onClick={this.props.addCompany}>{this.context.translate('Изменить')}</div>
                            </div>
                        </div>
                    </div>
                </div> : null}
                <h2 className="form__title">{this.context.translate('Информация о поставщике')}</h2>
                <div className="form__row">
                    {this.renderField('SupplierName')}
                    {this.renderField('SupplierCityName')}
                </div>
                <div>
                    <h4 className="form__innertitle">{this.context.translate('Контактное лицо поставщика')}</h4>
                    <div className="form__row">
                        {this.renderField('SupplierContactPersonPosition')}
                        {this.renderField('SupplierContactPersonName')}
                    </div>
                    <div className="form__row">
                        {this.renderField('SupplierContactPersonPhone')}
                        {this.renderField('SupplierContactPersonWebsite')}
                    </div>
                </div>
                <h2 className="form__title">{this.context.translate('Комментарий к заявке')}</h2>
                <div className="form__row">
                    {this.renderField('Comment')}
                </div>
                <OrdersDocs docs={fields && fields.Documents}
                            fileBuffer={fileBuffer}
                            fileParams={fileParams}
                            clearBuffer={clearBuffer}
                            addDocument={this.addDocument}
                            updateDocument={this.updateDocument}
                            removeDocument={this.removeDocument}
                            cancelDocument={cancelApplicationDocument}
                            download={downloadApplicationFile}
                            documentTypes={documentTypes}
                />
            {!fields || fields.IsDraft ? <div className="form__buttons">
                    <button className="btn" type="submit">{this.context.translate('Отправить заявку')}</button>
                </div> : (IsRecreate ? <div className="form__buttons">
                <button className="btn" type="submit">{this.context.translate('Отредактировать и отправить заново')}</button>
                    <Link className="btn btn_clear_blue" to={"/applications" + "#archive"} onClick={this.clearDocs}>{this.context.translate('Отмена')}</Link>
            </div>:
                <div className="form__buttons">
                    <button className={"btn"+(this.hasChanges() ? "" : " disabled")} type="submit">{this.context.translate('Сохранить')}</button>
                    <Link className="btn btn_clear_blue" to={"/applications" + "#progress"} onClick={this.clearDocs}>{this.context.translate('Отмена')}</Link>
                </div>)}
        </Form>
    }
}