import {parseErrors} from "../tools/api-helper";

const initState = {companies: [], communications: {data: [], pagination: {}}, documents: null, form: {isSending: false, error: null, validation: {}, success: null}}

export default function insurance(state = initState, action)
{
    switch (action.type) {
        case "GET_INSURANCE_COMMUNICATIONS_SUCCESS":
            return {...state, communications: action.data};
        case "GET_INSURANCE_COMMUNICATIONS_LOAD_MORE_SUCCESS":
            return {...state, communications: {data: [...state.communications.data, ...action.data.data], pagination: action.data.pagination}};
        case "GET_INSURANCE_COMPANIES_SUCCESS":
            return {...state, companies: action.data};
        case "GET_INSURANCE_DOCUMENTS_SUCCESS":
            let documents = {};
            action.data.map((item) => {documents[item.id] = item})
            return {...state, documents};
        case "GET_INSURANCE_DOCUMENTS_ERROR":
            return {...state, documents: {}};
        case "SEND_INSURANCE_COMMUNICATION":
            return {...state, form: {...initState.form, isSending: true}};
        case "SEND_INSURANCE_COMMUNICATION_CREATED":
            return {...state, form: {...initState.form, isSending: false, success: action.data}};
        case "SEND_INSURANCE_COMMUNICATION_BAD_REQUEST":
            return {...state, form: {...initState.form, isSending: false, error: action.data.message}};
        case "SEND_INSURANCE_COMMUNICATION_VALIDATION_ERROR":
            return {...state, form: {...initState.form, isSending: false, validation: parseErrors(action.data)}};
        case "CLEAR_FORM":
            return {...state, form: {...initState.form}};
        default:
            return state;
    }
}