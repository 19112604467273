import React, {Component} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

export default class Help extends Component{

    constructor(props){
        super(props);

        this.onSearchChange = this.onSearchChange.bind(this);
    }


    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    onSearchChange(e){
        this.props.search(e.target.value);
    }

    render(){
        return <div className="page page_projects page_instructions">
            <div className="page__main page__main_default">
                <div className="page__content-container">
                    <div className="page__title-section">
                        <h1 className="page__title">{this.context.translate("Помощь")}</h1>
                    </div>
                    <div className="page__instructions-search">
                        <div className="search-input">
                            <label className="search-input__wrap">
                                <div className="search-input__label-text"/>
                                <div className="search-input__input-wrap">
                                    <input className="search-input__input" type="text"
                                           placeholder={this.context.translate("Поиск по вопросам и ответам")}
                                           onBlur={this.onSearchChange}
                                    />
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="page__instructions-list">
                        <p className="page__instructions-list-title">{this.context.translate("Вопросы по темам")}</p>
                        <div className="instructions-list instructions-list_adapt-default">
                            {this.props.children}
                            <p className="instructions-list__footer">
                                {this.context.translate("Если вы не нашли ответ на свой вопрос, то можете задать его нашим специалистам.")}
                            </p>
                            <Link
                                className="page__title-button u-btn btn_appeal"
                                to={{pathname: "/communications/create", state: "help"}}>
                                    {this.context.translate('Создать обращение')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}