import React, {Component} from "react";
import DocumentsTable from "./DocumentsTable";
import TogglePanel from "../common/TogglePanel";
import DocumentsSearchForm from "./DocumentsSearchForm";
import Pagination from "../common/Pagination";
import Preloader from "../common/Preloader";
import {Link} from "react-router-dom";

import noFiles from "../../../img/no-files.svg";
import notFound from "../../../img/magnigier.svg";
import {getBodyData, getBodyDataOnPagination} from "../../tools/form-helper";
import Modal from "../common/Modal";
import DocumentsOrderingWidget, {DocumentOrderingOk} from "./DocumentsOrderingWidget";
import PropTypes from "prop-types";

export default class DocumentsList extends Component{

    constructor(props){
        super(props);
        this.state = {
            searchFields: {},
            sort: 'DESC',
            modal: null,
            data: this.props.location.state ? this.props.location.state : {}
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps(nextProps){
        if (nextProps.documentsRequestResult != this.props.documentsRequestResult && nextProps.documentsRequestResult) {
            this.openModal('success')();
        }
        if (nextProps.loading != this.props.loading && nextProps.loading === false) {
            this.closeModal();
        }
    }

    search = (fields = {}) => {
        this.setState({searchFields: fields, data: {}});
        this.props.getDocumentsList(getBodyData(fields), this.state.sort);
    }

    toPage = (num) => {
        var fields = {...this.state.searchFields};
        fields['page'] = num;
        this.props.getDocumentsList(getBodyDataOnPagination(fields), this.state.sort);
    }

    sortDoc = (e) => {
        if (e) {
            e.preventDefault();
        }
        let fields = {...this.state.searchFields};
        this.setState((prevState) => {
            return {
                sort: prevState.sort === 'DESC' ? 'ASC' : 'DESC'
            }
        }, () => {
            this.search(fields);
        })
    }

    openModal = (modal) => {
        return (e) => {
            if (e) e.preventDefault();
            this.setState({modal})
        }
    }

    closeModal = (e) => {
        if (e) e.preventDefault();
        this.setState({modal: null})
    }

    showInvoice = () => {
        this.setState({data: {DocumentName: "Счет-фактура"}, modal: false}, () => {
            this.props.getDocumentsList("DocumentName=Счет-фактура");
        });
    }

    renderModal = () => {
        var {
            catalog,
            getCatalog,
            downloadHelpfulDoc,
            addDocumentRequest,
            documentsRequestResult,
            downloadReconciliationStatement,
            documentsFormError
        } = this.props;

        switch (this.state.modal) {
            case 'widget':
                return <Modal onClickBackground={this.closeModal} classNameContent="modal_fullscreen page__widget-modal">
                    <DocumentsOrderingWidget catalog={catalog}
                                             getCatalog={getCatalog}
                                             downloadHelpfulDoc={downloadHelpfulDoc}
                                             showInvoice={this.showInvoice}
                                             addDocumentRequest={addDocumentRequest}
                                             downloadReconciliationStatement={downloadReconciliationStatement}
                                             documentsFormError={documentsFormError}
                    />
                </Modal>;
            case 'success':
                return <Modal onClickBackground={this.closeModal} classNameContent="modal_fullscreen page__widget-modal">
                    <DocumentOrderingOk {...documentsRequestResult}
                        onClick={this.closeModal}
                        translate={this.context.translate}
                    />
                </Modal>;
            default:
                return null;
        }
    }

    render(){

        let {
            documentsList,
            isOk,
            isSearch,
            isSearching,

            catalog,
            getCatalog,

            downloadDoc
        } = this.props;

        return <div className="page__content-container">
            <h1 className="page__title">{this.context.translate('Документооборот')}</h1>
            <div className="page__head-ui">
                <div className="page__head-ui-items">
                    <div className="page__head-ui-item">
                        <a className="u-btn u-btn_adapt-type_wide" href="#" onClick={this.openModal('widget')}>{this.context.translate('Заказать документ')}</a>
                    </div>
                    {/*<div className="page__head-ui-item">*/}
                        {/*<a className="u-btn u-btn_clear_blue u-btn_adapt-type_wide" href="#doc-widget">Сформировать выписку</a>*/}
                    {/*</div>*/}
                </div>
            </div>

            <TogglePanel className="search"
                         labelClassName="page__title-section page__title-ui"
                         contentClassName="page__filter-container"
                         label={this.context.translate('Поиск')}
                         slide={Object.keys(this.state.data).length ? "down" : ""}
            >
                <div className="page__filter">
                    <div className="items-filter items-filter_documents">
                        <DocumentsSearchForm data={this.state.data} catalog={catalog} getCatalog={getCatalog} search={this.search}/>
                    </div>
                </div>
            </TogglePanel>

            {isOk ?
                (documentsList.length ? <DocumentsTable
                        key="documents-table"
                        list={documentsList}
                        sort={this.state.sort}
                        setSort={this.sortDoc}
                        downloadDoc={downloadDoc}
                >
                    {isSearching ? <Preloader/> : null}
                </DocumentsTable> : renderEmpty(isSearch, this.context.translate)) :
                <Preloader/>}
            {documentsList.pagination ? <Pagination key="pagination" {...documentsList.pagination} toPage={this.toPage}/> : null}

            {this.renderModal()}
        </div>
    }
}

function renderEmpty(isSearch, translate) {
    return <div className="page__zero-results">
        {isSearch ? <div className="page__zero-results">
            <div className="info-widget">
                <div className="info-widget__head"><img className="info-widget__image" src={notFound} /></div>
                <div className="info-widget__title">{translate('Ничего не найдено')}</div>
                <div className="info-widget__message">{translate('Пожалуйста, повторите запрос с другими параметрами.')}</div>
            </div>
        </div> : <div className="info-widget">
            <div className="info-widget__head"><img className="info-widget__image" src={noFiles} /></div>
            <div className="info-widget__title">{translate('У вас нет добавленных документов')}</div>
            <div className="info-widget__message">{translate('Вы можете загрузить их при создании новой заявки на лизинг и они появятся на этой странице автоматически.')}
            </div>
            <div className="info-widget__footer">
                <Link className="info-widget__footer-btn u-btn u-btn_orange" to="/applications/create">{translate('Отправить заявку')}</Link>
            </div>
        </div>}
    </div>
}