import {
    call,
    put,
    take,
    all
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import {checkToken} from "./auth";

/**
 *
 * @returns {{expand: bool}}
 */
export function promotionsList(params = null) {
    return {
        type: 'PROMOTIONS_LIST',
        params
    }
}

export function translatePromotions(params = null) {
    return {
        type: 'TRANSLATE_PROMOTIONS',
        params
    }
}

/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* fetchPromotionImage(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi(`/promotions/image/${action.id}`, {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, status === 200 ? {id: action.id, img: data} : data, status));
        }

    } catch(e) {
        alert(e);
    }
}

/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* fetchPromotionsList(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, pagination, error } = yield call( () => {
            return fetchClientApi("/promotions"+(action.params ? '?'+action.params : ''), {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (pagination) data.pagination = pagination;
            yield put(checkErrors(action.type, data, status));
            if (status === 200 && action.type === 'PROMOTIONS_LIST') {
                let actions = [];
                data.map((promotion) => {
                    if (promotion.img_id) {
                        actions.push(call(fetchPromotionImage, {id: promotion.img_id, type: 'PROMOTION_IMAGE'}))
                    }
                })
                yield all(actions)
            }
        }

    } catch(e) {
        alert(e);
    }
}

/**
 *
 * @returns {{expand: bool}}
 */
export function topicsList(params = null) {
    return {
        type: 'TOPICS_LIST',
        params
    }
}

/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* fetchTopicsList(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/topics", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

