import React, {Component} from "react";
import {Link} from "react-router-dom";
import FormField from "../common/form/FormField";
import {numberMask} from "../../tools/mask";
import Form from "../common/form/Form";
import {screenSizeMd, getWord} from "../../tools/tools";
import {getBodyData} from "../../tools/form-helper";
import thanks from "../../../img/papers-thanks.svg";
import PropTypes from "prop-types";

function requestFields(requestTypeId, catalog, getCatalog, translate) {
    switch (requestTypeId) {
        case 1:
            return {
                DocumentAppearanceTypeId: {
                    type: window.innerWidth < screenSizeMd ? "select" : "radio",
                    label: translate("Вариант исполнения"),
                    options: catalog.documentAppearances && catalog.documentAppearances[requestTypeId] || [],
                    optionsType: 'documentAppearances',
                    value: catalog.documentAppearances ? catalog.documentAppearances[requestTypeId].filter(
                        (item) => {return item.hasDeliveryType == 0;})[0].value : ''
                },
                ProjectsDescription: {
                    type: "textarea",
                    label: translate("Укажите, за какой срок и по какой сделке вам нужен документ"),
                    value: "",
                    fieldClassName: "textarea-input_height-type_default",
                    subTitle: "Начисления в бух учете проходят последним днем текущего месяца, а не датой платежа, указанной в графике. Если вам необходимо свериться на текущую дату, пожалуйста, сформируйте выписку взаиморасчетов."
                },
                DeliveryTypeId: {
                    type: window.innerWidth < screenSizeMd ? "select" : "radio",
                    label: translate("Способ доставки"),
                    options: catalog.documentDeliveryWays || [],
                    optionsType: 'documentDeliveryWays',
                    value: catalog.documentDeliveryWays ? catalog.documentDeliveryWays.filter(
                        (item) => {return item.isSelfDelivery == 1;})[0].value : "",
                    hidden: true
                },
                SelfDelivery: {
                    type: "labeled-text",
                    label: translate("Самовывоз из Вашего офиса «Сименс Финанс»")+":",
                    value: catalog.officeAddress || "",
                    hidden: true
                },
                DeliveryAddress: {
                    type: "select",
                    label: translate("Адрес доставки"),
                    options: catalog.documentAddresses || [],
                    optionsType: "documentAddresses",
                    value: "",
                    hidden: true
                },
                Postcode: {
                    type: "text",
                    label: translate("Индекс"),
                    mask: numberMask(6),
                    value: "",
                    wrapperClassName: "doc-widget__item_wtype_index",
                    hidden: true
                },
                City: {
                    type: "text",
                    label: translate("Город"),
                    value: "",
                    wrapperClassName: "doc-widget__item_wtype_city",
                    hidden: true
                },
                Address: {
                    type: "text",
                    label: translate("Адрес"),
                    value: "",
                    hidden: true
                }
            };
        case 2:
            return {
                DocumentAppearanceTypeId: {
                    type: window.innerWidth < screenSizeMd ? "select" : "radio",
                    label: translate("Вариант исполнения"),
                    options: catalog.documentAppearances && catalog.documentAppearances[requestTypeId] || [],
                    optionsType: 'documentAppearances',
                    value: catalog.documentAppearances ? catalog.documentAppearances[requestTypeId].filter(
                        (item) => {return item.hasDeliveryType == 0;})[0].value : ''
                },
                DeliveryTypeId: {
                    type: window.innerWidth < screenSizeMd ? "select" : "radio",
                    label: translate("Способ доставки"),
                    options: catalog.documentDeliveryWays || [],
                    optionsType: 'documentDeliveryWays',
                    value: catalog.documentDeliveryWays ? catalog.documentDeliveryWays.filter(
                        (item) => {return item.isSelfDelivery == 1;})[0].value : "",
                    hidden: true
                },
                SelfDelivery: {
                    type: "labeled-text",
                    label: translate("Самовывоз из Вашего офиса «Сименс Финанс»:"),
                    value: catalog.officeAddress || "",
                    hidden: true
                },
                DeliveryAddress: {
                    type: "select",
                    label: translate("Адрес доставки"),
                    options: catalog.documentAddresses || [],
                    optionsType: "documentAddresses",
                    value: "",
                    hidden: true
                },
                Postcode: {
                    type: "text",
                    label: translate("Индекс"),
                    mask: numberMask(6),
                    value: "",
                    wrapperClassName: "doc-widget__item_wtype_index",
                    hidden: true
                },
                City: {
                    type: "text",
                    label: translate("Город"),
                    value: "",
                    wrapperClassName: "doc-widget__item_wtype_city",
                    hidden: true
                },
                Address: {
                    type: "text",
                    label: translate("Адрес"),
                    value: "",
                    hidden: true
                }
            };
        case 3:
            return {
                DocumentCriteria: {
                    type: window.innerWidth < screenSizeMd ? "select" : "radio",
                    label: translate("Критерий формирования"),
                    options: catalog.documentCriteria || [],
                    optionsType: 'documentCriteria',
                    value: catalog.documentCriteria ? catalog.documentCriteria.filter((item) => {return item.isByContract == 1;})[0].value : ""
                },
                ProjectId: {
                    type: "select",
                    label: translate("Проект"),
                    value: "",
                    options: catalog.documentProjects || [],
                    optionsType: 'documentProjects',
                    allowEmpty: true,
                    allowSearch: true,
                    hidden: true
                },
                ContractId: {
                    type: "select",
                    label: translate("Договор лизинга"),
                    value: "",
                    options: catalog.documentContracts || [],
                    optionsType: 'documentContracts',
                    allowEmpty: true,
                    allowSearch: true
                },
                StartDate: {
                    type: "date",
                    label: translate("Начало периода"),
                    value: "",
                    wrapperClassName: "doc-widget__item_half"
                },
                EndDate: {
                    type: "date",
                    label: translate("Окончание периода"),
                    value: "",
                    wrapperClassName: "doc-widget__item_half"
                },
                Format: {
                    type: window.innerWidth < screenSizeMd ? "select" : "radio",
                    label: translate("Формат"),
                    options: catalog.documentFormats || [],
                    optionsType: "documentFormats",
                    value: catalog.documentFormats ? catalog.documentFormats[0].value : ""
                }
            };
        default:
            return {};
    }
}

function getInitState(props, translate) {
    return {
        fields: {
            DocumentTypeId: {
                type: "select",
                label: translate("Тип документов"),
                value: "",
                options: props.catalog.documentTypes || [],
                optionsType: 'documentTypes',
                allowEmpty: true,
                allowSearch: true,
                withGroups: 'types'
            }
        },
        screenIsSmall: window.innerWidth < screenSizeMd,
        options: {
            manualAddressValue: props.catalog.documentAddresses ? props.catalog.documentAddresses.filter((item) => {return item.isManual == 1})[0].value : "",
            contractCriteriaValue: props.catalog.documentCriteria ? props.catalog.documentCriteria.filter((item) => {return item.isByContract == 1})[0].value : "",
            projectCriteriaValue: props.catalog.documentCriteria ? props.catalog.documentCriteria.filter((item) => {return item.isByProject == 1})[0].value : "",
            selfDeliveryValue: props.catalog.documentDeliveryWays ? props.catalog.documentDeliveryWays.filter((item) => {return item.isSelfDelivery == 1})[0].value : "",
            nonDeliveryAppearance: props.catalog.documentAppearances ? props.catalog.documentAppearances['1'].filter((item) => {return item.hasDeliveryType == 0})[0].value : ""
        },
        btnText: "",
        note: null,
        preventSubmit: false,
        selectedLabel: null
    }
}

export default class DocumentsOrderingWidget extends Component{
    constructor(props, context){
        super(props, context);

        this.state = getInitState(props, context.translate);
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentDidMount(){
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.onResize);
    }

    componentWillReceiveProps(nextProps){
        if (this.props.catalog != nextProps.catalog) {
            var state = {...this.state.fields};
            state = this.addCatalogValues(state, nextProps.catalog);
            this.setState({fields: state, options: {
                manualAddressValue: nextProps.catalog.documentAddresses ? nextProps.catalog.documentAddresses.filter((item) => {return item.isManual == 1})[0].value : "",
                contractCriteriaValue: nextProps.catalog.documentCriteria ? nextProps.catalog.documentCriteria.filter((item) => {return item.isByContract == 1})[0].value : "",
                projectCriteriaValue: nextProps.catalog.documentCriteria ? nextProps.catalog.documentCriteria.filter((item) => {return item.isByProject == 1})[0].value : "",
                selfDeliveryValue: nextProps.catalog.documentDeliveryWays ? nextProps.catalog.documentDeliveryWays.filter((item) => {return item.isSelfDelivery == 1})[0].value : "",
                nonDeliveryAppearance: nextProps.catalog.documentAppearances ? nextProps.catalog.documentAppearances['1'].filter((item) => {return item.hasDeliveryType == 0})[0].value : ""
            }})
        }
        if (this.props.loading != nextProps.loading && nextProps.loading === false ||
            this.props.documentsRequestResult != nextProps.documentsRequestResult && nextProps.documentsRequestResult) {
            this.onChangeField('DocumentTypeId', '');
            this.setState({preventSubmit: false});
        }

        if (this.props.documentsFormError != nextProps.documentsFormError) {
            var state = {...this.state.fields};
            let newError = false;
            for (var attr in state) {
                let err = state[attr].error;
                state[attr].error = nextProps.documentsFormError[attr] ? nextProps.documentsFormError[attr] : "";
                if (state[attr].error != err) {
                    newError = true;
                }
            }
            if (newError) {
                this.setState({preventSubmit: false});
            }
        }
    }

    addCatalogValues = (state, catalog) => {
        for (var key in state) {
            if (key == 'SelfDelivery') {
                state[key].value = catalog.officeAddress;
            } else if (key == 'DocumentAppearanceTypeId') {
                let documentTypeIdOptions = [];
                state.DocumentTypeId.options.map((group) => {
                    documentTypeIdOptions.push(...group.types)
                })
                var scenario = documentTypeIdOptions.filter((item) => {return item.value == this.state.fields.DocumentTypeId.value})[0].requestMethodId;
                state[key].options = catalog.documentAppearances[scenario];
            } else if (state[key].hasOwnProperty('optionsType')) {
                state[key].options = catalog[state[key].optionsType]
            }
        }
        return state;
    }

    onChangeField = (attribute, value) => {
        var state = {...this.state.fields};
        if (state.hasOwnProperty(attribute)) {
            state[attribute].value = value;
            state[attribute].error = "";


            var btnText = this.state.btnText;
            var catalog = this.props.catalog;
            var note = this.state.note;
            var {
                manualAddressValue,
                contractCriteriaValue,
                projectCriteriaValue,
                selfDeliveryValue,
                nonDeliveryAppearance
            } = this.state.options;
            switch (attribute) {
                case "DocumentTypeId":
                    var documentTypeIdOptions = [];
                    state.DocumentTypeId.options.map((group) => {
                        documentTypeIdOptions.push(...group.types)
                    })
                    var scenario = value ? documentTypeIdOptions.filter((item) => {return item.value == value})[0].requestMethodId : null;

                    state = {DocumentTypeId: state[attribute], ...requestFields(scenario, catalog, this.props.getCatalog, this.context.translate)};
                    state = this.addCatalogValues(state, this.props.catalog);

                    switch (scenario) {
                        case 1:
                            btnText = this.context.translate("Отправить запрос");
                            break;
                        case 2:
                            var val = state.DocumentAppearanceTypeId.value;
                            btnText = (val == nonDeliveryAppearance ? this.context.translate("Перейти для скачивания") : this.context.translate("Отправить запрос"));
                            break;
                        case 3:
                            btnText = this.context.translate("Сформировать выписку");
                            break;
                        case 4:
                            btnText = this.context.translate("Скачать документ");
                            break;
                        default:
                            btnText = "";
                    }
                    note = null;
                    break;
                case "DocumentCriteria":
                    state.ProjectId.hidden = (value != projectCriteriaValue);
                    state.ContractId.hidden = (value != contractCriteriaValue);
                    break;
                case "DocumentAppearanceTypeId":
                    var documentTypeIdOptions = [];
                    state.DocumentTypeId.options.map((group) => {
                        documentTypeIdOptions.push(...group.types)
                    })
                    var scenario = documentTypeIdOptions.filter((item) => {return item.value == state.DocumentTypeId.value})[0].requestMethodId;

                    state.DeliveryTypeId.hidden = (value == nonDeliveryAppearance);
                    state.SelfDelivery.hidden = (value == nonDeliveryAppearance || state.DeliveryTypeId.value != selfDeliveryValue);
                    state.DeliveryAddress.hidden = (value == nonDeliveryAppearance || state.DeliveryTypeId.value == selfDeliveryValue);
                    state.Postcode.hidden = (value == nonDeliveryAppearance || state.DeliveryTypeId.value == selfDeliveryValue || state.DeliveryAddress.value != manualAddressValue);
                    state.City.hidden = (value == nonDeliveryAppearance || state.DeliveryTypeId.value == selfDeliveryValue || state.DeliveryAddress.value != manualAddressValue);
                    state.Address.hidden = (value == nonDeliveryAppearance || state.DeliveryTypeId.value == selfDeliveryValue || state.DeliveryAddress.value != manualAddressValue);
                    btnText = (scenario == 2 && value == nonDeliveryAppearance ? this.context.translate("Перейти для скачивания") : this.context.translate("Отправить запрос"));
                    note = value == nonDeliveryAppearance ? null : catalog.documentDeliveryWays.filter((item) => {return item.value == state.DeliveryTypeId.value;})[0].note;
                    break;
                case "DeliveryTypeId":
                    note = catalog.documentDeliveryWays.filter((item) => {return item.value == value;})[0].note;
                    state.SelfDelivery.hidden = (value != selfDeliveryValue);
                    state.DeliveryAddress.hidden = (value == selfDeliveryValue);
                    state.Postcode.hidden = (value == selfDeliveryValue || state.DeliveryAddress.value != manualAddressValue);
                    state.City.hidden = (value == selfDeliveryValue || state.DeliveryAddress.value != manualAddressValue);
                    state.Address.hidden = (value == selfDeliveryValue || state.DeliveryAddress.value != manualAddressValue);
                    break;
                case "DeliveryAddress":
                    state.Postcode.hidden = (value != manualAddressValue);
                    state.City.hidden = (value != manualAddressValue);
                    state.Address.hidden = (value != manualAddressValue);
                    break;
            }
            this.setState({fields: state, btnText, isValid: this.isValid(state), note});
        }
    };

    valueSelected = (e) => {
        this.setState({selectedLabel: e.label})
    }

    renderField = (attribute) => {
        var fields = {...this.state.fields};
        var config = {...fields[attribute]};
        delete config['wrapperClassName'];
        config.label = this.context.translate(config.label);
        if (config.placeholder) this.context.translate(config.placeholder);

        return fields.hasOwnProperty(attribute) && !config.hidden ?
            <div className={"doc-widget__item"+(fields[attribute].wrapperClassName ? " "+fields[attribute].wrapperClassName : "")} key={attribute}>
                <FormField fieldConfig={config}
                           attribute={attribute}
                           onChange={this.onChangeField}
                           valueSelected={(e)=> this.valueSelected(e)}
                           selectedLabel={this.state.selectedLabel}
                           className={fields[attribute].fieldClassName || ""}
                />
            </div> : null;
    };

    renderFields = () => {
        var attributes = Object.keys(this.state.fields);
        var fields = [];
        attributes.map((attr) => {fields.push(this.renderField(attr))});
        return fields;
    }

    isValid = (fields) => {
        for (var attr in fields) {
            if (!(fields[attr].value || fields[attr].hidden)) return false;
        }
        return true;
    }

    onSubmit = () => {
        if (!this.isValid(this.state.fields) || this.state.preventSubmit) return false;

        this.setState({preventSubmit: true}, () => {
            var {
                DocumentTypeId,
                DocumentAppearanceTypeId
            } = this.state.fields;

            let documentTypeIdOptions = [];
            DocumentTypeId.options.map((group) => {
                documentTypeIdOptions.push(...group.types)
            })
            var scenario = DocumentTypeId.value ? documentTypeIdOptions.filter((item) => {return item.value == DocumentTypeId.value})[0].requestMethodId : null;

            switch (scenario) {
                case 1:
                case 2:
                    if (scenario == 2 && DocumentAppearanceTypeId.value == this.state.options.nonDeliveryAppearance) {
                        this.props.showInvoice();
                    } else {
                        var {
                            ProjectsDescription,
                            DeliveryTypeId,
                            DeliveryAddress,
                            Postcode,
                            City,
                            Address
                        } = this.state.fields;

                        var {
                            manualAddressValue,
                            selfDeliveryValue,
                            nonDeliveryAppearance
                        } = this.state.options;

                        var body = new FormData();
                        body.append('DocumentTypeId', DocumentTypeId.value);
                        body.append('DocumentAppearanceTypeId', DocumentAppearanceTypeId.value);
                        if (ProjectsDescription) {
                            body.append('ProjectsDescription', ProjectsDescription.value);
                        }
                        if (DocumentAppearanceTypeId.value != nonDeliveryAppearance) {
                            body.append('DeliveryTypeId', DeliveryTypeId.value);
                            if (DeliveryTypeId.value != selfDeliveryValue) {
                                if (DeliveryAddress.value == manualAddressValue) {
                                    body.append('DeliveryAddress', Postcode.value+', '+City.value+', '+Address.value);
                                } else {
                                    body.append('DeliveryAddress', DeliveryAddress.options.filter(
                                        (item) => {
                                            return item.value == DeliveryAddress.value;
                                        })[0].label);
                                }
                            }
                        }
                        this.props.addDocumentRequest(body);
                    }
                    break;
                case 3:
                    var fields = {...this.state.fields};
                    fields.StartDate.value = fields.StartDate.value.split('.').reverse().join('-');
                    fields.EndDate.value = fields.EndDate.value.split('.').reverse().join('-');
                    var params = getBodyData(fields);
                    this.props.downloadReconciliationStatement(params);
                    break;
                case 4:
                    this.props.downloadHelpfulDoc(DocumentTypeId.value);
                    break;
            }
        });
    }

    onResize = () => {
        if (this.state.screenIsSmall != window.innerWidth < screenSizeMd) {
            var state = {...this.state.fields};
            for (var key in state) {
                if (['DocumentAppearanceTypeId', 'DeliveryTypeId', 'DocumentCriteria', 'Format'].indexOf(key) != -1) {
                    state[key].type = window.innerWidth < screenSizeMd ? "select" : "radio";
                }
            }
            this.setState({fields: state, screenIsSmall: window.innerWidth < screenSizeMd});
        }
    }

    render(){

        var {
            btnText,
            isValid,
            note
        } = this.state;

        return <div className="doc-widget doc-widget_adapt_default">
            <Form validate={true} onSubmit={this.onSubmit}>
                <div className="doc-widget__wrapper">
                    <div className="doc-widget__title">{this.context.translate("Заказ документов")}</div>
                    <div className="doc-widget__row">
                        {this.renderFields()}
                        {note ? <div className="doc-widget__item">
                            <div className="u-notice u-notice_info">{note}</div>
                        </div> : null}
                        {btnText ? <div className="doc-widget__item">
                            <button className={"u-btn u-btn_adapt-type_wide"+(isValid ? "" : " disabled")}>{btnText}</button>
                        </div> : null}
                    </div>
                </div>
            </Form>
        </div>
    }
}

export function DocumentOrderingOk({CommunicationId, WorkingDaysDeadline, Message, onClick, translate}) {
    return <div className="thanks-message">
        <div className="thanks-message__wrapper">
            <div className="thanks-message__img"><img src={thanks} /></div>
            <div className="thanks-message__title">{Message ? translate("Возникла ошибка") : translate("Спасибо, ваш запрос принят")}</div>
            {Message ? <div className="thanks-message__subtitle">
                {Message}
            </div> : <div className="thanks-message__subtitle">
                    {translate("Плановый срок обработки составляет")} {WorkingDaysDeadline} {getWord(WorkingDaysDeadline, translate('рабочий день'), translate('рабочих дня'), translate('рабочих дней'))}. <br/>
                    {translate("Вы можете отслеживать обработку запроса по")} <Link to={"/communications/"+CommunicationId} className="u-link">{translate("ссылке")}</Link>.
            </div>}
            <div className="thanks-message__button">
                <button className="btn" onClick={onClick}>ок</button>
            </div>
        </div>
    </div>;
}