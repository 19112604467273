import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import logo from "../../../../img/logo.svg";
import PropTypes from "prop-types";
import Footer from "./Footer";

export default class LoginLayout extends Component {
    constructor(props, context){
        super(props, context);
        props.footerSettings();
        props.getLanguages();
        props.getDictionary(context.currentLanguage.alias);
    }

    static contextTypes = {
        screenSize: PropTypes.string,
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render() {

        let {
            info = {},
            footerLinks,
            children,
            multilingual,
            setLanguage
        } = this.props;

        let isScreenLg = this.context.screenSize === 'lg';
        let isScreenSm = this.context.screenSize === 'sm';

        return <div>
            <header className="header" key="header">
                <div className="header__column"><Link className="logo" to="/"><img src={logo}/></Link></div>
            </header>

            {children}

            <Footer key={"footer"}
                    info={info}
                    footerLinks={footerLinks}
                    isGuest={true}
                    isScreenLg={isScreenLg}
                    multilingual={multilingual}
                    setLanguage={setLanguage}
            />

        </div>;
    }
}