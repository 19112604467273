import React, {Component} from "react";

export default class Number extends Component{

    arrowChange = (change) => {
        return () => {
            let {
                mask,
                min,
                max,
                value,
                attribute,
                onChange,
                saveField
            } = this.props;

            var newVal = parseInt(value.replace(/\D/gim, '')) + change;

            if (typeof min !== undefined && newVal < min) newVal = min;
            if (typeof max !== undefined && newVal > max) newVal = max;

            var val = mask ? mask(newVal) : newVal;

            onChange(attribute, val);
            saveField(attribute, newVal);
        }
    };

    onChange = (e) => {
        let {
            mask,
            min,
            max,
            attribute,
            onChange
        } = this.props;
        var newVal = e.target.value;
        if (typeof min !== undefined && newVal < min) newVal  = min;
        if (typeof max !== undefined && newVal > max) newVal  = max;
        var val = mask ? mask(newVal) : newVal;
        onChange && onChange(attribute, val);
    };

    render(){

        let {
            value,

            onBlur,
            onFocus,

            success,
            error
        } = this.props;

        return <div className={"form__input form__input_number"+(success ? ' success' : (error ? ' error' : ''))}>
            <input value={value}
                   type="text"
                   onChange={this.onChange}
                   onBlur={onBlur}
                   onFocus={onFocus}
            />
            <div className="input-decrease" onClick={this.arrowChange(-1)}/>
            <div className="input-increase" onClick={this.arrowChange(1)}/>
        </div>
    }
}