import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link} from "react-router-dom";
import error from "../../../img/inhtml/error.png";
import {clearError} from "../../actions/catalog";
import PropTypes from "prop-types";


class ErrorContainer extends Component {

    static contextTypes = {
        translate: PropTypes.func
    }

    componentDidMount(){
        this.props.clearError();
    }

    renderErrorText = () => {
        let {
            errorStatus
        } = this.props;

        switch (errorStatus) {
            case 403:
                return <p>{this.context.translate('Страница, которую вы запрашиваете, недоступна.')}</p>;
            case 500:
                return <p>{this.context.translate('Страница, которую вы запрашиваете, временно недоступна.')}<br/>
                    {this.context.translate('Попробуйте повторить запрос через некоторое время')}</p>;
            default:
                return <p>{this.context.translate('Страница, которую вы запрашиваете, не существует.')}</p>
        }
    }


    render() {



        return (
            <div className="wrapper error-page">
                <div className="page">
                    <div className="page__main">
                        <img src={error} width={210}/>
                        <h1 className="page__title">{this.context.translate('Ошибка сервера')}</h1>
                        {this.renderErrorText()}
                        <Link to={"/"} onClick={this.props.clearError} className="btn btn_orange">{this.context.translate('вернуться на главную страницу')}</Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        errorMessage: state.catalog && state.catalog.errorMessage,
        errorStatus: state.catalog && state.catalog.errorStatus
    }),
    (dispatch) => {
        return {
            clearError: () => dispatch(clearError())
        }
    }
)(ErrorContainer);