import React, { Component } from 'react';
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import pdf from '../../../../document/document.pdf'
import {phoneMask} from "../../../tools/mask"

export default class Footer extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            footerMenu: this.getMenuItems(props.isGuest)
        };

        this.year = (new Date()).getFullYear();
    }

    static contextTypes = {
        translate: PropTypes.func
    }

    getMenuItems = () => {
        let {
            isGuest,
            isScreenLg,
            footerLinks
        } = this.props;

        let pdfLink = pdf.replace(/\/{2,}/g,'/');

        var footerMenu = [];

        if (isGuest) {
            footerMenu.push(<div className="footer-menu" key="block-1">
                <div className="footer-menu__item">
                    <a className="footer-menu__link" href={footerLinks && footerLinks.private_policy_link}
                       target="_blank">
                        <span>{this.context.translate('Политика конфиденциальности')}</span>
                    </a>
                </div>
                <div className="footer-menu__item">
                    <a className="footer-menu__link" href={pdfLink}
                       target="_blank">
                        <span>{this.context.translate('Правила использования')}</span>
                    </a>
                </div>
            </div>)
        } else {
            footerMenu.push(<div className="footer-menu" key="block-1">
                <div className="footer-menu__item">
                    <a className="footer-menu__link" href={footerLinks && footerLinks.private_policy_link}
                       target="_blank">
                        <span>{this.context.translate('Политика конфиденциальности')}</span>
                    </a>
                </div>
                <div className="footer-menu__item">
                    <a className="footer-menu__link" href={pdfLink}
                       target="_blank">
                        <span>{this.context.translate('Правила использования')}</span>
                    </a>
                </div>
                <div className="footer-menu__item">
                    <Link to='/library' className={"footer-menu__link"} activeclassname={"active"}>
                        <span>{this.context.translate('Полезное')}</span>
                    </Link>
                </div>
            </div>)
        }

        if (isGuest && isScreenLg) footerMenu.push(this.copyright());

        if (!isGuest) {
            footerMenu.push(<div className="footer-menu" key="block-2">
                <div className="footer-menu__item" key='help'>
                    <Link to='/help' className={"footer-menu__link"} activeclassname={"active"}>
                        <span>{this.context.translate('Помощь')}</span>
                    </Link>
                </div>
                <div className="footer-menu__item">
                    <Link to='/contacts' className={"footer-menu__link"} activeclassname={"active"}>
                        <span>{this.context.translate('Контакты')}</span>
                    </Link>
                </div>
            </div>)
        }

        return footerMenu;
    }

    copyright = () => {
        return <div className="footer-info" key="copyright">
            <div className="copyright"><span>© <a href={this.props.footerLinks && this.props.footerLinks.copyright_link} target="_blank">{this.context.translate('ООО ЛК «Сименс Финанс»')}</a> {this.year}</span></div>
        </div>
    }

    componentWillReceiveProps(nextProps){
        if (this.props.isGuest != nextProps.isGuest) {
            this.setState({
                footerMenu: this.getMenuItems(nextProps.isGuest)
            })
        }
    }

    render() {

        let {
            info,
            isGuest,
            isScreenLg,
            multilingual,
            setLanguage
        } = this.props;

        return (<div className="container-wrap__bt">
            <footer className={"footer"+(isGuest ? " footer_auth" : "")}>
                <div className={"footer__row"+(isGuest ? " footer__row_flex" : "")}>
                    <div className={"footer__row_flex"+(isGuest ? " footer-menu_auth" : "")}>
                        {multilingual.languages && multilingual.languages.length > 1 ?
                            <LanguageList {...multilingual} setLanguage={setLanguage} key="language-list"/> : null}
                        {this.getMenuItems()}
                    </div>
                </div>
                {isGuest ? (
                    !isScreenLg ? this.copyright() : null )
                 : <div className="footer__row">
                    <div className="footer-info">
                        {this.copyright()}
                        <div>
                            <div className="b-phone">
                                <a target="_blank" href={info.LessorPhone ? "tel:"+(info.LessorPhone.replace(/\s/g, '')) : "#"}>{phoneMask(info.LessorPhone, 'def')}</a><span>{this.context.translate('по россии')}</span>
                            </div>
                        </div>
                    </div>
                </div>}
            </footer>
        </div>);
    }
}



function FooterMenu ({items}) {
    var links = [];
    {items.map((item, key) => {
        links.push(<div className="footer-menu__item" key={key}>
            <Link to={item.url} className={"footer-menu__link"} activeclassname={"active"}>
                <span>{item.label}</span>
            </Link>
        </div>);
    })}
    return links;
}

class LanguageList extends Component {

    constructor(props){
        super(props);
        this.state = {
            menuOpened: false
        }
    }

    componentDidMount(){
        document.addEventListener('click', this.windowClick);
    }

    componentWillUnmount(){
        document.removeEventListener('click', this.windowClick);
    }

    windowClick = () => {
        this.setState({menuOpened: false});
    };

    toggleMenu = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        var prevState = this.state.menuOpened;

        var event = document.createEvent("Event");
        event.initEvent("click", false, true);
        document.dispatchEvent(event);

        this.setState({menuOpened: !prevState});
    };

    render(){
        var {languages = [], currentLanguage, setLanguage} = this.props;

        return <div className={"choice-lang"+(this.state.menuOpened ? " active" : "")}>
            <div className="choice-lang__head" onClick={this.toggleMenu}>
                <img src={currentLanguage.img}/><span>{currentLanguage.label}</span>
            </div>

            {this.state.menuOpened ? <div className="choice-lang__dropdown">
                {languages.map((lang) => <div className="choice-lang__option"
                                              key={lang.alias}
                                              onClick={() => {setLanguage(lang)}}
                >
                    <img src={lang.img}/><span>{lang.label}</span>
                </div>)}
            </div> : null}
        </div>
    }
}
