import React, {Component} from "react";

export default class AutoComplete extends Component{
    constructor(props){
        super(props);

        var options = props.options && props.options.map((opt) => {return opt.label});
        this.state = {
            options,
            propOptions: options,
            opened: false
        }
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.options != this.props.options && nextProps.options) {
            var options = nextProps.options.map((opt) => {return opt.label});
            this.setState({
                options: options.filter((opt) => {
                    return opt.toLowerCase().indexOf(nextProps.value.toLowerCase()) !== -1;
                }),
                propOptions: options
            })
        }
        if (this.props.value != nextProps.value) {
            if (nextProps.getOptions) {
                if (nextProps.value) nextProps.getOptions(nextProps.optionsType,nextProps.value);
                else this.setState({options: []});
            }
            else this.setState({options: this.state.propOptions ? this.state.propOptions.filter((opt) => {
                return opt.toLowerCase().indexOf(nextProps.value.toLowerCase()) !== -1;
            }) : []})
        }
    }

    openList = (e) => {
        this.setState({opened: true});
    };

    componentWillMount(){
        let {
            value,
            optionsType,
            getOptions
        } = this.props;

        if (value && getOptions) {
            getOptions(optionsType, value);
        }
    }

    componentDidMount(){
        document.addEventListener('click', this.windowClick);
    }

    componentWillUnmount(){
        document.removeEventListener('click', this.windowClick);
    }

    windowClick = () => {
        this.setState({opened: false});
    };

    onSelect = (item) => {
        return () => {
            this.setState({
                options: this.state.propOptions && this.state.propOptions.filter((opt) => {
                    return opt.toLowerCase().indexOf(item.toLowerCase()) !== -1;
                })
            });
            this.props.onChange && this.props.onChange(this.props.attribute, item);
            this.props.saveField && this.props.saveField(this.props.attribute, item);
        }
    };

    onChange = (e) => {
        let {
            attribute,
            optionsType,
            onChange,
            getOptions,
            value
        } = this.props;

        var query = e.target.value;
        onChange && onChange(attribute, query);

        if (query) {
            if ((query.length == 1 || query.length < value.length) && getOptions) {
                getOptions && getOptions(optionsType, query);
            } else {
                this.setState({
                    options: this.state.propOptions && this.state.propOptions.filter((opt) => {
                        return opt.toLowerCase().indexOf(query.toLowerCase()) !== -1;
                    }),
                    query
                })
            }
        } else this.setState({options: getOptions ? [] : this.state.propOptions, query})

    };

    onBlur = () => {

        setTimeout(() => {
            var {
                attribute,
                value,
                saveField,
                onBlur
            } = this.props;

            saveField && saveField(attribute, value);
            onBlur && onBlur(attribute, value)
        }, 500)

    }

    render(){
        let {
            placeholder = "Не выбрано",
            value
        } = this.props;

        let {
            opened,
            options
        } = this.state;

        return <div className="select-styler">
            <div className={"jq-selectbox__select"+(opened ? " opened" : "")+(options && options.length ? "" : " no-pointer")} onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                var event = document.createEvent("Event");
                event.initEvent("click", false, true);
                document.dispatchEvent(event);
                this.openList();
            }}>
                <input type="text"
                       value={value}
                       placeholder={placeholder}
                       onChange={this.onChange}
                       onBlur={this.onBlur}
                       onFocus={this.openList}
                />
                {options && options.length ? <div className={"jq-selectbox__trigger"}>
                    <div className="jq-selectbox__trigger-arrow"/>
                </div> : null}
            </div>
            {opened && options && options.length ?
            <div className="jq-selectbox__dropdown">
                <ul>
                    {options.map(
                        (item, key) => <li key={key} onClick={this.onSelect(item)}
                                           className={value == item ? "sel selected" : ""}>
                        {item}
                    </li>)}
                </ul>
            </div> : null}
        </div>
    }
}