const initState = {isOk: false};

export function promotions(state = initState, action) {
    switch (action.type) {
        case "PROMOTIONS_LIST":
            return {...state, list: [], isOk: false};
        case "PROMOTIONS_LIST_SUCCESS":
            var pagination = action.data.pagination;
            var list = action.data;
            list.pagination = pagination;
            return {...state, list, isOk: true};
        case "PROMOTION_IMAGE_SUCCESS":
            var pagination = {...state.list}.pagination;
            var list = [...state.list].map((item) => {
                if (item.img_id == action.data.id) {
                    return {
                        ...item,
                        img: action.data.img
                    }
                }
                return item;
            });
            list.pagination = pagination;
            return {...state, list, isOk: true};
        case "TRANSLATE_PROMOTIONS_SUCCESS":
            var pagination = {...state.list}.pagination;
            let data = {};
            action.data.map((item) => {
                data[item.id] = item;
            })
            var list = [...state.list].map((item) => {
                if (data[item.id]) {
                    return {
                        ...item,
                        title: data[item.id].title,
                        description: data[item.id].description,
                        topic: data[item.id].topic,
                    }
                }
                return item;
            });
            list.pagination = pagination;
            return {...state, list, isOk: true};
        case "TOPICS_LIST_SUCCESS":
            return {...state, topics: action.data.map((item) => {
                var title = {};
                item.title.map((arr) => {
                    title[arr.lang] = arr.translation;
                });
                return {
                    ...item,
                    title
                }
            })};

        case "LOGOUT_SUCCESS":
        case "BLOCKED":
            return {...initState};
    }
    return state;
}