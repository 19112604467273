const initState = {};

export function companiesList(state = initState, action) {

    switch (action.type) {
        case "COMPANIES_LIST":
            return {list: []};
        case "COMPANIES_LIST_SUCCESS":
            return {list: action.data};
        case "LOGOUT_SUCCESS":
        case "BLOCKED":
            return {...initState};
    }

    return state;
}

export function companiesRequestForm(state = initState, action) {
    switch (action.type) {
        case "COMPANIES_DETAIL":
            return {detail: {}};
        case "COMPANIES_DETAIL_SUCCESS":
            return {detail: action.data};
        case "COMPANIES_REQUEST_CREATED":
            return {...initState};
        case "LOGOUT_SUCCESS":
        case "BLOCKED":
            return {...initState};
    }

    return state;
}