import React, {Component} from "react";
import { connect } from 'react-redux';
import {Link} from "react-router-dom";
import NotificationsList from "../../notifications/NotificationsList";

import PropTypes from "prop-types";
import {notificationsList, readNotification} from "../../../actions/notifications";
import {downloadHelpfulDocument} from "../../../actions/documents";

/**
 * Component for header notifications
 */
class ProfileNotifications extends Component {

    constructor(props){
        super(props);


        this.state = {
            active: false
        };
    }


    static contextTypes = {
        translate: PropTypes.func
    }

    onClick = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        var active = !this.state.active;
        var event = document.createEvent("Event");
        event.initEvent("click", false, true);
        document.dispatchEvent(event);
        this.setState({active}, () => {
            this.props.notifications.list.slice(0,4).map((item) => {
                if (item.IsNew) {
                    setTimeout(() => {this.props.markAsRead(item.NotificationId)}, 1000);
                }
            })
        });
    };

    componentWillMount() {
        this.props.getNotificationsList();
        this.notificationsInterval = setInterval(() => {this.props.getNotificationsList()}, 60000);
    }

    componentDidMount(){
        document.addEventListener('click', this.windowClick);
    }

    componentWillUnmount(){
        document.removeEventListener('click', this.windowClick);
        clearInterval(this.notificationsInterval);
    }

    windowClick = () => {
        this.setState({active: false});
    };

    render() {
        let {
            notifications,
            downloadHelpfulDoc
        } = this.props;

        let newNotificationsCount = notifications.newCount
        let notificationsCount = notifications.totalCount

        return notifications.isOk ? <div className="profile-notification active">
            <div className="profile-notification__head" onClick={this.onClick}>
                {newNotificationsCount ? <span className="profile-notification__num">{newNotificationsCount}</span> : null}
                {!this.state.active ? <div className="icon-appeal-txt">Уведомления</div> : null}
            </div>
            {this.state.active ? <div className="profile-notification__dropdown">
                <NotificationsList list={notifications.list.slice(0,4)} className="profile-notification__dropdown-inner" download={downloadHelpfulDoc}/>
                <Link className="profile-notification__more" to="/notifications">
                    <span>{this.context.translate('Показать все')}<sup>({notificationsCount})</sup></span>
                </Link>
            </div> : null}
        </div> : null;
    }
}

export default connect(state => ({
    notifications: state.notifications,
}), (dispatch) => {
    return {
        getNotificationsList: () => dispatch(notificationsList()),
        markAsRead: (id) => dispatch(readNotification(id)),
        downloadHelpfulDoc: (id) => dispatch(downloadHelpfulDocument(id)),
    }
})(ProfileNotifications)