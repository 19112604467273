import React, {Component} from "react";
import {Link} from "react-router-dom";
import FileIcon from "../common/FileIcon";

export default class DocumentsTable extends Component{

    constructor(props){
        super(props);
        this.labels = ['Название документа', 'Лизингополучатель', 'К чему относится', 'Кем загружен', 'Когда загружен', 'Файл'];

        this.state = {
            showMore: false
        }
    }

    toggleShowMore = () => {
        this.setState((prevState) => {
            return {
                showMore: !prevState.showMore
            }
        })
    }

    render(){
        var items = this.props.list;
        let {sort, setSort} = this.props;
        let showMore = this.state.showMore;

        return <div className="page__docs-table">
            <div className="default-table default-table_documents default-table_pp-documents js-demo">
                <div className="default-table__head">
                    <div className="default-table__row">
                        {this.labels.map((item, key) => <div className="default-table__th" key={key}>
                            {key === 4 ? <a href="#" onClick={setSort}>
                                {item}
                                <span className={'arrow '+(sort === 'DESC' ? 'down' : 'up')}>&#10141;</span>
                            </a>
                                : item}
                        </div>)}
                    </div>
                </div>
                <div className="default-table__body">
                    {items.map((item, key) => <div className="default-table__row" key={item.DocumentId}>
                        <div className="default-table__td">
                            <div className="default-table__inner-label">{this.labels[0]}</div>
                            <div className="default-table__value">{item.DocumentName}</div>
                        </div>

                        <div className={`default-table__td${showMore ? "" : " default-table__td_more-item"}`}>
                            <div className="default-table__inner-label">{this.labels[1]}</div>
                            <div className="default-table__value">{item.CompanyName}</div>
                        </div>
                        <div className={`default-table__td${showMore ? "" : " default-table__td_more-item"}`}>
                            <div className="default-table__inner-label">{this.labels[2]}</div>
                            <div className="default-table__value">
                                {item.EntityId ? <Link to={(item.EntityName == 'application' ? "/applications/view/" : "/projects/dfa/")+item.EntityId}>
                                    {item.EntityTitle}</Link> : null}
                            </div>
                        </div>
                        <div className="default-table__td">
                            <div className="default-table__inner-label">{this.labels[3]}</div>
                            <div className="default-table__value"><nobr>{item.Uploader}</nobr></div>
                        </div>
                        <div className="default-table__td default-table__td_align-right">
                            <div className="default-table__inner-label">
                                <a href="#" onClick={setSort}>
                                    {this.labels[4]}
                                    <span className={'arrow '+(sort === 'DESC' ? 'down' : 'up')}>&#10141;</span>
                                </a>
                            </div>
                            <div className="default-table__value">{item.UploadDt}</div>
                        </div>
                        <div
                            className={`default-table__td default-table__td_align-center default-table__td_mobile-inline${showMore ? "" : " default-table__td_more-item"}`}>
                            <div className="default-table__inner-label">Скачать файл</div>
                            <div className="default-table__value">
                                <a href="#" onClick={(e) => {e.preventDefault(); this.props.downloadDoc(item.FileId, item.DocumentName+'.'+item.Extension)}}>
                                    <FileIcon ext={item.Extension}/>
                                </a>
                            </div>
                        </div>
                        <div className="default-table__more-toggle" onClick={this.toggleShowMore}>
                            <span className="u-more-link"><span>{showMore ? 'Скрыть' : 'Подробнее'}</span></span>
                        </div>
                    </div>)}
                </div>
            </div>
            {this.props.children}
        </div>
    }
}