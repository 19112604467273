import {
    call,
    put,
    take
} from 'redux-saga/effects';

import {checkErrors, fetchApi, fetchClientApi} from "../tools/api-helper";
import {checkToken, refreshToken} from "./auth";

export function contactsList() {
    return {
        type: 'CONTACTS_LIST'
    }
}

export function* fetchContactsList(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/contacts", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function detailContact(id) {
    return {
        type: 'DETAIL_CONTACT',
        id
    }
}

export function* fetchDetailContact(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/contacts/"+action.id, {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}