import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import NewMessageForm from "../../components/communications/NewMessageForm";
import {Link} from "react-router-dom";
import {cancelFile, clearBuffer} from "../../actions/file-buffer";
import {
    communicationsAddMessage,
    communicationsAddFile,
    communicationsDeleteFile,
    communicationsUpdateFile,
    setRating,
    clearDraft,
    communicationsWidget,
    communicationsDownloadFile,
    communicationsDecline
} from "../../actions/communications";
import MessagesList from "../../components/communications/MessagesList";
import Rating from "../../components/communications/Rating";
import Decline from "../../components/communications/Decline";

class CommunicationDetailContainer extends Component{

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps(nextProps){

        if (Object.keys(this.props.detail).length && !Object.keys(nextProps.detail).length) {
            nextProps.history.push("/communications");
        }
    }

    componentWillMount(){
        this.props.getCommunicationsWidget();
    }

    componentWillUnmount(){
        if (Object.keys(this.props.detail).length) {
            this.props.clearDraft();
        }
    }

    setRating = (rating, comment) => {
        this.props.setRating(this.props.detail.CommunicationId, rating, comment);
    }

    decline = (comment) => {
        this.props.decline(this.props.detail.CommunicationId, comment);
    }

    render(){

        var {
            CommunicationId,
            TypeName,
            Number,
            CommunicationDate,
            RatingId,
            RatingValue,
            CanRate,
            CanComment,
            IsClosed,
            Messages,
            Header
        } = this.props.detail;

        return <div className="page page_projects page_request page_request-view">
            <div className="page__main page__main_projects">
                <div className="page__content-container">
                    <div className="page__back-link">
                        <Link className="u-back-link" to="/communications"><span>{this.context.translate('Обращения')}</span></Link>
                    </div>
                    <h1 className="page__title">{TypeName} №{Number} {this.context.translate('от')} {CommunicationDate}</h1>
                    <div className={IsClosed ? "page__request-view-reopen" : "page__request-view"}>
                        {RatingValue ? <Rating rating={RatingValue}
                                               ratingList={this.props.ratingList}
                                               className="page__request-rating"
                                               isBig={true}
                        /> : null}
                        <div className="communication-header">
                            {Header ? Header.map((item, key) => item.Header.indexOf('ID') === -1 ? <div key={key} className="communication-header__item">
                                <strong className="label">{item.Header}:</strong>
                                <span>&nbsp;{item.Header === 'Дата договора' ? item.Text.split('-').reverse().join('.') : item.Text}</span>
                            </div> : null) : null}
                        </div>
                        <MessagesList messages={Messages} downloadFile={this.props.downloadFile}/>
                        <div className="page__request-form">
                            {CanComment ? <NewMessageForm {...this.props} isFirst={false}/> : (
                                IsClosed ? null : <div className="page__request-evaluate">
                                    <Rating rating={RatingValue}
                                            ratingList={this.props.ratingList}
                                            setRating={this.setRating}
                                            className="page__request-evaluate"
                                            isBig={true}
                                            decline={this.decline}
                                            canComment={CanComment}
                                    />
                                    <p className="page__request-evaluate-info">
                                        {this.context.translate('Если вы считаете, что обращение не было рассмотрено и решено должным образом, то вы можете')}&nbsp;
                                        <Decline decline={this.decline} canComment={CanComment}/>.</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default connect(
    state => ({
        fileBuffer: state.fileBuffer,
        fileParams: state.catalog.fileParams,
        detail: state.communications.detail || {},
        files: state.communications.files || [],
        catalog: {communicationTypes: state.catalog.communicationTypes},
        ratingList: state.catalog.communicationRatings || []
    }),
    (dispatch) => {
        return {
            addDocument: (id, file) => dispatch(communicationsAddFile(id, file)),
            removeDocument: (id, fileId) => dispatch(communicationsDeleteFile(id, fileId)),
            updateDocument: (id, fileId, fileName) => dispatch(communicationsUpdateFile(id, fileId, fileName)),
            cancelDocument: (id) => dispatch(cancelFile(id)),
            clearBuffer: () => dispatch(clearBuffer()),
            send: (id, message) => dispatch(communicationsAddMessage(id, message)),
            setRating: (id, rating, comment) => dispatch(setRating(id, rating, comment)),
            clearDraft: () => dispatch(clearDraft()),
            getCommunicationsWidget: () => dispatch(communicationsWidget()),
            downloadFile: (id, name) => dispatch(communicationsDownloadFile(id, name)),
            decline: (id, message) => dispatch(communicationsDecline(id, message))
        }
    }
)(CommunicationDetailContainer);