import React, {Component} from "react";

import {Link} from "react-router-dom";
import Slider from "react-slick";
import {PromotionsSlideItem} from "../promotions/PromotionsListItem";
import PropTypes from "prop-types";

export default class PromotionsWidget extends Component{

    constructor(props){
        super(props);

        this.state = {
            slider: React.createRef()
        }

    }
    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render(){

        let {
            items
        } = this.props;

        let isItems = items && items.length;

        var slider = this.state.slider;

        var adaptiveHeight = !isItems || (window.innerWidth > 1279 && items.length <= 3) ||
            (window.innerWidth <= 1279 && window.innerWidth > 767 && items.length <= 2) ||
            (window.innerWidth <= 767 && items.length <= 1);

        var settings = {
            dots: true,
            dotsClass: 'main-carousel__dots',
            adaptiveHeight,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1279,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        fade: true
                    }
                }
            ]
        };

        return  isItems ? <div className="main-page__carousel">
                <div className="main-page__carousel-inner">
                    <div className="main-carousel main-carousel_adapt-default">
                        <div className="main-carousel__header">
                            <p className="main-carousel__title">{this.context.translate("Акции")}</p>
                            {!adaptiveHeight ? <div className="main-carousel__controls">
                                <div className="main-carousel__buttons">
                                    <button className="main-carousel__button main-carousel__button_prev" onClick={() => {slider.current.slickPrev()}}/>
                                    <button className="main-carousel__button main-carousel__button_next" onClick={() => {slider.current.slickNext()}}/>
                                </div>
                            </div> : null}
                        </div>
                        <div className="main-carousel__slides-holder">
                            <Slider className="main-carousel__slides" {...settings} ref={slider}>
                                {items.map((item, key) => <PromotionsSlideItem item={item} key={key} {...this.context}/>)}
                            </Slider>
                        </div>
                        <div className="main-carousel__show-all">
                            <Link to="/promotions" className="main-carousel__link u-all-link">
                                {this.context.translate("Показать все")}
                                <sup className="u-all-link__sup">({items.pagination && items.pagination.totalCount})</sup>
                            </Link>
                        </div>
                    </div>
                </div>
            </div> : null
    }
}