import React from 'react';

import preloader from "../../../img/elements/elem-preload.svg";
import PropTypes from "prop-types";

export default class Preloader extends React.Component{

    static contextTypes = {
        translate: PropTypes.func
    }

    render(){
        return (<div className="preloader">
            <div className="preloader__inner">
                <img className="splashscreen__indicator" src={preloader} alt=""/>
                <div>{this.context.translate('Загрузка...')}</div>
            </div>
        </div>);
    }

}
