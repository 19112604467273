import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import {activation} from "../../actions/auth";
import {companyAccept, companyReject} from "../../actions/companies";

import SetPasswordForm from "../../components/auth/SetPasswordForm";
import AcceptAgreementForm from "../../components/auth/AcceptAgreementForm";
import ConfirmEmailForm from "../../components/auth/ConfirmEmailForm";
import ConfirmPhoneForm from "../../components/auth/ConfirmPhoneForm";

class ActivationContainer extends Component {

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    sendAction = (action) => {
        return (password = null) => {
            this.props.activation(action, password);
        }
    }

    render() {

        let {
            is_temporary,
            is_active,
            is_email_confirmed,
            is_phone_confirmed,
            user_name,
            email,
            phone_number,
            sendRequest,
            activationErrors
        } = this.props.authState;

        let companies = this.props.companies.filter((item) => {return !item.IsConfirmed});

        var content = '';

        if (is_temporary) {
            content = <div className="temporary-password text-left">
                <h1 className="auth-ban__title">{user_name}, {this.context.translate('Вы зашли в систему по одноразовому паролю')}</h1>
                <p>{this.context.translate('Пожалуйста, задайте свой персональный пароль для входа в систему')}:</p>
                <SetPasswordForm onSubmit={this.sendAction('change-password')} sendRequest={sendRequest} activationErrors={activationErrors}/>
            </div>
        } else if (!is_active) {
            content = <div className="text-left">
                <h1 className="auth-ban__title">{user_name}, {this.context.translate('необходимо подтвердить согласие с условиями обработки персональных данных')}</h1>
                <AcceptAgreementForm onSubmit={this.sendAction('accept-agreement')} sendRequest={sendRequest}/>
            </div>
        } else if (!is_email_confirmed) {
            content = <div className="text-left">
                <h1 className="auth-ban__title">{user_name}, {this.context.translate('проверьте Ваш e-mail адрес')}</h1>
                <p>{this.context.translate('Ваш e-mail адрес в системе')}: <b>{email}</b></p>
                <ConfirmEmailForm onSubmit={this.sendAction('confirm-email')} sendRequest={sendRequest}/>
            </div>
        } else if (!is_phone_confirmed) {
            content = <div className="text-left">
                <h1 className="auth-ban__title">{user_name}, {this.context.translate('проверьте Ваш номер телефона')}</h1>
                <p>{this.context.translate('Ваш номер телефона в системе')}: <b>{phone_number}</b></p>
                <ConfirmPhoneForm onSubmit={this.sendAction('confirm-phone')} sendRequest={sendRequest}/>
            </div>
        } else if (companies.length) {
            content = <div className="text-left">
                <h1 className="auth-ban__title">{user_name}, {this.context.translate('Вам выдан доступ к компании')} {companies[0].CompanyName}</h1>
                <div className="form__buttons">
                    <button className="btn" onClick={() => {this.props.accept(companies[0].CompanyId)}}>{this.context.translate('Принять')}</button>
                    <button className="btn btn_clear_blue" onClick={() => {this.props.reject(companies[0].CompanyId)}}>{this.context.translate('Отклонить')}</button>
                </div>
            </div>
        }

        return (
            <div className="auth page activation">
                <div className="auth__inner auth_ban">
                    <div className="auth-ban">
                        {content}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        authState: state.auth,
        companies: state.catalog.companies || []
    }),
    (dispatch) => {
        return {
            activation: (action, password) => dispatch(activation(action, password)),
            accept: (id) => dispatch(companyAccept(id)),
            reject: (id) => dispatch(companyReject(id))
        }
    }
)(ActivationContainer);