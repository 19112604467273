import React, {Component} from "react";

import {Link} from "react-router-dom";
import LibraryListItem from "../library/LibraryListItem";

export default class LibraryWidget extends Component{

    render(){

        let {
            items,
            count,
            download
        } = this.props;

        return <div className="main-page__section main-page__section_articles">
            <p className="main-page__title">Полезное</p>
            <div className="articles articles_adapt-default articles_main-page">
                <div className="articles__list">
                    {items.map((item, key) => <LibraryListItem key={key} item={item} download={download}/>)}
                </div>
            </div>
            {count > 2 ? <div className="main-page__show-all">
                <Link className="u-all-link" to="/library">
                    Показать все
                    <sup className="u-all-link__sup">({count})</sup>
                </Link>
            </div> : null}
        </div>
    }
}