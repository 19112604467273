import React, {Component} from "react"
import {connect} from "react-redux";
import CompaniesListItem from "../../components/companies/CompaniesListItem";
import Preloader from "../../components/common/Preloader";
import {companiesList} from "../../actions/companies";
import PropTypes from "prop-types";

class CompaniesContainer extends Component{

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render(){
        var {
            list
        } = this.props;

        return <div className="page page_projects page_companies">
            <div className="page__main page__main_default">
                <div className="page__content-container">
                    <div className="page__title-section">
                        <h1 className="page__title">{this.context.translate('Компании')}</h1>
                    </div>
                    <div className="page__companies-list">
                        {list && list.length ? list.map((item) => <CompaniesListItem item={item} key={item.CompanyId}/>) : null}
                    </div>
                </div>
            </div>
        </div>
    }

}

export default connect(
    state => ({
        list: state.companiesList.list
    }),
    (dispatch) => {
        return {
            getCompaniesList: () => dispatch(companiesList())
        }
    }
)(CompaniesContainer)
