import React, { Component } from 'react';
import { connect } from 'react-redux';
import Home from "../../components/home/Home";

import {applicationsList} from "../../actions/applications";
import {promotionsList, translatePromotions} from "../../actions/promotions";
import {dealsList, downloadInvoice, downloadPaymentNotification} from "../../actions/deals";
import {library, libraryDownload} from "../../actions/library";
import {investPotential, autoComplete} from "../../actions/catalog";
import {documentsWidgetInfo, downloadHelpfulDocument, addDocumentsRequest, downloadReconciliationStatement} from "../../actions/documents";

class HomeContainer extends Component {

    render() {

        return (
            <Home {...this.props}/>
        )
    }
}

export default connect(
    state => ({
        catalog: state.catalog,
        hasPenalties: state.catalog.hasPenalties,
        investPotential: state.catalog.investPotential,
        applications: state.applications && state.applications.widget,
        promotions: state.promotions.list,
        deals: state.deals,
        library: state.library.data,
        documentsRequestResult: state.documents.requestResult,
        loading: state.documents.loading,
        documentsFormError: state.documents.documentsFormError,
        isOk: state.applications.widget && state.applications.widget.isOk && state.promotions.isOk && state.deals.overdue.isOk && state.deals.contracts.isOk  && state.deals.payments.isOk && state.library && state.library.isOk
    }),
    (dispatch) => {
        return {
            getInvestPotential: () => dispatch(investPotential()),
            applicationsList: () => dispatch(applicationsList('widget')),
            promotionsList: () => dispatch(promotionsList()),
            translatePromotions: () => dispatch(translatePromotions()),
            dealsList: (type) => dispatch(dealsList(type)),
            downloadInvoice: (id, data) => dispatch(downloadInvoice(id, data)),
            libraryList: () => dispatch(library('isHome=1')),
            downloadLibItem: (url, filename) => dispatch(libraryDownload(url, filename)),
            documentsWidgetInfo: () => dispatch(documentsWidgetInfo()),
            downloadHelpfulDoc: (id) => dispatch(downloadHelpfulDocument(id)),
            addDocumentRequest: (body) => dispatch(addDocumentsRequest(body)),
            downloadReconciliationStatement: (params) => dispatch(downloadReconciliationStatement(params)),
            getCatalog: (type, query) => dispatch(autoComplete(type, query)),
            paymentNotification: (requisites) => dispatch(downloadPaymentNotification(requisites))
        }
    }
)(HomeContainer);