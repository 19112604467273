import React, {Component} from "react";
import Modal from "../common/Modal";
import Form from "../common/form/Form";
import {Link} from "react-router-dom";
import Decline from "./Decline";
import PropTypes from "prop-types";

export default class Rating extends Component{
    
    constructor(props){
        super(props);
        this.state = {
            modal: false,
            comment: "",
            rating: "",
            ratingId: "",
            isNeedComment: 1
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps = (nextProps) => {
        if (this.props.rating != nextProps.rating && nextProps.rating) {
            this.setState({
                modal: false,
                comment: "",
                rating: "",
                ratingId: "",
                isNeedComment: 1
            });
        }
    }
    
    openModal = (ratingId) => {
        return () => {
            var {rating, ratingList} = this.props;

            if (!rating) {
                var ratingItem = ratingList.filter((item) => {
                    return item.RatingId == ratingId;
                });

                if (ratingItem.length) {
                    this.setState({
                        modal: true,
                        rating: ratingItem[0].Value,
                        isNeedComment: ratingItem[0].NeedComment,
                        ratingId
                    })
                }
            }
        }
    }

    closeModal = () => {
        this.setState({
            modal: false
        })
    }

    onChange = (e) => {
        this.setState({
            comment: e.target.value
        })
    }

    onSubmit = () => {
        var {
            comment,
            ratingId,
            isNeedComment
        } = this.state;

        if (ratingId && (!isNeedComment || comment)) {
            this.props.setRating(ratingId, comment);
        }
    }

    renderModal = () => {
        var {
            modal,
            comment,
            rating,
            ratingId,
            isNeedComment
        } = this.state;

        return modal ? <Modal classNameContent="modal modal_fullscreen" onClickBackground={this.closeModal}>
            <div className="request-evaluate-modal request-evaluate-modal_adapt-default">
                <p className="request-evaluate-modal__title">{this.context.translate('Оцените работу по обращению')}:</p>
                <div className="request-evaluate-modal__stars">
                    <div className="rating-star rating-star_big checked">
                        {this.renderStars(this.props.ratingList, rating)}
                    </div>
                </div>
                <Form className="request-evaluate-modal__form" validate={true} onSubmit={this.onSubmit}>
                    <div className="request-evaluate-modal__text">
                        <div className="textarea-input textarea-input_request-evaluate">
                            <label className="textarea-input__wrap">
                                <div className="textarea-input__label-text">{this.context.translate('Отзыв о работе по обращению')}</div>
                                <div className="textarea-input__input-wrap">
                                    <textarea className="textarea-input__input" type="text" placeholder={this.context.translate("Введите текст отзыва")} onChange={this.onChange} />
                                </div>
                            </label>
                        </div>
                    </div>
                    <button className={"u-btn request-evaluate-modal__form-submit"+(!ratingId || (isNeedComment && !comment) ? " disabled" : "")}
                            type="submit">{this.context.translate('Отправить')}</button>
                </Form>
                <p className="request-evaluate-modal__info">
                    {this.context.translate('Если вы считаете, что обращение не было рассмотрено и решено должным образом, то вы можете')}&nbsp;
                    <Decline decline={this.props.decline} canComment={this.props.canComment}/>.
                </p>
            </div>
        </Modal> : null;
    }

    renderStars = (ratingList, rating) => {
        var stars = [];
        var n = ratingList.length;
        for (var i = 0; i < n; i++){
            var val = ratingList[i].Value;
            stars.push(<span className={"rating-star__item value-"+val+(rating && val <= rating ? " checked" : "")}
                             onClick={this.openModal(ratingList[i].RatingId)}
                             key={'star-'+ratingList[i].RatingId}
            />)
        }
        return stars;
    }

    render(){
        var {
            className = "message__rating",
            rating,
            ratingList,
            isBig = false
        } = this.props;

        return <div className={className} onClick={(e) => {
            if (!rating) {
                e.preventDefault();
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation()
            }
        }}>
            <div className={className+"-title"}>{rating ? this.context.translate("Ваша оценка") : this.context.translate("Оцените работу по обращению")}:</div>
            <div className={className+"-stars"}>
                <div className={"rating-star"+(isBig ? " rating-star_big" : "")+(rating ? " checked" : "")}>
                    {this.renderStars(ratingList, rating)}
                </div>
            </div>
            {this.renderModal()}
        </div>
    }
}
