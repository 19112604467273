import {
    call,
    put,
    take
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors,
} from '../tools/api-helper';

import {checkToken} from "./auth";

export function notificationsList() {
    return {
        type: 'NOTIFICATIONS_LIST'
    }
}

export function* fetchNotificationsList(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/notifications", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (!error && (status === 200 || status === 401)) {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function readNotification(id = null) {
    return {
        type: "READ_NOTIFICATION",
        id
    }
}

export function* fetchReadNotification(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/notifications"+(action.id ? "/"+action.id : ""), {
                method: 'PUT',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, {id: action.id}, status));
        }

    } catch(e) {
        alert(e);
    }
}