const initState = {list: [], isOk: false};
import {parseErrors} from "../tools/api-helper";


export function documents(state = initState, action) {
    switch (action.type){
        case "DOCUMENTS_LIST":
            return {...state, list: [], isOk: false, isSearch: false, isSearching: false};
        case "DOCUMENTS_LIST_SEARCH":
            return {...state, isSearching: true};
        case "DOCUMENTS_LIST_SUCCESS":
            return {...state, list: action.data, isOk: true, isSearch: false};
        case "DOCUMENTS_LIST_SEARCH_SUCCESS":
            return {...state, list: action.data, isOk: true, isSearch: true, isSearching: false};
        case "DOCUMENTS_REQUEST":
            var newState = {...state};
            delete newState['requestResult'];
            return newState;
        case "DOCUMENTS_REQUEST_CREATED":
            return {...state, requestResult: action.data};
        case "DOCUMENTS_REQUEST_ERROR":
            return {...state, requestResult: {Message: action.data.message}};
        case "DOWNLOAD_RECONCILIATION_STATEMENT":
        case "DOWNLOAD_HELPFUL_DOCUMENT":
            return {...state, loading: true, documentsFormError: {}};
        case "DOWNLOAD_RECONCILIATION_STATEMENT_VALIDATION_ERROR":
            var documentsFormError = parseErrors(action.data);
            return {...state, documentsFormError};
        case "DOWNLOAD_RECONCILIATION_STATEMENT_SUCCESS":
        case "DOWNLOAD_HELPFUL_DOCUMENT_SUCCESS":
            return {...state, loading: false};
    }
    return state;
}