import React, { Component } from 'react';
import { connect } from 'react-redux';

import AddOrder from "../../components/orders/AddOrder";
import {addFile, deleteFile, clearBuffer, updateFile, cancelFile} from "../../actions/file-buffer";
import {autoComplete} from "../../actions/catalog";
import {
    newApplication,
    saveField,
    saveFields,
    applicationFormInfo,
    viewApplication,
    sendApplication,
    findCompany,
    clearApplicationForm,
    clearCompanyForm,
    setField
} from "../../actions/add-applications";

import {
    downloadFile
} from "../../actions/applications";

class AddOrderContainer extends Component {

    constructor(){
        super();
        this.state = {
            preventSubmit: false
        }
    }

    componentWillReceiveProps(nextProps) {
        //if application just created
        if (!this.props.match.params.id && nextProps.application.fields && nextProps.application.fields.ApplicationId) {
            if (this.props.location.state && this.props.location.state.from == 'recreate') {
                if (this.props.application && this.props.application.fields && this.props.application.fields.ApplicationId && nextProps.application && nextProps.application.fields && this.props.application.fields.ApplicationId != nextProps.application.fields.ApplicationId) {
                    nextProps.sendApplication(nextProps.application.fields.ApplicationId)
                }
            } else {
                // nextProps.history.push('/applications/update/' + nextProps.application.fields.ApplicationId);
                // this.props.viewApplication(nextProps.application.fields.ApplicationId);
            }
        }

        if (nextProps.applicationsDraft != this.props.applicationsDraft && nextProps.applicationsDraft.isOk && this.props.application.fields && this.props.application.fields.IsDraft) {
            var shouldGoBack = true;
            for (var i = 0; i < nextProps.applicationsDraft.length; i++) {
                if (nextProps.applicationsDraft[i].ApplicationId == nextProps.application.fields.ApplicationId) {
                    shouldGoBack = false;
                    break;
                }
            }
        }

        if (nextProps.applicationsInProgress != this.props.applicationsInProgress && nextProps.applicationsInProgress.isOk && (this.props.application.fields && !this.props.application.fields.IsDraft) && !(this.props.location.state && this.props.location.state.from == 'recreate')) {
            var shouldGoBack = true;
            for (var i = 0; i < nextProps.applicationsInProgress.length; i++) {
                if (nextProps.applicationsInProgress[i].ApplicationId == nextProps.application.fields.ApplicationId) {
                    shouldGoBack = false;
                    break;
                }
            }
            if (shouldGoBack) nextProps.history.push('/applications/progress');
        }

        if (nextProps.application.status == 'send') {
            nextProps.history.push('/applications/progress');
        }

        if (JSON.stringify(nextProps.application.errors) != JSON.stringify(this.props.application.errors)) {
            this.setState({preventSubmit: false});
        }
    }

    sendApplication = () => {
        if (this.state.preventSubmit) return false;

        this.setState({preventSubmit: true}, () => {
            this.props.sendApplication(this.props.application.fields.ApplicationId);
        });
    };

    addCompany = (fields) => {
        let {
            application,
            saveFields
        } = this.props;

        saveFields(application.fields.ApplicationId, fields, true);
    };


    render() {

        let {
            catalog,
            getCatalog,

            application,
            saveField,
            saveFields,
            setField,

            company,
            findCompany,

            fileBuffer,
            fileParams,

            addApplicationDocument,
            removeApplicationDocument,
            updateApplicationDocument,
            cancelApplicationDocument,
            clearBuffer,

            location,

            downloadApplicationFile,
            clearCompanyForm,
            documentTypes
        } = this.props;

        return (
            <AddOrder catalog={catalog} //catalog autocomplete values
                getCatalog={getCatalog} //autocomplete request

                {...application}
                IsRecreate={location.state && location.state.from == 'recreate'}
                comeFrom={location.state}

                saveField={saveField}
                saveFields={saveFields}
                sendApplication={this.sendApplication}
                setField={setField}

                company={company}
                findCompany={findCompany}
                addCompany={this.addCompany}
                clearCompanyForm={clearCompanyForm}

                fileBuffer={fileBuffer}
                fileParams={fileParams}

                addApplicationDocument={addApplicationDocument}
                removeApplicationDocument={removeApplicationDocument}
                updateApplicationDocument={updateApplicationDocument}
                cancelApplicationDocument={cancelApplicationDocument}
                clearBuffer={clearBuffer}

                downloadApplicationFile={downloadApplicationFile}
                documentTypes={documentTypes}
            />
        )
    }
}

export default connect(
    state => ({
        catalog: state.catalog,
        application: state.detailApplication,
        applicationsDraft: state.applications.draft,
        applicationsInProgress: state.applications.inProgress,
        applicationsArchive: state.applications.archive,
        documentTypes: state.applications.documentTypes,
        company: state.company,
        isReady: state.catalog.isOk,
        fileBuffer: state.fileBuffer,
        fileParams: state.catalog && state.catalog.fileParams
    }),
    (dispatch) => {
        return {
            getCatalog: (type, query) => dispatch(autoComplete(type, query)),
            newApplication: (id) => dispatch(newApplication(id)),
            saveField: (id, name, value) => dispatch(saveField(id, name, value)),
            saveFields: (id, fields, isCompany) => dispatch(saveFields(id, fields, isCompany)),
            formInfo: (id) => dispatch(applicationFormInfo(id)),
            viewApplication: (id) => dispatch(viewApplication(id)),
            sendApplication: (id) => dispatch(sendApplication(id)),
            findCompany: (inn) => dispatch(findCompany(inn)),
            addApplicationDocument: (id, file) => dispatch(addFile(id, file)),
            removeApplicationDocument: (id, fileId) => dispatch(deleteFile(id, fileId)),
            updateApplicationDocument: (id, fileId, fileName) => dispatch(updateFile(id, fileId, fileName)),
            cancelApplicationDocument: (id) => dispatch(cancelFile(id)),
            clearBuffer: () => dispatch(clearBuffer()),
            clearApplicationForm: () => dispatch(clearApplicationForm()),
            downloadApplicationFile: (id, name) => dispatch(downloadFile(id, name)),
            clearCompanyForm: () => dispatch(clearCompanyForm()),
            setField: (attr, val) => dispatch(setField(attr, val))
        }
    }
)(AddOrderContainer);