import React, { Component } from 'react';
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import FormField from "../common/form/FormField";
import Form from "../common/form/Form";
import {getWord} from "../../tools/tools";
import {numberMask, phoneMask} from "../../tools/mask";

export default class Auth extends Component {
    constructor(props, context){
        super(props, context);
        this.state = {
            time: 0,
            fieldsAuth: {
                phone_number: {
                    type: 'phone_number',
                    label: "Номер телефона",
                    name: "authPhone",
                    value: "",
                    required: true,
                    error: '',
                    mask: phoneMask,
                    ref: (input) => { this.phoneInput = input; }
                },
                password: {
                    type: 'password',
                    label: "Пароль",
                    name: "authPassword",
                    value: "",
                    required: true,
                    error: '',
                    ref: (input) => { this.passwordInput = input; }
                }
            },
            fieldsCodevalidate: {
                code: {
                    type: 'text',
                    label: "Введите код подтверждения, отправленный Вам в SMS:",
                    name: "authCode",
                    value: "",
                    required: true,
                    error: '',
                    mask: numberMask(4),
                    min: 4,
                    ref: (input) => { this.codeInput = input; }
                }
            },
            autoFilled: []
        };

    }

    componentDidMount(){
        this.phoneInput.addEventListener("transitionend", () => {
            var autoFilled = [...this.state.autoFilled];
            autoFilled.push('phone_number');
            this.setState({autoFilled});
        }, false);

        this.passwordInput.addEventListener("transitionend", () => {
            var autoFilled = [...this.state.autoFilled];
            autoFilled.push('password');
            this.setState({autoFilled});
        }, false);
    }

    componentWillReceiveProps(nextProps){
        if (this.props.authErrors != nextProps.authErrors) {
            var state = {...this.state};
            for (var attr in state.fieldsAuth) {
                state.fieldsAuth[attr].error = nextProps.authErrors[attr] || ''
            }
            this.setState(state);
        }
        if (this.props.codevalidateErrors != nextProps.codevalidateErrors) {
            var state = {...this.state};
            for (var attr in state.fieldsCodevalidate) {
                state.fieldsCodevalidate[attr].error = nextProps.codevalidateErrors[attr] || ''
            }
            this.setState(state);
        }
        if (this.props.triesMore != nextProps.triesMore) {
            clearInterval(this.timer);
            this.setState({time: 30}, () => {
                this.timer = setInterval(this.setTime, 1000);
            });
        }
        if (this.props.fields.code != nextProps.fields.code) {
            this.setState({fieldsCodevalidate: {code: {...this.state.fieldsCodevalidate.code, value: nextProps.fields.code}}});
        }
    }

    componentWillUnmount = () => {
        clearInterval(this.timer);
    }

    static contextTypes = {
        screenSize: PropTypes.string,
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    funcState = (prevState) => {
        if(this.state.time < 1) {
            clearInterval(this.timer);
            return {time: 0};
        }
        var timer = --prevState.time;
        if (timer < 10) timer = '0'+(timer.toString());
        return {time: timer};
    };

    setTime = () => {
        this.setState(this.funcState);
    };


    onChangeField = (name) => {
        return (attribute, value, error, selectionStart) => {
            var prevState = {...this.state[name]};
            if (prevState.hasOwnProperty(attribute)) {
                prevState[attribute].value = value;
                prevState[attribute].error = error;
                this.setState({
                    ...this.state,
                    [name]: prevState,
                    autoFilled: []
                }, () => {
                    if (attribute == 'phone_number' && selectionStart) {
                        this.phoneInput.selectionStart = this.phoneInput.selectionEnd;
                    }
                })
            }
        }
    };

    onCodeRequest = () => {
        var {
            phone_number,
            password
        } = this.state.fieldsAuth;

        this.codeRequestBtn.focus();

        this.props.onCodeRequest && this.props.onCodeRequest(phone_number.value, password.value);
    }

    onRepeatCodeRequest = (e) => {
        e.preventDefault();

        this.setState({time: 30}, () => {
            this.timer = setInterval(this.setTime, 1000);
            this.onCodeRequest();
        });
    }

    onCodeValidate = () => {
        this.props.onCodeValidate && this.props.onCodeValidate(this.state.fieldsAuth.phone_number.value, this.state.fieldsCodevalidate.code.value);
    }

    isValid = (name) => {
        if (this.state.autoFilled.length == 2) return true;
        var fields = {...this.state[name]};
        for (var attr in fields) {
            if (fields[attr].error || fields[attr].required && !fields[attr].value) return false;
        }
        return true;
    }

    changePassType = (passType) => {
        this.setState({
            fieldsAuth: {
                ...this.state.fieldsAuth,
                password: {
                    ...this.state.fieldsAuth.password,
                    type: passType
                }
            }
        })
    }

    renderFields = (fields, name) => {
        var result = [];

        for (var attribute in fields) {
            if (fields.hasOwnProperty(attribute)) {

                result.push(<div className="form__row" key={name+attribute}>
                    <FormField fieldConfig={{...fields[attribute], label: this.context.translate(fields[attribute].label)}}
                               attribute={attribute}
                               onChange={this.onChangeField(name)}
                               changePassType={this.changePassType}
                    />
                </div>)
            }
        }

        return result;
    };

    render() {

        let {
            fieldsAuth,
            fieldsCodevalidate,
            time
        } = this.state;

        let {
            step,
            triesMore,
            toCodeRequest,
            sendRequest
        } = this.props;

        return (<div className="auth__form">
            <Form className={"auth__form-slide"+(step == 2 ? " active" : "")}
                                validate={true}
                                onSubmit={this.onCodeValidate}
            >
                {this.renderFields(fieldsCodevalidate, 'fieldsCodevalidate')}
                {triesMore ? <div className="form__message">
                    <div className="form__message-fix">
                        <p dangerouslySetInnerHTML={{__html: this.context.translate("У вас осталось {n} {unit} ввести код, после чего аккаунт будет заблокирован.", {
                            n: '<span className="t-red t-bold">'+triesMore+'</span>',
                            unit: getWord(triesMore, 'попытка', 'попытки', 'попыток')
                        })}}/>
                        {time ? <p>{this.context.translate("Вы сможете запросить код повторно через {n} сек.", {
                            n: "00:"+time
                        })}</p> : null}
                    </div>
                    <a href="#" className={time == 0 ? "" : "disabled"}
                       onClick={this.onRepeatCodeRequest}>{this.context.translate("Запросить повторно")}</a>
                </div> : null}
                <div className="form__buttons">
                    <button className="btn btn_clear_blue" type="button" onClick={toCodeRequest}>{this.context.translate("Назад")}</button>
                    <button className={"btn btn_orange"+(this.isValid('fieldsCodevalidate') && !sendRequest ? "" : " disabled")} type="submit">{this.context.translate("Подтвердить")}</button>
                </div>
            </Form>
            <Form className={"auth__form-slide"+(step != 2 ? " active" : "")}
                            validate={true}
                            onSubmit={this.onCodeRequest}
            >
                {this.renderFields(fieldsAuth, 'fieldsAuth')}
                <p className="recovery-link">
                    <Link to="/recovery">{this.context.translate("Забыли пароль?")}</Link>
                </p>
                <div className="form__buttons" onClick={this.shownPass}>
                    <button type="submit"
                            className={"btn btn_orange"+(this.isValid('fieldsAuth') && !sendRequest ? "" : " disabled")}
                            ref={(button) => {this.codeRequestBtn = button;}}
                    >{this.context.translate("Войти")}</button>
                </div>
            </Form>
        </div>)
    }
}