import React, {Component} from "react";
import {phoneMask} from "../../tools/mask";
import Form from "../common/form/Form";
import FormField from "../common/form/FormField";
import Modal from "../common/Modal";
import {getBodyData} from "../../tools/form-helper";
import ok from "../../../img/notification_ready.svg";
import PropTypes from "prop-types";


export default class ProfileForm extends Component{

    constructor(props){
        super(props);

        this.phoneInput = React.createRef();

        this.state = {
            fields: {
                LastName: {
                    type: 'text',
                    label: "Фамилия",
                    value: props.LastName || "",
                    error: '',
                    required: true
                },
                FirstName: {
                    type: 'text',
                    label: "Имя",
                    value: props.FirstName || "",
                    error: '',
                    required: true
                },
                MiddleName: {
                    type: 'text',
                    label: "Отчество",
                    value: props.MiddleName || "",
                    error: ''
                },
                Position: {
                    type: 'text',
                    label: "Должность",
                    value: props.Position || "",
                    error: ''
                },
                Email: {
                    type: 'email',
                    label: "E-mail",
                    value: props.Email || "",
                    error: '',
                    required: true
                },
                Phone: {
                    type: 'text',
                    label: "Мобильный телефон",
                    value: props.Phone ? phoneMask(props.Phone) : "",
                    error: '',
                    mask: phoneMask,
                    ref: this.phoneInput,
                    required: true
                },
                Comment: {
                    type: 'textarea',
                    label: "",
                    value: "",
                    error: '',
                    required: true
                }
            },
            modal: null
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps(nextProps){
        if (nextProps.savingStatus != this.props.savingStatus && nextProps.savingStatus == 'success' && this.state.sendRequest) {
            this.setState({modal: 'success', sendRequest: false});
            setTimeout(this.closeModal, 3000);
        }
    }

    onChange = (attribute, value, error, selectionStart) => {
        var fields = {...this.state.fields};
        fields[attribute].value = value;
        fields[attribute].error = error;
        this.setState({fields}, () => {
            if (attribute == 'Phone' && selectionStart) {
                this.phoneInput.current.selectionStart = this.phoneInput.current.selectionEnd = selectionStart;
            }
        })
    }

    openModal = (type) => {
        this.setState({modal: type})
    }

    closeModal = () => {
        this.setState({modal: null})
    }

    renderFields = () => {
        var fields = {...this.state.fields};
        delete fields['Comment'];

        var renderedFields = [];

        for (var attr in fields) {
            renderedFields.push(<div className="profile-form__item" key={attr}>
                <FormField fieldConfig={{
                    ...fields[attr],
                    label: this.context.translate(fields[attr].label),
                    placeholder: this.context.translate(fields[attr].placeholder)
                }} attribute={attr} onChange={this.onChange}/>
            </div>)
        }

        return renderedFields;
    }

    renderModal = () => {
        switch (this.state.modal) {
            case 'request':
                return <Modal classNameContent="modal modal_visible" onClickBackground={this.closeModal}>
                    <Form className="form_modal" onSubmit={this.onSubmit} >
                        <h2 className="form__title">{this.context.translate('Запросить изменение данных')}</h2>
                        <div className="form__row">
                            <div className="form__field">
                                <div className="form__textarea">
                                    <textarea onChange={(e) => {this.onChange('Comment', e.target.value, '')}} placeholder={this.context.translate('Описание запрашиваемых изменений')}/>
                                </div>
                                {this.props.message ? <div className="form__text">
                                    <span className="error">{this.props.message}</span>
                                </div> : null}
                            </div>
                        </div>

                        <div className="form__buttons">
                            <button className={"btn"+(!!this.state.fields.Comment.value ? "" : " disabled")} type="submit">{this.context.translate('Запросить')}</button>
                        </div>
                    </Form>
                </Modal>;
            case 'success':
                return <Modal classNameContent="modal modal_visible modal-wrapper modal__xs text-center" onClickBackground={this.closeModal}>
                    <p><img src={ok} /></p>
                    <h2 className="form__title">{this.context.translate('Ваш запрос успешно отправлен')}</h2>
                </Modal>;
            default:
                return null;
        }
    }

    onSubmit = () => {
        var fields = {...this.state.fields};
        fields.Phone.value = fields.Phone.value.replace(/[^+0-9]/gim, '');
        var body = getBodyData(fields);
        this.setState({sendRequest: true});
        this.props.dataChangeRequest(body);
    }

    render(){
        return <Form validate={true}>
            <div className="profile-form__container">
                {this.renderFields()}
            </div>
            <div className="profile-form__item_btn">
                <button type="button" className="u-btn u-btn_adapt-type_wide" onClick={() => {this.openModal('request')}}>{this.context.translate('Запросить изменение данных')}</button>
            </div>
            {this.renderModal()}
        </Form>
    }
}