import React, {Component} from "react";
import Form from "../common/form/Form";
import FormField from "../common/form/FormField";
import DocumentLoader from "../common/DocumentLoader";
import FileIcon from "../common/FileIcon";
import {getFileSize} from "../../tools/tools";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

export default class InsuranceForm extends Component{

    constructor(props){
        super(props);

        this.state = {
            fields: {
                ContractId: {
                    type: 'select',
                    label: "Номер договора",
                    value: "",
                    options: props.catalog && props.catalog.projectContracts || [],
                    optionsType: 'projectContracts',
                    allowSearch: true,
                    allowEmpty: true,
                    required: true,
                    onBlur: this.onBlur
                },
                Date: {
                    type: 'date',
                    label: "Дата происшествия",
                    value: "",
                    required: true,
                    onBlur: this.onBlur,
                },
                Message: {
                    type: 'textarea',
                    label: "Пожалуйста, укажите дату происшествия и опишите, что произошло",
                    placeholder: "Введите текст",
                    value: "",
                    required: true
                }
            },
            modal: false,
            files: []
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps(nextProps, nextState){
        if (nextProps.catalog !== this.props.catalog) {
            var fields = {...this.state.fields};
            fields.ContractId.options = nextProps.catalog.projectContracts || [];
            this.setState({fields});
        }

        if (nextProps.success && !this.props.success) {
            this.setState({fields: {
                    ContractId: {
                        type: 'select',
                        label: "Номер договора",
                        value: "",
                        options: nextProps.catalog && nextProps.catalog.projectContracts || [],
                        optionsType: 'projectContracts',
                        allowSearch: true,
                        allowEmpty: true,
                        required: true,
                        onBlur: this.onBlur
                    },
                    Date: {
                        type: 'date',
                        label: "Дата происшествия",
                        value: "",
                        required: true,
                        onBlur: this.onBlur,
                    },
                    Message: {
                        type: 'textarea',
                        label: "Пожалуйста, укажите дату происшествия и опишите, что произошло",
                        placeholder: "Введите текст",
                        value: "",
                        required: true
                    }
                }, files: []}, () => {
                this.setState({success: true}, () => {
                    nextProps.clearForm()
                })
            });
        } else if (Object.keys(this.props.validation) !== Object.keys(nextProps.validation)) {
            const fields = {...this.state.fields};
            fields.ContractId.error = nextProps.validation.contract_id;
            fields.Date.error = nextProps.validation.date;
            fields.Message.error = nextProps.validation.message;
            this.setState({fields})
        }
    }

    renderField = (attribute) => {
        var fields = {...this.state.fields};

        return fields.hasOwnProperty(attribute) && !fields[attribute].hidden ?
            <FormField fieldConfig={{
                ...fields[attribute],
                label: this.context.translate(fields[attribute].label),
                placeholder: this.context.translate(fields[attribute].placeholder)
            }} attribute={attribute} onChange={this.onChange}/> : null;
    }

    onBlur = (attribute, value) => {
        if (!value) {
            var fields = {...this.state.fields};
            fields[attribute].error = 'Это поле необходимо заполнить.';
            this.setState({fields});
        }
    }

    onChange = (attribute, value, error) => {
        if (!value && !error) {
            error = 'Это поле необходимо заполнить.'
        }
        var fields = {...this.state.fields};
        fields[attribute].value = value;
        fields[attribute].error = error;
        this.setState({fields});
    }

    onSubmit = () => {
        if (this.isValid()) {
            const {Date, ContractId, Message} = this.state.fields;
            const files = [...this.state.files];

            let formData = new FormData();
            formData.append("date", Date.value)
            formData.append("contract_id", ContractId.value)
            formData.append("message", Message.value)

            files.forEach((file, key) => {
                formData.append(`files[${key}]`, file)
                formData.append(`fileNames[${key}]`, file.filename)
            })

            this.props.sendInsuranceCommunication(formData)
        }
    }

    toggleModal = (e) => {
        if (e) e.preventDefault();
        this.setState((prevState) => {
            return {modal: !prevState.modal}
        })
    }

    addDocument = (file) => {
        file.status = 'success';
        setTimeout(() => this.setState((prevState) => ({files: [...prevState.files, file]})), 100);
    }

    removeDocument = (id) => {
        this.setState((prevState) => ({files: [...prevState.files].filter((item) => {return item.ID != id})}))
    }

    updateDocument = (id, name) => {
        this.setState(
        (prevState) => ({
                files: [...prevState.files].map(
                    (item) => {
                        if (item.ID == id) {
                            item.filename = name;
                        }
                        return item;
                    }
                )
            })
        )
    }

    renderFiles = () => {
        var attaches = [];
        var files = [...this.state.files];

        files.map((file) => {
            attaches.push(
                <div className="attach attach_adapt-default" key={file.ID}>
                    <div className="attach__type"><FileIcon ext={file.name.substr(file.name.lastIndexOf('.')+1)}/></div>
                    <div className="attach__content">
                        <div className="attach__name">{file.filename || file.name}</div>
                        <div className="attach__size">{getFileSize(file.size)}</div>
                    </div>
                    <button className="attach__remove" onClick={() => {this.removeDocument(file.ID)}}/>
                </div>);
        });

        return attaches;
    }

    isValid = () => {
        var fields = {...this.state.fields};
        for (var attr in fields) {
            if (fields[attr].required && !fields[attr].value || fields[attr].error) {
                return false;
            }
        }
        return true;
    }

    render(){

        var {
            self,
            error,
            isSending
        } = this.props;

        return <div className={'insurance__form'} id={'insurance-form'}>
            <h3 className={'insurance__subtitle'}>Сообщить о страховом событии</h3>
            {self ? <div className='alert alert-info'>
                Если вы страховали предмет лизинга самостоятельно, в первую очередь обязательно уведомите вашу страховую компанию
            </div> : null}
            <Form validate className="page__request-add-form" onSubmit={this.onSubmit}>
                <div className="page__request-insurance-row">
                    <div className="page__request-add-type">
                        {this.renderField('ContractId')}
                    </div>
                    <div className="page__request-add-type">
                        {this.renderField('Date')}
                    </div>
                </div>
                <div className="page__request-add-text">
                    <div className="request-form request-form_adapt-default">
                        <div className="request-form__text">
                            {this.renderField('Message')}
                        </div>
                        <div className="request-form__attaches">
                            {this.renderFiles()}
                        </div>
                        <a className={"request-form__attach"} href="#" onClick={this.toggleModal}>{this.context.translate('Добавить файлы')}</a>
                        {error ? <div className="text-error">{error}</div> : null}
                        {this.state.success ? <div className="text-success">Обращение успешно отправлено</div> : null}
                        <button className={"u-btn request-form__submit"+(this.isValid() && !isSending ? "" : " disabled")} type="submit">{this.context.translate('Отправить')}</button>
                    </div>
                </div>
                {this.state.modal ? <DocumentLoader files={this.state.files}
                                                    accept={'.pdf, .jpg, .jpeg, .png'}
                                                    addDocument={this.addDocument}
                                                    removeDocument={this.removeDocument}
                                                    updateDocument={this.updateDocument}
                                                    cancelDocument={this.removeDocument}
                                                    close={this.toggleModal}
                /> : null}
            </Form>
        </div>
    }
}