import React,{Component} from "react";

/**
 * Tabs component
 * Properties:
 *  tabsMenu - array of strings (menu labels)
 *  tabsContent - array of content tabs
 */
export default class Tabs extends Component{

    constructor(props){
        super(props);
        this.state = {
            activeTab: props.activeTab || 0
        }
    }

    toTab = (number) => {
        return (e) => {
            e.preventDefault();
            this.setState({
                activeTab: number
            })
        }
    };

    render(){
        let activeTab = this.state.activeTab;

        let {
            tabsMenu,
            children
        } = this.props;

        return <div className="tabs">
            <div className="tabs__menu">
                <div className="tabs__menu-prev" onClick={this.toTab(activeTab - 1 < 0 ? tabsMenu.length - 1 : activeTab - 1)}/>
                <div className="tabs__menu-next" onClick={this.toTab(activeTab + 1 < tabsMenu.length ? activeTab + 1 : 0)}/>
                {tabsMenu.map((menuItem, key) => <a key={key}
                                                    href={"#"}
                                                    className={"tabs__menu-link"+(activeTab === key ? " active" : "")}
                                                    onClick={this.toTab(key)}
                >{menuItem}</a>)}
            </div>
            <div className="tabs__main">
                <div className="tabs__content" data-tab={activeTab}>
                    {Array.isArray(children) ? children.map((item, key) => <div key={key} className={"tab"+(key === activeTab ? " active" : "")}>
                        {item}
                    </div>) : children}
                </div>
            </div>
        </div>
    }

}