const initListState = {isOk: false};
const initDetailState = {isOk: false};

export function contactsList(state = initListState, action) {

    switch (action.type) {
        case "CONTACTS_LIST":
            return {isOk: false};
        case "CONTACTS_LIST_SUCCESS":
            return {list: action.data, isOk: true};
        case "LOGOUT_SUCCESS":
        case "BLOCKED":
            return {...initListState};
    }

    return state;
}

export function detailContact(state = initDetailState, action) {
    switch (action.type){
        case "DETAIL_CONTACT":
            return {isOk: false};
        case "DETAIL_CONTACT_SUCCESS":
            return {...action.data, isOk: true};
        case "LOGOUT_SUCCESS":
        case "BLOCKED":
            return {...initDetailState};
    }

    return state;
}