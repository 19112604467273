import React, {Component} from "react"
import {connect} from "react-redux";
import DocumentsList from "../../components/documents/DocumentsList";
import {documentsList, documentsSearchInfo, documentsWidgetInfo, downloadDocument, downloadHelpfulDocument, addDocumentsRequest, downloadReconciliationStatement} from "../../actions/documents";
import {autoComplete} from "../../actions/catalog";

class DocumentsContainer extends Component{

    render(){
        return <div className="page page_documents">
            <div className="page__main page__main_default">
               <DocumentsList {...this.props}/>
            </div>
        </div>
    }

}

export default connect(
    state => ({
        documentsList: state.documents.list,
        documentsRequestResult: state.documents.requestResult,
        loading: state.documents.loading,
        isOk: state.documents.isOk,
        isSearch: state.documents.isSearch,
        isSearching: state.documents.isSearching,
        catalog: state.catalog,
        documentsFormError: state.documents.documentsFormError
    }),
    (dispatch) => {
        return {
            getDocumentsList: (params, sort) => dispatch(documentsList(params, sort)),
            getSearchFormInfo: () => dispatch(documentsSearchInfo()),
            getWidgetFormInfo: () => dispatch(documentsWidgetInfo()),
            getCatalog: (type, query) => dispatch(autoComplete(type, query)),
            downloadDoc: (id, title) => dispatch(downloadDocument(id, title)),
            downloadHelpfulDoc: (id) => dispatch(downloadHelpfulDocument(id)),
            addDocumentRequest: (body) => dispatch(addDocumentsRequest(body)),
            downloadReconciliationStatement: (params) => dispatch(downloadReconciliationStatement(params))
        }
    }
)(DocumentsContainer)
