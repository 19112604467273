const initState = {isOk: false};

export function detailDocument(state = initState, action) {
    switch (action.type) {
        case 'DOCUMENT_VIEW':
            return {data: {}, isOk: false};
        case 'DOCUMENT_VIEW_SUCCESS':
            return {data: {...state.data, ...action.data}, isOk: true};
        case 'DOCUMENT_EQUIPMENTS_SUCCESS':
            return {data: {...state.data, Equipments: action.data}, isOk: true};

        case 'PROJECT_LIST_SUCCESS':

            if (!(state.data && state.data.ContractId)) return state;

            let projects = [...action.data.projects.active, ...action.data.projects.archive];
            for (var i = 0; i < projects.length; i++) {
                if (!!projects[i].Documents) {
                    for (var j = 0; j < projects[i].Documents.length; j++) {
                        if (state.data.ContractId == projects[i].Documents[j].ContractId) {
                            return state;
                        }
                    }
                } else {
                    return projects
                }
            }
            return {...state, data: {}};

        case 'LOGOUT_SUCCESS':
            return {...initState};
    }
    return state;
}