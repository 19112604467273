import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import {ConnectedRouter} from 'react-router-redux';
import App, {store, history} from './containers/App';
import "isomorphic-fetch";
import promise from 'es6-promise';
promise.polyfill();

render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);
