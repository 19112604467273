const initState = {overdue: {isOk:false}, contracts: {isOk: false}, payments: {isOk: false}};

export function deals(state = initState, action) {
    switch (action.type) {
        case 'DEALS_LIST_OVERDUE':
            var data = state.overdue ? [...state.overdue] : [];
            if (state.overdue && state.overdue.pagination) {
                data.pagination = state.overdue.pagination
            }
            data.isOk = false;
            return {...state, overdue: data};
        case 'DEALS_LIST_OVERDUE_SUCCESS':
            var data = action.data;
            data.isOk = true;
            return {...state, overdue: data};
        case 'DEALS_LIST_CONTRACTS':
            var data = state.contracts ? [...state.contracts] : [];
            if (state.contracts && state.contracts.pagination) {
                data.pagination = state.contracts.pagination
            }
            data.isOk = false;
            return {...state, contracts: data};
        case 'DEALS_LIST_CONTRACTS_SUCCESS':
            var data = action.data;
            data.isOk = true;
            return {...state, contracts: data};
        case 'DEALS_LIST_PAYMENTS':
            var data = state.payments ? [...state.payments] : [];
            if (state.payments && state.payments.pagination) {
                data.pagination = state.payments.pagination
            }
            data.isOk = false;
            return {...state, payments: data};
        case 'DEALS_LIST_PAYMENTS_SUCCESS':
            var data = action.data;
            data.isOk = true;
            return {...state, payments: data};

        case 'LOGOUT_SUCCESS':
            return {...initState};
    }
    return state;
}