import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {Switch, Route, Link, NavLink} from "react-router-dom";
import PropTypes from "prop-types";

import OrdersListDraft from "../../components/orders/OrdersListDraft";
import OrdersListProgress from "../../components/orders/OrdersListProgress";
import OrdersListArchive from "../../components/orders/OrdersListArchive";
import Preloader from "../../components/common/Preloader";

import {addFile, deleteFile, clearBuffer, updateFile, cancelFile} from "../../actions/file-buffer";
import {applicationsList, archiveSearchInfo, recallApplication, requestApplication, resumeApplication} from "../../actions/applications";
import {sendApplication} from "../../actions/add-applications";
import {autoComplete} from "../../actions/catalog";
import noApps from "../../../img/no_apps.svg";


class OrdersContainer extends Component {

    constructor(props){
        super(props);

        this.state = {
            fields: {},
            activeTab: 1
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillMount(){
        // this.props.applicationsList('draft');
        // this.props.applicationsList('in-progress');
        // this.props.applicationsList('archive');
        // this.props.archiveSearchInfo();
        if (this.props.applications.draft || this.props.applications.inProgress || this.props.applications.archive) {
            let tabsMenu = [];

            if (this.props.applications.draft.length) {
                tabsMenu.push({key: 'drafts', tab: 0, content: <span>{this.context.translate('Неотправленные')}<sup>({this.props.applications.draft.length})</sup></span>});
            }
            if (this.props.applications.inProgress.length) {
                tabsMenu.push({key: 'progress', tab: 1, content: <span>{this.context.translate('В работе')}<sup>({this.props.applications.inProgress.length})</sup></span>});
            }

            if (this.props.applications.archive.length || this.props.applications.archive.message) {
                tabsMenu.push({key: 'archive', tab: 2, content: <span>{this.context.translate('Архив')}<sup>({this.props.applications.archive.pagination && this.props.applications.archive.pagination.totalCount})</sup></span>});
            }

            this.setState({tabsMenu});
        }

        let hash = this.props.location && this.props.location.hash;

        if (this.props.applications.draft.length > 0 ) {
            this.setState({activeTab: 0});
        }

        if ( hash === "#drafts" ) {
            if (this.props.applications.draft.length > 0 && this.state.activeTab !== 0 ) {
                this.setState({
                    activeTab: 0
                });
            }
            this.removeHash();
        }

        if ( hash === "#progress" ) {
            if (this.state.activeTab !== 1) {
                this.setState({
                    activeTab: 1
                });
            }
            this.removeHash();
        }

        if ( hash === "#archive" ) {
            if (this.state.activeTab !== 2) {
                this.setState({
                    activeTab: 2
                });
            }
            this.removeHash();
        }
    }

    componentDidUpdate(prevProps){
        if (this.props.applications !== prevProps.applications) {
            if (this.props.applications.draft || this.props.applications.inProgress || this.props.applications.archive) {
                let tabsMenu = [];

                if (this.props.applications.draft.length) {
                    tabsMenu.push({
                        key: 'drafts',
                        tab: 0,
                        content:
                            <span>{this.context.translate('Неотправленные')}<sup>({this.props.applications.draft.length})</sup></span>
                    });
                    this.setState({activeTab: 0});
                }
                if (this.props.applications.inProgress.length) {
                    tabsMenu.push({
                        key: 'progress',
                        tab: 1,
                        content:
                            <span>{this.context.translate('В работе')}<sup>({this.props.applications.inProgress.length})</sup></span>
                    });
                    if (!this.props.applications.draft.length) {
                        this.setState({activeTab: 1});
                    }
                }

                if (this.props.applications.archive.length || this.props.applications.archive.message) {
                    tabsMenu.push({
                        key: 'archive',
                        tab: 2,
                        content:
                            <span>{this.context.translate('Архив')}<sup>({this.props.applications.archive.pagination && this.props.applications.archive.pagination.totalCount})</sup></span>
                    });
                    if (!this.props.applications.draft.length && !this.props.applications.inProgress.length) {
                        this.setState({activeTab: 2});
                    }
                }

                this.setState({tabsMenu});
            }
        }
    }

    removeHash = () => {
        window.history.replaceState(null, null, window.location.pathname);
    };

    archiveSearch = (params = null) => {
        this.props.applicationsList('archive', params);
    };

    searchFieldsSave = (fields) => {
        this.setState({fields});
    };

    toTab = (num) => {
        return (e) => {
            e.preventDefault();
            this.setState({activeTab: num});
        }
    };

    renderEmpty = () => {
        return <div className="page-message">
            <div className="page-message__main">
                <div className="page-message__img"><img src={noApps} /></div>
                <span className="page-message__title">{this.context.translate('У вас нет заявок')}</span>
                <div className="page-message__text">
                    <p>{this.context.translate('Пожалуйста, создайте новую заявку на лизинг и она автоматически появится на этой странице')}</p>
                </div>
                <div className="page-message__buttons">
                    <Link className="btn btn_orange hidden-tablet hidden-mobile" to="/applications/create">{this.context.translate('Отправить заявку')}</Link>
                    <Link className="btn btn_orange hidden-desktop" to="/applications/create">{this.context.translate('Создать заявку')}</Link>
                </div>
            </div>
        </div>
    };

    render() {

        let {
            applications,
            isOk,

            sendApplication,
            recallApplication,
            requestApplication,
            resumeApplication,

            catalog,
            getCatalog,

            fileBuffer,
            fileParams,

            addApplicationDocument,
            removeApplicationDocument,
            updateApplicationDocument,
            cancelApplicationDocument,
            clearBuffer
        } = this.props;

        return (
            <div className="page">
                <div className="page__main">
                    <h1 className="page__title">{this.context.translate('Заявки')}</h1>
                    {this.state.tabsMenu.length ? <Fragment>
                        <div className='tabs__menu'>
                            {this.state.tabsMenu.map((menuItem, key) =>
                                <Link key={key} to={"#"} className={"tabs__menu-link"+(this.state.activeTab === menuItem.tab ? " active" : "")} onClick={this.toTab(menuItem.tab)}>{menuItem.content}</Link>
                            )}
                        </div>
                        {this.state.activeTab === 0 ?
                            <OrdersListDraft list={applications.draft}
                                             sendApplication={sendApplication}

                                             fileBuffer={fileBuffer}
                                             fileParams={fileParams}

                                             addApplicationDocument={addApplicationDocument}
                                             removeApplicationDocument={removeApplicationDocument}
                                             updateApplicationDocument={updateApplicationDocument}
                                             cancelApplicationDocument={cancelApplicationDocument}
                                             clearBuffer={clearBuffer}
                                             documentTypes={applications.documentTypes}
                            /> : null
                        }

                        {this.state.activeTab === 1 ?
                            <OrdersListProgress list={applications.inProgress}
                                                recallApplication={recallApplication}
                                                requestApplication={requestApplication}
                                                isActionOk={applications.isActionOk}
                                                actionMessage={applications.actionMessage}

                                                fileBuffer={fileBuffer}
                                                fileParams={fileParams}

                                                addApplicationDocument={addApplicationDocument}
                                                removeApplicationDocument={removeApplicationDocument}
                                                updateApplicationDocument={updateApplicationDocument}
                                                cancelApplicationDocument={cancelApplicationDocument}
                                                clearBuffer={clearBuffer}
                                                documentTypes={applications.documentTypes}
                            /> : null
                        }

                        {this.state.activeTab === 2 ?
                            <OrdersListArchive list={applications.archive}
                                               sendApplication={sendApplication}
                                               resumeApplication={resumeApplication}
                                               isActionOk={applications.isActionOk}
                                               actionMessage={applications.actionMessage}

                                               catalog={catalog}
                                               getCatalog={getCatalog}

                                               search={this.archiveSearch}
                                               searchFields={this.state.fields}
                                               searchFieldsSave={this.searchFieldsSave}

                                               fileBuffer={fileBuffer}
                                               fileParams={fileParams}

                                               addApplicationDocument={addApplicationDocument}
                                               removeApplicationDocument={removeApplicationDocument}
                                               updateApplicationDocument={updateApplicationDocument}
                                               cancelApplicationDocument={cancelApplicationDocument}
                                               clearBuffer={clearBuffer}
                                               documentTypes={applications.documentTypes}
                            /> : null
                        }
                    </Fragment> : this.renderEmpty()}
                </div>
                {isOk ? null : <Preloader/>}
            </div>
        )
    }
}

export default connect(
    state => ({
        applications: state.applications,
        isOk: state.applications.draft.isOk && state.applications.inProgress.isOk && state.applications.archive.isOk,

        catalog: state.catalog,

        fileBuffer: state.fileBuffer,
        fileParams: state.catalog && state.catalog.fileParams
    }),
    (dispatch) => {
        return {
            applicationsList: (type, params) => dispatch(applicationsList(type, params)),
            sendApplication: (id) => dispatch(sendApplication(id)),
            recallApplication: (id, reason) => dispatch(recallApplication(id, reason)),
            requestApplication: (id, description) => dispatch(requestApplication(id, description)),
            resumeApplication: (id, description) => dispatch(resumeApplication(id, description)),

            archiveSearchInfo: () => dispatch(archiveSearchInfo()),
            getCatalog: (type, query) => dispatch(autoComplete(type, query)),

            addApplicationDocument: (id, file) => dispatch(addFile(id, file)),
            removeApplicationDocument: (id, fileId) => dispatch(deleteFile(id, fileId)),
            updateApplicationDocument: (id, fileId, fileName) => dispatch(updateFile(id, fileId, fileName)),
            cancelApplicationDocument: (id) => dispatch(cancelFile(id)),
            clearBuffer: () => dispatch(clearBuffer())
        }
    }
)(OrdersContainer);