import React, {Component} from "react";
import DealItem from "./DealItem";
import PropTypes from "prop-types";

export default class ProjectDealsList extends Component{

    constructor(props){
        super(props);
        this.state = {
            hidden: true
        }
        this.showDefault = 3;
    }
    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    toggleList = (e) => {
        e.preventDefault();
        this.setState((prevState) => {
            return {
                hidden: !prevState.hidden
            }
        })
    }

    render(){
        let items = this.props.items, shownItems;
        if (this.state.hidden && items && items.length > this.showDefault) shownItems = items.slice(0,this.showDefault);
        else shownItems = items;

        return <div className="project-detail__notice-list">
            <div className="notice-list notice-list_adapt-default notice-list_project-detail">
                <div className="notice-list__items">
                    {shownItems && shownItems.map((item, key) => <DealItem item={item} key={key} paymentNotification={this.props.paymentNotification} downloadInvoice={this.props.downloadInvoice}/>)}
                </div>
                {items && items.length > this.showDefault ? <div className="notice-list__ui">
                    <div className="notice-list__ui-item">
                        <a className="notice-list__more-btn u-more-btn" href="#" onClick={this.toggleList}>
                            {this.state.hidden ? <span>{this.context.translate('Показать все')}<sup>({items.length})</sup></span> : <span>{this.context.translate('Скрыть')}</span>}
                        </a>
                    </div>
                </div> : null}
            </div>
        </div>
    }
}