import React, { Component } from 'react';
import PropTypes from "prop-types";
import {getWord} from "../../../tools/tools";
import {isNumeric} from "../../../tools/mask";
import Select from "./Select";
import DateRange from "./DateRange";
import Radio from "./Radio";
import NumberSlide from "./NumberSlide";
import NumberRange from "./NumberRange";
import AutoComplete from "./AutoComplete";
import Number from "./Number";
import CheckBox from "./CheckBox";
import Date from "./Date";

export default class FormField extends Component {

    constructor(props){
        super(props);

        if (props.fieldConfig && props.fieldConfig.error) {
            this.state = {
                isValid: false,
                error: props.fieldConfig.error
            }
        } else {
            this.state = {
                isValid: props.fieldConfig.success ? true: null,
                error: null
            }
        }
    }

    static contextTypes = {
        validate: PropTypes.bool,
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps(nextProps){
        if (this.context.validate && this.state.error != nextProps.fieldConfig.error) {
            this.setState({
                isValid: nextProps.fieldConfig.error ? false : (nextProps.fieldConfig.value ? true : null),
                error: nextProps.fieldConfig.error
            })
        }
        if (this.context.validate && nextProps.fieldConfig.success) {
            if (nextProps.fieldConfig.success) {
                this.setState({
                    isValid: true,
                    error: null
                });
            } else {
                this.setState({
                    isValid: null,
                    error: nextProps.fieldConfig.error
                });
            }
        }
    }

    onChange = (e) => {
        let {
            attribute,
            fieldConfig,
            onChange
        } = this.props;
        var val = e.target.value, sel = null, diff =  0 - val.length;
        if (fieldConfig.mask) {
            sel = e.target.selectionStart;
            val = fieldConfig.mask(val);
            diff += val.length;

            while (!isNumeric(val[sel]) && sel < val.length) {
                sel++
            }
            if (diff > 0) {
                sel = val.indexOf(e.target.value[e.target.selectionStart], sel - diff);
            }
        }
        var error = this.getErrorMessage(fieldConfig, val, attribute);
        onChange && onChange(attribute, val, error, sel);
    };

    onBlur = (e) => {
        if (this.context.validate) {
            let {
                fieldConfig,
                attribute
            } = this.props;

            var error = this.getErrorMessage(fieldConfig, e.target.value, attribute);
            if (error || fieldConfig.error) {
                this.setState({
                    isValid: false,
                    error: error || fieldConfig.error,
                    focus: false
                })
            } else {
                this.setState({
                    isValid: (e.target.value ? true : null),
                    error: null,
                    focus: false
                });
            }
            //send to server on blur for text fields
            var val = e.target.value;
            if (fieldConfig.type == 'number' && this.props.saveField) val = val.replace(/\D/gim, '');

            if (attribute === 'AdvancePercent' && val !== '0' || attribute === 'ContractTerm') {
                val = val.replace(/^0+/, '');
                if (val < fieldConfig.min) val = fieldConfig.min;

                this.props.onChange && this.props.onChange(attribute, val, error);
                this.props.saveField && this.props.saveField(attribute, val);
            } else {
                this.props.onChange && this.props.onChange(attribute, val, error);
                this.props.saveField && this.props.saveField(attribute, val);
            }
        }

        if (!!this.props.attribute && this.props.attribute === 'SearchPage' && !!this.props.searchOnBlur) {
            this.props.searchOnBlur();
        }
    };

    onFocus = () => {
        if (this.context.validate) {
            this.setState({
                isValid: null,
                error: null,
                focus: true
            })
        }
    };

    getErrorMessage = (fieldConfig, value, attribute) => {
        if (fieldConfig.required && !value) return this.context.translate("Это поле необходимо заполнить.");

        if (attribute == 'email') {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(value) ? false : this.context.translate("Пожалуйста, введите корректный адрес электронной почты.");
        }

        var type = fieldConfig.type;
        switch (type) {
            case 'email':
                var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(value) ? false : this.context.translate("Пожалуйста, введите корректный адрес электронной почты.");
            case 'phone_number':
                var re = /^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/;
                return re.test(value) ? false : this.context.translate("Пожалуйста, введите корректный номер телефона.");
            default:
                return false;
        }
    };

    getFormInputClassName = (type) => {
        var className = "form__input";
        if (type === 'number' || type === 'number-slide') className += " form__input_number";
        if (type === 'textarea') className = "form__textarea";
        if (type === 'select' || type === 'auto-complete') className = "form__select";
        if (type === 'radio') className = "radio";
        if (type === 'labeled-text') className = "labeled-text";
        if (type === 'date') className = "form__input date__input";

        switch (this.state.isValid && !this.state.focus) {
            case true:
                className += " success";
                break;
            case false:
                className += " error";
                break;
        }
        return className;
    };

    getField = (fieldConfig,  attribute, selectedLabel = false, valueSelected = ()=> {}) => {
        let {
            onChange,
            saveField
        } = this.props;

        switch (fieldConfig.type) {
            case 'radio':
                return <div key={attribute} className={this.getFormInputClassName(fieldConfig.type)}>
                        <Radio {...fieldConfig} attribute={attribute} onChange={onChange} saveField={saveField}/>
                    </div>;
            case 'select':
                return <div key={attribute} className={this.getFormInputClassName(fieldConfig.type)}>
                        <Select {...fieldConfig} attribute={attribute} onChange={onChange} saveField={saveField} valueSelected={(e) => valueSelected(e)}/>
                    </div>;
            case 'date-range':
                return <div key={attribute} className={this.getFormInputClassName(fieldConfig.type)}>
                        <DateRange {...fieldConfig} attribute={attribute} onChange={onChange}/>
                    </div>;
            case 'date':
                return <div key={attribute} className={this.getFormInputClassName(fieldConfig.type)}>
                        <Date {...fieldConfig} attribute={attribute} onChange={onChange}/>
                    </div>;
            case 'number-slide':
                return <div  key={attribute} className={this.getFormInputClassName(fieldConfig.type)}>
                        <NumberSlide
                            {...fieldConfig}
                            attribute={attribute}
                            onChange={onChange}
                            saveField={saveField}
                            onBlur={this.onBlur}
                        />
                    </div>;
            case 'number':
                return <Number key={attribute}
                               {...this.props.fieldConfig}
                               attribute={attribute}
                               onChange={onChange}
                               saveField={saveField}
                               onBlur={this.onBlur}
                               onFocus={this.onFocus}
                        />;
            case 'number-range':
                return <div key={attribute} className={this.getFormInputClassName(fieldConfig.type)}>
                        <NumberRange {...this.props.fieldConfig}
                                     onBlur={this.onBlur}
                                     onFocus={this.onFocus}
                                     attribute={attribute} onChange={onChange}
                        />
                    </div>;
            case 'textarea':
                delete fieldConfig['mask'];
                delete fieldConfig['error'];
                delete fieldConfig['success'];
                delete fieldConfig['fieldClassName'];
                return <div key={attribute} className={this.getFormInputClassName(fieldConfig.type)}>
                        <textarea {...fieldConfig}
                                  onChange={this.onChange}
                                  onBlur={this.onBlur}
                                  onFocus={this.onFocus}
                        />

                        {attribute === 'ProjectsDescription' && !!fieldConfig.subTitle && selectedLabel === 'Акт сверки' ?
                            <span className="desctiption-comment" dangerouslySetInnerHTML={{__html: fieldConfig.subTitle}} />
                        : null}
                    </div>;
            case 'auto-complete':
                return <div key={attribute} className={this.getFormInputClassName(fieldConfig.type)}>
                        <AutoComplete {...fieldConfig}
                                  attribute={attribute}
                                  onChange={onChange}
                                   saveField={saveField}
                        />
                    </div>;
            case 'checkbox':
                return <div key={attribute} className={this.getFormInputClassName(fieldConfig.type)}>
                        <CheckBox {...fieldConfig}
                                  attribute={attribute}
                                  onChange={onChange}
                        />
                    </div>;
            case 'labeled-text':
                return <div className="labeled-text__text">{fieldConfig.value}</div>;
            default:
                delete fieldConfig['mask'];
                delete fieldConfig['error'];
                delete fieldConfig['success'];
                delete fieldConfig['fieldClassName'];
                return <div key={attribute} className={this.getFormInputClassName(fieldConfig.type)}>
                        {fieldConfig.name === 'authPassword' && fieldConfig.value !== '' ?
                            <div className={'icon-eye' + (fieldConfig.type === 'password' ? ' eye-hidden' : ' eye-alt')}
                            onClick={()=> this.props.changePassType(fieldConfig.type === 'password' ? 'text' : 'password')} /> : null}
                        <input {...fieldConfig}
                            onChange={this.onChange}
                            onBlur={this.onBlur}
                            onFocus={this.onFocus}
                        />
                    </div>;
        }
    }


    render() {
        var fieldConfig = {...this.props.fieldConfig};
        delete fieldConfig['mask'];
        var {
            attribute,
            className = "",
            labelOptions = {},
            selectedLabel,
            valueSelected
        } = this.props;
        var {
            isValid,
            focus,
            error
        } = this.state;

        var field = this.getField(fieldConfig, attribute, selectedLabel, valueSelected);

        return <div className={"form__field "+className+(fieldConfig.disabled ? " disabled" : "")+(fieldConfig.required ? " required-field" : "")}>
            {fieldConfig.label && fieldConfig.type != 'checkbox' ? <label>{fieldConfig.label}</label> : null}
            {field}
            {isValid || focus ? null : <span className="error">{error}</span>}
        </div>
    }
}