const initState = {errors: {}};

import {
    parseErrors
} from "../tools/api-helper";

export function detailApplication(state = initState, action) {
    switch (action.type) {
        case "SAVE_FIELD_CREATED":
        case "SAVE_FIELDS_CREATED":
        case "NEW_APPLICATION_CREATED":
            return {...state, fields: {...state.fields, ApplicationId: action.data.ApplicationId}, isNew: true};
        case "ADD_FILE_CREATED":
            return {...state, fields: {...state.fields, ApplicationId: action.data.id}, isNew: true};

        case "SAVE_FIELD":
            var errors = {...state.errors} || {};
            delete errors[action.fieldName];

            var success = {...state.success} || {};
            delete success[action.fieldName];

            return {...state,success,errors};

        case "SAVE_FIELDS":
        case "SEND_APPLICATION":
            return {...state,success: {},errors: {}, status: ''};

        case "SAVE_FIELD_SUCCESS":
            var errors = {...state.errors} || {};
            delete errors[action.data.fieldName];

            var success = {...state.success} || {};
            if (action.data.fieldValue) success[action.data.fieldName] = 'ok';

            return {...state, fields: {...state.fields, [action.data.fieldName]: action.data.fieldValue}, errors, success};
        case "SAVE_FIELD_VALIDATION_ERROR":
        case "SAVE_FIELDS_VALIDATION_ERROR":
            var errors = {...state.errors} || {};
            var success = {...state.success} || {};

            action.data.map((error) => {
                errors[error.field] = error.message;
                delete success[error.field];
            });

            return {...state, errors, success};
        case "SEND_APPLICATION_VALIDATION_ERROR":
            var errors = {...state.errors} || {};
            var success = {...state.success} || {};

            action.data.map((error) => {
                errors[error.field] = error.message;
                delete success[error.field];
            });

            return {...state, errors, success, status: 'send-error'};

        case "DETAIL_APPLICATION_SUCCESS":
            var success = {...state.success} || {};

            for (var key in action.data) {
                if (action.data[key]) success[key] = 'ok';
            }

            return {...state, fields: action.data, success, status: '', isOk: true};

        case "DETAIL_APPLICATION_BAD_REQUEST":
            return {...initState, status: 'error', message: action.data.message};

        case "SEND_APPLICATION_SUCCESS":
            return {...state, errors: {}, success: {}, status: 'send'};
        case "SAVE_FIELDS_SUCCESS":
            return {...state, fields: {...state.fields, LesseeId: null, ...action.data.fields}, errors: {}, success: {}};
        case "UPDATE_APPLICATION_SUCCESS":
            return {...state, tmpDocs: [], errors: {}, success: {}, status: 'send'};

        case 'DELETE_FILE_SUCCESS':
            if (state.fields && state.fields.ApplicationId == action.data.id) {
                var docs = [...state.fields.Documents];
                docs = docs.filter(function(doc){
                    return doc.ID != action.data.fileId;
                });
                return {...state, fields: {...state.fields, Documents: docs}};
            } else {
                return state;
            }
        case 'APPLICATION_DOCUMENTS_SUCCESS':
            if (state.fields && state.fields.ApplicationId == action.data.id) {
                var docs = action.data.docs;

                var tmpDocs = state.tmpDocs ? [...state.tmpDocs] : [];

                // if (!state.fields.IsDraft) {
                //     tmpDocs.push(action.data.file.ID);
                // }

                return {...state, fields: {...state.fields, Documents: docs}, tmpDocs};
            } else {
                return state;
            }
        case 'UPDATE_FILE_SUCCESS':
            if (state.fields && state.fields.ApplicationId == action.data.id) {
                var docs = [...state.fields.Documents];
                docs = docs.map((item) => {
                    if (item.ID == action.data.fileId) item.DocumentName = action.data.fileName;
                    return item;
                });
                return {...state, fields: {...state.fields, Documents: docs}};
            } else {
                return state;
            }

        case "FORM_INFO_APPLICATION":
            return {...state, isOk: false};

        case "FORM_INFO_APPLICATION_SUCCESS":
            return {...state, isOk: true};

        case "CLEAR_APPLICATION_FORM":
            let fields = state.fields ? (state.fields.ApplicationId ? {} : state.fields) : {};
            return {fields: {...fields, AdvancePercent: 0, ContractTerm: 6, LeasePaymentDay: 22, EquipmentCostCurrencyId: 2, IsDraft: 1}};
        case "SET_FIELD":
            return {fields: {...state.fields, [action.attribute]: action.value}};
        case "LOGOUT_SUCCESS":
        case "BLOCKED":
            return {...initState};
    }
    return state;
}