import {
    call,
    put,
    take
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors, fetchFileApi
} from '../tools/api-helper';

import {checkToken} from "./auth";

import fileDownload from "js-file-download";

import {clientApiUrl} from "../tools/api-helper";

export function dealsList(type, params = null) {
    return {
        dealType: type,
        type: 'DEALS_LIST_'+type.toUpperCase(),
        params
    }
}


export function* fetchDealsList(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, pagination, error } = yield call( () => {
            return fetchClientApi("/deals/"+action.dealType+(action.params ? '?'+action.params : ''), {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (pagination) data.pagination = pagination;
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function downloadInvoice(data) {
    return {
        type: 'DOWNLOAD_INVOICE',
        data
    }
}


export function* fetchDownloadInvoice(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchFileApi(clientApiUrl+"/deals/invoices?data="+action.data, localStorage.getItem('authToken'))
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status == 200) fileDownload(data, "invoice.pdf");
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function downloadPaymentNotification(data) {
    return {
        type: 'DOWNLOAD_PAYMENT_NOTIFICATION',
        data
    }
}


export function* fetchDownloadPaymentNotification(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchFileApi(
                clientApiUrl+"/deals/payment-notification",
                localStorage.getItem('authToken'),
                "POST",
                JSON.stringify(action.data),
                {
                    'Content-Type': 'application/json'
                }
            )
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status == 200) fileDownload(data, "payment-notification.pdf");
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}