import React, {Component} from 'react'
import {Link} from "react-router-dom";

export default class Advantages extends Component {
    render() {
        const advantages = [
            {icon: 'consult', title: 'Консультирование и поддержка по всем вопросам страхования и регулирования убытков'},
            {icon: 'technical-support', title: 'Минимальный закрытый перечень документов для регулирования убытков'},
            {icon: 'checklist', title: 'Возможность самостоятельно выбирать оценочную компанию при определении ущерба'},
            {icon: 'info', title: 'Минимальный перечень исключений из страхового покрытия'},
            {icon: 'fast-time', title: 'Транспорт возрастом до 5 лет ремонтируется у дилера без учета износа'},
            {icon: 'car-crush', title: 'Отсутствие суброгации к лизингополучателю'},
        ];

        return <div>
            <h3 className='insurance__subtitle'>Преимущества страхования через ЛК «Сименс Финанс»</h3>
            <div className={'advantages'}>
                {advantages.map((item) => <div key={item.icon} className={'advantage-item advantage-item__'+item.icon}>
                    {item.title}
                </div>)}
            </div>
            <div className={'advantages__bottom'}>
                Если вы хотите изменить страхование по текущему или новому проекту на "Страхование через Сименс Финанс" - <Link to={{pathname: '/communications/create', state: 'help'}}>обратитесь к нам</Link>.
            </div>
        </div>
    }
}