import React, {Component} from "react";
import PropTypes from "prop-types";

export default class LibraryListItem extends Component{

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    downloadFile = (e) => {
        e.preventDefault();
        let {
            item,
            download
        } = this.props;
        download(item.file.url, item.file.name);
    }

    render(){
        let item  = this.props.item;
        return item && item.file  ? <div className="articles__item" onClick={this.downloadFile}>
            <div className="articles__article">
                <div className="articles__article-img-wrap">
                    {item.img ? <img className="articles__article-img" src={item.img} /> : null}
                </div>
                <div className="articles__article-inner">
                    <p className="articles__article-heading">{item.title[this.context.currentLanguage.alias]}</p>
                    <p className="articles__article-content" dangerouslySetInnerHTML={{__html: item.description[this.context.currentLanguage.alias]}}/>
                    <p className="articles__article-info">{item.file.extension + ", " + item.file.size}</p>
                </div>
            </div>
        </div> : null
    }
}