const initState = {};

export function projects(state = initState, action) {
    switch (action.type) {
        case 'PROJECT_LIST':
            return {...state, isOk: false};
        case 'PROJECT_LIST_SUCCESS':
            return {...state, ...action.data, count: action.data.projects.active.length + action.data.projects.archive.length, isOk: true};
        case 'PROJECT_LIST_SEARCH':
            return {...state, isSearch: true};
        case 'PROJECT_LIST_SEARCH_SUCCESS':
            var totalContractsCount = 0;
            action.data.projects.map((item) => {
                if (!!item.Documents) {
                    totalContractsCount += item.Documents.length;
                }
            })
            return {...state, ...action.data, totalCount: action.data.projects.length, totalContractsCount, isSearch: false};
        case 'PROJECT_DOCUMENTS_SUCCESS':
            let data = {};

            if (Array.isArray(state.projects)) {
                data = {projects: [...state.projects]};
                for (var i = 0; i < data.projects.length; i++) {
                    if (action.data.projectId == data.projects[i].ProjectId) {
                        data.projects[i].Documents = [...action.data];
                        break;
                    }
                }
            } else {
                data = {projects: {...state.projects}};
                let inActive = false;
                if (data.projects.active) {
                    for (var i = 0; i < data.projects.active.length; i++) {
                        if (action.data.projectId == data.projects.active[i].ProjectId) {
                            data.projects.active[i].Documents = [...action.data];
                            inActive = true;
                            break;
                        }
                    }
                }
                if (!inActive) {
                    if (data.projects.archive) {
                        for (var i = 0; i < data.projects.archive.length; i++) {
                            if (action.data.projectId == data.projects.archive[i].ProjectId) {
                                data.projects.archive[i].Documents = [...action.data];
                                break;
                            }
                        }
                    }
                }
            }

            return {...state, ...data};
        case 'LOGOUT_SUCCESS':
            return {...initState};
    }
    return state;
}